import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { SecInstrumentState } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQConfigList: {
		...fluxListDefaults,
		actionNamespace: 'EQConfigList',
		entityName: 'EQConfig',
		fetchUriTemplate: 'EQConfig',
		orderBy: 'Id asc',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'TradeProfitLossCenter($select=CenterCode,CenterName)',
			'PryIssueWalkinPartnerSubClass($select=SubClassCode)',
			'FundPartnerSubClass($select=SubClassCode)',
			'EmployeeSchemePartnerSubClass($select=SubClassCode)',
			'InvestorSchemePartnerSubClass($select=SubClassCode)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
		},
	},

	EQExchangeTradeAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeAcctSubList',
		entityName: 'EQExchangeTradeAcct',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQExchangeTradeAccts',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoOrgType,CoShortCode,CoName)',
			'ClearingAcct($select=AcctNo,AcctTitle)',
			'ProfitLossAcct($select=AcctNo,AcctTitle)',
			'DealerBankAcct($select=AcctNo,AcctTitle)',
			'MakerBankAcct($select=AcctNo,AcctTitle)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQExchangeTradeRuleSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeRuleSubList',
		entityName: 'EQExchangeTradeRule',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQExchangeTradeRules',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},

	EQTradeFeeList: {
        ...fluxListDefaults,
		actionNamespace: 'EQTradeFeeList',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		orderBy: 'RefExchangeDef/UniqueCode asc, InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
			ExchangeId: NullEntityId,
			InstrType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},
	EQTradeFeePick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQTradeFeePick',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		orderBy: 'InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
		},
	},
	EQTradeFeeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQTradeFeeSubList',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQTradeFees',
		orderBy: 'InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},

	RefExchangeBoardPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefExchangeBoardPick',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefExchangeBoards',
		orderBy: 'FIXMarketId asc, FIXMarketSegmentId asc, BoardCode asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['BoardCode','BoardName','FIXMarketSegmentId','Description'],
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefExchangeBoardSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeBoardSubList',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeBoards',
		orderBy: 'FIXMarketId asc, FIXMarketSegmentId asc, BoardCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['BoardCode','BoardName','FIXMarketSegmentId','Description'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefExchangeDefList: {
        ...fluxListDefaults,
		actionNamespace: 'RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		orderBy: 'UniqueCode asc',
		selectFields: ['Id', 'UniqueCode','Country','Location','CommonName','IsOTC','IsStock','IsBond','IsCommodity','IsFutures','IsCurrency','ResearchReporting','DealerOperating','MktMakerOperating'],
		expandProps: [],
		searchFields: ['UniqueCode','Country','Location','CommonName'],
		filterValues: {
			searchText: '',
			country: NullEntityId,
			exchangeType: EnumAllOptionValue,
			exchangeOperations: 'ResearchReporting',
		},
	},
	RefExchangeDefPick: {
        ...fluxPickDefaults,
		actionNamespace: 'RefExchangeDefPick',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		orderBy: 'UniqueCode asc',
		selectFields: ['Id', 'UniqueCode','Country','Location','CommonName','IsOTC','IsStock','IsBond','IsCommodity','IsFutures','IsCurrency','ResearchReporting','DealerOperating','MktMakerOperating'],
		expandProps: [],
		searchFields: ['UniqueCode','Country','Location','CommonName'],
		filterValues: {
			searchText: '',
		},
	},

	RefExchangeUserSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeUserSubList',
		entityName: 'RefExchangeUser',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeUsers',
		orderBy: 'ExchUserName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['ExchUserName','ExchUserRoles'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefSectorDefPick: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefSectorDefs',
		orderBy: 'SectorCode asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['SectorCode','SectorName'],
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefSectorDefSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefSectorDefs',
		orderBy: 'SectorCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['SectorCode','SectorName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	BondView: {
		...fluxViewDefaults,
		actionNamespace: 'BondView',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		selectFields: [],
		expandProps: [
			'ListExchange($select=UniqueCode,CommonCode,CommonName)',
			'ListBoard($select=BoardCode,BoardName)',
			'ListSector($select=SectorCode,SectorName)',
			'ResearchSector($select=SectorCode,SectorName)',
			'TradeExchange($select=UniqueCode,CommonCode,CommonName)',
			'TradeBoard($select=BoardCode,BoardName)',
		],
	},

	EQTradeFeeView: {
		...fluxViewDefaults,
		actionNamespace: 'EQTradeFeeView',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		selectFields: [],
		expandProps: [
			'RefExchangeDef($select=UniqueCode,CommonCode,CommonName)',
			'EQTradeFeeValues($orderby=StartDate desc,TradeType asc)',
			'EQTradeFeeAccts($orderby=StartDate desc;'
			+ '$expand=OrgCompany($select=CoShortCode,CoName)'
			+ ',IndvAcct($select=AcctNo,AcctTitle),CorpAcct($select=AcctNo,AcctTitle)'
			+ ',DealerAcct($select=AcctNo,AcctTitle),MakerAcct($select=AcctNo,AcctTitle))'
		],
	},

	EquityView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityView',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		selectFields: [],
		expandProps: [],
	},

	RefExchangeDefView: {
		...fluxViewDefaults,
		actionNamespace: 'RefExchangeDefView',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		selectFields: [],
		expandProps: [
			'MasterRecord($expand=PrimaryAddress,PartnerLocations($expand=PrimaryAddress))',
			'DealerCompany($select=CoOrgType,CoShortCode,CoName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
