import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxViewDefaults, fluxListDefaults, fluxPickDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	DocumentTranGroupItemList: {
		...fluxListDefaults,
		actionNamespace: 'DocumentTranGroupItemList',
		entityName: 'DocumentTranGroupItem',
		fetchUriTemplate: 'DocumentTranGroupItem',
		orderBy: 'DocumentTranGroup/doctrangroupcode asc',
		selectFields: ['Id', 'RequireTypeCode', 'TypeClass', 'IssuerType'],
		expandProps: ['DocumentTranGroup($select=DocTranGroupCode,TranEntityType,CheckType,ClosureType)',
			'DocumentType($select=TypeCode,TypeName,IsKnowYourPartner)'],
		searchFields: ['DocumentTranGroup/doctrangroupcode', 'DocumentType/TypeCode', 'DocumentType/TypeName'],
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},

	DocumentTranGroupList: {
		...fluxListDefaults,
		actionNamespace: 'DocumentTranGroupList',
		entityName: 'DocumentTranGroup',
		fetchUriTemplate: 'DocumentTranGroup',
		orderBy: 'doctrangroupcode asc',
		selectFields: ['Id', 'DocTranGroupCode', 'TranEntityType', 'CheckType', 'ClosureType', 'ClosureDate'],
		expandProps: [],
		searchFields: ['doctrangroupcode', 'tranentitytype', 'checktype'],
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},
	DocumentTranGroupPick: {
		...fluxPickDefaults,
		actionNamespace: 'DocumentTranGroupPick',
		entityName: 'DocumentTranGroup',
		fetchUriTemplate: 'DocumentTranGroup',
		orderBy: 'doctrangroupcode asc',
		selectFields: ['Id', 'DocTranGroupCode', 'TranEntityType', 'CheckType', 'ClosureType', 'ClosureDate'],
		expandProps: [],
		searchFields: ['doctrangroupcode', 'tranentitytype', 'checktype'],
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},

	DocumentTypeList: {
		...fluxListDefaults,
		actionNamespace: 'DocumentTypeList',
		entityName: 'DocumentType',
		fetchUriTemplate: 'DocumentType',
		exportUriTemplate: 'DocumentType/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			typeClass: EnumAllOptionValue,
		},
	},
	DocumentTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'DocumentTypePick',
		entityName: 'DocumentType',
		fetchUriTemplate: 'DocumentType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	MetaReportList: {
		...fluxListDefaults,
		actionNamespace: 'MetaReportList',
		entityName: 'metaReport',
		fetchUriTemplate: 'metaReport',
		orderBy: 'Code asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Code', 'Description', 'FilePath'],
		filterValues: {
			searchText: '',
			isLayoutLoaded: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	DocumentTranGroupView: {
		...fluxViewDefaults,
		actionNamespace: 'DocumentTranGroupView',
		entityName: 'DocumentTranGroup',
		fetchUriTemplate: 'DocumentTranGroup',
		selectFields: [],
		expandProps: [
            'DocumentTranGroupItems($expand=DocumentType($select=TypeCode,TypeName))'
        ],
	},

	DocumentTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'DocumentTypeView',
		entityName: 'DocumentType',
		fetchUriTemplate: 'DocumentType',
		selectFields: [],
		expandProps: [],
	},

	MetaReportView: {
		...fluxViewDefaults,
		actionNamespace: 'MetaReportView',
		entityName: 'metaReport',
		fetchUriTemplate: 'metaReport',
		exportUriTemplate: 'metaReport(${entityId})/ExportReportTemplate',
		selectFields: [],
		expandProps: ['metaReportEmbeds($expand=SubReport)'],
	},
};

let stViewNames: string[] = null;

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
