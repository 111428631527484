import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPxWorkflowHistorySubListDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FundLiquidationList: {
		...fluxListDefaults,
		actionNamespace: 'FundLiquidationList',
		entityName: 'FundLiquidation',
		fetchUriTemplate: 'FundLiquidation/GetLiquidations(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},IsFullLiquidation=${isFullLiquidation},MemberStatus=${enumParamMemberStatus},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType},Searchtext=${searchText})',
		exportUriTemplate: 'FundLiquidation/ExportLiquidations',
		printUriTemplate: 'FundLiquidation/PRINT()',
		orderBy: 'RequestDate desc, Fund/InstrumentCode asc, Subscriber/Partner/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Subscriber($select=SubscriberCode,ClosureType;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel,ClosureType),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			isFullLiquidation: null,
			enumParamMemberStatus: EnumAllOptionValue,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	FundLiquidationDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundLiquidationDocumentSubList',
		fetchUriTemplate: 'FundLiquidation(${subListParentId})/Documents',
		exportUriTemplate: 'FundLiquidation(${subListParentId})/Documents(${entityId})/Export',
	},

	FundLiquidationWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FundLiquidationWorkflowHistorySubList',
		fetchUriTemplate: 'FundLiquidation(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FundLiquidation(${subListParentId})/ExportWorkflowHistory',
	},

	FundLiquidationDistributionList: {
		...fluxListDefaults,
		actionNamespace: 'FundLiquidationDistributionList',
		entityName: 'FundLiquidationDistribution',
		fetchUriTemplate: 'FundLiquidationDistribution/GetFundLiquidationDistributions(StartDate=${startDate},EndDate=${endDate},FundId=${fundId},Searchtext=${searchText})',
		exportUriTemplate: 'FundLiquidationDistribution/ExportFundLiquidationDistributions',
		printUriTemplate: 'FundLiquidationDistribution/PRINT()',
		orderBy: 'Liquidation/RequestDate desc, Liquidation/Fund/InstrumentCode asc, Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Liquidation($select=RequestDate,ProcessDate;$expand=Fund($select=InstrumentCode,InstrumentName),' 
				+ 'Subscriber($select=SubscriberCode;$expand=Partner($select=PartnerNo,PartnerLabel)))',
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
	FundLiquidationDistributionSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundLiquidationDistributionSubList',
		entityName: 'FundLiquidationDistribution',
		fetchUriTemplate: 'FundLiquidation(${subListParentId})/Distributions',
		exportUriTemplate: 'FundLiquidation(${subListParentId})/ExportLiquidationDistribution',
		printUriTemplate: 'FundLiquidationDistribution/PRINT()',
		orderBy: 'Profile/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Profile($select=DefDistribution;$expand=Customer($select=PartnerNo,PartnerLabel))',
		],
		searchFields: ['Profile/Customer/PartnerNo', 'Profile/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundLiquidationView: {
		...fluxViewDefaults,
		actionNamespace: 'FundLiquidationView',
		entityName: 'FundLiquidation',
		fetchUriTemplate: 'FundLiquidation',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Subscriber($select=SubscriberCode;'
				+ '$expand=SponsorAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'SelfAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle),'
				+ 'Partner($select=PartnerNo,PartnerLabel),'
				+ 'Fund($select=InstrumentCode,InstrumentName,Currency))',
				
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
