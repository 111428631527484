import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today } from 'app-model/ModelConstants';
import { HRClosure } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerBankAcctSubListDefaults, fluxDocumentSubListDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults, fluxPartnerGlAcctSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRStaffList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffList',
		entityName: 'HRStaff',
		fetchUriTemplate: 'HRStaff',
		exportUriTemplate: 'HRStaff/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: '1900-01-01',
			endDate: '2099-01-01',
			companyId: NullEntityId,
			locationId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	HRStaffPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRStaffPick',
		entityName: 'HRStaff',
		fetchUriTemplate: 'HRStaff',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},


	HRStaffBankAcctSubList: {
		...fluxPartnerBankAcctSubListDefaults,
		actionNamespace: 'HRStaffBankAcctSubList',
		cmdUriTemplate: 'HRStaff(${subListParentId})/BankAccts',
		fetchUriTemplate: 'HRStaff(${subListParentId})/BankAccts',
		exportUriTemplate: 'HRStaff(${subListParentId})/BankAccts/Export',
	},

	HRStaffDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRStaffDocumentSubList',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Documents',
		exportUriTemplate: 'HRStaff(${subListParentId})/Documents(${entityId})/Export',
	},

	HRStaffGlAcctSubList: {
		...fluxPartnerGlAcctSubListDefaults,
		actionNamespace: 'HRStaffGlAcctSubList',
		fetchUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
		exportUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
	},

	HRStaffLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'HRStaffLocationSubList',
		cmdUriTemplate: 'HRStaff(${subListParentId})/Locations',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Locations',
		exportUriTemplate: 'HRStaff(${subListParentId})/Locations/Export',
	},

	HRStaffRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'HRStaffRelationshipSubList',
		cmdUriTemplate: 'HRStaff(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'HRStaff(${subListParentId})/RelatedParties',
		exportUriTemplate: 'HRStaff(${subListParentId})/RelatedParties/Export',
	},


	HRStaffEducationSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffEducationSubList',
		entityName: 'HRStaffEducation',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Education',
		orderBy: 'EndDate desc',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffPayList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffPayList',
		entityName: 'HRStaffPay',
		fetchUriTemplate: 'HRStaffPay',
		exportUriTemplate: 'HRStaffPay/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			category: EnumAllOptionValue,
			// gradeId: NullEntityId,
			closureType: HRClosure.Running,
		},
	},

	HRStaffPositionList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffPositionList',
		entityName: 'HRStaffPosition',
		fetchUriTemplate: 'HRStaffPosition',
		exportUriTemplate: 'HRStaffPosition/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			locationId: NullEntityId,
			unitId: NullEntityId,
			closureType: HRClosure.Running,
		},
	},

	HRStaffQualificationSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffQualificationSubList',
		entityName: 'HRStaffQualification',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Qualification',
		exportUriTemplate: 'HRStaff(${subListParentId})/Qualification/Export',
		cmdUriTemplate: 'HRStaff(${subListParentId})/Qualification',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffRelieverSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffRelieverSubList',
		entityName: 'HRStaffReliever',
		fetchUriTemplate: 'HRStaff(${subListParentId})/HRStaffRelievers',
		orderBy: 'EndDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Duties', 'Reliever/StaffNo', 'Reliever/StaffName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffRewardSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffRewardSubList',
		entityName: 'HRStaffReward',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Rewards',
		exportUriTemplate: 'HRStaff(${subListParentId})/Rewards/Export',
		orderBy: 'RewardDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['DirectPayReference', 'Notes'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffSkillSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffSkillSubList',
		entityName: 'HRStaffSkill',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Skills',
		exportUriTemplate: 'HRStaff(${subListParentId})/Skills/Export',
		orderBy: 'DateAcquired desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Description', 'SkillName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffTrainingSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffTrainingSubList',
		entityName: 'HRStaffTraining',
		fetchUriTemplate: 'HRStaff(${subListParentId})/Training',
		exportUriTemplate: 'HRStaff(${subListParentId})/Training/Export',
		orderBy: 'HRTraining/EndDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRStaffWorkExperienceSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffWorkExperienceSubList',
		entityName: 'HRStaffWorkExperience',
		fetchUriTemplate: 'HRStaff(${subListParentId})/WorkExperience',
		exportUriTemplate: 'HRStaff(${subListParentId})/WorkExperience/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRStaffPayView: {
		...fluxViewDefaults,
		actionNamespace: 'HRStaffPayView',
		entityName: 'HRStaffPay',
		fetchUriTemplate: 'HRStaffPay',
		queryUseRestEndpoint: true,
	},	
	
	HRStaffPositionView: {
		...fluxViewDefaults,
		actionNamespace: 'HRStaffPositionView',
		entityName: 'HRStaffPosition',
		fetchUriTemplate: 'HRStaffPosition',
		queryUseRestEndpoint: true,
	},	
	
	HRStaffView: {
		...fluxViewDefaults,
		actionNamespace: 'HRStaffView',
		entityName: 'HRStaff',
		fetchUriTemplate: 'HRStaff',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
