import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { HRClosure } from 'app-model/enums';

import { fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRConfigList: {
		...fluxListDefaults,
		actionNamespace: 'HRConfigList',
        entityName: 'HRConfig',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyHRConfig()',
		orderBy: 'startdate desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},
	
	HRLoanTypeList: {
		...fluxListDefaults,
		actionNamespace: 'HRLoanTypeList',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
			counterPartyType: EnumAllOptionValue,
		},
	},
	HRLoanTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'HRLoanTypePick',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
		},
		filterExpressions: [],
	},

	HROrgRoleList: {
		...fluxListDefaults,
		actionNamespace: 'HROrgRoleList',
		entityName: 'HROrgRole',
		fetchUriTemplate: 'HROrgRole',
		orderBy: 'Name asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
		},
	},

};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'HRConfigView',
		entityName: 'HRConfig',
		fetchUriTemplate: 'HRConfig',
		selectFields: [],
		expandProps: [],
	},

};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
