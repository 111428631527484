export const sygnet = [
	'690 134', 
	`<svg version="1.1" id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	width="173.2px" height="200px" viewBox="0 0 173.2 200" enable-background="new 0 0 173.2 200" xml:space="preserve">
   <polygon fill="#61DAFB" points="0,150 0,50 86.6,0 173.2,50 173.2,150 86.6,200 "/>
   <polygon fill="#FFFFFF" points="86.6,133.3 57.7,116.7 57.7,83.3 86.6,66.7 115.5,83.3 144.3,66.7 86.6,33.3 28.9,66.7 28.9,133.3 86.6,166.7 144.3,133.3 115.5,116.7 "/>
   <polygon opacity="0.04" stroke="#FFFFFF" stroke-miterlimit="10" enable-background="new    " points="0,50 86.6,100 86.6,0 "/>
   <polygon opacity="0.04" stroke="#FFFFFF" stroke-miterlimit="10" enable-background="new    " points="0,150 86.6,200 86.6,100 "/>
   <polygon opacity="0.08" stroke="#FFFFFF" stroke-miterlimit="10" enable-background="new    " points="86.6,100 173.2,150 173.2,50 "/>
   <polygon fill-opacity="0" points="86.6,100 0,50 0,150 "/>
   <polygon opacity="0.08" stroke="#FFFFFF" stroke-miterlimit="10" enable-background="new    " points="173.2,150 86.6,100 86.6,200 "/>
   <polygon opacity="0.08" stroke="#FFFFFF" stroke-miterlimit="10" enable-background="new    " points="173.2,50 86.6,0 86.6,100 "/>
</svg>
`
]
