import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SysActions } from 'app-model/SysActions';

import willdrafting from './willdrafting/_Reducers';
import globaltrust from './globaltrust/_Reducers';

export default combineReducers({
	willdrafting,
	globaltrust,
});
