import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	JobDefinitionList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'JobDefinitionList',
		entityName: 'JobDefinition',
		fetchUriTemplate: 'JobDefinition',
		orderBy: 'JobCode asc',
		//selectFields: ['Id', 'IsGroup', 'JobCode', 'JobDescription', 'TranEntityType', 'OpCode', 'Active', 'ScheduleType', 'ScheduleLastRunDate', 'ActualLastRunDate', 'ClosureType'],
		selectFields: [],
		expandProps: [],
		searchFields: ['JobCode', 'JobDescription'],
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
    },
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HydSyncClassView: {
		...fluxViewDefaults,
		actionNamespace: 'HydSyncClassView',
		entityName: 'HydSyncClass',
		fetchUriTemplate: 'HydSyncClass',
		selectFields: [],
		expandProps: [],
    },
    
	JobDefinitionView: {
		...fluxViewDefaults,
		actionNamespace: 'JobDefinitionView',
		entityName: 'JobDefinition',
		fetchUriTemplate: 'JobDefinition',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
