import { FunctionComponent, lazy, Suspense } from 'react';

import { Loading } from 'app-widgets/NonDefault';

const DefaultLayoutLazy = lazy(() => import('app-containers/TheLayout'));

export const AppDefaultLayout: FunctionComponent<any> = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <DefaultLayoutLazy {...props} />
        </Suspense>
    );
};

export const HomeDashboard = lazy(() => import('./_Dashboard'));
