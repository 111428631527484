import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, DefNgNseExchangeId, tenYearsAgo } from 'app-model/ModelConstants';
import { PartnerState } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpTradeFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeFeeList',
		entityName: 'CmpTradeFee',
		fetchUriTemplate: 'RefExchangeDef(${exchangeId})/GetPrevailingTradeFees(AtDate=${atDate},InstrType=${enumParamInstrType},FeeName=${searchText})',
		exportUriTemplate: 'RefExchangeDef(${exchangeId})/ExportPrevailingTradeFees',
		orderBy: 'InstrType asc, TradeType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQInstrumentTechnicalInfoTOList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentTechnicalInfoTOList',
		entityName: 'EQInstrumentTechnicalInfoTO',
		keyField: 'InstrumentId',
		fetchUriTemplate: 'RefExchangeDef(${exchangeId})/GetExchTechnicalInfoAtDate(AtDate=${atDate},InstrType=${enumParamInstrType},SearchText=${searchText})',
		exportUriTemplate: 'RefExchangeDef(${exchangeId})/ExportExchTechnicalInfoAtDate',
		orderBy: 'ExchangeCode asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQMarketDealAggrSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketDealAggrSubList',
		entityName: 'EQMarketDealAggr',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/EQMarketDealAggrs',
		orderBy: 'InstrumentCode asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['InstrumentCode', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketEquitySubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketEquitySubList',
		entityName: 'EQMarketEquity',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/EQMarketEquities',
		orderBy: 'EqName asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['EqCode', 'EqName', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketInfoSheetList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketInfoSheetList',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		orderBy: 'TradeDate desc',
		selectFields: [],
		expandProps: [
			//'EQMarketCapital($select=ASIndex,QuantityTraded,ValueTraded,NoDeals,EquityCap,BondCap,EtfCap,TotalCap)',
			'EQMarketCapital',
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
		searchFields: ['RefExchangeDef/UniqueCode', 'RefExchangeDef/CommonName'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	NSETerminalPriceSubList: {
		...fluxListDefaults,
		actionNamespace: 'NSETerminalPriceSubList',
		entityName: 'NSETerminalPrice',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/NSETerminalPrices',
		orderBy: 'InstrumentCode asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['InstrumentCode', 'BoardCode', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ResCompanyList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyList',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		orderBy: 'CoName asc',
		selectFields: [],
		expandProps: ['MasterRecord'],
		searchFields: ['CoCode', 'CoName'],
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	ResCompanyPick: {
		...fluxPickDefaults,
		actionNamespace: 'ResCompanyPick',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		orderBy: 'CoName asc',
		selectFields: [],
		expandProps: ['MasterRecord'],
		searchFields: ['CoCode', 'CoName'],
		filterValues: {
			searchText: '',
		},
	},

	ResCompanyLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'ResCompanyLocationSubList',
		cmdUriTemplate: 'ResCompany(${subListParentId})/Locations',
		fetchUriTemplate: 'ResCompany(${subListParentId})/Locations',
		exportUriTemplate: 'ResCompany(${subListParentId})/Locations/Export',
	},

	ResCompanyRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'ResCompanyRelationshipSubList',
		cmdUriTemplate: 'ResCompany(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'ResCompany(${subListParentId})/RelatedParties',
		exportUriTemplate: 'ResCompany(${subListParentId})/RelatedParties/Export',
	},

	ResCompanyCapitalList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyCapitalList',
		entityName: 'ResCompanyCapital',
		fetchUriTemplate: 'ResCompanyCapital',
		orderBy: 'ReviewDate desc',
		selectFields: [],
		expandProps: ['ResCompany($select=CoCode,CoName)'],
		searchFields: ['Comments'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	ResCompanyResultList: {
		...fluxListDefaults,
		actionNamespace: 'ResCompanyResultList',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		orderBy: 'EffectiveDate desc',
		selectFields: [],
		expandProps: ['ResCompany($select=CoCode,CoName)'],
		searchFields: ['ResultNotes', 'AnalystNotes'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			resultClass: EnumAllOptionValue,
			resultType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		selectFields: [],
		expandProps: [],
	},

	EQMarketInfoSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketInfoSheetView',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		selectFields: [],
		expandProps: [
			//'EQMarketCapital($select=ASIndex,QuantityTraded,ValueTraded,NoDeals,EquityCap,BondCap,EtfCap,TotalCap)',
			'EQMarketCapital',
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
	},

	ResCompanyView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyView',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		selectFields: [],
		expandProps: [
			'Registrar($select=ClassCode,OperatorNo,OperatorCode,OperatorLabel)',
			'MasterRecord($expand=PrimaryAddress)',
		],
	},

	ResCompanyCapitalView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyCapitalView',
		entityName: 'ResCompanyCapital',
		fetchUriTemplate: 'ResCompanyCapital',
		selectFields: [],
		expandProps: [
			'ResCompany($select=CoCode,CoName)',
			'ResCompanyCapitalHoldings',
		],
	},

	ResCompanyReportView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyReportView',
		entityName: 'ResCompanyReport',
		fetchUriTemplate: 'ResCompanyReport',
		selectFields: [],
		expandProps: [
			'ResCompanyResult($expand=ResCompany($select=CoCode,CoName))',
			'ResCompanyReportItems',
		],
	},

	ResCompanyResultView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyResultView',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		selectFields: [],
		expandProps: [
			'ResCompany($select=CoCode,CoName)'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
