import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	CustomerCashSwapList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCashSwapList',
        entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap/GetCustomerWrkflwCashSwaps(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'SwapCurrency', 'SwapRateRequested', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	CustomerTransferList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerTransferList',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer/GetCustomerWrkflwTransfers(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Transferor($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'Transferor/PartnerNo', 'Transferor/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerCashSwapView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerCashSwapView',
		entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'SwapCompany($select=CoShortCode,CoName,CoOrgType)',
			'CollectAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'PayoutAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	CustomerTransferView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerTransferView',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer',
		selectFields: [],
		expandProps: [
			'Transferor($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'PartnerTransferEntries($expand=Transferee($select=PartnerNo,PartnerLabel))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
