import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	NFBulkTypeList: {
		...fluxListDefaults,
		actionNamespace: 'NFBulkTypeList',
		entityName: 'NFBulkType',
		fetchUriTemplate: 'NFBulkType',
		exportUriTemplate: 'NFBulkType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			deliveryMethod: EnumAllOptionValue,
		},
	},
	
	NFQueuedItemList: {
		...fluxListDefaults,
		actionNamespace: 'NFQueuedItemList',
		entityName: 'NFQueuedItem',
		fetchUriTemplate: 'NFQueuedItem',
		orderBy: 'Id asc',
		selectFields: ['Id', 'RecipientId', 'TemplateId', 'xnTo', 'TranEntityType', 'TranEntityId', 'AutoSend', 'ClosureType', 'CreatedAt'],
		expandProps: [
			'NFTemplate($select=TemplateCode,DeliveryMethod;$expand=NotifyType($select=Code,Description))',
			'Recipient($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['NFTemplate/TemplateCode', 'NFTemplate/TemplateDesc', 'Recipient/PartnerNo', 'Recipient/PartnerLabel', 'xnTo', 'xnCc'],
		filterValues: {
			searchText: '',
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
		},
	},
	
	NFTemplateList: {
		...fluxListDefaults,
		actionNamespace: 'NFTemplateList',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		orderBy: 'NotifyType/Code asc,TemplateCode asc',
		//selectFields: ['Id', 'NotificationType', 'TemplateCode', 'TemplateDesc', 'DeliveryMethod', 'nFromDisplayName', 'nFromAddress', 'nTemplateToOnly', 'ClosureType'],
		selectFields: [],
		expandProps: [
			'NotifyType($select=Code,Description)'
		],
		searchFields: ['TemplateCode', 'TemplateDesc', 'nFromDisplayName', 'nFromAddress', 'nTo', 'nCc', 'nBcc'],
		filterValues: {
			searchText: '',
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
		},
	},
	NFTemplatePick: {
		...fluxPickDefaults,
		actionNamespace: 'NFTemplatePick',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		orderBy: 'NotifyType/Code asc,TemplateCode asc',
		//selectFields: ['Id', 'NotificationType', 'TemplateCode', 'TemplateDesc', 'DeliveryMethod', 'nFromDisplayName', 'nFromAddress', 'nTemplateToOnly', 'ClosureType'],
		selectFields: [],
		expandProps: [
			'NotifyType($select=Code,Description)'
		],
		searchFields: ['TemplateCode', 'TemplateDesc', 'nFromDisplayName', 'nFromAddress', 'nTo', 'nCc', 'nBcc'],
		filterValues: {
			searchText: '',
		},
		filterExpressions: [],
	},
	
	NFTypeDefList: {
		...fluxListDefaults,
		actionNamespace: 'NFTypeDefList',
		entityName: 'NFTypeDef',
		fetchUriTemplate: 'NFTypeDef',
		orderBy: 'Code asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	NFTemplateView: {
		...fluxViewDefaults,
		actionNamespace: 'NFTemplateView',
		entityName: 'NFTemplate',
		fetchUriTemplate: 'NFTemplate',
		selectFields: [],
		expandProps: [
			'NotifyType($select=Code,Description)',
			'NFTemplateFields',
			'NFTemplateReports',
			'NFTemplateAttachments',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
