import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	TermLoanList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanList',
		entityName: 'TermLoan',
		fetchUriTemplate: 'TermLoan',
		exportUriTemplate: 'TermLoan/Export',
		printUriTemplate: 'TermLoan/PRINT',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			loanTypeId: NullEntityId,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	TermLoanPick: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanPick',
		entityName: 'TermLoan',
		fetchUriTemplate: 'TermLoan',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	TermLoanDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TermLoanDocumentSubList',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Documents',
		exportUriTemplate: 'TermLoan(${subListParentId})/Documents(${entityId})/Export',
	},

	TermLoanWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'TermLoanWorkflowHistorySubList',
		fetchUriTemplate: 'TermLoan(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'TermLoan(${subListParentId})/ExportWorkflowHistory',
	},

	TermLoanAccrualSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanAccrualSubList',
		entityName: 'TermLoanAccrual',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Accruals',
		exportUriTemplate: 'TermLoan(${subListParentId})/Accruals/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},

	TermLoanCollateralSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanCollateralSubList',
		entityName: 'TermLoanCollateral',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Collaterals',
		exportUriTemplate: 'TermLoan(${subListParentId})/Collaterals/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermLoanPaymentList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanPaymentList',
		entityName: 'TermLoanPayment',
		fetchUriTemplate: 'TermLoanPayment',
		exportUriTemplate: 'TermLoanPayment/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
	TermLoanPaymentSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanPaymentSubList',
		entityName: 'TermLoanPayment',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Payments',
		exportUriTemplate: 'TermLoan(${subListParentId})/Payments/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	TermLoanProvisionList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanProvisionList',
		entityName: 'TermLoanProvision',
		fetchUriTemplate: 'TermLoanProvision',
		exportUriTemplate: 'TermLoanProvision/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
	TermLoanProvisionSubList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanProvisionSubList',
		entityName: 'TermLoanProvision',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Provisions',
		exportUriTemplate: 'TermLoan(${subListParentId})/Provisions/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	TermLoanRateSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanRateSubList',
		entityName: 'TermLoanRate',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Rates',
		exportUriTemplate: 'TermLoan(${subListParentId})/Rates/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermLoanRepayPlanList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanRepayPlanList',
		entityName: 'TermLoanRepayPlan',
		fetchUriTemplate: 'TermLoanRepayPlan',
		exportUriTemplate: 'TermLoanRepayPlan/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
	TermLoanRepayPlanSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanRepayPlanSubList',
		entityName: 'TermLoanRepayPlan',
		fetchUriTemplate: 'TermLoan(${subListParentId})/RepayPlans',
		exportUriTemplate: 'TermLoan(${subListParentId})/RepayPlans/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermLoanRepayPlanItemSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanRepayPlanItemSubList',
		entityName: 'TermLoanRepayPlanItem',
		fetchUriTemplate: 'TermLoanRepayPlan(${subListParentId})/PlanItems',
		exportUriTemplate: 'TermLoanRepayPlan(${subListParentId})/PlanItems/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	TermLoanSyndicateSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanSyndicateSubList',
		entityName: 'TermLoanSyndicate',
		fetchUriTemplate: 'TermLoan(${subListParentId})/Syndicates',
		exportUriTemplate: 'TermLoan(${subListParentId})/Syndicates/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermLoanTypeList: {
		...fluxListDefaults,
		actionNamespace: 'TermLoanTypeList',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		exportUriTemplate: 'TermLoanType/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			counterPartyType: EnumAllOptionValue,
			interestTypeId: EnumAllOptionValue,
		},
	},
	TermLoanTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanTypePick',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	TermLoanTypeGlAcctSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanTypeGlAcctSubList',
		entityName: 'TermLoanTypeGlAcct',
		fetchUriTemplate: 'TermLoanType(${subListParentId})/GlAccts',
		exportUriTemplate: 'TermLoanType(${subListParentId})/GlAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	TermLoanTypeSyndicateSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TermLoanTypeSyndicateSubList',
		entityName: 'TermLoanTypeSyndicate',
		fetchUriTemplate: 'TermLoanType(${subListParentId})/Syndicates',
		exportUriTemplate: 'TermLoanType(${subListParentId})/Syndicates/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	TermLoanView: {
		...fluxViewDefaults,
		actionNamespace: 'TermLoanView',
		entityName: 'TermLoan',
		fetchUriTemplate: 'TermLoan',
		printUriTemplate: 'TermLoan/PRINT',
		queryUseRestEndpoint: true,
	},

	TermLoanRepayPlanView: {
		...fluxViewDefaults,
		actionNamespace: 'TermLoanRepayPlanView',
		entityName: 'TermLoanRepayPlan',
		fetchUriTemplate: 'TermLoanRepayPlan',
		exportUriTemplate: 'TermLoanRepayPlan(${entityId})/ExportSchedule',
		printUriTemplate: 'TermLoanRepayPlan(${entityId})/PrintSchedule',
		queryUseRestEndpoint: true,
	},

	TermLoanTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'TermLoanTypeView',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		selectFields: [],
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
