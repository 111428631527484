import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerTermInvestmentList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerTermInvestmentList',
		entityName: 'VALTermInstrument',
		fetchUriTemplate: 'Customer/GetAllTermInvestments(AtDate=${atDate},TypeId=${typeId},SubClassId=${subClassId},Origin=${enumParamOrigin},SearchText=${searchText})',
		exportUriTemplate: 'Customer/ExportAllTermInvestments',
		orderBy: 'ExpectedMaturity asc, PartnerLabel asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	VALCustActivityList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALCustActivityList',
		entityName: 'VALCustActivity',
		fetchUriTemplate: 'Customer/TransactionSummary',
		exportUriTemplate: 'Customer/TransactionSummary/Export',
		keyField: 'CustomerId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'HRConfigView',
		entityName: 'HRConfig',
		fetchUriTemplate: 'HRConfig',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
