import { $isNull, toIsoDateString } from 'basicFunctions';
import { SysActions } from 'app-model/SysActions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, oneYearAgo, today } from 'app-model/ModelConstants';
import { IListComponentControl, IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { CurrencyCodes, PartnerState } from 'app-model/enums';

export const fluxGlobalListDefaults = {
	totalCount: 0,
	listData: [],
	isLoading: false,
	delayFetch: false,
	fetched: false,
	error: false,
	orderBy: 'Id asc',
	keyField: 'Id',
	selectFields: [],
	expandProps: [],
	filterExpressions: [],

	cmdIsExecuting: false,
	cmdUseRestEndpoint: true,
	//queryUseRestEndpoint: false,
};

export const fluxListDefaults = {
	...fluxGlobalListDefaults,
	sizePage: 25,
	totalPages: 0,
	currentPage: 0,
	searchFields: [],
	filterValues: {
		searchText: '',
	},
	selectedIds: [],
};

export const fluxDocumentSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	sizePage: 50,
	entityName: 'Document',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
		closureType: EnumAllOptionValue,
	},
};

export const fluxPartnerBankAcctSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerBankAcct',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
	},
};

export const fluxPartnerBeneficiarySubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerBeneficiary',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
		closureType: PartnerState.Active,
	},
};

export const fluxPartnerGlAcctSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerGlAcct',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
		enumParamAcctType: EnumAllOptionValue,
		enumParamCurrency: EnumAllOptionValue,
	},
};

export const fluxPartnerInterestRunSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerInterestRun',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
		startDate: toIsoDateString(oneYearAgo),
		endDate: toIsoDateString(today),
		enumParamCurrency: CurrencyCodes.NGN,
	},
};

export const fluxPartnerLocationSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerLocation',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
	},
};

export const fluxPartnerRelationshipSubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	entityName: 'PartnerRelationship',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
	},
};

export const fluxPxWorkflowHistorySubListDefaults : IListComponentControl = {
	...fluxListDefaults,
	sizePage: 50,
	entityName: 'PxWorkflowInstanceHistory',
	queryUseRestEndpoint: true,
	filterValues: {
		searchText: '',
		subListParentId: -1,
		closureType: EnumAllOptionValue,
	},
};

export const fluxPickDefaults = {
	...fluxListDefaults,
	delayFetch: true,
	sizePage: 10,
};

export const fluxViewDefaults = {
	fetchUriComposited: false,
	entityId: -1,
	entityData: {},
	relatedData: {},
	selectFields: [],
	expandProps: [],
	fetched: false,
	error: false,
	isLoading: false,

	defaultSubTab: 'Tab1',
	
	cmdIsExecuting: false,
	cmdUseRestEndpoint: true,
};

export function reduceListCmdStart(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			cmdIsExecuting: true,
		},
	};
}

export function reduceListCmdEnd(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			cmdIsExecuting: false,
		},
	};
}

export function reduceListFetchData(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			isLoading: true,
			sizePage: action.payload.sizePage,
			currentPage: action.payload.currentPage,
			filterValues: { ...action.payload.filterValues },
			filterExpressions: [ ...(action.payload.filterExpressions || []) ],
		},
	};
}

export function reduceListReceiveData(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			...action.payload.data,
			dataTimeStamp: new Date(),
			isLoading: false,
			filterValues: { ...action.payload.filterValues },
			filterExpressions: [ ...(action.payload.filterExpressions || []) ],
			selectedIds: [],
		},
	};
}

export function reduceListSelectItems(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			selectedIds: [...action.payload.selectedIds],
		},
	};
}

export function reduceListChangeFilter(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],

			totalCount: 0, totalPages: 0, //sizePage: 25, //no change in sizePage
			currentPage: 0,
			listData: [],
			dataTimeStamp: new Date(),
			error: false,
			selectedIds: [],

			//perhaps compare state (filterValues, filterExpressions - to see if we have to blank out the {listData and related properties})
			filterValues: { ...action.payload.filterValues },
			filterExpressions: [ ...(action.payload.filterExpressions || []) ],
		},
	};
}

export function reduceListReset(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],

			totalCount: 0, totalPages: 0,
			currentPage: 0,
			listData: [],
			dataTimeStamp: new Date(),
			error: false,
			filterExpressions: [], //generally, only used by *Pick
			selectedIds: [],
		},
	};
}


export function masterListReducer(state: IListComponentControlList, action: IFluxAction): IListComponentControlList {
	const actionTypeParts = action.type.split('/');

	if (actionTypeParts.length == 2) {
		const controlName = actionTypeParts[0];
		const opCode = actionTypeParts[1];

		if (!$isNull(state[controlName])) {
			switch (opCode) {
				case SysActions.LIST_CMDSTART:
					return reduceListCmdStart(state, action);

				case SysActions.LIST_CMDEND:
					return reduceListCmdEnd(state, action);

				case SysActions.LIST_FETCHDATA:
					return reduceListFetchData(state, action);
		
				case SysActions.LIST_RECEIVEDATA:
					return reduceListReceiveData(state, action);

				case SysActions.LIST_SELECTITEMS:
					return reduceListSelectItems(state, action);

				case SysActions.LIST_CHANGEFILTER:
					return reduceListChangeFilter(state, action);

				case SysActions.LIST_RESET:
					return reduceListReset(state, action);

				default:
					return state;
			}
		}
	}

	//if we get this far, none of our filters passed
	return state;
}


export function reduceViewCmdStart(state: IViewComponentControlList, action: IFluxAction): IViewComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			cmdIsExecuting: true,
		},
	};
}

export function reduceViewCmdEnd(state: IViewComponentControlList, action: IFluxAction): IViewComponentControlList {
	return {
		...state,

		[action.payload.listName]: {
			...state[action.payload.listName],
			cmdIsExecuting: false,
		},
	};
}

export function reduceViewFetchData(state: IViewComponentControlList, action: IFluxAction): IViewComponentControlList {
	return {
		...state,

		[action.payload.viewName]: {
			...state[action.payload.viewName],
			entityId: action.payload.entityId,
			entityData: {},
			relatedData: {},
			dataTimeStamp: new Date(),
			isLoading: true,
		},
	};
}

export function reduceViewReceiveData(state: IViewComponentControlList, action: IFluxAction): IViewComponentControlList {
	return {
		...state,

		[action.payload.viewName]: {
			...state[action.payload.viewName],
			...action.payload.data,
			dataTimeStamp: new Date(),
			isLoading: false,
		},
	};
}


export function masterViewReducer(state: IViewComponentControlList, action: IFluxAction) : IViewComponentControlList {
	const actionTypeParts = action.type.split('/');

	if (actionTypeParts.length == 2) {
		const controlName = actionTypeParts[0];
		const opCode = actionTypeParts[1];

		if (!$isNull(state[controlName])) {
			switch (opCode) {
				case SysActions.VIEW_CMDSTART:
					return reduceViewCmdStart(state, action);

				case SysActions.VIEW_CMDEND:
					return reduceViewCmdEnd(state, action);

				case SysActions.VIEW_FETCHDATA:
					return reduceViewFetchData(state, action);

				case SysActions.VIEW_RECEIVEDATA:
					return reduceViewReceiveData(state, action);

				default:
					return state;
			}
		}
	}

	//if we get this far, none of our filters passed
	return state;
}
