import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { NullEntityId, threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	ScItemSaleClassList: {
		...fluxListDefaults,
		actionNamespace: 'ScItemSaleClassList',
        entityName: 'ScItemSaleClass',
		fetchUriTemplate: 'ScItemSaleClass',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ScMeasureUnitClassList: {
		...fluxListDefaults,
		actionNamespace: 'ScMeasureUnitClassList',
        entityName: 'ScMeasureUnitClass',
		fetchUriTemplate: 'ScMeasureUnitClass',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ScMeasureUnitList: {
		...fluxListDefaults,
		actionNamespace: 'ScMeasureUnitList',
        entityName: 'ScMeasureUnit',
		fetchUriTemplate: 'ScMeasureUnit',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			unitClassId: NullEntityId,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	VendorCashSwapView: {
		...fluxViewDefaults,
		actionNamespace: 'VendorCashSwapView',
		entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'SwapCompany($select=CoShortCode,CoName,CoOrgType)',
			'CollectAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'PayoutAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	VendorPaymentView: {
		...fluxViewDefaults,
		actionNamespace: 'VendorPaymentView',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=OrgCompanyId,AcctNo,AcctTitle)',
			'FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	VendorReceiptView: {
		...fluxViewDefaults,
		actionNamespace: 'VendorReceiptView',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=OrgCompanyId,AcctNo,AcctTitle)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	VendorTransferView: {
		...fluxViewDefaults,
		actionNamespace: 'VendorTransferView',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer',
		selectFields: [],
		expandProps: [
			'Transferor($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'PartnerTransferEntries($expand=Transferee($select=PartnerNo,PartnerLabel))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
