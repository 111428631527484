import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	TRCommPaperAccrualSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TRCommPaperAccrualSubList',
		entityName: 'TRCommPaperAccrual',
		fetchUriTemplate: 'TRCommPaperTran(${subListParentId})/Accruals',
		orderBy: 'AccrueDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},

	TRCommPaperTranList: {
		...fluxListDefaults,
		actionNamespace: 'TRCommPaperTranList',
		entityName: 'TRCommPaperTran',
		fetchUriTemplate: 'TRCommPaperTran/GetTRCommPaperTranForRange(StartDate=${startDate},EndDate=${endDate},IssuerId=${issuerId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'TRCommPaperTran/ExportTRCommPaperTranForRange',
		printUriTemplate: 'TRCommPaperTran/PRINT',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country;$expand=FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			issuerId: NullEntityId,
			currency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TRCommPaperTranDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TRCommPaperTranDocumentSubList',
		fetchUriTemplate: 'TRCommPaperTran(${subListParentId})/Documents',
		exportUriTemplate: 'TRCommPaperTran(${subListParentId})/Documents(${entityId})/Export',
	},

	TRIssuerAcctList: {
		...fluxListDefaults,
		actionNamespace: 'TRIssuerAcctList',
		entityName: 'TRIssuerAcct',
		fetchUriTemplate: 'TRIssuerAcct',
		orderBy: 'Issuer/CoCode asc',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'OrgCompany($select=CoShortCode,CoName)',
			'DefChargeAccrualAcct($select=AcctNo,AcctTitle)',
			'DefTranAcct($select=AcctNo,AcctTitle)',
			'OwnCommAcct($select=AcctNo,AcctTitle)',
		],
		searchFields: ['Issuer/CoCode', 'Issuer/CoName'],
		filterValues: {
			searchText: '',
			currency: EnumAllOptionValue,
		},
	},

	TRPromNoteAccrualSubList: {
		...fluxPickDefaults,
		actionNamespace: 'TRPromNoteAccrualSubList',
		entityName: 'TRPromNoteAccrual',
		fetchUriTemplate: 'TRPromNoteTran(${subListParentId})/Accruals',
		orderBy: 'AccrueDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},

	TRPromNoteTranList: {
		...fluxListDefaults,
		actionNamespace: 'TRPromNoteTranList',
		entityName: 'TRPromNoteTran',
		fetchUriTemplate: 'TRPromNoteTran/GetTRPromNoteTranForRange(StartDate=${startDate},EndDate=${endDate},IssuerId=${issuerId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'TRPromNoteTran/ExportTRPromNoteTranForRange',
		printUriTemplate: 'TRPromNoteTran/PRINT',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country;$expand=FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			issuerId: NullEntityId,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TRPromNoteTranDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TRPromNoteTranDocumentSubList',
		fetchUriTemplate: 'TRPromNoteTran(${subListParentId})/Documents',
		exportUriTemplate: 'TRPromNoteTran(${subListParentId})/Documents(${entityId})/Export',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	TRCommPaperTranView: {
		...fluxViewDefaults,
		actionNamespace: 'TRCommPaperTranView',
		entityName: 'TRCommPaperTran',
		fetchUriTemplate: 'TRCommPaperTran',
		printUriTemplate: 'TRCommPaperTran/PRINT',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country;$expand=FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
	},

	TRIssuerAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'TRIssuerAcctView',
		entityName: 'TRIssuerAcct',
		fetchUriTemplate: 'TRIssuerAcct',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'OrgCompany($select=CoShortCode,CoName)',
			'DefChargeAccrualAcct($select=AcctNo,AcctTitle)',
			'DefTranAcct($select=AcctNo,AcctTitle)',
			'OwnCommAcct($select=AcctNo,AcctTitle)',
		],
	},

	TRPromNoteTranView: {
		...fluxViewDefaults,
		actionNamespace: 'TRPromNoteTranView',
		entityName: 'TRPromNoteTran',
		fetchUriTemplate: 'TRPromNoteTran',
		printUriTemplate: 'TRPromNoteTran/PRINT',
		selectFields: [],
		expandProps: [
			'Issuer($select=CoCode,CoName)',
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country;$expand=FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
