import { antAccountBook } from './antAccountBook';
import { antSnippetsOutlined } from './antSnippetsOutlined';


export const antIcons = Object.assign(
	{}, 
	{
		antAccountBook,
		antSnippetsOutlined,
	}
);
