import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	ResNewsItemFeedList: {
		...fluxListDefaults,
		actionNamespace: 'ResNewsItemFeedList',
		entityName: 'ResNewsItemFeed',
		fetchUriTemplate: 'ResNewsItemFeed',
		orderBy: 'FeedSource asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['FeedSource', 'DefaultRegion', 'DefaultCountry'],
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},

	ResNewsItemList: {
		...fluxListDefaults,
		actionNamespace: 'ResNewsItemList',
		entityName: 'ResNewsItem',
		fetchUriTemplate: 'ResNewsItem',
		orderBy: 'ItemDate desc, ItemHead asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['ItemSource', 'ItemHead', 'Regions', 'Countries'],
		filterValues: {
			searchText: '',
			exchangeId: NullEntityId,
			startDate: toIsoDateString(today),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
