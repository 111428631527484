import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FAConfigList: {
		...fluxListDefaults,
		actionNamespace: 'FAConfigList',
        entityName: 'FAConfig',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyFAConfig()',
		orderBy: 'startdate desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FAConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'FAConfigView',
		entityName: 'FAConfig',
		fetchUriTemplate: 'FAConfig',
		entityId: -1,
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
