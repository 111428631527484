import { combineReducers } from 'redux';

import setup from './setup/_Reducers';
import acctmgt from './acctmgt/_Reducers';
import trading from './trading/_Reducers';
import misctrans from './misctrans/_Reducers';
import corpactions from './corpactions/_Reducers';
import pryissues from './pryissues/_Reducers';
import certmgt from './certmgt/_Reducers';

export default combineReducers({
	setup,
	trading,
	pryissues,
	corpactions,
    acctmgt,
    misctrans,
	certmgt,
});
