import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'MyLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetMyLeavesForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'StartDate desc,HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: ['HRStaff/StaffNo','HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyLeaveDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyLeaveDocumentSubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeave(${subListParentId})/Documents(${entityId})/Export',
	},

	MyLeaveWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyLeaveWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRLeave(${subListParentId})/ExportWorkflowHistory',
	},

	MyLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'MyLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetMyLeaveRotasForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc,SequenceNumber asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyLeaveRotaDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyLeaveRotaDocumentSubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/Documents(${entityId})/Export',
	},

	MyLeaveRotaWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyLeaveRotaWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/ExportWorkflowHistory',
	},

	MyUnitLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'MyUnitLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetMyUnitLeavesForYear(ClosureType=${enumParamClosureType})',
		orderBy: 'StartDate desc,HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: ['HRStaff/StaffNo','HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyUnitLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'MyUnitLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetMyUnitLeaveRotasForYear(ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc,SequenceNumber asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRLeaveYearQuotaSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveYearQuotaSubList',
		entityName: 'HRLeaveYearQuota',
		fetchUriTemplate: 'hrleaveyear(${subListParentId})/HRLeaveYearQuotas',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyLeaveView: {
		...fluxViewDefaults,
		actionNamespace: 'MyLeaveView',
		entityName: 'HRLeave',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRLeave/GetMyLeaveById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRLeaveDelegates($expand=HRStaff($select=StaffNo,StaffName))',
			'HRLeaveReviewers($expand=Reviewer($select=StaffNo,StaffName);$orderby=Precedence)',
		],
	},

	MyLeaveRotaView: {
		...fluxViewDefaults,
		actionNamespace: 'MyLeaveRotaView',
		entityName: 'HRLeaveRota',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRLeaveRota/GetMyLeaveRotaById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRLeaveRotaDates($orderby=StartDate)',
			'HRLeaveRotaReviewers($expand=Reviewer($select=StaffNo,StaffName);$orderby=Precedence)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
