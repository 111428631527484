import { combineReducers } from 'redux';

import records from './records/_Reducers';
import clientadmin from './clientadmin/_Reducers';
import activity from './activity/_Reducers';
import transactions from './transactions/_Reducers';
import relationship from './relationship/_Reducers';
import portal from './portal/_Reducers';
import invstmgt from './invstmgt/_Reducers';

export default combineReducers({
	records,
	clientadmin,
	transactions,
	activity,
	relationship,
	portal,
	invstmgt,
});
