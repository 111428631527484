import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SysActions } from 'app-model/SysActions';

import setup from './setup/_Reducers';
import requests from './requests/_Reducers';
import projmgt from './projmgt/_Reducers';

export default combineReducers({
	setup,
	requests,
	projmgt,
});
