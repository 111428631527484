import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { HRClosure } from 'app-model/enums';

import { fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRConfigList: {
		...fluxListDefaults,
		actionNamespace: 'HRConfigList',
        entityName: 'HRConfig',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyHRConfig()',
		orderBy: 'startdate desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	HRGradePackageList: {
		...fluxListDefaults,
		actionNamespace: 'HRGradePackageList',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'hrheirarchy(${hierarchyId})/GetHRGradePackagesForHeirarchy()',
		orderBy: 'HRGrade/Category asc, HRGrade/Grade asc, GradeLevel asc, StartDate asc',
		selectFields: [],
		expandProps: ['HRGrade($select=Category,Grade,Designation;$expand=HRHeirarchy($select=StartDate,EndDate))'],
		searchFields: ['HRGrade/Grade'],
		filterValues: {
			searchText: '',
			hierarchyId: NullEntityId,
			category: EnumAllOptionValue,
			closureType: HRClosure.Running,
		},
	},
	HRGradePackagePick: {
		...fluxPickDefaults,
		actionNamespace: 'HRGradePackagePick',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'hrheirarchy(${hierarchyId})/GetHRGradePackagesForHeirarchy()',
		orderBy: 'HRGrade/Category asc, HRGrade/Grade asc, GradeLevel asc, StartDate asc',
		selectFields: [],
		expandProps: ['HRGrade($select=Category,Grade,Designation;$expand=HRHeirarchy($select=StartDate,EndDate))'],
		searchFields: ['HRGrade/Grade'],
		filterValues: {
			searchText: '',
		},
	},
	
	HRLoanTypeList: {
		...fluxListDefaults,
		actionNamespace: 'HRLoanTypeList',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
			counterPartyType: EnumAllOptionValue,
		},
	},
	HRLoanTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'HRLoanTypePick',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'typedesc'],
		filterValues: {
			searchText: '',
		},
		filterExpressions: [],
	},

	HROrgRoleList: {
		...fluxListDefaults,
		actionNamespace: 'HROrgRoleList',
		entityName: 'HROrgRole',
		fetchUriTemplate: 'HROrgRole',
		orderBy: 'Name asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
		},
	},

	HRPayItemList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayItemList',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		orderBy: 'ElementClass asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TypeCode', 'TypeName', 'Description'],
		filterValues: {
			searchText: '',
			elementClass: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	HRPayItemPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRPayItemPick',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		orderBy: 'ElementClass asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TypeCode', 'TypeName', 'Description'],
		filterValues: {
			searchText: '',
		},
	},

	HRRewardList: {
		...fluxListDefaults,
		actionNamespace: 'HRRewardList',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		orderBy: 'RewardType asc, RewardCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['RewardCode', 'RewardDescription', 'RewardNature'],
		filterValues: {
			searchText: '',
			rewardType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	HRRewardPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRRewardPick',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		orderBy: 'RewardType asc, RewardCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['RewardCode', 'RewardDescription', 'RewardNature'],
		filterValues: {
			searchText: '',
		},
	},

};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'HRConfigView',
		entityName: 'HRConfig',
		fetchUriTemplate: 'HRConfig',
		selectFields: [],
		expandProps: [],
	},

	HRGradePackageView: {
		...fluxViewDefaults,
		actionNamespace: 'HRGradePackageView',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'HRGradePackage',
		selectFields: [],
		expandProps: [
			'HRGrade($select=Category,Grade,Designation;$expand=HRHeirarchy($select=StartDate,LevelsInGrade))',
			'HRGradePackageItems($expand=HRPayItem($select=ElementClass,TypeCode,TypeName))',
			'HRGradePackageLoanLimits($expand=LoanType($select=TypeCode,TypeDesc))'
		],
	},

	HRPayItemView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayItemView',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		selectFields: [],
		expandProps: [
			'ItemAccts($select=Id,OrgCompanyId,Currency,ExpenseAcctId,AccrueAcctId;$expand=OrgCompany($select=CoShortCode,CoName),ExpenseAcct($select=AcctNo,AcctTitle),AccrueAcct($select=AcctNo,AcctTitle))', 
			'ItemReliefs'
		],
	},

	HRRewardView: {
		...fluxViewDefaults,
		actionNamespace: 'HRRewardView',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		selectFields: [],
		expandProps: [],
	},

};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
