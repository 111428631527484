import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, DefNgNseExchangeId, tenYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PartnerState } from 'app-model/enums';
import { SecInstrumentState } from 'app-model/enums';

const stLists : IListComponentControlList = {
	CommodityList: {
		...fluxListDefaults,
		actionNamespace: 'CommodityList',
		entityName: 'Commodity',
		fetchUriTemplate: 'Commodity',
		orderBy: 'InstrumentName asc',
		selectFields: [],
		expandProps: ['ResearchSector($select=SectorCode)','InstrumentCompany($select=CoShortCode)','InstrumentCustomer($select=Id,PartnerNo,PartnerLabel)'],
		searchFields: ['ISINCode', 'InstrumentCode', 'InstrumentName'],
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			closureType: SecInstrumentState.Active,
		},
	},
	CommodityPick: {
		...fluxPickDefaults,
		actionNamespace: 'CommodityPick',
		entityName: 'Commodity',
		fetchUriTemplate: 'Commodity',
		orderBy: 'InstrumentName asc',
		selectFields: [],
		expandProps: ['ResearchSector($select=SectorCode)','InstrumentCompany($select=CoShortCode)','InstrumentCustomer($select=Id,PartnerNo,PartnerLabel)'],
		searchFields: ['ISINCode', 'InstrumentCode', 'InstrumentName'],
		filterValues: {
			searchText: '',
		},
	},

	CommodityProductionList: {
		...fluxListDefaults,
		actionNamespace: 'CommodityProductionList',
		entityName: 'CommodityProduction',
		fetchUriTemplate: 'CommodityProduction',
		exportUriTemplate: 'CommodityProduction/ExportCommodityProductionForRange',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	ResNationalIndicatorList: {
		...fluxListDefaults,
		actionNamespace: 'ResNationalIndicatorList',
		entityName: 'ResNationalIndicator',
		fetchUriTemplate: 'ResNationalIndicator',
		exportUriTemplate: 'ResNationalIndicator/ExportResNationalIndicatorForRange',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		selectFields: [],
		expandProps: [],
	},

	EQMarketInfoSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketInfoSheetView',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		selectFields: [],
		expandProps: [
			//'EQMarketCapital($select=ASIndex,QuantityTraded,ValueTraded,NoDeals,EquityCap,BondCap,EtfCap,TotalCap)',
			'EQMarketCapital',
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
	},

	ResCompanyView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyView',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		selectFields: [],
		expandProps: [
			'Registrar($select=ClassCode,OperatorNo,OperatorCode,OperatorLabel)',
			'MasterRecord($expand=PrimaryAddress)',
		],
	},

	ResCompanyCapitalView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyCapitalView',
		entityName: 'ResCompanyCapital',
		fetchUriTemplate: 'ResCompanyCapital',
		selectFields: [],
		expandProps: [
			'ResCompany($select=CoCode,CoName)',
			'ResCompanyCapitalHoldings',
		],
	},

	ResCompanyReportView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyReportView',
		entityName: 'ResCompanyReport',
		fetchUriTemplate: 'ResCompanyReport',
		selectFields: [],
		expandProps: [
			'ResCompanyResult($expand=ResCompany($select=CoCode,CoName))',
			'ResCompanyReportItems',
		],
	},

	ResCompanyResultView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyResultView',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		selectFields: [],
		expandProps: [
			'ResCompany($select=CoCode,CoName)'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
