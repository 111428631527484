import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	TRDiscLoanList: {
		...fluxListDefaults,
		actionNamespace: 'TRDiscLoanList',
		entityName: 'TRDiscLoan',
		fetchUriTemplate: 'TRDiscLoan',
		exportUriTemplate: 'TRDiscLoan/Export',
		printUriTemplate: 'TRDiscLoan/PRINT',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			loanTypeId: NullEntityId,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	TRDiscLoanPick: {
		...fluxPickDefaults,
		actionNamespace: 'TRDiscLoanPick',
		entityName: 'TRDiscLoan',
		fetchUriTemplate: 'TRDiscLoan',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	TRDiscLoanDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TRDiscLoanDocumentSubList',
		fetchUriTemplate: 'TRDiscLoan(${subListParentId})/Documents',
		exportUriTemplate: 'TRDiscLoan(${subListParentId})/Documents(${entityId})/Export',
	},

	TRDiscLoanAccrualSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRDiscLoanAccrualSubList',
		entityName: 'TRDiscLoanAccrual',
		fetchUriTemplate: 'TRDiscLoan(${subListParentId})/Accruals',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},

	TRDiscLoanTypeList: {
		...fluxListDefaults,
		actionNamespace: 'TRDiscLoanTypeList',
		entityName: 'TRDiscLoanType',
		fetchUriTemplate: 'TRDiscLoanType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			counterPartyType: EnumAllOptionValue,
			interestTypeId: EnumAllOptionValue,
		},
	},
	TRDiscLoanTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'TRDiscLoanTypePick',
		entityName: 'TRDiscLoanType',
		fetchUriTemplate: 'TRDiscLoanType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	TRDiscLoanView: {
		...fluxViewDefaults,
		actionNamespace: 'TRDiscLoanView',
		entityName: 'TRDiscLoan',
		fetchUriTemplate: 'TRDiscLoan',
		printUriTemplate: 'TRDiscLoan/PRINT',
		queryUseRestEndpoint: true,
	},

	TRDiscLoanTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'TRDiscLoanTypeView',
		entityName: 'TRDiscLoanType',
		fetchUriTemplate: 'TRDiscLoanType',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
