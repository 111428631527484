export const AccountOpeningStatus = {
    FAILED_CREATE_3RDPARTY: 'FAILED_CREATE_3RDPARTY',
    FAILED_MGT_APPROVAL: 'FAILED_MGT_APPROVAL',
    FAILED_RISK_CHECK: 'FAILED_RISK_CHECK',
    FAILED_CTRL_CHECK: 'FAILED_CTRL_CHECK',
    FAILED_BVN_CHECK: 'FAILED_BVN_CHECK',
    FAILED_KYC_REVIEW: 'FAILED_KYC_REVIEW',
    FAILED_SALES_CONFIRM: 'FAILED_SALES_CONFIRM',
    FAILED_SALES_REVIEW: 'FAILED_SALES_REVIEW',
    PENDING: 'PENDING',
    RECEIVED: 'RECEIVED',
    KYC_REVIEW: 'KYC_REVIEW',
    BVN_CHECK: 'BVN_CHECK',
    CTRL_CHECK: 'CTRL_CHECK',
    RISK_CHECK: 'RISK_CHECK',
    MGT_APPROVAL: 'MGT_APPROVAL',
    CREATE_3RDPARTY: 'CREATE_3RDPARTY',
    CREATE_INTERNAL: 'CREATE_INTERNAL',
    CREATE_PORTAL: 'CREATE_PORTAL',
    INVALIDATED: 'INVALIDATED',
    RUNNING: 'RUNNING',
};
export type AccountOpeningStatus = typeof AccountOpeningStatus[keyof typeof AccountOpeningStatus];

export const AccrualPostingType = {
    Reversed: 'Reversed',
    PostMark: 'PostMark',
    Suspended: 'Suspended',
    Recognized: 'Recognized',
};
export type AccrualPostingType = typeof AccrualPostingType[keyof typeof AccrualPostingType];

export const AcctHoldingTypeCodes = {
    TANGIBLE: 'TANGIBLE',
    TRK_LOANED: 'TRK_LOANED',
    TRK_BORROWED: 'TRK_BORROWED',
    ALL_HOLDING: 'ALL_HOLDING',
};
export type AcctHoldingTypeCodes = typeof AcctHoldingTypeCodes[keyof typeof AcctHoldingTypeCodes];

export const AdClosure = {
    Discarded: 'Discarded',
    Invalidated: 'Invalidated',
    Request_Invalidate: 'Request_Invalidate',
    Failed: 'Failed',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Treating: 'Treating',
    Retiring: 'Retiring',
    Closed: 'Closed',
};
export type AdClosure = typeof AdClosure[keyof typeof AdClosure];

export const AdPaymentMode = {
    None: 'None',
    Accrue: 'Accrue',
    Advance: 'Advance',
    Retire: 'Retire',
};
export type AdPaymentMode = typeof AdPaymentMode[keyof typeof AdPaymentMode];

export const AdProviderType = {
    NONE: 'NONE',
    Accounting: 'Accounting',
    Audit: 'Audit',
    HumanResources: 'HumanResources',
    IT: 'IT',
    Repair: 'Repair',
    Service: 'Service',
    Telecoms: 'Telecoms',
    Transport: 'Transport',
    Utility: 'Utility',
};
export type AdProviderType = typeof AdProviderType[keyof typeof AdProviderType];

export const AdRequestStatus = {
    VOIDED: 'VOIDED',
    INVALIDATED: 'INVALIDATED',
    FAILED_FINPOST: 'FAILED_FINPOST',
    FAILED_ADMINCLOSE: 'FAILED_ADMINCLOSE',
    FAILED_CTRLCLOSE: 'FAILED_CTRLCLOSE',
    FAILED_APPROVERCLOSE: 'FAILED_APPROVERCLOSE',
    FAILED_SCHEDULED: 'FAILED_SCHEDULED',
    FAILED_ADMINTREAT: 'FAILED_ADMINTREAT',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_CTRLCHECK: 'FAILED_CTRLCHECK',
    FAILED_ADMINCHECK: 'FAILED_ADMINCHECK',
    FAILED_CONFIRM: 'FAILED_CONFIRM',
    RECEIVED: 'RECEIVED',
    CONFIRM: 'CONFIRM',
    ADMIN_CHECK: 'ADMIN_CHECK',
    CTRL_CHECK: 'CTRL_CHECK',
    MGT_APPROVAL: 'MGT_APPROVAL',
    ADMIN_TREAT: 'ADMIN_TREAT',
    SCHEDULED: 'SCHEDULED',
    USER_CLOSE: 'USER_CLOSE',
    APPROVER_CLOSE: 'APPROVER_CLOSE',
    CTRL_CLOSE: 'CTRL_CLOSE',
    ADMIN_CLOSE: 'ADMIN_CLOSE',
    FIN_POST: 'FIN_POST',
    CLOSED: 'CLOSED',
};
export type AdRequestStatus = typeof AdRequestStatus[keyof typeof AdRequestStatus];

export const AdScheduleStatus = {
    VOIDED: 'VOIDED',
    INVALIDATED: 'INVALIDATED',
    FAILED_ADMINTREAT: 'FAILED_ADMINTREAT',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_CTRLCHECK: 'FAILED_CTRLCHECK',
    FAILED_ADMINCHECK: 'FAILED_ADMINCHECK',
    FAILED_CONFIRM: 'FAILED_CONFIRM',
    RECEIVED: 'RECEIVED',
    CONFIRM: 'CONFIRM',
    ADMIN_CHECK: 'ADMIN_CHECK',
    CTRL_CHECK: 'CTRL_CHECK',
    MGT_APPROVAL: 'MGT_APPROVAL',
    ADMIN_TREAT: 'ADMIN_TREAT',
    USER_CLOSING: 'USER_CLOSING',
    ADMIN_CLOSING: 'ADMIN_CLOSING',
    CLOSED: 'CLOSED',
};
export type AdScheduleStatus = typeof AdScheduleStatus[keyof typeof AdScheduleStatus];

export const AdTripType = {
    Departure: 'Departure',
    Return: 'Return',
    RoundTrip: 'RoundTrip',
};
export type AdTripType = typeof AdTripType[keyof typeof AdTripType];

export const AdVehicleSource = {
    Fleet: 'Fleet',
    Thirdparty: 'Thirdparty',
};
export type AdVehicleSource = typeof AdVehicleSource[keyof typeof AdVehicleSource];

export const AdVehicleType = {
    Bike: 'Bike',
    Bus: 'Bus',
    Car: 'Car',
    Plane: 'Plane',
};
export type AdVehicleType = typeof AdVehicleType[keyof typeof AdVehicleType];

export const AdviceRecommendations = {
    BUY: 'BUY',
    ACCUMULATE: 'ACCUMULATE',
    NEUTRAL: 'NEUTRAL',
    REDUCE: 'REDUCE',
    SELL: 'SELL',
    UNDER_REVIEW: 'UNDER_REVIEW',
};
export type AdviceRecommendations = typeof AdviceRecommendations[keyof typeof AdviceRecommendations];

export const AllotModeEnum = {
    DIRECT: 'DIRECT',
    OMNIBUS: 'OMNIBUS',
    NOMINEE: 'NOMINEE',
};
export type AllotModeEnum = typeof AllotModeEnum[keyof typeof AllotModeEnum];

export const AllotTreatmentEnum = {
    DontAllot: 'DontAllot',
    Buy: 'Buy',
    Sell: 'Sell',
};
export type AllotTreatmentEnum = typeof AllotTreatmentEnum[keyof typeof AllotTreatmentEnum];

export const AuthenticationType = {
    APP: 'APP',
    CERT: 'CERT',
    DB: 'DB',
    LDAP: 'LDAP',
    OAUTH: 'OAUTH',
    PROV: 'PROV',
};
export type AuthenticationType = typeof AuthenticationType[keyof typeof AuthenticationType];

export const BasisClosure = {
    New: 'New',
    Open: 'Open',
    Closed: 'Closed',
    InternalAudit: 'InternalAudit',
    ExternalAudit: 'ExternalAudit',
};
export type BasisClosure = typeof BasisClosure[keyof typeof BasisClosure];

export const BasisPeriodNo = {
    Period1: 'Period1',
    Period2: 'Period2',
    Period3: 'Period3',
    Period4: 'Period4',
    Period5: 'Period5',
    Period6: 'Period6',
    Period7: 'Period7',
    Period8: 'Period8',
    Period9: 'Period9',
    Period10: 'Period10',
    Period11: 'Period11',
    Period12: 'Period12',
    OtherPeriods: 'OtherPeriods',
};
export type BasisPeriodNo = typeof BasisPeriodNo[keyof typeof BasisPeriodNo];

export const BondValuationReason = {
    AccrueCostInterest: 'AccrueCostInterest',
    FairValueAdjust: 'FairValueAdjust',
    Sale: 'Sale',
};
export type BondValuationReason = typeof BondValuationReason[keyof typeof BondValuationReason];

export const BudgetTypes = {
    None: 'None',
    Periods: 'Periods',
    Year: 'Year',
};
export type BudgetTypes = typeof BudgetTypes[keyof typeof BudgetTypes];

export const BulletType = {
    Manual: 'Manual',
    FixedInterval: 'FixedInterval',
    QuarterAlignment: 'QuarterAlignment',
    SemiAnnualAlignment: 'SemiAnnualAlignment',
    YearlyAlignment: 'YearlyAlignment',
};
export type BulletType = typeof BulletType[keyof typeof BulletType];

export const CaseInitiator = {
    Firm: 'Firm',
    Partner: 'Partner',
    Provider: 'Provider',
    Regulator: 'Regulator',
};
export type CaseInitiator = typeof CaseInitiator[keyof typeof CaseInitiator];

export const CaseTypeEnum = {
    Admin: 'Admin',
    Complaint: 'Complaint',
};
export type CaseTypeEnum = typeof CaseTypeEnum[keyof typeof CaseTypeEnum];

export const CashBalanceType = {
    ALL: 'ALL',
    TRADE: 'TRADE',
    WITHDRAW: 'WITHDRAW',
};
export type CashBalanceType = typeof CashBalanceType[keyof typeof CashBalanceType];

export const CashStmtClosure = {
    Pending: 'Pending',
    Loaded: 'Loaded',
    Approved: 'Approved',
    PartiallyMatched: 'PartiallyMatched',
    FullyMatched: 'FullyMatched',
    Closed: 'Closed',
    Sealed: 'Sealed',
};
export type CashStmtClosure = typeof CashStmtClosure[keyof typeof CashStmtClosure];

export const CertClosure = {
    Invalidated: 'Invalidated',
    Pending: 'Pending',
    Processing: 'Processing',
    Dematerialized: 'Dematerialized',
    Retrieved: 'Retrieved',
    ReturnedOwner: 'ReturnedOwner',
};
export type CertClosure = typeof CertClosure[keyof typeof CertClosure];

export const CertClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Invalidated: 'Invalidated',
    Pending: 'Pending',
    Processing: 'Processing',
    Dematerialized: 'Dematerialized',
    Retrieved: 'Retrieved',
    ReturnedOwner: 'ReturnedOwner',
};
export type CertClosureSupra = typeof CertClosureSupra[keyof typeof CertClosureSupra];

export const CertDepositClosure = {
    Pending: 'Pending',
    Processed: 'Processed',
};
export type CertDepositClosure = typeof CertDepositClosure[keyof typeof CertDepositClosure];

export const CertType = {
    NA: 'NA',
    P_ISSUE: 'P_ISSUE',
    SPLIT: 'SPLIT',
    MERGER: 'MERGER',
    SPLIT_R: 'SPLIT_R',
};
export type CertType = typeof CertType[keyof typeof CertType];

export const Channels = {
    SYSTEM: 'SYSTEM',
    EMAIL: 'EMAIL',
    FILE: 'FILE',
    LETTER: 'LETTER',
    PM: 'PM',
    SMS: 'SMS',
    PORTAL: 'PORTAL',
    REMOTEAPP: 'REMOTEAPP',
    INTEGRATION: 'INTEGRATION',
    UPLOAD: 'UPLOAD',
};
export type Channels = typeof Channels[keyof typeof Channels];

export const CommShareKeys = {
    AgentKey: 'AgentKey',
    ExecutorKey: 'ExecutorKey',
    OwnKey: 'OwnKey',
    RebateKey: 'RebateKey',
};
export type CommShareKeys = typeof CommShareKeys[keyof typeof CommShareKeys];

export const ContractClosure = {
    Reversed: 'Reversed',
    Partial: 'Partial',
    Posted: 'Posted',
};
export type ContractClosure = typeof ContractClosure[keyof typeof ContractClosure];

export const ConvertStatusEnum = {
    REQUEST_VOID: 'REQUEST_VOID',
    FAILED_RECEIPT: 'FAILED_RECEIPT',
    FAILED_SOURCEMOVE: 'FAILED_SOURCEMOVE',
    FAILED_INITIATE: 'FAILED_INITIATE',
    Pending: 'Pending',
    Initiated: 'Initiated',
    SourceMoved: 'SourceMoved',
    AutoReceived: 'AutoReceived',
    CertReceived: 'CertReceived',
    VOIDED: 'VOIDED',
};
export type ConvertStatusEnum = typeof ConvertStatusEnum[keyof typeof ConvertStatusEnum];

export const CorpActionClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    Approved: 'Approved',
    Closed: 'Closed',
    AllotmentPartial: 'AllotmentPartial',
    AllotmentFull: 'AllotmentFull',
};
export type CorpActionClosure = typeof CorpActionClosure[keyof typeof CorpActionClosure];

export const CorpActionMergerType = {
    BasicAcquisition: 'BasicAcquisition',
    BasicMerger: 'BasicMerger',
    DivestComplete: 'DivestComplete',
    DivestPartial: 'DivestPartial',
    DivestRetain: 'DivestRetain',
};
export type CorpActionMergerType = typeof CorpActionMergerType[keyof typeof CorpActionMergerType];

export const CorpActionPryIssueTypeEnum = {
    OFFER: 'OFFER',
    PRIVATE: 'PRIVATE',
    RIGHT: 'RIGHT',
    RIGHT_OFFER: 'RIGHT_OFFER',
    RIGHT_PRIVATE: 'RIGHT_PRIVATE',
    SALE: 'SALE',
};
export type CorpActionPryIssueTypeEnum = typeof CorpActionPryIssueTypeEnum[keyof typeof CorpActionPryIssueTypeEnum];

export const CorpActionTypes = {
    NONE: 'NONE',
    SPLIT: 'SPLIT',
    MERGER: 'MERGER',
    PRYISSUE_SUB: 'PRYISSUE_SUB',
    PRYISSUE_ALLOT: 'PRYISSUE_ALLOT',
    DIVIDEND: 'DIVIDEND',
    PRYISSUE_RETURN: 'PRYISSUE_RETURN',
};
export type CorpActionTypes = typeof CorpActionTypes[keyof typeof CorpActionTypes];

export const CSCSResolverClosure = {
    NonReceptiveAccount: 'NonReceptiveAccount',
    MultipleAccounts: 'MultipleAccounts',
    MissingInstrument: 'MissingInstrument',
    MissingAccount: 'MissingAccount',
    Unresolved: 'Unresolved',
    Sorted: 'Sorted',
    AutoReconciled: 'AutoReconciled',
};
export type CSCSResolverClosure = typeof CSCSResolverClosure[keyof typeof CSCSResolverClosure];

export const DataSheetClosure = {
    Invalid: 'Invalid',
    Open: 'Open',
    Closed: 'Closed',
    Processed: 'Processed',
    Archived: 'Archived',
};
export type DataSheetClosure = typeof DataSheetClosure[keyof typeof DataSheetClosure];

export const DealAllotClosure = {
    Reposting: 'Reposting',
    Discarded: 'Discarded',
    Pending: 'Pending',
    ApprovedForDispatch: 'ApprovedForDispatch',
    Matched: 'Matched',
    Posted: 'Posted',
};
export type DealAllotClosure = typeof DealAllotClosure[keyof typeof DealAllotClosure];

export const DealAllotType = {
    ManualAllot: 'ManualAllot',
    AutoAllot: 'AutoAllot',
    FixAllot: 'FixAllot',
    MatchDirect: 'MatchDirect',
    MatchOrder: 'MatchOrder',
};
export type DealAllotType = typeof DealAllotType[keyof typeof DealAllotType];

export const DealMatchClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    OrderVerified: 'OrderVerified',
    AllotCreated: 'AllotCreated',
    AllotVerified: 'AllotVerified',
};
export type DealMatchClosure = typeof DealMatchClosure[keyof typeof DealMatchClosure];

export const DealSheetClosure = {
    Invalidated: 'Invalidated',
    New: 'New',
    OpenForOrders: 'OpenForOrders',
    ClosedForOrders: 'ClosedForOrders',
    DealRevision: 'DealRevision',
    DealsProcessed: 'DealsProcessed',
    DealsPosted: 'DealsPosted',
    DealsSettled: 'DealsSettled',
    Archived: 'Archived',
};
export type DealSheetClosure = typeof DealSheetClosure[keyof typeof DealSheetClosure];

export const DealSource = {
    NA: 'NA',
    CSD: 'CSD',
    EXCH_TRMNL: 'EXCH_TRMNL',
    FIX: 'FIX',
    CUSTOM: 'CUSTOM',
    EXCH_PRTL: 'EXCH_PRTL',
};
export type DealSource = typeof DealSource[keyof typeof DealSource];

export const DelistTypeEnum = {
    Delist: 'Delist',
    Maturity: 'Maturity',
    Recall: 'Recall',
};
export type DelistTypeEnum = typeof DelistTypeEnum[keyof typeof DelistTypeEnum];

export const DiscountTreatment = {
    None: 'None',
    UpfrontDisburse: 'UpfrontDisburse',
    UpfrontPrepayment: 'UpfrontPrepayment',
};
export type DiscountTreatment = typeof DiscountTreatment[keyof typeof DiscountTreatment];

export const DividendType = {
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
    INTERIM: 'INTERIM',
    FULL: 'FULL',
};
export type DividendType = typeof DividendType[keyof typeof DividendType];

export const DocumentCheckStatusEnum = {
    DocumentWrongIssuer: 'DocumentWrongIssuer',
    DocumentExpired: 'DocumentExpired',
    DocumentInvalid: 'DocumentInvalid',
    DocumentMissing: 'DocumentMissing',
    None: 'None',
    SystemExempt: 'SystemExempt',
    SystemPassed: 'SystemPassed',
    UserPassed: 'UserPassed',
};
export type DocumentCheckStatusEnum = typeof DocumentCheckStatusEnum[keyof typeof DocumentCheckStatusEnum];

export const DocumentClass = {
    NONE: 'NONE',
    AOD: 'AOD',
    AUTHORIZATION: 'AUTHORIZATION',
    CERTIFICATE: 'CERTIFICATE',
    CONTRACT: 'CONTRACT',
    DOCUMENTATION: 'DOCUMENTATION',
    DRAWING: 'DRAWING',
    EMP_DETAIL: 'EMP_DETAIL',
    FIN_STATEMENT: 'FIN_STATEMENT',
    GOV_DOC: 'GOV_DOC',
    IDENTITY: 'IDENTITY',
    INCORPORATION: 'INCORPORATION',
    JUDGEMENT: 'JUDGEMENT',
    KYP_COMPLY: 'KYP_COMPLY',
    KYP_CONFIRM: 'KYP_CONFIRM',
    LAW: 'LAW',
    LETTER: 'LETTER',
    MANDATE: 'MANDATE',
    MESSAGE: 'MESSAGE',
    MISCELLANEOUS: 'MISCELLANEOUS',
    OPERATING: 'OPERATING',
    PICTURE: 'PICTURE',
    PUBLICATION: 'PUBLICATION',
    REPORT_GENERAL: 'REPORT_GENERAL',
    REPORT_ECONOMY: 'REPORT_ECONOMY',
    REPORT_INSTRUMENT: 'REPORT_INSTRUMENT',
    REPORT_MARKET: 'REPORT_MARKET',
    REPORT_SECTOR: 'REPORT_SECTOR',
    SIGNATURE: 'SIGNATURE',
    SPECIFICATION: 'SPECIFICATION',
    UTILITY: 'UTILITY',
    VIDEO: 'VIDEO',
};
export type DocumentClass = typeof DocumentClass[keyof typeof DocumentClass];

export const DocumentCurrencyCheckEnum = {
    None: 'None',
    ExpiryDefinite: 'ExpiryDefinite',
    ExpiryOptional: 'ExpiryOptional',
    FromIssue: 'FromIssue',
    FromReceipt: 'FromReceipt',
    TerminateDate: 'TerminateDate',
};
export type DocumentCurrencyCheckEnum = typeof DocumentCurrencyCheckEnum[keyof typeof DocumentCurrencyCheckEnum];

export const DocumentIssuerTypeEnum = {
    None: 'None',
    AnyFirm: 'AnyFirm',
    EconomicCommunity: 'EconomicCommunity',
    Employer: 'Employer',
    FinBank: 'FinBank',
    FinInsurance: 'FinInsurance',
    GovAgency: 'GovAgency',
    GovCompanyReg: 'GovCompanyReg',
    GovFederal: 'GovFederal',
    GovJudiciary: 'GovJudiciary',
    GovLawEnforcement: 'GovLawEnforcement',
    GovLegislature: 'GovLegislature',
    GovLocal: 'GovLocal',
    GovMotorVehicleAuth: 'GovMotorVehicleAuth',
    GovRevenueService: 'GovRevenueService',
    GovState: 'GovState',
    GovTraditional: 'GovTraditional',
    GovUtility: 'GovUtility',
    MktBroker: 'MktBroker',
    MktExchange: 'MktExchange',
    MktRegistrar: 'MktRegistrar',
    MktSEC: 'MktSEC',
    ProfessionalBody: 'ProfessionalBody',
    ReligiousBody: 'ReligiousBody',
    UnitedNations: 'UnitedNations',
};
export type DocumentIssuerTypeEnum = typeof DocumentIssuerTypeEnum[keyof typeof DocumentIssuerTypeEnum];

export const DocumentRequireTypeEnum = {
    Optional: 'Optional',
    AllOf: 'AllOf',
    AnyOf: 'AnyOf',
    Exactly: 'Exactly',
};
export type DocumentRequireTypeEnum = typeof DocumentRequireTypeEnum[keyof typeof DocumentRequireTypeEnum];

export const DocumentStoreTypes = {
    FilePath: 'FilePath',
    Binary: 'Binary',
    BinaryStream: 'BinaryStream',
};
export type DocumentStoreTypes = typeof DocumentStoreTypes[keyof typeof DocumentStoreTypes];

export const EcoAllotMethod = {
    Withdrawal: 'Withdrawal',
    ReverseSplit: 'ReverseSplit',
    Resignation: 'Resignation',
    Punishment: 'Punishment',
    None: 'None',
    Certificate: 'Certificate',
    Offer: 'Offer',
    Rights: 'Rights',
    Split: 'Split',
    Transfer: 'Transfer',
};
export type EcoAllotMethod = typeof EcoAllotMethod[keyof typeof EcoAllotMethod];

export const EcoVestReason = {
    Withdrawal: 'Withdrawal',
    ReverseSplit: 'ReverseSplit',
    Resignation: 'Resignation',
    Punishment: 'Punishment',
    CompensationOption: 'CompensationOption',
    Loyalty: 'Loyalty',
    Performance: 'Performance',
    Split: 'Split',
};
export type EcoVestReason = typeof EcoVestReason[keyof typeof EcoVestReason];

export const EQAccountClassEnum = {
    Exchange: 'Exchange',
    Nominee: 'Nominee',
    OverTheCounter: 'OverTheCounter',
};
export type EQAccountClassEnum = typeof EQAccountClassEnum[keyof typeof EQAccountClassEnum];

export const EQAccountTypeEnum = {
    ExchCollateralCustody: 'ExchCollateralCustody',
    ExchCollateralTracking: 'ExchCollateralTracking',
    ExchCollateralTrade: 'ExchCollateralTrade',
    ExchConduit: 'ExchConduit',
    ExchMktMaker: 'ExchMktMaker',
    ExchRegularTrade: 'ExchRegularTrade',
    ExchSecLendBorrower: 'ExchSecLendBorrower',
    ExchSecLendLender: 'ExchSecLendLender',
    ExchSpecialTrade: 'ExchSpecialTrade',
    NomCollateralCustody: 'NomCollateralCustody',
    NomCollateralTrade: 'NomCollateralTrade',
    NomConduit: 'NomConduit',
    NomRegularTrade: 'NomRegularTrade',
    NomSchemeTrade: 'NomSchemeTrade',
    NomSecLendBorrower: 'NomSecLendBorrower',
    NomSecLendLender: 'NomSecLendLender',
    OtcFundAccount: 'OtcFundAccount',
    OtcRegularTrade: 'OtcRegularTrade',
};
export type EQAccountTypeEnum = typeof EQAccountTypeEnum[keyof typeof EQAccountTypeEnum];

export const EQCertificateStatusEnum = {
    RECEIVED: 'RECEIVED',
    SALES_VAULT: 'SALES_VAULT',
    OPS_VAULT: 'OPS_VAULT',
    REGISTRAR: 'REGISTRAR',
    OWNER: 'OWNER',
    CSCS: 'CSCS',
    RETRIEVED: 'RETRIEVED',
    CONVERTED: 'CONVERTED',
};
export type EQCertificateStatusEnum = typeof EQCertificateStatusEnum[keyof typeof EQCertificateStatusEnum];

export const EQInstrumentAttributeEnum = {
    None: 'None',
    IsClosedEnded: 'IsClosedEnded',
    IsCallable: 'IsCallable',
    IsPutable: 'IsPutable',
    IsBearer: 'IsBearer',
    IsNegotiable: 'IsNegotiable',
    IsSinking: 'IsSinking',
    IsPayoutPredetermined: 'IsPayoutPredetermined',
    AllowPhysicalDeposits: 'AllowPhysicalDeposits',
    AllowTransactions: 'AllowTransactions',
};
export type EQInstrumentAttributeEnum = typeof EQInstrumentAttributeEnum[keyof typeof EQInstrumentAttributeEnum];

export const EQInstrumentClassEnum = {
    Bonds: 'Bonds',
    Commodity: 'Commodity',
    Currency: 'Currency',
    Equity: 'Equity',
    Fund: 'Fund',
    Index: 'Index',
    MoneyMarket: 'MoneyMarket',
    TBills: 'TBills',
};
export type EQInstrumentClassEnum = typeof EQInstrumentClassEnum[keyof typeof EQInstrumentClassEnum];

export const EQInstrumentGlAcctTypes = {
    BrokerExpense: 'BrokerExpense',
    BrokerPayable: 'BrokerPayable',
    BrokerReceivable: 'BrokerReceivable',
    CustodianExpense: 'CustodianExpense',
    CustodianPayable: 'CustodianPayable',
    CustodianReceivable: 'CustodianReceivable',
    FundCustomer: 'FundCustomer',
    FundDistribution: 'FundDistribution',
    FundSubscription: 'FundSubscription',
    FundTransit: 'FundTransit',
    ManagerExpense: 'ManagerExpense',
    ManagerPayable: 'ManagerPayable',
    ManagerReceivable: 'ManagerReceivable',
    PrimaryBank: 'PrimaryBank',
    RegistrarExpense: 'RegistrarExpense',
    RegistrarPayable: 'RegistrarPayable',
    RegistrarReceivable: 'RegistrarReceivable',
    TrusteeExpense: 'TrusteeExpense',
    TrusteePayable: 'TrusteePayable',
    TrusteeReceivable: 'TrusteeReceivable',
};
export type EQInstrumentGlAcctTypes = typeof EQInstrumentGlAcctTypes[keyof typeof EQInstrumentGlAcctTypes];

export const EQInstrumentTypeEnum = {
    BOND_CORP: 'BOND_CORP',
    BOND_NAT: 'BOND_NAT',
    BOND_SUBNAT: 'BOND_SUBNAT',
    CMDTY_PRODUCE: 'CMDTY_PRODUCE',
    CMDTY_RESOURCE: 'CMDTY_RESOURCE',
    CURRENCY: 'CURRENCY',
    EQUITY: 'EQUITY',
    FML: 'FML',
    GDR: 'GDR',
    RIGHTS: 'RIGHTS',
    ETF_FOREIGN: 'ETF_FOREIGN',
    ETF_LOCAL: 'ETF_LOCAL',
    FUND: 'FUND',
    INDEX: 'INDEX',
};
export type EQInstrumentTypeEnum = typeof EQInstrumentTypeEnum[keyof typeof EQInstrumentTypeEnum];

export const FAAssetClosure = {
    Pending: 'Pending',
    Acquired: 'Acquired',
    DepreciatedFully: 'DepreciatedFully',
    Disposed: 'Disposed',
};
export type FAAssetClosure = typeof FAAssetClosure[keyof typeof FAAssetClosure];

export const FAAssetEstimateReason = {
    ACQUISITION: 'ACQUISITION',
    IMPAIRMENT: 'IMPAIRMENT',
    POLICYCHANGE: 'POLICYCHANGE',
    REVALUATION: 'REVALUATION',
    UPGRADE: 'UPGRADE',
};
export type FAAssetEstimateReason = typeof FAAssetEstimateReason[keyof typeof FAAssetEstimateReason];

export const FAAssetType = {
    FixedAsset: 'FixedAsset',
    IntangibleAsset: 'IntangibleAsset',
};
export type FAAssetType = typeof FAAssetType[keyof typeof FAAssetType];

export const FACardType = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
    PREPAID: 'PREPAID',
};
export type FACardType = typeof FACardType[keyof typeof FACardType];

export const FACashAcctTypeEnum = {
    None: 'None',
    PettyCash: 'PettyCash',
    VaultCash: 'VaultCash',
    BusCall: 'BusCall',
    BusChecking: 'BusChecking',
    BusClearing: 'BusClearing',
    BusCollateral: 'BusCollateral',
    BusDomChecking: 'BusDomChecking',
    BusDomSavings: 'BusDomSavings',
    BusFxdDep: 'BusFxdDep',
    BusMerchant: 'BusMerchant',
    BusSavings: 'BusSavings',
    ClientChecking: 'ClientChecking',
    ClientCall: 'ClientCall',
    ClientDomChecking: 'ClientDomChecking',
    ClientDomSavings: 'ClientDomSavings',
    ClientFxdDep: 'ClientFxdDep',
    ClientSavings: 'ClientSavings',
    FinHouseSvcSavings: 'FinHouseSvcSavings',
    FinHouseSvcLoan: 'FinHouseSvcLoan',
    FinHouseSvcCall: 'FinHouseSvcCall',
};
export type FACashAcctTypeEnum = typeof FACashAcctTypeEnum[keyof typeof FACashAcctTypeEnum];

export const FACashDisburseBearer = {
    Self: 'Self',
    Project: 'Project',
    Partner: 'Partner',
};
export type FACashDisburseBearer = typeof FACashDisburseBearer[keyof typeof FACashDisburseBearer];

export const FACashDisburseStatusEnum = {
    FAILED_DISBURSE: 'FAILED_DISBURSE',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_CTRLCHECK: 'FAILED_CTRLCHECK',
    FAILED_CONFIRM: 'FAILED_CONFIRM',
    RECEIVED: 'RECEIVED',
    CONFIRM: 'CONFIRM',
    CTRL_CHECK: 'CTRL_CHECK',
    MGT_APPROVAL: 'MGT_APPROVAL',
    FIN_DISBURSE: 'FIN_DISBURSE',
    FIN_POST: 'FIN_POST',
    INVALIDATED: 'INVALIDATED',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type FACashDisburseStatusEnum = typeof FACashDisburseStatusEnum[keyof typeof FACashDisburseStatusEnum];

export const FACategoryEnum = {
    NONE: 'NONE',
    AGENT: 'AGENT',
    BANK: 'BANK',
    CASH: 'CASH',
    CUSTOMER: 'CUSTOMER',
    DEBT: 'DEBT',
    INTERNAL: 'INTERNAL',
    INVENTORY: 'INVENTORY',
    INTERCOMPANY: 'INTERCOMPANY',
    INVESTMENT: 'INVESTMENT',
    MANUFACTURING: 'MANUFACTURING',
    MARKETOPERATOR: 'MARKETOPERATOR',
    PREPAYMENT: 'PREPAYMENT',
    RECOVERABLE: 'RECOVERABLE',
    STAFF: 'STAFF',
    TRADING: 'TRADING',
    VENDOR: 'VENDOR',
};
export type FACategoryEnum = typeof FACategoryEnum[keyof typeof FACategoryEnum];

export const FACenterType = {
    Cost: 'Cost',
    Profit: 'Profit',
    Investment: 'Investment',
};
export type FACenterType = typeof FACenterType[keyof typeof FACenterType];

export const FACompanyReportTypeEnum = {
    CashFlow: 'CashFlow',
    ComprehensiveIncome: 'ComprehensiveIncome',
    FinancialPosition: 'FinancialPosition',
};
export type FACompanyReportTypeEnum = typeof FACompanyReportTypeEnum[keyof typeof FACompanyReportTypeEnum];

export const FAFinHouseClass = {
    CardIssuer: 'CardIssuer',
    CardProcessor: 'CardProcessor',
    CreditUnion: 'CreditUnion',
    DiscountHouse: 'DiscountHouse',
    Insurance: 'Insurance',
    LocalBank: 'LocalBank',
    MerchantBank: 'MerchantBank',
    MicroFinance: 'MicroFinance',
    MortgageBank: 'MortgageBank',
    NationalBank: 'NationalBank',
    RegionalBank: 'RegionalBank',
};
export type FAFinHouseClass = typeof FAFinHouseClass[keyof typeof FAFinHouseClass];

export const FAGlTypeEnum = {
    ASSET: 'ASSET',
    LIABILITY: 'LIABILITY',
    PROVISION: 'PROVISION',
    EQUITY: 'EQUITY',
    INCOME: 'INCOME',
    EXPENSE: 'EXPENSE',
    OCI: 'OCI',
};
export type FAGlTypeEnum = typeof FAGlTypeEnum[keyof typeof FAGlTypeEnum];

export const FAJrnlTypeEnum = {
    USER: 'USER',
    BANKRECON: 'BANKRECON',
    BASIS_AUDIT: 'BASIS_AUDIT',
    BASIS_CLOSING: 'BASIS_CLOSING',
    BASIS_OPENING: 'BASIS_OPENING',
    BASIS_SUMMARY: 'BASIS_SUMMARY',
    CASH_ADVANCE: 'CASH_ADVANCE',
    CASH_DISBURSE: 'CASH_DISBURSE',
    CASH_REIMBURSE: 'CASH_REIMBURSE',
    CASH_RETIRE: 'CASH_RETIRE',
    CASH_SWAP: 'CASH_SWAP',
    CASH_TRANSFER: 'CASH_TRANSFER',
    CASH_REVALUE: 'CASH_REVALUE',
    CUST_CERTFEE: 'CUST_CERTFEE',
    CUST_CSCSFEE: 'CUST_CSCSFEE',
    CUST_MGTFEE: 'CUST_MGTFEE',
    CUST_XFERFEE: 'CUST_XFERFEE',
    FUNDTRANS: 'FUNDTRANS',
    FAASSET_ACQUIRE: 'FAASSET_ACQUIRE',
    FAASSET_AMORT: 'FAASSET_AMORT',
    FAASSET_DISPOSE: 'FAASSET_DISPOSE',
    FAASSET_MAINTAIN: 'FAASSET_MAINTAIN',
    INCOME_AMORT: 'INCOME_AMORT',
    INCOME_DIRECT: 'INCOME_DIRECT',
    INTEREST: 'INTEREST',
    OPENINGBALANCE: 'OPENINGBALANCE',
    ORDER: 'ORDER',
    PREPAYMENT: 'PREPAYMENT',
    PRYISSUE_PAYMENT: 'PRYISSUE_PAYMENT',
    PRYISSUE_REMIT: 'PRYISSUE_REMIT',
    PTNR_CPRTY_TRANSFER: 'PTNR_CPRTY_TRANSFER',
    PTNR_OWN_TRANSFER: 'PTNR_OWN_TRANSFER',
    PTNR_PAYMENT: 'PTNR_PAYMENT',
    PTNR_RECEIPT: 'PTNR_RECEIPT',
    PTNR_SUBSCRIPTION: 'PTNR_SUBSCRIPTION',
    PTNR_WARRANT: 'PTNR_WARRANT',
    PTNR_PURCH_INVOICE: 'PTNR_PURCH_INVOICE',
    PTNR_SALE_INVOICE: 'PTNR_SALE_INVOICE',
    TI_ACCRUAL: 'TI_ACCRUAL',
    TI_PAYMENT: 'TI_PAYMENT',
    TI_PROVISION: 'TI_PROVISION',
    TI_WRITEOFF: 'TI_WRITEOFF',
    TRADE_CONTRACT: 'TRADE_CONTRACT',
    TRADE_SETTLEMENT: 'TRADE_SETTLEMENT',
};
export type FAJrnlTypeEnum = typeof FAJrnlTypeEnum[keyof typeof FAJrnlTypeEnum];

export const FAReportTreatment = {
    Posting: 'Posting',
    RollupFx: 'RollupFx',
    Summary: 'Summary',
};
export type FAReportTreatment = typeof FAReportTreatment[keyof typeof FAReportTreatment];

export const FAStatementType = {
    Ifrs: 'Ifrs',
    Gaap: 'Gaap',
};
export type FAStatementType = typeof FAStatementType[keyof typeof FAStatementType];

export const FAStatMeasure = {
    Count: 'Count',
    Area: 'Area',
    Force: 'Force',
    Length: 'Length',
    Mass: 'Mass',
    Pressure: 'Pressure',
    Temperature: 'Temperature',
    Time: 'Time',
    Volume: 'Volume',
    Weight: 'Weight',
};
export type FAStatMeasure = typeof FAStatMeasure[keyof typeof FAStatMeasure];

export const FATransactionMethod = {
    CASH: 'CASH',
    CHEQUE: 'CHEQUE',
    DIRECT: 'DIRECT',
    DRAFT: 'DRAFT',
    DTF: 'DTF',
    NEFT: 'NEFT',
    NIBSS: 'NIBSS',
    WARRANT: 'WARRANT',
    CARD: 'CARD',
};
export type FATransactionMethod = typeof FATransactionMethod[keyof typeof FATransactionMethod];

export const FATransactionVerifierType = {
    None: 'None',
    Processor: 'Processor',
    Teller: 'Teller',
};
export type FATransactionVerifierType = typeof FATransactionVerifierType[keyof typeof FATransactionVerifierType];

export const FeatureState = {
    Disabled: 'Disabled',
    Enabled: 'Enabled',
};
export type FeatureState = typeof FeatureState[keyof typeof FeatureState];

export const FinRateType = {
    Annualized: 'Annualized',
    FixedRate: 'FixedRate',
    Monthly: 'Monthly',
};
export type FinRateType = typeof FinRateType[keyof typeof FinRateType];

export const FiTradeClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    ApprovedForDispatch: 'ApprovedForDispatch',
    Allotted: 'Allotted',
    InitPending: 'InitPending',
    Running: 'Running',
    Terminated: 'Terminated',
    RolledOver: 'RolledOver',
};
export type FiTradeClosure = typeof FiTradeClosure[keyof typeof FiTradeClosure];

export const FiTradeClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Discarded: 'Discarded',
    Pending: 'Pending',
    ApprovedForDispatch: 'ApprovedForDispatch',
    Allotted: 'Allotted',
    InitPending: 'InitPending',
    Running: 'Running',
    Terminated: 'Terminated',
    RolledOver: 'RolledOver',
};
export type FiTradeClosureSupra = typeof FiTradeClosureSupra[keyof typeof FiTradeClosureSupra];

export const FundContributionStatusEnum = {
    BUILDING: 'BUILDING',
    FUNDADMIN_REVIEW: 'FUNDADMIN_REVIEW',
    CTRL_REVIEW: 'CTRL_REVIEW',
    FIN_POST: 'FIN_POST',
    INVALIDATED: 'INVALIDATED',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type FundContributionStatusEnum = typeof FundContributionStatusEnum[keyof typeof FundContributionStatusEnum];

export const FundLiquidationReason = {
    Redemption: 'Redemption',
    PreExitLoan: 'PreExitLoan',
    HouseKeeping: 'HouseKeeping',
};
export type FundLiquidationReason = typeof FundLiquidationReason[keyof typeof FundLiquidationReason];

export const FundLoanRepayStatusEnum = {
    BUILDING: 'BUILDING',
    FUNDADMIN_REVIEW: 'FUNDADMIN_REVIEW',
    CTRL_SUBMISSION: 'CTRL_SUBMISSION',
    SCHEMEADMIN_COLLATE: 'SCHEMEADMIN_COLLATE',
    CTRL_REVIEW: 'CTRL_REVIEW',
    FIN_POST: 'FIN_POST',
    INVALIDATED: 'INVALIDATED',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type FundLoanRepayStatusEnum = typeof FundLoanRepayStatusEnum[keyof typeof FundLoanRepayStatusEnum];

export const FundLoanStatusEnum = {
    FAILED: 'FAILED',
    RECEIVED: 'RECEIVED',
    FUNDADMIN_REVIEW: 'FUNDADMIN_REVIEW',
    CTRL_REVIEW: 'CTRL_REVIEW',
    FIN_POST: 'FIN_POST',
    INVALIDATED: 'INVALIDATED',
    RUNNING: 'RUNNING',
    TERMINATED: 'TERMINATED',
};
export type FundLoanStatusEnum = typeof FundLoanStatusEnum[keyof typeof FundLoanStatusEnum];

export const FundOrderStating = {
    QTY: 'QTY',
    VAL: 'VAL',
};
export type FundOrderStating = typeof FundOrderStating[keyof typeof FundOrderStating];

export const FxHandlingTypeEnum = {
    NONE: 'NONE',
    TRACK: 'TRACK',
    TRADE: 'TRADE',
    TRACK_TRADE: 'TRACK_TRADE',
};
export type FxHandlingTypeEnum = typeof FxHandlingTypeEnum[keyof typeof FxHandlingTypeEnum];

export const GeneralApprovalClosure = {
    Invalidated: 'Invalidated',
    Request_Invalidate: 'Request_Invalidate',
    Failed: 'Failed',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Implementing: 'Implementing',
    Done: 'Done',
};
export type GeneralApprovalClosure = typeof GeneralApprovalClosure[keyof typeof GeneralApprovalClosure];

export const GeneralApprovalClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Invalidated: 'Invalidated',
    Request_Invalidate: 'Request_Invalidate',
    Failed: 'Failed',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Implementing: 'Implementing',
    Done: 'Done',
};
export type GeneralApprovalClosureSupra = typeof GeneralApprovalClosureSupra[keyof typeof GeneralApprovalClosureSupra];

export const GeneralDataClosure = {
    Corrupted: 'Corrupted',
    Expired: 'Expired',
    Invalidated: 'Invalidated',
    Pending: 'Pending',
    Approved: 'Approved',
    Published: 'Published',
};
export type GeneralDataClosure = typeof GeneralDataClosure[keyof typeof GeneralDataClosure];

export const GovIdProvisionEnum = {
    Indeterminate: 'Indeterminate',
    Given: 'Given',
    NotIssued: 'NotIssued',
    NotObtained: 'NotObtained',
    NotRequired: 'NotRequired',
};
export type GovIdProvisionEnum = typeof GovIdProvisionEnum[keyof typeof GovIdProvisionEnum];

export const HoldingCorpActionClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    CertFullReceipt: 'CertFullReceipt',
    EFullReceipt: 'EFullReceipt',
};
export type HoldingCorpActionClosure = typeof HoldingCorpActionClosure[keyof typeof HoldingCorpActionClosure];

export const HoldingMovtClosure = {
    PendingRequest: 'PendingRequest',
    Failed: 'Failed',
    Cancelled: 'Cancelled',
    Pending: 'Pending',
    Processing: 'Processing',
    Posted: 'Posted',
    Closed: 'Closed',
};
export type HoldingMovtClosure = typeof HoldingMovtClosure[keyof typeof HoldingMovtClosure];

export const HoldingMovtClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    PendingRequest: 'PendingRequest',
    Failed: 'Failed',
    Cancelled: 'Cancelled',
    Pending: 'Pending',
    Processing: 'Processing',
    Posted: 'Posted',
    Closed: 'Closed',
};
export type HoldingMovtClosureSupra = typeof HoldingMovtClosureSupra[keyof typeof HoldingMovtClosureSupra];

export const HoldingPryIssueClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    Processed: 'Processed',
    Remitted: 'Remitted',
    Alloted: 'Alloted',
};
export type HoldingPryIssueClosure = typeof HoldingPryIssueClosure[keyof typeof HoldingPryIssueClosure];

export const HoldingPryIssueClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Processed: 'Processed',
    Remitted: 'Remitted',
    Alloted: 'Alloted',
};
export type HoldingPryIssueClosureSupra = typeof HoldingPryIssueClosureSupra[keyof typeof HoldingPryIssueClosureSupra];

export const HRClosure = {
    Discarded: 'Discarded',
    Failed: 'Failed',
    Invalid: 'Invalid',
    New: 'New',
    Open: 'Open',
    Approving: 'Approving',
    Running: 'Running',
    Closed: 'Closed',
    Terminated: 'Terminated',
    Archived: 'Archived',
};
export type HRClosure = typeof HRClosure[keyof typeof HRClosure];

export const HRConfirmRecommendation = {
    Confirm: 'Confirm',
    ConfirmPromote: 'ConfirmPromote',
    Disengage: 'Disengage',
    ExtendProbation: 'ExtendProbation',
};
export type HRConfirmRecommendation = typeof HRConfirmRecommendation[keyof typeof HRConfirmRecommendation];

export const HRConfirmState = {
    Contract: 'Contract',
    Probation: 'Probation',
    Ongoing: 'Ongoing',
    ExtendProbation: 'ExtendProbation',
    Confirmed: 'Confirmed',
};
export type HRConfirmState = typeof HRConfirmState[keyof typeof HRConfirmState];

export const HREduInstituteTypeEnum = {
    College: 'College',
    CraftSchool: 'CraftSchool',
    LawSchool: 'LawSchool',
    MedicalSchool: 'MedicalSchool',
    Polytechnic: 'Polytechnic',
    ProfessionalBody: 'ProfessionalBody',
    SecondarySchool: 'SecondarySchool',
    TrainingInstitute: 'TrainingInstitute',
    University: 'University',
};
export type HREduInstituteTypeEnum = typeof HREduInstituteTypeEnum[keyof typeof HREduInstituteTypeEnum];

export const HRLeaveExclusionReason = {
    APPRAISAL: 'APPRAISAL',
    AUDIT: 'AUDIT',
    RETREAT: 'RETREAT',
};
export type HRLeaveExclusionReason = typeof HRLeaveExclusionReason[keyof typeof HRLeaveExclusionReason];

export const HRLeaveStatusEnum = {
    FAILED_DISCARDED: 'FAILED_DISCARDED',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_HRAPPROVE: 'FAILED_HRAPPROVE',
    FAILED_SUPAPPROVE: 'FAILED_SUPAPPROVE',
    NEW: 'NEW',
    SUP_APPROVAL: 'SUP_APPROVAL',
    HR_APPROVAL: 'HR_APPROVAL',
    MGT_APPROVAL: 'MGT_APPROVAL',
    WAIT_COMMENCE: 'WAIT_COMMENCE',
    RUNNING: 'RUNNING',
    STAFF_CLOSING: 'STAFF_CLOSING',
    HR_CLOSING: 'HR_CLOSING',
    CLOSED: 'CLOSED',
};
export type HRLeaveStatusEnum = typeof HRLeaveStatusEnum[keyof typeof HRLeaveStatusEnum];

export const HRLeaveTypeEnum = {
    Annual: 'Annual',
    Casual: 'Casual',
    Exam: 'Exam',
    Sick: 'Sick',
    Bereavement: 'Bereavement',
    Maternity: 'Maternity',
    Paternity: 'Paternity',
};
export type HRLeaveTypeEnum = typeof HRLeaveTypeEnum[keyof typeof HRLeaveTypeEnum];

export const HRPayBatchMode = {
    NONE: 'NONE',
    ONCEREQUEST: 'ONCEREQUEST',
    QUARTERSTART: 'QUARTERSTART',
    QUARTEREND: 'QUARTEREND',
    YEARSTART: 'YEARSTART',
    YEAREND: 'YEAREND',
    NOVEMBER: 'NOVEMBER',
    DECEMBER: 'DECEMBER',
};
export type HRPayBatchMode = typeof HRPayBatchMode[keyof typeof HRPayBatchMode];

export const HRPayChangeReason = {
    Confirmation: 'Confirmation',
    Decrease: 'Decrease',
    Demotion: 'Demotion',
    Employment: 'Employment',
    Increase: 'Increase',
    Promotion: 'Promotion',
    Rehire: 'Rehire',
    Restructure: 'Restructure',
};
export type HRPayChangeReason = typeof HRPayChangeReason[keyof typeof HRPayChangeReason];

export const HRPayClass = {
    BasePay: 'BasePay',
    Allowance: 'Allowance',
    Benefit: 'Benefit',
    Deduction: 'Deduction',
    EmployeeContribution: 'EmployeeContribution',
    CompanyContribution: 'CompanyContribution',
    Tax: 'Tax',
    Refund: 'Refund',
    Relief: 'Relief',
    Scheme: 'Scheme',
    Loan: 'Loan',
    Penalty: 'Penalty',
    Statutory: 'Statutory',
};
export type HRPayClass = typeof HRPayClass[keyof typeof HRPayClass];

export const HRPayDisburseMethod = {
    Monthly: 'Monthly',
    CanBatch: 'CanBatch',
    MustBatch: 'MustBatch',
    Reimbursable: 'Reimbursable',
    Leave: 'Leave',
};
export type HRPayDisburseMethod = typeof HRPayDisburseMethod[keyof typeof HRPayDisburseMethod];

export const HRPayrollClosure = {
    FirstToReview: 'FirstToReview',
    IntCtrlToReview: 'IntCtrlToReview',
    MgtToApprove: 'MgtToApprove',
    PendingPosting: 'PendingPosting',
    Posted: 'Posted',
};
export type HRPayrollClosure = typeof HRPayrollClosure[keyof typeof HRPayrollClosure];

export const HRPayrollStatusEnum = {
    FAILED_DISCARDED: 'FAILED_DISCARDED',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_INTCTRLAPPROVE: 'FAILED_INTCTRLAPPROVE',
    FAILED_HRAPPROVE: 'FAILED_HRAPPROVE',
    NEW: 'NEW',
    HR_APPROVAL: 'HR_APPROVAL',
    INTCTRL_APPROVAL: 'INTCTRL_APPROVAL',
    MGT_APPROVAL: 'MGT_APPROVAL',
    WAIT_DISBURSE: 'WAIT_DISBURSE',
    RUNNING: 'RUNNING',
    STAFF_CLOSING: 'STAFF_CLOSING',
    HR_CLOSING: 'HR_CLOSING',
    CLOSED: 'CLOSED',
};
export type HRPayrollStatusEnum = typeof HRPayrollStatusEnum[keyof typeof HRPayrollStatusEnum];

export const HRPayrollType = {
    Monthly: 'Monthly',
    Quarter: 'Quarter',
    SemiAnnual: 'SemiAnnual',
    YearEnd: 'YearEnd',
};
export type HRPayrollType = typeof HRPayrollType[keyof typeof HRPayrollType];

export const HRPerfRecommendation = {
    ACKNOWLEDGE: 'ACKNOWLEDGE',
    CAUTION: 'CAUTION',
    COMMENDATION: 'COMMENDATION',
    DEMOTION: 'DEMOTION',
    DISCHARGE: 'DISCHARGE',
    LATERAL_MOVE: 'LATERAL_MOVE',
    PROMOTION: 'PROMOTION',
    RESIGNATION: 'RESIGNATION',
    SKIP: 'SKIP',
};
export type HRPerfRecommendation = typeof HRPerfRecommendation[keyof typeof HRPerfRecommendation];

export const HRPerfStatusEnum = {
    FAILED_DISCARDED: 'FAILED_DISCARDED',
    FAILED_SKIPPED: 'FAILED_SKIPPED',
    NEW: 'NEW',
    SUP_APPRAISING: 'SUP_APPRAISING',
    SUP_STAFFCOMMENT: 'SUP_STAFFCOMMENT',
    SUP_BACKREVIEW: 'SUP_BACKREVIEW',
    MGT_REVIEW: 'MGT_REVIEW',
    HR_COMMUNICATING: 'HR_COMMUNICATING',
    CLOSED: 'CLOSED',
};
export type HRPerfStatusEnum = typeof HRPerfStatusEnum[keyof typeof HRPerfStatusEnum];

export const HRPresenceEnum = {
    Client: 'Client',
    Offsite: 'Offsite',
    Onsite: 'Onsite',
    Remote: 'Remote',
};
export type HRPresenceEnum = typeof HRPresenceEnum[keyof typeof HRPresenceEnum];

export const HRProbationPeriodType = {
    Days: 'Days',
    Weeks: 'Weeks',
    Months: 'Months',
};
export type HRProbationPeriodType = typeof HRProbationPeriodType[keyof typeof HRProbationPeriodType];

export const HRQualificationTypeEnum = {
    Academic: 'Academic',
    Professional: 'Professional',
};
export type HRQualificationTypeEnum = typeof HRQualificationTypeEnum[keyof typeof HRQualificationTypeEnum];

export const HRRewardTypeEnum = {
    Incentive: 'Incentive',
    Sanction: 'Sanction',
};
export type HRRewardTypeEnum = typeof HRRewardTypeEnum[keyof typeof HRRewardTypeEnum];

export const HRSchemePayment = {
    FirmExact: 'FirmExact',
    FirmArmotized: 'FirmArmotized',
    FirmAU: 'FirmAU',
    ProviderADM: 'ProviderADM',
    ProviderADQ: 'ProviderADQ',
    ProviderEDM: 'ProviderEDM',
    ProviderEDQ: 'ProviderEDQ',
    ProviderAU: 'ProviderAU',
};
export type HRSchemePayment = typeof HRSchemePayment[keyof typeof HRSchemePayment];

export const HRStaffCategory = {
    EXECUTIVE: 'EXECUTIVE',
    SENIOR_MGT: 'SENIOR_MGT',
    FULL: 'FULL',
    TRAINEE: 'TRAINEE',
    INTERN: 'INTERN',
    CONTRACT: 'CONTRACT',
    TEMP: 'TEMP',
    NAT_SERVICE: 'NAT_SERVICE',
};
export type HRStaffCategory = typeof HRStaffCategory[keyof typeof HRStaffCategory];

export const HRWorkflowClosure = {
    Discarded: 'Discarded',
    Failed: 'Failed',
    Invalid: 'Invalid',
    New: 'New',
    Validating: 'Validating',
    Reviewing: 'Reviewing',
    Running: 'Running',
    PrepForClose: 'PrepForClose',
    Closed: 'Closed',
    Archived: 'Archived',
};
export type HRWorkflowClosure = typeof HRWorkflowClosure[keyof typeof HRWorkflowClosure];

export const HRWorkflowClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Discarded: 'Discarded',
    Failed: 'Failed',
    Invalid: 'Invalid',
    New: 'New',
    Validating: 'Validating',
    Reviewing: 'Reviewing',
    Running: 'Running',
    PrepForClose: 'PrepForClose',
    Closed: 'Closed',
    Archived: 'Archived',
};
export type HRWorkflowClosureSupra = typeof HRWorkflowClosureSupra[keyof typeof HRWorkflowClosureSupra];

export const IndexWeighting = {
    PRICE: 'PRICE',
    QUANTITY: 'QUANTITY',
    VALUE: 'VALUE',
};
export type IndexWeighting = typeof IndexWeighting[keyof typeof IndexWeighting];

export const InstrumentIssuerClass = {
    Corporate: 'Corporate',
    Government: 'Government',
};
export type InstrumentIssuerClass = typeof InstrumentIssuerClass[keyof typeof InstrumentIssuerClass];

export const InstrumentStatClass = {
    Nav: 'Nav',
    Price: 'Price',
    QuantityTraded: 'QuantityTraded',
    ValueTraded: 'ValueTraded',
    Yield: 'Yield',
};
export type InstrumentStatClass = typeof InstrumentStatClass[keyof typeof InstrumentStatClass];

export const InstrumentStatType = {
    High: 'High',
    Low: 'Low',
};
export type InstrumentStatType = typeof InstrumentStatType[keyof typeof InstrumentStatType];

export const InstrumentValuationMethod = {
    AmortiseCost: 'AmortiseCost',
    FVTPL: 'FVTPL',
    FVTOCI: 'FVTOCI',
};
export type InstrumentValuationMethod = typeof InstrumentValuationMethod[keyof typeof InstrumentValuationMethod];

export const InterestDayCount = {
    UsPsa30_360: 'UsPsa30_360',
    ActualActual: 'ActualActual',
    Actual360: 'Actual360',
    Actual365: 'Actual365',
    Europ30_360: 'Europ30_360',
    NigeriaActual366: 'NigeriaActual366',
};
export type InterestDayCount = typeof InterestDayCount[keyof typeof InterestDayCount];

export const InvstMgtChangeType = {
    Absolute: 'Absolute',
    Percentage: 'Percentage',
};
export type InvstMgtChangeType = typeof InvstMgtChangeType[keyof typeof InvstMgtChangeType];

export const InvstMgtFeePeriodType = {
    None: 'None',
    OneOffBackLoaded: 'OneOffBackLoaded',
    OneOffFrontLoaded: 'OneOffFrontLoaded',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Yearly: 'Yearly',
};
export type InvstMgtFeePeriodType = typeof InvstMgtFeePeriodType[keyof typeof InvstMgtFeePeriodType];

export const InvstMgtFlowTypes = {
    ALL: 'ALL',
    CASH: 'CASH',
    CERT: 'CERT',
    MEMBER_XFER: 'MEMBER_XFER',
};
export type InvstMgtFlowTypes = typeof InvstMgtFlowTypes[keyof typeof InvstMgtFlowTypes];

export const InvstMgtGrowthBasis = {
    None: 'None',
    All: 'All',
    ThresholdAll: 'ThresholdAll',
    ThresholdResidue: 'ThresholdResidue',
};
export type InvstMgtGrowthBasis = typeof InvstMgtGrowthBasis[keyof typeof InvstMgtGrowthBasis];

export const InvstMgtReferenceValueType = {
    Market: 'Market',
    Portfolio: 'Portfolio',
};
export type InvstMgtReferenceValueType = typeof InvstMgtReferenceValueType[keyof typeof InvstMgtReferenceValueType];

export const InvstMgtTriggerType = {
    NotifyClient: 'NotifyClient',
    NotifyPm: 'NotifyPm',
    NotifyPmClient: 'NotifyPmClient',
    OrderBuy: 'OrderBuy',
    OrderSell: 'OrderSell',
};
export type InvstMgtTriggerType = typeof InvstMgtTriggerType[keyof typeof InvstMgtTriggerType];

export const InvstMgtValuationStyle = {
    PointInTime: 'PointInTime',
    Period: 'Period',
    PeriodWithFees: 'PeriodWithFees',
};
export type InvstMgtValuationStyle = typeof InvstMgtValuationStyle[keyof typeof InvstMgtValuationStyle];

export const InvstMgtValueBasis = {
    PeriodStart: 'PeriodStart',
    PeriodAverage: 'PeriodAverage',
    PeriodEnd: 'PeriodEnd',
};
export type InvstMgtValueBasis = typeof InvstMgtValueBasis[keyof typeof InvstMgtValueBasis];

export const InvstMgtWatchClosure = {
    Discarded: 'Discarded',
    Request_Discard: 'Request_Discard',
    Suspended: 'Suspended',
    Request_Suspend: 'Request_Suspend',
    Pending: 'Pending',
    Active: 'Active',
    Triggered: 'Triggered',
    Terminated: 'Terminated',
    Lapsed: 'Lapsed',
};
export type InvstMgtWatchClosure = typeof InvstMgtWatchClosure[keyof typeof InvstMgtWatchClosure];

export const InvstMgtWatchType = {
    DecreaseAbsolute: 'DecreaseAbsolute',
    DecreasePercent: 'DecreasePercent',
    DefiniteValue: 'DefiniteValue',
    IncreasePercent: 'IncreasePercent',
    IncreaseAbsolute: 'IncreaseAbsolute',
};
export type InvstMgtWatchType = typeof InvstMgtWatchType[keyof typeof InvstMgtWatchType];

export const JobClosure = {
    IsDisabled: 'IsDisabled',
    IsActive: 'IsActive',
};
export type JobClosure = typeof JobClosure[keyof typeof JobClosure];

export const JournalClosure = {
    Gateway_ToTransact: 'Gateway_ToTransact',
    Gateway_ToConfirm: 'Gateway_ToConfirm',
    Invalidated: 'Invalidated',
    Request_Invalidate: 'Request_Invalidate',
    Failed: 'Failed',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Closed: 'Closed',
    Clearing: 'Clearing',
    Posted: 'Posted',
    Reversed: 'Reversed',
    Archived: 'Archived',
};
export type JournalClosure = typeof JournalClosure[keyof typeof JournalClosure];

export const JournalClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Gateway_ToTransact: 'Gateway_ToTransact',
    Gateway_ToConfirm: 'Gateway_ToConfirm',
    Invalidated: 'Invalidated',
    Request_Invalidate: 'Request_Invalidate',
    Failed: 'Failed',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Closed: 'Closed',
    Clearing: 'Clearing',
    Posted: 'Posted',
    Reversed: 'Reversed',
    Archived: 'Archived',
};
export type JournalClosureSupra = typeof JournalClosureSupra[keyof typeof JournalClosureSupra];

export const MaintDetailType = {
    Part: 'Part',
    Work: 'Work',
    ServicePackage: 'ServicePackage',
};
export type MaintDetailType = typeof MaintDetailType[keyof typeof MaintDetailType];

export const MarketDataType = {
    HolidayInterpolate: 'HolidayInterpolate',
    TradeCompute: 'TradeCompute',
    TradeInterpolate: 'TradeInterpolate',
    TradeRaw: 'TradeRaw',
};
export type MarketDataType = typeof MarketDataType[keyof typeof MarketDataType];

export const MarketOperatorClass = {
    NONE: 'NONE',
    AssetMgt: 'AssetMgt',
    Brokerage: 'Brokerage',
    ClearingHouse: 'ClearingHouse',
    Custodian: 'Custodian',
    Exchange: 'Exchange',
    FinancialAdviser: 'FinancialAdviser',
    IssuingHouse: 'IssuingHouse',
    LendingAgent: 'LendingAgent',
    Registrar: 'Registrar',
    SEC: 'SEC',
    Trustee: 'Trustee',
    PDMM: 'PDMM',
    TechnologyProvider: 'TechnologyProvider',
};
export type MarketOperatorClass = typeof MarketOperatorClass[keyof typeof MarketOperatorClass];

export const MarStatusEnum = {
    NA: 'NA',
    Divorced: 'Divorced',
    Married: 'Married',
    Separated: 'Separated',
    Single: 'Single',
    Widowed: 'Widowed',
};
export type MarStatusEnum = typeof MarStatusEnum[keyof typeof MarStatusEnum];

export const metaUserClosure = {
    IsDeleted: 'IsDeleted',
    IsLocked: 'IsLocked',
    IsActive: 'IsActive',
};
export type metaUserClosure = typeof metaUserClosure[keyof typeof metaUserClosure];

export const metaUserKnownNames = {
    griffinsetup: 'griffinsetup',
    pxadmin: 'pxadmin',
    pxtest: 'pxtest',
    griffinguest: 'griffinguest',
    grifintegrator: 'grifintegrator',
    grifintegratoradmin: 'grifintegratoradmin',
    eiMarshal: 'eiMarshal',
};
export type metaUserKnownNames = typeof metaUserKnownNames[keyof typeof metaUserKnownNames];

export const MoneyMarketCategory = {
    BOND: 'BOND',
    COMMERCIAL_PAPER: 'COMMERCIAL_PAPER',
    FIXED_DEPOSIT: 'FIXED_DEPOSIT',
    INTERBANK: 'INTERBANK',
    TBILL: 'TBILL',
    TERM_INSTRUMENT: 'TERM_INSTRUMENT',
    SAVINGS_DEPOSIT: 'SAVINGS_DEPOSIT',
};
export type MoneyMarketCategory = typeof MoneyMarketCategory[keyof typeof MoneyMarketCategory];

export const MoneyMarketExit = {
    Maturing: 'Maturing',
    Selling: 'Selling',
};
export type MoneyMarketExit = typeof MoneyMarketExit[keyof typeof MoneyMarketExit];

export const MoneyMarketType = {
    CENBNK_MPR: 'CENBNK_MPR',
    FXDEP_VAR: 'FXDEP_VAR',
    FXDEP_030: 'FXDEP_030',
    FXDEP_060: 'FXDEP_060',
    FXDEP_090: 'FXDEP_090',
    FXDEP_120: 'FXDEP_120',
    FXDEP_180: 'FXDEP_180',
    FXDEP_360: 'FXDEP_360',
    INTERBANK_CALL: 'INTERBANK_CALL',
    INTERBANK_OBB: 'INTERBANK_OBB',
    INTERBANK_007: 'INTERBANK_007',
    INTERBANK_030: 'INTERBANK_030',
    INTERBANK_060: 'INTERBANK_060',
    INTERBANK_090: 'INTERBANK_090',
    INTERBANK_180: 'INTERBANK_180',
    INTERBANK_360: 'INTERBANK_360',
    TBILL_VAR: 'TBILL_VAR',
    TBILL_091: 'TBILL_091',
    TBILL_182: 'TBILL_182',
    TBILL_364: 'TBILL_364',
};
export type MoneyMarketType = typeof MoneyMarketType[keyof typeof MoneyMarketType];

export const MovtClosure = {
    QuantityDiffers: 'QuantityDiffers',
    InstrNotFound: 'InstrNotFound',
    AcctNotFound: 'AcctNotFound',
    TxnNotFound: 'TxnNotFound',
    TxnNotMatched: 'TxnNotMatched',
    TxnFound: 'TxnFound',
    TxnProcessed: 'TxnProcessed',
};
export type MovtClosure = typeof MovtClosure[keyof typeof MovtClosure];

export const MsgClosure = {
    Discarded: 'Discarded',
    Composing: 'Composing',
    ClearedForSend: 'ClearedForSend',
    Sent: 'Sent',
    Resent: 'Resent',
};
export type MsgClosure = typeof MsgClosure[keyof typeof MsgClosure];

export const MsgSendMode = {
    API: 'API',
    RAW: 'RAW',
};
export type MsgSendMode = typeof MsgSendMode[keyof typeof MsgSendMode];

export const NationalIdTypeEnum = {
    None: 'None',
    CompanyReg: 'CompanyReg',
    DriversLicense: 'DriversLicense',
    InternationalPassport: 'InternationalPassport',
    NationalIdCard: 'NationalIdCard',
    ResidencePermit: 'ResidencePermit',
    VotersCard: 'VotersCard',
};
export type NationalIdTypeEnum = typeof NationalIdTypeEnum[keyof typeof NationalIdTypeEnum];

export const NFAttachmentType = {
    Document: 'Document',
    Report: 'Report',
    ResourceImage: 'ResourceImage',
};
export type NFAttachmentType = typeof NFAttachmentType[keyof typeof NFAttachmentType];

export const NFDelivery = {
    None: 'None',
    Email: 'Email',
    RSS: 'RSS',
    SMS: 'SMS',
};
export type NFDelivery = typeof NFDelivery[keyof typeof NFDelivery];

export const NFSubscriptionMode = {
    Email: 'Email',
    Group: 'Group',
    Partner: 'Partner',
};
export type NFSubscriptionMode = typeof NFSubscriptionMode[keyof typeof NFSubscriptionMode];

export const NFTypeEnum = {
    NONE: 'NONE',
    BULLETIN: 'BULLETIN',
    CAMPAIGN: 'CAMPAIGN',
    NEWSLETTER: 'NEWSLETTER',
    LOGIN_PORTAL: 'LOGIN_PORTAL',
    LOGIN_REMOTEAPP: 'LOGIN_REMOTEAPP',
    CALLNOTE: 'CALLNOTE',
    CRM_ACCTOPENED: 'CRM_ACCTOPENED',
    CRM_KYCINCOMPLETE: 'CRM_KYCINCOMPLETE',
    CRM_KYCWARNEXPIRY: 'CRM_KYCWARNEXPIRY',
    CRM_KYCEXPIRED: 'CRM_KYCEXPIRED',
    CRM_REGISTERED: 'CRM_REGISTERED',
    CRM_OTP: 'CRM_OTP',
    CRM_USER_OTP: 'CRM_USER_OTP',
    CRM_BIRTHDAY: 'CRM_BIRTHDAY',
    EQCERTCSCSSHEET_SEND: 'EQCERTCSCSSHEET_SEND',
    EQCERTIFICATEDEPOSIT_PROCESS: 'EQCERTIFICATEDEPOSIT_PROCESS',
    EQCERTREGDISPATCH_SEND: 'EQCERTREGDISPATCH_SEND',
    EQCERTIFICATE_SCRIP: 'EQCERTIFICATE_SCRIP',
    ECOCONTRACT_DETAIL: 'ECOCONTRACT_DETAIL',
    EQCONTRACT_DETAIL: 'EQCONTRACT_DETAIL',
    EQCONTRACT_SUMMARY: 'EQCONTRACT_SUMMARY',
    EQCORPACTIONSPLIT_PROPAGATE: 'EQCORPACTIONSPLIT_PROPAGATE',
    EQCORPACTIONSPLIT_PURGE: 'EQCORPACTIONSPLIT_PURGE',
    EQCORPACTIONDIVIDEND_PROPAGATE: 'EQCORPACTIONDIVIDEND_PROPAGATE',
    EQCORPACTIONDIVIDEND_PURGE: 'EQCORPACTIONDIVIDEND_PURGE',
    EQCORPACTIONMERGER_PROPAGATE: 'EQCORPACTIONMERGER_PROPAGATE',
    EQCORPACTIONMERGER_PURGE: 'EQCORPACTIONMERGER_PURGE',
    EQCORPACTIONPRYISSUE_PROPAGATE: 'EQCORPACTIONPRYISSUE_PROPAGATE',
    EQCORPACTIONPRYISSUE_PURGE: 'EQCORPACTIONPRYISSUE_PURGE',
    EQCORPACTIONPRYISSUEALLOTRETURN_PROPAGATE: 'EQCORPACTIONPRYISSUEALLOTRETURN_PROPAGATE',
    EQCORPACTIONPRYISSUEALLOTRETURN_PURGE: 'EQCORPACTIONPRYISSUEALLOTRETURN_PURGE',
    EQDEALALLOT_PROXY: 'EQDEALALLOT_PROXY',
    EQDEALSHEET_POST: 'EQDEALSHEET_POST',
    EQDEALSHEET_REPOST: 'EQDEALSHEET_REPOST',
    EQDEALSHEET_REVERSE: 'EQDEALSHEET_REVERSE',
    EQDEALSHEET_REVISE: 'EQDEALSHEET_REVISE',
    EQDEALSHEET_OPEN: 'EQDEALSHEET_OPEN',
    EQORDER_FAILED: 'EQORDER_FAILED',
    EQORDER_FAILED_PM: 'EQORDER_FAILED_PM',
    EQORDER_RECEIVED: 'EQORDER_RECEIVED',
    EQORDER_UNFILLED: 'EQORDER_UNFILLED',
    EQORDER_LOCK: 'EQORDER_LOCK',
    EQORDER_JOBBINGPROPAGATE: 'EQORDER_JOBBINGPROPAGATE',
    FUNDTRADESHEET_POST: 'FUNDTRADESHEET_POST',
    FUNDTRADESHEET_REVERSE: 'FUNDTRADESHEET_REVERSE',
    FUNDTRANSACTION: 'FUNDTRANSACTION',
    HR_RESUME_FIRM: 'HR_RESUME_FIRM',
    HR_RESUME_STAFF: 'HR_RESUME_STAFF',
    HR_SUSPEND: 'HR_SUSPEND',
    HR_TERMINATE: 'HR_TERMINATE',
    HRLEAVEROTA_RECALL: 'HRLEAVEROTA_RECALL',
    HRPERFAPPRAISAL_CLOSE: 'HRPERFAPPRAISAL_CLOSE',
    HRPERFTARGET_SET: 'HRPERFTARGET_SET',
    HRPERFTARGET_SUPAPPRAISING: 'HRPERFTARGET_SUPAPPRAISING',
    HRPERFTARGET_SUPSTAFFCOMMENT: 'HRPERFTARGET_SUPSTAFFCOMMENT',
    HRPERFTARGET_SUPBACKREVIEW: 'HRPERFTARGET_SUPBACKREVIEW',
    HRPERFTARGET_MGTAPPROVE: 'HRPERFTARGET_MGTAPPROVE',
    HRPERFTARGET_CLOSE: 'HRPERFTARGET_CLOSE',
    HRPERFTARGET_RECALL: 'HRPERFTARGET_RECALL',
    HRPERFAPPRAISAL_RECALL: 'HRPERFAPPRAISAL_RECALL',
    HRSTAFFREIMBURSE_FIRSTREVIEW: 'HRSTAFFREIMBURSE_FIRSTREVIEW',
    HRSTAFFREIMBURSE_INTCTRLREVIEW: 'HRSTAFFREIMBURSE_INTCTRLREVIEW',
    HRSTAFFREIMBURSE_MGTAPPROVE: 'HRSTAFFREIMBURSE_MGTAPPROVE',
    HRSTAFFREIMBURSE_FINPOST: 'HRSTAFFREIMBURSE_FINPOST',
    INSTRWATCH_CLIENT: 'INSTRWATCH_CLIENT',
    INSTRWATCH_PM: 'INSTRWATCH_PM',
    MARKETADVICE: 'MARKETADVICE',
    MARKETMONITOR: 'MARKETMONITOR',
    MMSHEET_OPEN: 'MMSHEET_OPEN',
    MMSHEET_POST: 'MMSHEET_POST',
    MMSHEET_REPOST: 'MMSHEET_REPOST',
    MMSHEET_REVERSE: 'MMSHEET_REVERSE',
    MMSHEET_REVISE: 'MMSHEET_REVISE',
    MMORDER_JOBBINGPROPAGATE: 'MMORDER_JOBBINGPROPAGATE',
    MMPLACEMENTALLOT_DETAIL: 'MMPLACEMENTALLOT_DETAIL',
    MMPLACEMENTCONTRACT_DETAIL: 'MMPLACEMENTCONTRACT_DETAIL',
    MMPLACEMENTCONTRACT_SUMMARY: 'MMPLACEMENTCONTRACT_SUMMARY',
    MMPLACEMENT_INITDO: 'MMPLACEMENT_INITDO',
    MMPLACEMENT_ROLLOVER: 'MMPLACEMENT_ROLLOVER',
    MMPLACEMENT_TERMINATEWARN: 'MMPLACEMENT_TERMINATEWARN',
    MMPLACEMENT_TERMINATEWARNREMIND: 'MMPLACEMENT_TERMINATEWARNREMIND',
    MMPLACEMENT_TERMINATE: 'MMPLACEMENT_TERMINATE',
    PARTNERCASE_RECEIPT: 'PARTNERCASE_RECEIPT',
    PARTNERCASE_RECEIPT_PM: 'PARTNERCASE_RECEIPT_PM',
    WORKFLOW_STEPASSIGN: 'WORKFLOW_STEPASSIGN',
    WORKFLOW_STEPCLOSE: 'WORKFLOW_STEPCLOSE',
    PARTNERCASE_CLOSE: 'PARTNERCASE_CLOSE',
    PARTNERCASE_CLOSE_PM: 'PARTNERCASE_CLOSE_PM',
    WORKFLOW_STEPASSIGN_REMIND: 'WORKFLOW_STEPASSIGN_REMIND',
    WORKFLOW_STEPWALKBACK: 'WORKFLOW_STEPWALKBACK',
    QUESTION_RECEIPT: 'QUESTION_RECEIPT',
    QUESTION_ASSIGN: 'QUESTION_ASSIGN',
    QUESTION_CLOSE: 'QUESTION_CLOSE',
    PARTNERWEB_ISSUECHECK: 'PARTNERWEB_ISSUECHECK',
    PARTNERWEB_VALIDATE: 'PARTNERWEB_VALIDATE',
    PARTNERPAYMENT_FAILED: 'PARTNERPAYMENT_FAILED',
    PARTNERPAYMENT_FAILED_PM: 'PARTNERPAYMENT_FAILED_PM',
    PARTNERPAYMENT_INVALIDATE: 'PARTNERPAYMENT_INVALIDATE',
    PARTNERPAYMENT_INVALIDATE_PM: 'PARTNERPAYMENT_INVALIDATE_PM',
    PARTNERPAYMENT_VOID: 'PARTNERPAYMENT_VOID',
    PARTNERPAYMENT_VOID_PM: 'PARTNERPAYMENT_VOID_PM',
    PARTNERPAYMENT_RECEIPT: 'PARTNERPAYMENT_RECEIPT',
    PARTNERPAYMENT_PM_CONFIRM: 'PARTNERPAYMENT_PM_CONFIRM',
    PARTNERPAYMENT_POST: 'PARTNERPAYMENT_POST',
    PARTNERPAYMENT_POST_PM: 'PARTNERPAYMENT_POST_PM',
    PARTNERPAYMENT_READY: 'PARTNERPAYMENT_READY',
    PARTNERPAYMENT_FAILEDVALID: 'PARTNERPAYMENT_FAILEDVALID',
    PARTNERPAYMENT_FAILED_CONFIRM: 'PARTNERPAYMENT_FAILED_CONFIRM',
    PARTNERPAYMENT_FAILED_MGTAPPROVE: 'PARTNERPAYMENT_FAILED_MGTAPPROVE',
    PARTNERRECEIPT_FAILED: 'PARTNERRECEIPT_FAILED',
    PARTNERRECEIPT_FAILED_PM: 'PARTNERRECEIPT_FAILED_PM',
    PARTNERRECEIPT_INVALIDATE: 'PARTNERRECEIPT_INVALIDATE',
    PARTNERRECEIPT_INVALIDATE_PM: 'PARTNERRECEIPT_INVALIDATE_PM',
    PARTNERRECEIPT_POST: 'PARTNERRECEIPT_POST',
    PARTNERRECEIPT_POST_PM: 'PARTNERRECEIPT_POST_PM',
    PARTNERRECEIPT_RECEIPT: 'PARTNERRECEIPT_RECEIPT',
    PARTNERRECEIPT_VOID: 'PARTNERRECEIPT_VOID',
    PARTNERRECEIPT_VOID_PM: 'PARTNERRECEIPT_VOID_PM',
    EQWARRANT_PICKUP: 'EQWARRANT_PICKUP',
    EQWARRANT_DISCARD: 'EQWARRANT_DISCARD',
    EQWARRANT_POST: 'EQWARRANT_POST',
    EQWARRANT_RECEIVE: 'EQWARRANT_RECEIVE',
    EQWARRANT_SCRIP: 'EQWARRANT_SCRIP',
    PARTNERTRANSFER_FAILED: 'PARTNERTRANSFER_FAILED',
    PARTNERTRANSFER_FAILED_PM: 'PARTNERTRANSFER_FAILED_PM',
    PARTNERTRANSFER_INVALIDATE: 'PARTNERTRANSFER_INVALIDATE',
    PARTNERTRANSFER_INVALIDATE_PM: 'PARTNERTRANSFER_INVALIDATE_PM',
    PARTNERTRANSFER_POST: 'PARTNERTRANSFER_POST',
    PARTNERTRANSFER_POST_PM: 'PARTNERTRANSFER_POST_PM',
    PARTNERTRANSFER_RECEIPT: 'PARTNERTRANSFER_RECEIPT',
    PARTNERTRANSFER_VOID: 'PARTNERTRANSFER_VOID',
    PARTNERTRANSFER_VOID_PM: 'PARTNERTRANSFER_VOID_PM',
    PARTNERTRANSFER_PM_CONFIRM: 'PARTNERTRANSFER_PM_CONFIRM',
    RESEARCH_REPORT: 'RESEARCH_REPORT',
    RESEARCH_COMPANY_REPORT: 'RESEARCH_COMPANY_REPORT',
    RESEARCH_COMPANY_RESULT: 'RESEARCH_COMPANY_RESULT',
    RESNEWSITEM_EXCH_RECEIVE: 'RESNEWSITEM_EXCH_RECEIVE',
    EQINSTRUMENT_EXCH_MISSING: 'EQINSTRUMENT_EXCH_MISSING',
    TRTBILLSHEET_OPEN: 'TRTBILLSHEET_OPEN',
    TRTBILLSHEET_POST: 'TRTBILLSHEET_POST',
    TRTBILLSHEET_REPOST: 'TRTBILLSHEET_REPOST',
    TRTBILLSHEET_REVERSE: 'TRTBILLSHEET_REVERSE',
    TRTBILLSHEET_REVISE: 'TRTBILLSHEET_REVISE',
    TRTBILLORDER_JOBBINGPROPAGATE: 'TRTBILLORDER_JOBBINGPROPAGATE',
    TRTBILLTRAN_INITDO: 'TRTBILLTRAN_INITDO',
    TRTBILLTRAN_ROLLOVER: 'TRTBILLTRAN_ROLLOVER',
    TRTBILLTRAN_TERMINATEWARN: 'TRTBILLTRAN_TERMINATEWARN',
    TRTBILLTRAN_TERMINATE: 'TRTBILLTRAN_TERMINATE',
    TRTBILLTRAN_TERMINATEWARNREMIND: 'TRTBILLTRAN_TERMINATEWARNREMIND',
    TRCOMMPAPERTRAN_APPLY: 'TRCOMMPAPERTRAN_APPLY',
    TRCOMMPAPERTRAN_INITDO: 'TRCOMMPAPERTRAN_INITDO',
    TRCOMMPAPERTRAN_ROLLOVER: 'TRCOMMPAPERTRAN_ROLLOVER',
    TRCOMMPAPERTRAN_TERMINATEWARN: 'TRCOMMPAPERTRAN_TERMINATEWARN',
    TRCOMMPAPERTRAN_TERMINATEWARNREMIND: 'TRCOMMPAPERTRAN_TERMINATEWARNREMIND',
    TRCOMMPAPERTRAN_TERMINATE: 'TRCOMMPAPERTRAN_TERMINATE',
    TRPROMNOTETRAN_APPLY: 'TRPROMNOTETRAN_APPLY',
    TRPROMNOTETRAN_INITDO: 'TRPROMNOTETRAN_INITDO',
    TRPROMNOTETRAN_ROLLOVER: 'TRPROMNOTETRAN_ROLLOVER',
    TRPROMNOTETRAN_TERMINATEWARN: 'TRPROMNOTETRAN_TERMINATEWARN',
    TRPROMNOTETRAN_TERMINATEWARNREMIND: 'TRPROMNOTETRAN_TERMINATEWARNREMIND',
    TRPROMNOTETRAN_TERMINATE: 'TRPROMNOTETRAN_TERMINATE',
    TRTERMINSTR_INITDO: 'TRTERMINSTR_INITDO',
    TRTERMINSTR_ROLLOVER: 'TRTERMINSTR_ROLLOVER',
    TRTERMINSTR_TERMINATEWARN: 'TRTERMINSTR_TERMINATEWARN',
    TRTERMINSTR_TERMINATE: 'TRTERMINSTR_TERMINATE',
    TRTERMINSTR_TERMINATEWARNREMIND: 'TRTERMINSTR_TERMINATEWARNREMIND',
    VALUATION_ANNUALLY: 'VALUATION_ANNUALLY',
    VALUATION_MONTHLY: 'VALUATION_MONTHLY',
    VALUATION_QUARTERLY: 'VALUATION_QUARTERLY',
    VALUATION_SPOT: 'VALUATION_SPOT',
};
export type NFTypeEnum = typeof NFTypeEnum[keyof typeof NFTypeEnum];

export const OrderClassEnum = {
    Fund: 'Fund',
    MoneyMarket: 'MoneyMarket',
    TBill: 'TBill',
    Trade: 'Trade',
};
export type OrderClassEnum = typeof OrderClassEnum[keyof typeof OrderClassEnum];

export const OrderClosure = {
    KILLED: 'KILLED',
    REQUEST_KILL: 'REQUEST_KILL',
    REQUEST_SUSPEND: 'REQUEST_SUSPEND',
    REQUEST_EDIT: 'REQUEST_EDIT',
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    RECEIVED: 'RECEIVED',
    APPROVING: 'APPROVING',
    EXECUTING: 'EXECUTING',
    FILLED: 'FILLED',
};
export type OrderClosure = typeof OrderClosure[keyof typeof OrderClosure];

export const OrderClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    KILLED: 'KILLED',
    REQUEST_KILL: 'REQUEST_KILL',
    REQUEST_SUSPEND: 'REQUEST_SUSPEND',
    REQUEST_EDIT: 'REQUEST_EDIT',
    FAILED: 'FAILED',
    PENDING: 'PENDING',
    RECEIVED: 'RECEIVED',
    APPROVING: 'APPROVING',
    EXECUTING: 'EXECUTING',
    FILLED: 'FILLED',
};
export type OrderClosureSupra = typeof OrderClosureSupra[keyof typeof OrderClosureSupra];

export const OrderJobClosure = {
    Rejected: 'Rejected',
    Cancelled: 'Cancelled',
    Replaced: 'Replaced',
    InvalidInstrument: 'InvalidInstrument',
    InvalidAccount: 'InvalidAccount',
    InsufficientHoldings: 'InsufficientHoldings',
    Expired: 'Expired',
    Suspended: 'Suspended',
    Pending: 'Pending',
    Indeterminate: 'Indeterminate',
    Jobbed: 'Jobbed',
    PriceLimitExceeded: 'PriceLimitExceeded',
    RequestStatus: 'RequestStatus',
    RequestTradeCapture: 'RequestTradeCapture',
    SubmittedSystem: 'SubmittedSystem',
    QueuedApproval: 'QueuedApproval',
    QueuedRoute: 'QueuedRoute',
    SubmittedManual: 'SubmittedManual',
    RequestCancel: 'RequestCancel',
    RequestCancelReplace: 'RequestCancelReplace',
    RequestSuspend: 'RequestSuspend',
    RequestUnsuspend: 'RequestUnsuspend',
    CancelSubmitted: 'CancelSubmitted',
    SuspendSubmitted: 'SuspendSubmitted',
    UnsuspendSubmitted: 'UnsuspendSubmitted',
    PartialFill: 'PartialFill',
    Filled: 'Filled',
    TradingEnded: 'TradingEnded',
};
export type OrderJobClosure = typeof OrderJobClosure[keyof typeof OrderJobClosure];

export const OrderLifeEnum = {
    GoodForToday: 'GoodForToday',
    GoodForDays: 'GoodForDays',
    GoodTillCancelled: 'GoodTillCancelled',
    GoodForClose: 'GoodForClose',
    GoodForOpen: 'GoodForOpen',
    GoodTillDate: 'GoodTillDate',
    GoodTillTime: 'GoodTillTime',
    FillKill: 'FillKill',
    ImmediateCancel: 'ImmediateCancel',
};
export type OrderLifeEnum = typeof OrderLifeEnum[keyof typeof OrderLifeEnum];

export const OrderValidationEnum = {
    KillRequested: 'KillRequested',
    SuspendRequested: 'SuspendRequested',
    EditRequested: 'EditRequested',
    NoneYet: 'NoneYet',
    AutoApproval: 'AutoApproval',
    ManualApproval: 'ManualApproval',
    Suspended: 'Suspended',
    Stale: 'Stale',
    Killed: 'Killed',
    InsufficientHolding: 'InsufficientHolding',
    LimitOutOfRange: 'LimitOutOfRange',
    Unfunded: 'Unfunded',
    Excluded: 'Excluded',
    Matched: 'Matched',
    Filled: 'Filled',
    KycIncomplete: 'KycIncomplete',
    LimitInvalid: 'LimitInvalid',
    QuantityInvalid: 'QuantityInvalid',
    AccountInvalid: 'AccountInvalid',
    InstrumentInvalid: 'InstrumentInvalid',
};
export type OrderValidationEnum = typeof OrderValidationEnum[keyof typeof OrderValidationEnum];

export const OrgCompanyType = {
    Firm: 'Firm',
    Fund: 'Fund',
    Scheme: 'Scheme',
    SBU: 'SBU',
    SPV: 'SPV',
};
export type OrgCompanyType = typeof OrgCompanyType[keyof typeof OrgCompanyType];

export const OrgUnitCommonNames = {
    IBD: 'IBD',
    WM: 'WM',
    TRUST: 'TRUST',
    RESEARCH: 'RESEARCH',
    ADMIN: 'ADMIN',
    CORPSUPP: 'CORPSUPP',
    HR: 'HR',
    RISKMGT: 'RISKMGT',
    ICTL: 'ICTL',
    OPSTECH: 'OPSTECH',
    OPS: 'OPS',
    TECH: 'TECH',
    TECH_INF: 'TECH_INF',
    TECH_APPS: 'TECH_APPS',
    LEGAL: 'LEGAL',
    EXECOFF: 'EXECOFF',
    FIN: 'FIN',
};
export type OrgUnitCommonNames = typeof OrgUnitCommonNames[keyof typeof OrgUnitCommonNames];

export const PartnerAcctInclusion = {
    Exclude: 'Exclude',
    Include: 'Include',
    Only: 'Only',
};
export type PartnerAcctInclusion = typeof PartnerAcctInclusion[keyof typeof PartnerAcctInclusion];

export const PartnerCallType = {
    Other: 'Other',
    Email: 'Email',
    Letter: 'Letter',
    Meeting: 'Meeting',
    PhoneCall: 'PhoneCall',
    SMS: 'SMS',
    Teleconference: 'Teleconference',
    Visit: 'Visit',
};
export type PartnerCallType = typeof PartnerCallType[keyof typeof PartnerCallType];

export const PartnerCashStatusEnum = {
    FAILED_DISBURSE: 'FAILED_DISBURSE',
    FAILED_MGTAPPROVE: 'FAILED_MGTAPPROVE',
    FAILED_RISKCHECK: 'FAILED_RISKCHECK',
    FAILED_KYC: 'FAILED_KYC',
    FAILED_BALANCE: 'FAILED_BALANCE',
    FAILED_CONFIRM: 'FAILED_CONFIRM',
    RECEIVED: 'RECEIVED',
    CONFIRM: 'CONFIRM',
    BALANCE_VERIFY: 'BALANCE_VERIFY',
    KYC_VALIDATION: 'KYC_VALIDATION',
    RISK_CHECK: 'RISK_CHECK',
    MGT_APPROVAL: 'MGT_APPROVAL',
    FIN_DISBURSE: 'FIN_DISBURSE',
    FIN_POST: 'FIN_POST',
    INVALIDATED: 'INVALIDATED',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type PartnerCashStatusEnum = typeof PartnerCashStatusEnum[keyof typeof PartnerCashStatusEnum];

export const PartnerClass = {
    NONE: 'NONE',
    CUSTOMER: 'CUSTOMER',
    STAFF: 'STAFF',
    VENDOR: 'VENDOR',
    PATRON: 'PATRON',
};
export type PartnerClass = typeof PartnerClass[keyof typeof PartnerClass];

export const PartnerGlAcctTypes = {
    BondIncome: 'BondIncome',
    Cash: 'Cash',
    CurrencyFx: 'CurrencyFx',
    DividendIncome: 'DividendIncome',
    EmployeeScheme: 'EmployeeScheme',
    Expense: 'Expense',
    FacilityInterest: 'FacilityInterest',
    FacilityPrincipal: 'FacilityPrincipal',
    LoanInterest: 'LoanInterest',
    LoanPrincipal: 'LoanPrincipal',
    MMInterestExpense: 'MMInterestExpense',
    MMInterestIncome: 'MMInterestIncome',
    MMPrincipal: 'MMPrincipal',
    MMAccrual: 'MMAccrual',
    TBillDiscount: 'TBillDiscount',
    TBillIncome: 'TBillIncome',
    WarehouseAgent: 'WarehouseAgent',
    WarehouseVariation: 'WarehouseVariation',
    TCommPaperAccrual: 'TCommPaperAccrual',
    TCommPaperDiscount: 'TCommPaperDiscount',
    TCommPaperExpense: 'TCommPaperExpense',
    TCommPaperIncome: 'TCommPaperIncome',
    TPromNoteAccrual: 'TPromNoteAccrual',
    TPromNoteDiscount: 'TPromNoteDiscount',
    TPromNoteExpense: 'TPromNoteExpense',
    TPromNoteIncome: 'TPromNoteIncome',
};
export type PartnerGlAcctTypes = typeof PartnerGlAcctTypes[keyof typeof PartnerGlAcctTypes];

export const PartnerModeEnum = {
    Direct: 'Direct',
    proXy: 'proXy',
    Warehouse: 'Warehouse',
};
export type PartnerModeEnum = typeof PartnerModeEnum[keyof typeof PartnerModeEnum];

export const PartnerOriginEnum = {
    NonResidentForeign: 'NonResidentForeign',
    NonResidentLocal: 'NonResidentLocal',
    ResidentForeign: 'ResidentForeign',
    ResidentLocal: 'ResidentLocal',
};
export type PartnerOriginEnum = typeof PartnerOriginEnum[keyof typeof PartnerOriginEnum];

export const PartnerState = {
    Dormant: 'Dormant',
    Discontinued: 'Discontinued',
    Inactive: 'Inactive',
    Pending: 'Pending',
    Reviewed: 'Reviewed',
    Active: 'Active',
};
export type PartnerState = typeof PartnerState[keyof typeof PartnerState];

export const PartnerTypeCodes = {
    Corporate: 'Corporate',
    Fund: 'Fund',
    Individual: 'Individual',
    Own: 'Own',
    PryIssue: 'PryIssue',
    Trust: 'Trust',
    JointAccount: 'JointAccount',
    Admor: 'Admor',
    Partnership: 'Partnership',
    SchemeFoundation: 'SchemeFoundation',
    SocAssocClubNGO: 'SocAssocClubNGO',
    Custodian: 'Custodian',
    Enterprise: 'Enterprise',
    Nominee: 'Nominee',
};
export type PartnerTypeCodes = typeof PartnerTypeCodes[keyof typeof PartnerTypeCodes];

export const PartnerWebClosure = {
    NoOp: 'NoOp',
    ActivateValidate: 'ActivateValidate',
    ActivateRequest: 'ActivateRequest',
    Deactivated: 'Deactivated',
    UserNamePasswordValidate: 'UserNamePasswordValidate',
    UserPasswordResetRequest: 'UserPasswordResetRequest',
    UserNameRemindRequest: 'UserNameRemindRequest',
    UnlockValidate: 'UnlockValidate',
    UnlockRequest: 'UnlockRequest',
    Locked: 'Locked',
    IssueCheck: 'IssueCheck',
    Pending: 'Pending',
    Received: 'Received',
    ConfirmValidate: 'ConfirmValidate',
    Active: 'Active',
};
export type PartnerWebClosure = typeof PartnerWebClosure[keyof typeof PartnerWebClosure];

export const PartnerWebOpType = {
    None: 'None',
    Delete: 'Delete',
    EmailChange: 'EmailChange',
    NameChange: 'NameChange',
};
export type PartnerWebOpType = typeof PartnerWebOpType[keyof typeof PartnerWebOpType];

export const PartnerXferType = {
    FROM: 'FROM',
    TO: 'TO',
};
export type PartnerXferType = typeof PartnerXferType[keyof typeof PartnerXferType];

export const PortalChatTypeEnum = {
    ChatRoom: 'ChatRoom',
    Forum: 'Forum',
    SupportRequest: 'SupportRequest',
    SupportResponder: 'SupportResponder',
};
export type PortalChatTypeEnum = typeof PortalChatTypeEnum[keyof typeof PortalChatTypeEnum];

export const PortalServiceEnum = {
    SiteAdmin: 'SiteAdmin',
    ResearchAdmin: 'ResearchAdmin',
    ResearchExpress: 'ResearchExpress',
    ResearchPremium: 'ResearchPremium',
    ResearchProfessional: 'ResearchProfessional',
    PortfolioExpress: 'PortfolioExpress',
    PortfolioPremium: 'PortfolioPremium',
    PortfolioProfessional: 'PortfolioProfessional',
    AgentExpress: 'AgentExpress',
    AgentPremium: 'AgentPremium',
    AgentProfessional: 'AgentProfessional',
    ProjectManagement: 'ProjectManagement',
    RelationshipManagement: 'RelationshipManagement',
    HrMgr: 'HrMgr',
};
export type PortalServiceEnum = typeof PortalServiceEnum[keyof typeof PortalServiceEnum];

export const PriceQuotationEnum = {
    Absolute: 'Absolute',
    Percent: 'Percent',
    BasisPoints: 'BasisPoints',
};
export type PriceQuotationEnum = typeof PriceQuotationEnum[keyof typeof PriceQuotationEnum];

export const ProjectClosure = {
    Abandoned: 'Abandoned',
    Suspended: 'Suspended',
    Pending: 'Pending',
    Approved: 'Approved',
    Pipeline: 'Pipeline',
    SignOn: 'SignOn',
    Running: 'Running',
    CloseOut: 'CloseOut',
    Closed: 'Closed',
};
export type ProjectClosure = typeof ProjectClosure[keyof typeof ProjectClosure];

export const ProjectClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Abandoned: 'Abandoned',
    Suspended: 'Suspended',
    Pending: 'Pending',
    Approved: 'Approved',
    Pipeline: 'Pipeline',
    SignOn: 'SignOn',
    Running: 'Running',
    CloseOut: 'CloseOut',
    Closed: 'Closed',
};
export type ProjectClosureSupra = typeof ProjectClosureSupra[keyof typeof ProjectClosureSupra];

export const ProjectGlAcctTypes = {
    Asset: 'Asset',
    Cash: 'Cash',
    Liability: 'Liability',
    Expense: 'Expense',
    Income: 'Income',
};
export type ProjectGlAcctTypes = typeof ProjectGlAcctTypes[keyof typeof ProjectGlAcctTypes];

export const ProjectMemberTypeEnum = {
    Lead: 'Lead',
    Manager: 'Manager',
    Owner: 'Owner',
    Resource: 'Resource',
    Sponsor: 'Sponsor',
    Stakeholder: 'Stakeholder',
};
export type ProjectMemberTypeEnum = typeof ProjectMemberTypeEnum[keyof typeof ProjectMemberTypeEnum];

export const PxWorkflowAssigneeType = {
    External: 'External',
    Machine: 'Machine',
    Staff: 'Staff',
    Unit: 'Unit',
    SecRole: 'SecRole',
    OrgRole: 'OrgRole',
    SecRoleStaff: 'SecRoleStaff',
    SecRoleUnit: 'SecRoleUnit',
    SecRoleOrgRole: 'SecRoleOrgRole',
};
export type PxWorkflowAssigneeType = typeof PxWorkflowAssigneeType[keyof typeof PxWorkflowAssigneeType];

export const PxWorkflowAssignmentFilter = {
    EXPLICITLY_ME: 'EXPLICITLY_ME',
    MY_SECROLE: 'MY_SECROLE',
    MY_UNIT: 'MY_UNIT',
    MY_DIVISION: 'MY_DIVISION',
    POSSIBLY_ME: 'POSSIBLY_ME',
    NOT_DIRECTLY_ME: 'NOT_DIRECTLY_ME',
    NOT_ME_AT_ALL: 'NOT_ME_AT_ALL',
    NOT_HUMAN: 'NOT_HUMAN',
    MY_ORGROLE: 'MY_ORGROLE',
};
export type PxWorkflowAssignmentFilter = typeof PxWorkflowAssignmentFilter[keyof typeof PxWorkflowAssignmentFilter];

export const PxWorkflowState = {
    Discarded: 'Discarded',
    Suspended: 'Suspended',
    Pending: 'Pending',
    Received: 'Received',
    Assigned: 'Assigned',
    Closed: 'Closed',
};
export type PxWorkflowState = typeof PxWorkflowState[keyof typeof PxWorkflowState];

export const PxWorkflowStateSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Discarded: 'Discarded',
    Suspended: 'Suspended',
    Pending: 'Pending',
    Received: 'Received',
    Assigned: 'Assigned',
    Closed: 'Closed',
};
export type PxWorkflowStateSupra = typeof PxWorkflowStateSupra[keyof typeof PxWorkflowStateSupra];

export const PxWorkflowStepButtonClick = {
    OK: 'OK',
    AddNotes: 'AddNotes',
    Advance: 'Advance',
    Approve: 'Approve',
    Reassign: 'Reassign',
    Reject: 'Reject',
    Submit: 'Submit',
    Cancel: 'Cancel',
};
export type PxWorkflowStepButtonClick = typeof PxWorkflowStepButtonClick[keyof typeof PxWorkflowStepButtonClick];

export const PxWorkflowStepType = {
    Machine: 'Machine',
    ViewApproval: 'ViewApproval',
    ViewEditApproval: 'ViewEditApproval',
    ViewEditSubmit: 'ViewEditSubmit',
    ViewNotesSubmit: 'ViewNotesSubmit',
    ViewValueCollect: 'ViewValueCollect',
};
export type PxWorkflowStepType = typeof PxWorkflowStepType[keyof typeof PxWorkflowStepType];

export const PxWorkflowType = {
    DynamicEntityApproval: 'DynamicEntityApproval',
    DynamicEntityWorkflow: 'DynamicEntityWorkflow',
    DynamicWFApproval: 'DynamicWFApproval',
    DynamicWFWorkflow: 'DynamicWFWorkflow',
    StaticEntityApproval: 'StaticEntityApproval',
    StaticEntityWorkflow: 'StaticEntityWorkflow',
};
export type PxWorkflowType = typeof PxWorkflowType[keyof typeof PxWorkflowType];

export const QuantityMultiplier = {
    Absolute: 'Absolute',
    Thousands: 'Thousands',
};
export type QuantityMultiplier = typeof QuantityMultiplier[keyof typeof QuantityMultiplier];

export const ReferenceDataClosure = {
    Deactivated: 'Deactivated',
    Unapproved: 'Unapproved',
    Active: 'Active',
};
export type ReferenceDataClosure = typeof ReferenceDataClosure[keyof typeof ReferenceDataClosure];

export const RelationshipTypeEnum = {
    BENEFICIARY: 'BENEFICIARY',
    CHILD: 'CHILD',
    DIRECTOR: 'DIRECTOR',
    EMPLOYEE: 'EMPLOYEE',
    EMPLOYER: 'EMPLOYER',
    MANAGER: 'MANAGER',
    NEXTOFKIN: 'NEXTOFKIN',
    OWNER: 'OWNER',
    PARENT: 'PARENT',
    REFREE: 'REFREE',
    RELATIVE: 'RELATIVE',
    SHAREHOLDER: 'SHAREHOLDER',
    SIBLING: 'SIBLING',
    SPOUSE: 'SPOUSE',
    TRUSTEE: 'TRUSTEE',
};
export type RelationshipTypeEnum = typeof RelationshipTypeEnum[keyof typeof RelationshipTypeEnum];

export const ResCompanyCommonType = {
    BANK: 'BANK',
    INSURANCE: 'INSURANCE',
    MANUFACTURING: 'MANUFACTURING',
    NGO: 'NGO',
    TELECOMS: 'TELECOMS',
    TRANSPORT: 'TRANSPORT',
    UNCLASSIFIED: 'UNCLASSIFIED',
};
export type ResCompanyCommonType = typeof ResCompanyCommonType[keyof typeof ResCompanyCommonType];

export const ResCompanyResultType = {
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
    Q4: 'Q4',
    Q5: 'Q5',
    FULL: 'FULL',
    MTH01: 'MTH01',
    MTH02: 'MTH02',
    MTH04: 'MTH04',
    MTH05: 'MTH05',
    MTH07: 'MTH07',
    MTH08: 'MTH08',
    MTH10: 'MTH10',
    MTH11: 'MTH11',
    MTH12: 'MTH12',
    MTH13: 'MTH13',
    MTH14: 'MTH14',
    MTH15: 'MTH15',
    MTH16: 'MTH16',
    MTH17: 'MTH17',
    MTH18: 'MTH18',
};
export type ResCompanyResultType = typeof ResCompanyResultType[keyof typeof ResCompanyResultType];

export const ResCoReportClassEnum = {
    CompanyProjection: 'CompanyProjection',
    ResearchProjection: 'ResearchProjection',
    UnauditedResult: 'UnauditedResult',
    AuditedResult: 'AuditedResult',
};
export type ResCoReportClassEnum = typeof ResCoReportClassEnum[keyof typeof ResCoReportClassEnum];

export const ResNewsItemTypeEnum = {
    Economic: 'Economic',
    Entertainment: 'Entertainment',
    Political: 'Political',
    Scientific: 'Scientific',
    Sports: 'Sports',
    World: 'World',
};
export type ResNewsItemTypeEnum = typeof ResNewsItemTypeEnum[keyof typeof ResNewsItemTypeEnum];

export const ResReportFrequency = {
    EVENT: 'EVENT',
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    QUARTERTLY: 'QUARTERTLY',
    SEMIANNUALLY: 'SEMIANNUALLY',
    ANNUALLY: 'ANNUALLY',
};
export type ResReportFrequency = typeof ResReportFrequency[keyof typeof ResReportFrequency];

export const ScheduleTranClosure = {
    Discarded: 'Discarded',
    Pending: 'Pending',
    Processed: 'Processed',
    Running: 'Running',
    AutoTerminated: 'AutoTerminated',
    ForciblyTerminated: 'ForciblyTerminated',
};
export type ScheduleTranClosure = typeof ScheduleTranClosure[keyof typeof ScheduleTranClosure];

export const ScheduleTypeEnum = {
    None: 'None',
    Second: 'Second',
    Minute: 'Minute',
    Hourly: 'Hourly',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Yearly: 'Yearly',
};
export type ScheduleTypeEnum = typeof ScheduleTypeEnum[keyof typeof ScheduleTypeEnum];

export const SecExchUserType = {
    Company: 'Company',
    FIX: 'FIX',
    Person: 'Person',
};
export type SecExchUserType = typeof SecExchUserType[keyof typeof SecExchUserType];

export const SecInstrumentState = {
    Discontinued: 'Discontinued',
    Pending: 'Pending',
    Active: 'Active',
    Deactivated: 'Deactivated',
};
export type SecInstrumentState = typeof SecInstrumentState[keyof typeof SecInstrumentState];

export const SecLendDirections = {
    BORROW: 'BORROW',
    LEND: 'LEND',
};
export type SecLendDirections = typeof SecLendDirections[keyof typeof SecLendDirections];

export const SecLendMovtType = {
    Collateral: 'Collateral',
    Instrument: 'Instrument',
};
export type SecLendMovtType = typeof SecLendMovtType[keyof typeof SecLendMovtType];

export const SecLendType = {
    ORIGINAL: 'ORIGINAL',
    TOPUP: 'TOPUP',
};
export type SecLendType = typeof SecLendType[keyof typeof SecLendType];

export const SecListingType = {
    Unlisted: 'Unlisted',
    NominalListing: 'NominalListing',
    TradeListing: 'TradeListing',
};
export type SecListingType = typeof SecListingType[keyof typeof SecListingType];

export const SecMarketMakerType = {
    Primary: 'Primary',
    Secondary: 'Secondary',
    Tertiary: 'Tertiary',
};
export type SecMarketMakerType = typeof SecMarketMakerType[keyof typeof SecMarketMakerType];

export const SecMarketStage = {
    NA: 'NA',
    GetSet: 'GetSet',
    PreOpen: 'PreOpen',
    OpenForTrading: 'OpenForTrading',
    PreClose: 'PreClose',
    Closed: 'Closed',
};
export type SecMarketStage = typeof SecMarketStage[keyof typeof SecMarketStage];

export const SecMktDataType = {
    BID: 'BID',
    TRADE: 'TRADE',
    INDEX_VALUE: 'INDEX_VALUE',
    NSE_OPEN_INTEREST: 'NSE_OPEN_INTEREST',
    NSE_STATISTICS: 'NSE_STATISTICS',
    NSE_NO_TRADES_EXIST: 'NSE_NO_TRADES_EXIST',
    NSE_TOTAL_TRADES: 'NSE_TOTAL_TRADES',
    NSE_OPENING_QUANTITY: 'NSE_OPENING_QUANTITY',
    NSE_UNADJ_PREVIOUS_CLOSE: 'NSE_UNADJ_PREVIOUS_CLOSE',
    NSE_DELTA_REFERENCE_PRICE: 'NSE_DELTA_REFERENCE_PRICE',
    NSE_PREVIOUS_CLOSE: 'NSE_PREVIOUS_CLOSE',
    NSE_TOTAL_VALUE: 'NSE_TOTAL_VALUE',
    NSE_TOTAL_VOLUME: 'NSE_TOTAL_VOLUME',
    NSE_REFERENCE_PRICE: 'NSE_REFERENCE_PRICE',
    NSE_52WEEK: 'NSE_52WEEK',
    NSE_INDICATIVE_OPENING: 'NSE_INDICATIVE_OPENING',
};
export type SecMktDataType = typeof SecMktDataType[keyof typeof SecMktDataType];

export const SecMktDataUpdateAction = {
    NEW: 'NEW',
    CHANGE: 'CHANGE',
    DELETE: 'DELETE',
    DELETE_THRU: 'DELETE_THRU',
    DELETE_FROM: 'DELETE_FROM',
    OVERLAY: 'OVERLAY',
};
export type SecMktDataUpdateAction = typeof SecMktDataUpdateAction[keyof typeof SecMktDataUpdateAction];

export const SecOrderExecType = {
    NothingSpecial: 'NothingSpecial',
    AllOrNone: 'AllOrNone',
    BestExecution: 'BestExecution',
    OkToCross: 'OkToCross',
    OverTheDay: 'OverTheDay',
    PegToVWAP: 'PegToVWAP',
};
export type SecOrderExecType = typeof SecOrderExecType[keyof typeof SecOrderExecType];

export const SecOrderLimitType = {
    Limit: 'Limit',
    Market: 'Market',
    MarketAtBest: 'MarketAtBest',
};
export type SecOrderLimitType = typeof SecOrderLimitType[keyof typeof SecOrderLimitType];

export const SecOrderPriceType = {
    Percentage: 'Percentage',
    PerUnit: 'PerUnit',
    Spread: 'Spread',
    Yield: 'Yield',
};
export type SecOrderPriceType = typeof SecOrderPriceType[keyof typeof SecOrderPriceType];

export const SecOrderTrackingType = {
    None: 'None',
    Bid: 'Bid',
    Mid: 'Mid',
    Offer: 'Offer',
};
export type SecOrderTrackingType = typeof SecOrderTrackingType[keyof typeof SecOrderTrackingType];

export const SecSectorType = {
    Exchange: 'Exchange',
    Research: 'Research',
};
export type SecSectorType = typeof SecSectorType[keyof typeof SecSectorType];

export const SecTradeStatus = {
    Suspended: 'Suspended',
    Inactive: 'Inactive',
    Halted: 'Halted',
    Active: 'Active',
};
export type SecTradeStatus = typeof SecTradeStatus[keyof typeof SecTradeStatus];

export const SecTransactionType = {
    BUY: 'BUY',
    CANCEL: 'CANCEL',
    ISSUE: 'ISSUE',
    SELL: 'SELL',
};
export type SecTransactionType = typeof SecTransactionType[keyof typeof SecTransactionType];

export const SecXferDirections = {
    XFIN: 'XFIN',
    XFOUT: 'XFOUT',
};
export type SecXferDirections = typeof SecXferDirections[keyof typeof SecXferDirections];

export const SecXferType = {
    Custodian: 'Custodian',
    DealingMember: 'DealingMember',
    LendingAgent: 'LendingAgent',
};
export type SecXferType = typeof SecXferType[keyof typeof SecXferType];

export const SexEnum = {
    Female: 'Female',
    Male: 'Male',
    Neuter: 'Neuter',
};
export type SexEnum = typeof SexEnum[keyof typeof SexEnum];

export const SimpleTermClosure = {
    Invalidated: 'Invalidated',
    Failed: 'Failed',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Closed: 'Closed',
    Running: 'Running',
    Terminated: 'Terminated',
};
export type SimpleTermClosure = typeof SimpleTermClosure[keyof typeof SimpleTermClosure];

export const SimpleTermClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Invalidated: 'Invalidated',
    Failed: 'Failed',
    Pending: 'Pending',
    Validating: 'Validating',
    Approving: 'Approving',
    Closed: 'Closed',
    Running: 'Running',
    Terminated: 'Terminated',
};
export type SimpleTermClosureSupra = typeof SimpleTermClosureSupra[keyof typeof SimpleTermClosureSupra];

export const StpContractModes = {
    SINGLE: 'SINGLE',
    BATCHORDER: 'BATCHORDER',
    BATCHDAY: 'BATCHDAY',
};
export type StpContractModes = typeof StpContractModes[keyof typeof StpContractModes];

export const StpOrderModes = {
    FIX_STP: 'FIX_STP',
    BATCHED: 'BATCHED',
    DEALER: 'DEALER',
};
export type StpOrderModes = typeof StpOrderModes[keyof typeof StpOrderModes];

export const SyncCategory = {
    REFERENCE: 'REFERENCE',
    MARKET: 'MARKET',
    MASTER: 'MASTER',
    TRANSACTION: 'TRANSACTION',
};
export type SyncCategory = typeof SyncCategory[keyof typeof SyncCategory];

export const SyncDirection = {
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD',
    BOTH: 'BOTH',
};
export type SyncDirection = typeof SyncDirection[keyof typeof SyncDirection];

export const TermInstrumentClosure = {
    Untouchable: 'Untouchable',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Reviewing: 'Reviewing',
    InitPending: 'InitPending',
    Running: 'Running',
    Terminated: 'Terminated',
    WrittenOff: 'WrittenOff',
    RolledOverPrin: 'RolledOverPrin',
    RolledOverPrinInt: 'RolledOverPrinInt',
};
export type TermInstrumentClosure = typeof TermInstrumentClosure[keyof typeof TermInstrumentClosure];

export const TermInstrumentClosureSupra = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    NEGLECTED: 'NEGLECTED',
    NOTACTIVE: 'NOTACTIVE',
    Untouchable: 'Untouchable',
    Discarded: 'Discarded',
    Pending: 'Pending',
    Reviewing: 'Reviewing',
    InitPending: 'InitPending',
    Running: 'Running',
    Terminated: 'Terminated',
    WrittenOff: 'WrittenOff',
    RolledOverPrin: 'RolledOverPrin',
    RolledOverPrinInt: 'RolledOverPrinInt',
};
export type TermInstrumentClosureSupra = typeof TermInstrumentClosureSupra[keyof typeof TermInstrumentClosureSupra];

export const RateComposition = {
    Fixed: 'Fixed',
    Floating: 'Floating',
    FloatingAndFixed: 'FloatingAndFixed',
};
export type RateComposition = typeof RateComposition[keyof typeof RateComposition];

export const TermInterestType = {
    Simple: 'Simple',
    Amortized: 'Amortized',
    Annuity: 'Annuity',
    Coupon: 'Coupon',
    Compound: 'Compound',
    Discounted: 'Discounted',
    Bullet: 'Bullet',
    Commitment: 'Commitment',
    RuleOf78: 'RuleOf78',
};
export type TermInterestType = typeof TermInterestType[keyof typeof TermInterestType];

export const TermPaymentType = {
    DRAWDOWN: 'DRAWDOWN',
    PAYDOWN: 'PAYDOWN',
};
export type TermPaymentType = typeof TermPaymentType[keyof typeof TermPaymentType];

export const TermProvisionType = {
    PROVISION: 'PROVISION',
    WRITEBACK: 'WRITEBACK',
};
export type TermProvisionType = typeof TermProvisionType[keyof typeof TermProvisionType];

export const TermSyndicationTypes = {
    NONE: 'NONE',
    MANDATORY: 'MANDATORY',
    FLEXIBLE: 'FLEXIBLE',
};
export type TermSyndicationTypes = typeof TermSyndicationTypes[keyof typeof TermSyndicationTypes];

export const TermTxnClosure = {
    Untouchable: 'Untouchable',
    Pending: 'Pending',
    Processed: 'Processed',
    InitEntry: 'InitEntry',
    TerminateEntry: 'TerminateEntry',
};
export type TermTxnClosure = typeof TermTxnClosure[keyof typeof TermTxnClosure];

export const TradeExclusionType = {
    AllowAll: 'AllowAll',
    Complete: 'Complete',
    ThresholdQuantity: 'ThresholdQuantity',
    ThresholdValue: 'ThresholdValue',
};
export type TradeExclusionType = typeof TradeExclusionType[keyof typeof TradeExclusionType];

export const TradingSessionClosure = {
    REQUEST_REJECTED: 'REQUEST_REJECTED',
    PENDING: 'PENDING',
    TRIGGERED: 'TRIGGERED',
    DELETE: 'DELETE',
};
export type TradingSessionClosure = typeof TradingSessionClosure[keyof typeof TradingSessionClosure];

export const TransactionSummary = {
    DAILY: 'DAILY',
    WEEKLY: 'WEEKLY',
    MONTHLY: 'MONTHLY',
    QUARTERLY: 'QUARTERLY',
    YEARLY: 'YEARLY',
};
export type TransactionSummary = typeof TransactionSummary[keyof typeof TransactionSummary];

export const TRTermTypeKnownType = {
    CLIENT_MARGIN: 'CLIENT_MARGIN',
    CLIENT_LOAN: 'CLIENT_LOAN',
    COMM_PAPER: 'COMM_PAPER',
    STAFF_CARLOAN: 'STAFF_CARLOAN',
    STAFF_PERSLOAN: 'STAFF_PERSLOAN',
};
export type TRTermTypeKnownType = typeof TRTermTypeKnownType[keyof typeof TRTermTypeKnownType];

export const TrustFrequency = {
    None: 'None',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    SemiAnnually: 'SemiAnnually',
    Annually: 'Annually',
    BiAnnually: 'BiAnnually',
    Custom: 'Custom',
};
export type TrustFrequency = typeof TrustFrequency[keyof typeof TrustFrequency];

export const UserModeEnum = {
    Disabled: 'Disabled',
    Onboarding: 'Onboarding',
    Reviewing: 'Reviewing',
    SteadyState: 'SteadyState',
};
export type UserModeEnum = typeof UserModeEnum[keyof typeof UserModeEnum];

export const ValHoldingTypeCodes = {
    ISSUE_SPLIT: 'ISSUE_SPLIT',
    ISSUE_MERGER: 'ISSUE_MERGER',
    ISSUE_PRY: 'ISSUE_PRY',
    ISSUE_ALL: 'ISSUE_ALL',
    CERTIFICATE: 'CERTIFICATE',
    TANGIBLE: 'TANGIBLE',
    TRK_LOANED: 'TRK_LOANED',
    TRK_BORROWED: 'TRK_BORROWED',
    LIEN_NOMINAL: 'LIEN_NOMINAL',
    LIEN_PHYSICAL: 'LIEN_PHYSICAL',
};
export type ValHoldingTypeCodes = typeof ValHoldingTypeCodes[keyof typeof ValHoldingTypeCodes];

export const ValPayoutTypeCodes = {
    WARRANT_DIVIDEND: 'WARRANT_DIVIDEND',
    WARRANT_RETURN: 'WARRANT_RETURN',
    WARRANT_ALL: 'WARRANT_ALL',
};
export type ValPayoutTypeCodes = typeof ValPayoutTypeCodes[keyof typeof ValPayoutTypeCodes];

export const WarrantStatusEnum = {
    RECEIVED: 'RECEIVED',
    VAULTED: 'VAULTED',
    PICKUP: 'PICKUP',
    POSTBATCHED: 'POSTBATCHED',
    REGISTRAR: 'REGISTRAR',
    OWNER: 'OWNER',
    RETRIEVED: 'RETRIEVED',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type WarrantStatusEnum = typeof WarrantStatusEnum[keyof typeof WarrantStatusEnum];

export const WarrantTypeEnum = {
    Dividend: 'Dividend',
    ReturnMoney: 'ReturnMoney',
};
export type WarrantTypeEnum = typeof WarrantTypeEnum[keyof typeof WarrantTypeEnum];

export const WillPartyTypes = {
    Attestor: 'Attestor',
    ExecutorPrimary: 'ExecutorPrimary',
    ExecutorSecondary: 'ExecutorSecondary',
    GuardianOfMinor: 'GuardianOfMinor',
};
export type WillPartyTypes = typeof WillPartyTypes[keyof typeof WillPartyTypes];

export const XferStatusEnum = {
    REQUEST_VOID: 'REQUEST_VOID',
    FAILED_CSD: 'FAILED_CSD',
    FAILED_CPRTY: 'FAILED_CPRTY',
    FAILED_REQ: 'FAILED_REQ',
    FAILED_RISK: 'FAILED_RISK',
    FAILED_CTRL: 'FAILED_CTRL',
    FAILED_CONFIRM: 'FAILED_CONFIRM',
    RECEIVED: 'RECEIVED',
    CONFIRMING: 'CONFIRMING',
    CTRL_CHECKING: 'CTRL_CHECKING',
    RISK_APPROVING: 'RISK_APPROVING',
    REQ_GATHER: 'REQ_GATHER',
    CPRTY_APPROVAL: 'CPRTY_APPROVAL',
    CSD_APPROVAL: 'CSD_APPROVAL',
    POSTED: 'POSTED',
    VOIDED: 'VOIDED',
};
export type XferStatusEnum = typeof XferStatusEnum[keyof typeof XferStatusEnum];

