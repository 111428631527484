import {
	cibBehance,
	cibCcAmex,
	cibCcMastercard,
	cibCcVisa,
	cibDribbble,
	cibFacebook,
	cibFlickr,
	cibGithub,
	cibGooglePay,
	cibInstagram,
	cibLinkedin,
	cibPaypal,
	cibPinterest,
	cibReddit,
	cibSkype,
	cibStackoverflow,
	cibStripe,
	cibTumblr,
	cibTwitter,
	cibVk,
	cibXing,
	cibYahoo,
	cibYoutube,
	cibVimeo,
} from '@coreui/icons';

export const cibIcons = Object.assign(
	{}, {
		cibBehance,
		cibCcAmex,
		cibCcMastercard,
		cibCcVisa,
		cibDribbble,
		cibFacebook,
		cibFlickr,
		cibGithub,
		cibGooglePay,
		cibInstagram,
		cibLinkedin,
		cibPaypal,
		cibPinterest,
		cibReddit,
		cibSkype,
		cibStackoverflow,
		cibStripe,
		cibTumblr,
		cibTwitter,
		cibVk,
		cibXing,
		cibYahoo,
		cibYoutube,
		cibVimeo,
	}
);
