import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { PartnerState } from 'app-model/enums';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { EnumAllOptionValue, oneYearAgo, today } from 'app-model/ModelConstants';

const stLists : IListComponentControlList = {
	ProjectList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectList',
		entityName: 'Project',
		fetchUriTemplate: 'Project',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: PartnerState.Active,
		},
	},
	ProjectPick: {
		...fluxListDefaults,
		actionNamespace: 'ProjectPick',
		entityName: 'Project',
		fetchUriTemplate: 'Project',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ProjectDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'ProjectDocumentSubList',
		fetchUriTemplate: 'Project(${subListParentId})/Documents',
		exportUriTemplate: 'Project(${subListParentId})/Documents(${entityId})/Export',
	},

	ProjectExpectationSubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectExpectationSubList',
		entityName: 'ProjectExpectation',
		cmdUriTemplate: 'Project(${subListParentId})/Expectations',
		fetchUriTemplate: 'Project(${subListParentId})/Expectations',
		exportUriTemplate: 'Project(${subListParentId})/Expectations/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ProjectGlAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectGlAcctSubList',
		entityName: 'ProjectGlAcct',
		cmdUriTemplate: 'Project(${subListParentId})/GlAccts',
		fetchUriTemplate: 'Project(${subListParentId})/GlAccts',
		exportUriTemplate: 'Project(${subListParentId})/GlAccts/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ProjectMemberSubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectMemberSubList',
		entityName: 'ProjectMember',
		cmdUriTemplate: 'Project(${subListParentId})/Members',
		fetchUriTemplate: 'Project(${subListParentId})/Members',
		exportUriTemplate: 'Project(${subListParentId})/Members/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ProjectOutputSubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectOutputSubList',
		entityName: 'ProjectOutput',
		cmdUriTemplate: 'Project(${subListParentId})/Outputs',
		fetchUriTemplate: 'Project(${subListParentId})/Outputs',
		exportUriTemplate: 'Project(${subListParentId})/Outputs/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ProjectOutputTypeList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectOutputTypeList',
		entityName: 'ProjectOutputType',
		fetchUriTemplate: 'ProjectOutputType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	ProjectOutputTypePick: {
		...fluxListDefaults,
		actionNamespace: 'ProjectOutputTypePick',
		entityName: 'ProjectOutputType',
		fetchUriTemplate: 'ProjectOutputType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ProjectPartySubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectPartySubList',
		entityName: 'ProjectParty',
		cmdUriTemplate: 'Project(${subListParentId})/Parties',
		fetchUriTemplate: 'Project(${subListParentId})/Parties',
		exportUriTemplate: 'Project(${subListParentId})/Parties/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			partnerClass: EnumAllOptionValue,
			subListParentId: -1,
		},
	},
	ProjectPartyPick: {
		...fluxPickDefaults,
		actionNamespace: 'ProjectPartyPick',
		entityName: 'ProjectParty',
		fetchUriTemplate: 'Project(${projectId})/Parties',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	ProjectStatusReportList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectStatusReportSubList',
		entityName: 'ProjectStatusReport',
		fetchUriTemplate: 'ProjectStatusReport',
		exportUriTemplate: 'ProjectStatusReport/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: oneYearAgo,
			endDate: today,
		},
	},
	ProjectStatusReportSubList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectStatusReportSubList',
		entityName: 'ProjectStatusReport',
		fetchUriTemplate: 'Project(${subListParentId})/StatusReports',
		exportUriTemplate: 'Project(${subListParentId})/StatusReports/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: oneYearAgo,
			endDate: today,
		},
	},

	ProjectTypeList: {
		...fluxListDefaults,
		actionNamespace: 'ProjectTypeList',
		entityName: 'ProjectType',
		fetchUriTemplate: 'ProjectType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	ProjectTypePick: {
		...fluxListDefaults,
		actionNamespace: 'ProjectTypePick',
		entityName: 'ProjectType',
		fetchUriTemplate: 'ProjectType',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	ProjectView: {
		...fluxViewDefaults,
		actionNamespace: 'ProjectView',
		entityName: 'Project',
		fetchUriTemplate: 'Project',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({	lists: listReducer,
	views: viewReducer,
});
