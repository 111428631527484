import { FunctionComponent } from 'react';
import { useMsal } from "@azure/msal-react";

import { $isNull } from 'basicFunctions';
import { handleLogin } from 'adalConfig';

//import './style.css'

const PageUnauthenticated: FunctionComponent<{}> = (props) => {
	const { instance } = useMsal();

	return (
		<section className='w-[100%] min-h-[100vh] p-[3%] flex items-center justify-between bg-gradient-to-r from-[#00b7ff] to-[#ffffc7] text-[#161b40]'>
			{/* <div className='hidden lg:flex lg:w-[30%]'>
				<p className='text-[100px] lg:text-[200px] xl:text-[300px] font-black'>401</p>
			</div> */}

			<div className='relative w-[100%] min-h-[90vh] lg:w-[100%] flex flex-col items-center justify-center text-[#fff]'>
				<p className='hidden absolute z-[1] lg:flex bg-pageUnauthenticatedBg bg-gradient-to-r from-cyan-500 to-blue-500 bg-no-repeat bg-cover blur-[04px] w-[100%] h-[100%]'>
				</p>

				<p className='z-[10] text-[25px] xl:text-[40px] font-[100] text-center'>
					Welcome To <span className='font-bold'>TradeOffice</span>
					{/* &nbsp;for */}
					<br/> 
					<span className='text-[35px] lg:text-[60px] xl:text-[70px] font-[900]'>_________</span>
				</p>	

				<button className='z-[10] mt-[5vh] hover:font-[700] hover:rounded-[30px] p-[20px] px-[35px] duration-500 text-[18px] text-[#161b40] bg-white' 
					onClick={() => handleLogin(instance) }>
					Click to Sign In
				</button>
			</div>				
		</section>
	);
};

export default PageUnauthenticated;
