import { combineReducers } from 'redux';

import records from './records/_Reducers';
import vendoradmin from './vendoradmin/_Reducers';
import activity from './activity/_Reducers';
import transactions from './transactions/_Reducers';
import relationship from './relationship/_Reducers';
import portal from './portal/_Reducers';

export default combineReducers({
	records,
	vendoradmin,
	transactions,
	activity,
	relationship,
	portal,
});
