import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeMonthsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';
import { ContractClosure } from 'app-model/enums';
import { CurrencyCodes, PartnerState, TermInstrumentClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerInterestRunSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpVendorRelMgrList: {
		...fluxListDefaults,
		actionNamespace: 'CmpVendorRelMgrList',
		entityName: 'VendorRelMgr',
		fetchUriTemplate: 'VendorRelMgr/GetRelMgrsAtDate',
		exportUriTemplate: 'VendorRelMgr/ExportRelMgrsAtDate',
		orderBy: 'PartnerLabel asc',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			relMgrId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	VendorDocumentTOList: {
		...fluxListDefaults,
		actionNamespace: 'VendorDocumentTOList',
		entityName: 'VendorDocumentTO',
		fetchUriTemplate: 'orgcompany(${companyId})/GetCustomerKypDocuments(SubClassId=${subClassId},DocumentClass=${enumParamDocumentClass},StaffId=${staffId},SearchText=${searchText})',
		exportUriTemplate: 'orgcompany(${companyId})/ExportCustomerKypDocuments',
		orderBy: 'PartnerLabel asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamDocumentClass: EnumAllOptionValue,
			staffId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},
	
	VendorInterestRunSubList: {
		...fluxPartnerInterestRunSubListDefaults,
		actionNamespace: 'VendorInterestRunSubList',
		entityName: 'PartnerInterestRun',
		fetchUriTemplate: 'Customer(${subListParentId})/InterestRuns',
		exportUriTemplate: 'Customer(${subListParentId})/InterestRuns/Export',
	},

	VendorOpeningList: {
		...fluxListDefaults,
		actionNamespace: 'VendorOpeningList',
		entityName: 'VendorOpening',
		fetchUriTemplate: 'VendorOpening',
		orderBy: 'RequestDate desc, Subclasscode asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			partnerClass: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},

	VendorSubClassList: {
		...fluxListDefaults,
		actionNamespace: 'VendorSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			companyId: 1,
			partnerClass: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	VendorSubClassPick: {
		...fluxPickDefaults,
		actionNamespace: 'VendorSubClassPick',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerValuation: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerValuation',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'PartnerSubClass($select=SubClassCode)',
			'BenchmarkInstrument($select=InstrumentCode,InstrumentName)',
			'MasterRecord($expand=PrimaryAddress,OrgLocation($select=LocationCode,Description))',
			'PartnerGlAccts($expand=OrgCompany($select=CoShortCode,CoName),FAGlAcct($select=AcctNo,AcctTitle,CashAcctNo))',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
