import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, today, oneYearAgo, threeYearsAgo, tenYearsAgo } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';
import { ContractClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FundContributionBatchList: {
		...fluxListDefaults,
		actionNamespace: 'FundContributionBatchList',
		entityName: 'FundContributionBatch',
		fetchUriTemplate: 'FundContributionBatch',
		orderBy: 'BatchDate desc, Fund/InstrumentCode asc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: ['Notes'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	FundOrderList: {
		...fluxListDefaults,
		actionNamespace: 'FundOrderList',
		entityName: 'FundOrder',
		fetchUriTemplate: 'FundOrder/GetFundOrderForRange(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},InstrId=${instrId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'FundOrder/ExportFundOrderForRange',
		orderBy: 'OrderDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrId: NullEntityId,
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FundOrderDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundOrderDocumentSubList',
		fetchUriTemplate: 'FundOrder(${subListParentId})/Documents',
		exportUriTemplate: 'FundOrder(${subListParentId})/Documents(${entityId})/Export',
	},

	FundOrderJobPick: {
		...fluxPickDefaults,
		actionNamespace: 'FundOrderJobPick',
		entityName: 'FundOrderJob',
		fetchUriTemplate: 'FundTradeSheet(${parentId})/GetOrdersForDealSheet(InstrId=${instrId},OrderType=${enumParamOrderType},OrderMode=${enumParamOrderMode},OrderValidation=${enumParamOrderValidation},Status=${enumParamStatus},SearchText=${searchText})',
		orderBy: 'AcctTitle asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			parentId: -1,
			instrId: NullEntityId,
			enumParamOrderType: EnumAllOptionValue,
			enumParamOrderMode: EnumAllOptionValue,
			enumParamOrderValidation: EnumAllOptionValue,
			enumParamStatus: EnumAllOptionValue,
		},
	},

	FundOrderJobSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundOrderJobSubList',
		entityName: 'FundOrderJob',
		fetchUriTemplate: 'FundTradeSheet(${subListParentId})/GetOrdersForDealSheet(InstrId=${instrId},OrderType=${enumParamOrderType},OrderMode=${enumParamOrderMode},OrderValidation=${enumParamOrderValidation},Status=${enumParamStatus},SearchText=${searchText})',
		exportUriTemplate: 'FundTradeSheet(${subListParentId})/ExportOrdersForDealSheet',
		orderBy: 'AcctTitle asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrId: NullEntityId,
			enumParamOrderType: EnumAllOptionValue,
			enumParamOrderMode: EnumAllOptionValue,
			enumParamOrderValidation: EnumAllOptionValue,
			enumParamStatus: EnumAllOptionValue,
		},
	},

	FundTradeSheetList: {
		...fluxListDefaults,
		actionNamespace: 'FundTradeSheetList',
		entityName: 'FundTradeSheet',
		fetchUriTemplate: 'FundTradeSheet',
		orderBy: 'TradeDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	FundTransactionList: {
		...fluxListDefaults,
		actionNamespace: 'FundTransactionList',
		entityName: 'FundTransaction',
		fetchUriTemplate: 'FundTransaction',
		printUriTemplate: 'FundTransaction/PRINT()',
		orderBy: 'FundTradeSheet/TradeDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			fundId: NullEntityId,
			transType: EnumAllOptionValue,
		},
	},
	FundTransactionSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundTransactionSubList',
		entityName: 'FundTransaction',
		fetchUriTemplate: 'FundTradeSheet(${subListParentId})/FundTransactions',
		printUriTemplate: 'FundTransaction/PRINT()',
		orderBy: 'EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Fund($select=InstrumentCode,InstrumentName,Currency)',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			fundId: NullEntityId,
			transType: EnumAllOptionValue,
		},
	},

	FundTransactionDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FundTransactionDocumentSubList',
		fetchUriTemplate: 'FundTransaction(${subListParentId})/Documents',
		exportUriTemplate: 'FundTransaction(${subListParentId})/Documents(${entityId})/Export',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundTransactionView: {
		...fluxViewDefaults,
		actionNamespace: 'FundTransactionView',
		entityName: 'FundTransaction',
		fetchUriTemplate: 'FundTransaction',
		selectFields: [],
		expandProps: [
			'FundTradeSheet($select=TradeDate)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentType,InstrumentCode,InstrumentName)',
			'WarehouseTradeAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'DealAllotCommission($select=GroupName)',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'FundTransactionFees',
		],
	},

	FundOrderView: {
		...fluxViewDefaults,
		actionNamespace: 'FundOrderView',
		entityName: 'FundOrder',
		fetchUriTemplate: 'FundOrder',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'OrderCommission($select=GroupName)',
			'FundOrderFees'
		],
	},

	FundTradeSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'FundTradeSheetView',
		entityName: 'FundTradeSheet',
		fetchUriTemplate: 'FundTradeSheet',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
