import { lazy, Suspense } from 'react';

export const Loading = (props) => {
	if (props && props.error) {
		//return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
		return <div>Error!</div>;
	} 
	else if (props && props.timedOut) {
		//return <div>Taking a long time... <button onClick={ props.retry }>Retry</button></div>;
		return <div>Taking a very long time...</div>;
	} 
	else { //if (props && props.pastDelay) {
		return (
			<div className="d-flex justify-content-center m-5">
				<button className="btn btn-primary" type="button" disabled>
					<span className="spinner-border" role="status" aria-hidden="true"></span>
					<strong>Loading...</strong>
				</button>
				{ /*<div className="animated fadeIn pt-3 text-center">Loading...</div> */}
			</div>
		);
		
		// return (
		// 	<div className="pt-3 text-center">
		// 		<div className="sk-spinner sk-spinner-pulse"></div>
		// 	</div>
		// );
	} 
	// else {
	//     return null;
	// }
};

const Page404Lazy = lazy(() => import('./Page404'));

export const Page404 = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <Page404Lazy {...props} />
        </Suspense>
    );
};

const Page500Lazy = lazy(() => import('./Page500'));

export const Page500 = (props) => {
    return (
        <Suspense fallback={<Loading />}>
            <Page500Lazy {...props} />
        </Suspense>
    );
};
