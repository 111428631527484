import { combineReducers } from 'redux';

import master from './master/_Reducers';
import invstmgt from './invstmgt/_Reducers';
import staffing from './staffing/_Reducers';
import structure from './structure/_Reducers';

export default combineReducers({
	master,
	invstmgt,
	staffing,
	structure,
});
