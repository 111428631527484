import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { FAAssetClosure, JournalClosure } from 'app-model/enums';
import { NullEntityId, today, threeMonthsAgo, oneYearAgo, tenYearsAgo, EnumAllOptionValue } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FAAssetAmortizationSubList: {
		...fluxPickDefaults,
		actionNamespace: 'FAAssetAmortizationSubList',
		entityName: 'FAAssetAmortization',
		fetchUriTemplate: 'FAAsset(${subListParentId})/FAAssetAmortizations',
		orderBy: 'AmortDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	FAAssetList: {
		...fluxListDefaults,
		actionNamespace: 'FAAssetList',
		entityName: 'FAAsset',
		//fetchUriTemplate: 'FAAsset',
		fetchUriTemplate: 'FAAsset/GetFAAssetForRange(StartDate=1900-01-01,EndDate=2099-01-01,CompanyId=${companyId},AssetGroupId=${assetGroupId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'FAAsset/ExportFAAssetForRange',
		orderBy: 'ValueDate desc',
		selectFields: ['Id', 'OrgCompanyId', 'AssetGroupId', 'AssetNo', 'AssetName', 'Department', 'Custodian', 'ValueDate', 'ValueAtAcquisition', 'ValueAtPresent', 'UsefulLifeAtPresent', 'ResidueDate', 'ResidualValue', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)', 'FAAssetGroup($select=AssetGroupName)'],
		searchFields: ['AssetNo', 'AssetName', 'Department', 'Custodian'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			assetGroupId: NullEntityId,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: FAAssetClosure.Acquired,
		},
	},
	FAAssetPick: {
		...fluxPickDefaults,
		actionNamespace: 'FAAssetPick',
		entityName: 'FAAsset',
		fetchUriTemplate: 'FAAsset',
		orderBy: 'ValueDate desc',
		selectFields: ['Id', 'OrgCompanyId', 'AssetGroupId', 'AssetNo', 'AssetName', 'ValueDate', 'ValueAtAcquisition', 'ValueAtPresent', 'UsefulLifeAtPresent', 'ResidueDate', 'ResidualValue', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)', 'FAAssetGroup($select=AssetGroupName)'],
		searchFields: ['AssetNo', 'AssetName'],
		filterValues: {
			searchText: '',
		},
	},

	FAAssetDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAAssetDocumentSubList',
		fetchUriTemplate: 'FAAsset(${subListParentId})/Documents',
		exportUriTemplate: 'FAAsset(${subListParentId})/Documents(${entityId})/Export',
	},

	FAAssetGroupList: {
		...fluxListDefaults,
		actionNamespace: 'FAAssetGroupList',
		entityName: 'FAAssetGroup',
		fetchUriTemplate: 'FAAssetGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	FAAssetGroupPick: {
		...fluxPickDefaults,
		actionNamespace: 'FAAssetGroupPick',
		entityName: 'FAAssetGroup',
		fetchUriTemplate: 'FAAssetGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAAssetMaintList: {
		...fluxListDefaults,
		actionNamespace: 'FAAssetMaintList',
		entityName: 'FAAssetMaint',
		fetchUriTemplate: 'FAAssetMaint',
		orderBy: 'AssetNo asc',
		selectFields: ['Id', 'MaintDate', 'Description', 'IsScheduled', 'IsCorrective', 'IsEnhancement', 'CompletionDate', 'ClosureType'],
		expandProps: ['FAAsset($select=AssetNo,AssetName,GroupAssetNo;$expand=FAAssetGroup($select=AssetGroupName))', 'Vendor($select=PartnerNo,PartnerLabel)'],
		searchFields: ['FAAsset/AssetNo', 'FAAsset/AssetName', 'Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			assetGroupId: NullEntityId,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: JournalClosure.Pending,
		},
	},
	FAAssetMaintSubList: {
		...fluxPickDefaults,
		actionNamespace: 'FAAssetMaintSubList',
		entityName: 'FAAssetMaint',
		fetchUriTemplate: 'FAAsset(${subListParentId})/GetFAAssetMaintForAsset()',
		orderBy: 'MaintDate desc',
		selectFields: [],
		expandProps: ['Vendor($select=PartnerNo,PartnerLabel)'],
		searchFields: ['FAAsset/AssetNo', 'FAAsset/AssetName', 'Description'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FAAssetGroupView: {
		...fluxViewDefaults,
		actionNamespace: 'FAAssetGroupView',
		entityName: 'FAAssetGroup',
		fetchUriTemplate: 'FAAssetGroup',
		queryUseRestEndpoint: true,
	},

	FAAssetView: {
		...fluxViewDefaults,
		actionNamespace: 'FAAssetView',
		entityName: 'FAAsset',
		fetchUriTemplate: 'FAAsset',
		selectFields: [],
		expandProps: [
			'FAAssetGroup($select=AssetGroupName,AssetType,UsefulLifeMonths)',
			'ParentAsset($select=AssetNo,AssetName)', 
			'OrgCompany($select=CoShortCode,CoName)', 
			'UtilizingCompany($select=CoShortCode,CoName)', 
			'Vendor($select=PartnerNo,PartnerLabel)', 
			'FACenter($select=CenterCode,CenterName)', 
			'FAAssetEstimates'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
