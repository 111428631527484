import { combineReducers } from 'redux';

import { toIsoDateString, toIsoDateTimeLocalInputString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { today, oneWeekAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	AuditOpList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'AuditOpList',
		entityName: 'AuditOp',
		fetchUriTemplate: 'AuditOp',
		exportUriTemplate: 'AuditOp/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			// startDate: toIsoDateTimeLocalInputString(oneMonthAgo),
			// endDate: toIsoDateTimeLocalInputString(today),
			startDate: toIsoDateString(oneWeekAgo),
			endDate: toIsoDateString(today),
		},
	},

	AuditValueList: {
		...fluxListDefaults,
		sizePage: 100,
		actionNamespace: 'AuditValueList',
		entityName: 'AuditValue',
		fetchUriTemplate: 'AuditValue',
		exportUriTemplate: 'AuditValue/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			// startDate: toIsoDateTimeLocalInputString(oneMonthAgo),
			// endDate: toIsoDateTimeLocalInputString(today),
			startDate: toIsoDateString(oneWeekAgo),
			endDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	AuditOpView: {
		...fluxViewDefaults,
		actionNamespace: 'AuditOpView',
		entityName: 'AuditOp',
		fetchUriTemplate: 'AuditOp',
		selectFields: [],
		expandProps: ['AuditValues'],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
