import { compose } from 'redux';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
		grifAdalConfig?: any;
		editor: any;
	}
}

const windowCopy = window;

export { windowCopy as window };
