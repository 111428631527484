import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	PartnerWebList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerWebList',
        entityName: 'PartnerWeb',
		fetchUriTemplate: 'PartnerWeb',
		orderBy: 'PartnerNo asc',
		selectFields: [],
		expandProps: ['PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	WebReviewFaqList: {
		...fluxListDefaults,
		actionNamespace: 'WebReviewFaqList',
        entityName: 'WebReviewFaq',
		fetchUriTemplate: 'WebReviewFaq',
		orderBy: 'PartnerNo asc',
		selectFields: [],
		expandProps: ['PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	WebSubmittedQuestionList: {
		...fluxListDefaults,
		actionNamespace: 'WebSubmittedQuestionList',
        entityName: 'WebSubmittedQuestion',
		fetchUriTemplate: 'WebSubmittedQuestion',
		orderBy: 'PartnerNo asc',
		selectFields: [],
		expandProps: ['PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	PartnerWebView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerWebView',
		entityName: 'PartnerWeb',
		fetchUriTemplate: 'PartnerWeb',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)',
			'PartnerWebOpQueues',
			'PartnerWebServices'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
