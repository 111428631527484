import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SysActions } from 'app-model/SysActions';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FABasisList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisList',
		entityName: 'FABasis',
		fetchUriTemplate: 'orgcompany(${companyId})/fabases',
		orderBy: 'businessStart desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['year'],
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	FACenterList: {
		...fluxListDefaults,
		actionNamespace: 'FACenterList',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	FACenterPick: {
		...fluxPickDefaults,
		actionNamespace: 'FACenterPick',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAConfigList: {
		...fluxListDefaults,
		actionNamespace: 'FAConfigList',
        entityName: 'FAConfig',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgFAConfig',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
		},
	},

	FAPaymentProcessorList: {
        ...fluxListDefaults,
		actionNamespace: 'FAPaymentProcessorList',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},
	FAPaymentProcessorPick: {
        ...fluxPickDefaults,
		actionNamespace: 'FAPaymentProcessorPick',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FABasisView: {
		...fluxViewDefaults,
		actionNamespace: 'FABasisView',
		entityName: 'FABasis',
		fetchUriTemplate: 'FABasis',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName,CoOrgType)', 'FABasisPeriods'],
	},

	FACenterView: {
		...fluxViewDefaults,
		actionNamespace: 'FACenterView',
		entityName: 'FACenter',
		fetchUriTemplate: 'FACenter',
		queryUseRestEndpoint: true,
	},

	FAConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'FAConfigView',
		entityName: 'FAConfig',
		fetchUriTemplate: 'FAConfig',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'FAConfigCurrencies('
			+ '$expand=BankChargeExpenseAcct($select=AcctNo,AcctTitle),BankHandlingChargeIncomeAcct($select=AcctNo,AcctTitle)'
			+ ',CapitalGainsTaxAcct($select=AcctNo,AcctTitle),DefClientPaymentReceiptAcct($select=AcctNo,AcctTitle)'
			+ ',DefVendorPaymentReceiptAcct($select=AcctNo,AcctTitle),DefSwapAcct($select=AcctNo,AcctTitle)'
			+ ',CurrencyGainAcct($select=AcctNo,AcctTitle),CurrencyLossAcct($select=AcctNo,AcctTitle)'
			+ ',GovStampDutyAcct($select=AcctNo,AcctTitle),GovVATAcct($select=AcctNo,AcctTitle)'
			+ ',GovWithholdingTaxAcct($select=AcctNo,AcctTitle),RetainedProfitAcct($select=AcctNo,AcctTitle)'
			+ ',RetainedLossAcct($select=AcctNo,AcctTitle),OtherCompProfitAcct($select=AcctNo,AcctTitle)'
			+ ',OtherCompLossAcct($select=AcctNo,AcctTitle),PayrollAccrueGratuityAcct($select=AcctNo,AcctTitle)'
			+ ',PayrollAccruePAYEAcct($select=AcctNo,AcctTitle),PayrollDisburseAcct($select=AcctNo,AcctTitle)'
			+ ',PensionCompanyContribAcct($select=AcctNo,AcctTitle),PensionEmployeeContribAcct($select=AcctNo,AcctTitle)'
			+ ')'
		],
	},

	FAPaymentProcessorView: {
		...fluxViewDefaults,
		actionNamespace: 'FAPaymentProcessorView',
		entityName: 'FAPaymentProcessor',
		fetchUriTemplate: 'FAPaymentProcessor',
		queryUseRestEndpoint: true,
		selectFields: [],
		expandProps: ['Configs'],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
