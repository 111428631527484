import React, { FunctionComponent } from 'react';

import { CCol, CContainer, CRow } from '@coreui/react';

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { getFileTypeIconProps, initializeFileTypeIcons } from '@fluentui/react-file-type-icons';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Loading } from 'app-widgets/NonDefault';

import { App } from './App';

import './App.scss';

import './indexApp.css';

import { cibIcons } from 'assets/icons/coreui/brand'
import { cilIcons } from 'assets/icons/coreui/line'
import { antIcons } from 'assets/icons/ant-design'
import { mdiIcons } from 'assets/icons/materialdesignicons'
import { logoIcons } from 'assets/icons/logo'

import PageUnauthenticated from 'app-widgets/PageUnauthenticated';

ChartJS.register(...registerables);

const ReactProxy: any = React;

ReactProxy.icons = Object.assign({}, cibIcons, cilIcons, antIcons, mdiIcons, logoIcons);

initializeIcons();
initializeFileTypeIcons();

export const AppMsal: FunctionComponent<any> = () => {
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	switch (inProgress) {
		case InteractionStatus.None:
			return (
				(isAuthenticated == true) 
				? <App />
				: <PageUnauthenticated />
			);
		default:
			return (
				<div className="app flex-row align-items-center">
					<CContainer>
						<CRow className="justify-content-center">
							<CCol md="6">
								<p>Authenticating...</p>
								<Loading />
							</CCol>
						</CRow>
					</CContainer>
				</div>
			);
	}
};
