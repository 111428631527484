import { applyMiddleware, combineReducers, compose, legacy_createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import freeze from 'redux-freeze';

import { window } from 'utils/window';

import rootReducer from "./Reducers";

const logger = createLogger();
const middlewares = [
	thunk, 
	freeze, 
	logger,
];

const composeEnhancers =
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	})
	: compose;

const enhancer = composeEnhancers(
	applyMiddleware(...middlewares),
	// other store enhancers if any
);

const store = legacy_createStore(rootReducer, /* preloadedState, */ enhancer);

export default store;
