import { combineReducers } from 'redux';

import setup from './setup/_Reducers';
import subscribermgt from './subscribermgt/_Reducers';
import transactions from './transactions/_Reducers';
import schemecontrib from './schemecontrib/_Reducers';
import schemeloan from './schemeloan/_Reducers';
import schemeliquidate from './schemeliquidate/_Reducers';
import valuation from './valuation/_Reducers';
import reporting from './reporting/_Reducers';

export default combineReducers({
	setup,
	subscribermgt,
	transactions,
	schemecontrib,
	schemeloan,
	schemeliquidate,
	valuation,
	reporting,
});
