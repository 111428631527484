import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeMonthsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	FACashDisburseList: {
		...fluxListDefaults,
		actionNamespace: 'FACashDisburseList',
		entityName: 'FACashDisburse',
		fetchUriTemplate: 'orgcompany(${companyId})/GetAllCashDisburseForCo(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'DisburseDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'DisburseDate', 'ItemDesc', 'Amount', 'Currency', 'CurrencyRate', 'Receiver', 'VoucherNo', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
		],
		searchFields: ['ItemDesc', 'VoucherNo', 'CashAcct/AcctNo', 'CashAcct/AcctTitle'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FACashDisburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FACashDisburseDocumentSubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/Documents',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/Documents(${entityId})/Export',
	},

	FACashDisburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FACashDisburseWorkflowHistorySubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/ExportWorkflowHistory',
	},

	FAExpenseAdvanceList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseAdvanceList',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance/GetAllExpenseAdvances(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	FAExpenseAdvancePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseAdvancePick',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
		},
	},

	FAExpenseAdvanceDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAExpenseAdvanceDocumentSubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents(${entityId})/Export',
	},

	FAExpenseAdvanceWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FAExpenseAdvanceWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/ExportWorkflowHistory',
	},

	FAExpenseRetireList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseRetireList',
		entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire/GetAllExpenseRetires(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAExpenseRetireDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAExpenseRetireDocumentSubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents(${entityId})/Export',
	},

	FAExpenseRetireWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'FAExpenseRetireWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/ExportWorkflowHistory',
	},

	FAExpenseTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseTypeList',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyExpenseTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAExpenseTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseTypePick',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyExpenseTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
		},
	},

	FAIncomeDirectList: {
		...fluxListDefaults,
		actionNamespace: 'FAIncomeDirectList',
		entityName: 'FAIncomeDirect',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCoIncomeDirect(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		orderBy: 'TransactionDate desc',
		selectFields: [],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
	],
		searchFields: ['ItemDesc', 'VoucherNo', 'CashAcct/AcctNo', 'CashAcct/AcctTitle'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAIncomeDirectDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAIncomeDirectDocumentSubList',
		fetchUriTemplate: 'FAIncomeDirect(${subListParentId})/Documents',
		exportUriTemplate: 'FAIncomeDirect(${subListParentId})/Documents(${entityId})/Export',
	},

	FAIncomeTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAIncomeTypeList',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyIncomeTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAIncomeTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAIncomeTypePick',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyIncomeTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FACashDisburseView: {
		...fluxViewDefaults,
		actionNamespace: 'FACashDisburseView',
		entityName: 'FACashDisburse',
		fetchUriTemplate: 'FACashDisburse',
		selectFields: [],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			// 'Project($select=ProjectCode,ProjectName)',
			// 'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	FAExpenseAdvanceView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseAdvanceView',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'Project($select=ProjectCode,ProjectName)',
			//'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	FAExpenseRetireView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseRetireView',
		entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'Project($select=ProjectCode,ProjectName)',
			//'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',

			//'FAExpenseAdvance($select=PayDate,PayCurrency,PayAmount,Description;$expand=HRStaff($select=StaffNo,StaffName),PlItem($select=Code,Description))',
			'FAExpenseAdvance($expand=HRStaff($select=StaffNo,StaffName),PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName)))',
		],
	},

	FAExpenseTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseTypeView',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'FAExpenseType',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)',
			'GlAccts($expand=PlAcct($select=AcctNo,AcctTitle),PrepayAcct($select=AcctNo,AcctTitle),PartnerInvoiceAcct($select=AcctNo,AcctTitle))'
		],
	},

	FAIncomeDirectView: {
		...fluxViewDefaults,
		actionNamespace: 'FAIncomeDirectView',
		entityName: 'FAIncomeDirect',
		fetchUriTemplate: 'FAIncomeDirect',
		selectFields: [],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			// 'Project($select=ProjectCode,ProjectName)',
			// 'FACenter($select=CenterCode,CenterName)',
		],
	},

	FAIncomeTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAIncomeTypeView',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'FAIncomeType',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)',
			'GlAccts($expand=PlAcct($select=AcctNo,AcctTitle),PrepayAcct($select=AcctNo,AcctTitle),PartnerInvoiceAcct($select=AcctNo,AcctTitle))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
