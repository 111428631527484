import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { metaUserClosure } from 'app-model/enums';

import audit from './audit/_Reducers';

const stLists : IListComponentControlList = {
	UserList: {
		...fluxListDefaults,
		actionNamespace: 'UserList',
		entityName: 'metaUser',
		fetchUriTemplate: 'metaUser',
		exportUriTemplate: 'metaUser/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			enumParamAuthenticationType: EnumAllOptionValue,
			enumParamClosureType: metaUserClosure.IsActive,
		},
	},

	UserTemplateList: {
		...fluxListDefaults,
		actionNamespace: 'UserTemplateList',
		entityName: 'metaUserTemplate',
		fetchUriTemplate: 'metaUserTemplate',
		orderBy: 'Name asc',
		selectFields: ['Id', 'Name', 'Comment', 'AuthenticationType', 'ClosureType', 'ClosureDate'],
		expandProps: [],
		searchFields: ['Name', 'Comment'],
		filterValues: {
			searchText: '',
			authenticationType: EnumAllOptionValue,
		},
	},
	UserTemplatePick: {
		...fluxPickDefaults,
		actionNamespace: 'UserTemplatePick',
		entityName: 'metaUserTemplate',
		fetchUriTemplate: 'metaUserTemplate',
		orderBy: 'Name asc',
		selectFields: ['Id', 'Name', 'Comment', 'AuthenticationType', 'ClosureType', 'ClosureDate'],
		expandProps: [],
		searchFields: ['Name', 'Comment'],
		filterValues: {
			searchText: '',
			authenticationType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	UserTemplateView: {
		...fluxViewDefaults,
		actionNamespace: 'UserTemplateView',
		entityName: 'metaUserTemplate',
		fetchUriTemplate: 'metaUserTemplate',
		exportUriTemplate: 'metaUserTemplate(${entityId})/ExportRoles',
		queryUseRestEndpoint: true,
	},

	UserView: {
		...fluxViewDefaults,
		actionNamespace: 'UserView',
		entityName: 'metaUser',
		fetchUriTemplate: 'metaUser',
		exportUriTemplate: 'metaUser(${entityId})/ExportRoles',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
	audit
});
