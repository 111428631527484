import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRPerfAppraisalList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfAppraisalList',
		entityName: 'HRPerfAppraisal',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetAllPerfAppraisalsForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate;$expand=HRPayrollYear($select=YearName,StartDate,EndDate))',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	HRPerfAppraisalSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfAppraisalSubList',
		entityName: 'HRPerfAppraisal',
		fetchUriTemplate: 'HRPerfAppraisalSession(${subListParentId})/HRPerfAppraisals',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	HRPerfPastAppraisalSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfPastAppraisalSubList',
		entityName: 'HRPerfAppraisal',
		fetchUriTemplate: 'HRPerfAppraisal(${subListParentId})/GetPriorPerfAppraisals()',
		orderBy: 'HRPerfAppraisalSession/PeriodStart desc',
		selectFields: [],
		expandProps: ['HRPerfAppraisalSession'],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	
	HRPerfAppraisalDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPerfAppraisalDocumentSubList',
		fetchUriTemplate: 'HRPerfAppraisal(${subListParentId})/Documents',
		exportUriTemplate: 'HRPerfAppraisal(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPerfAppraisalWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPerfAppraisalWorkflowHistorySubList',
		fetchUriTemplate: 'HRPerfAppraisal(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPerfAppraisal(${subListParentId})/ExportWorkflowHistory',
	},

	HRPerfAppraisalSessionList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfAppraisalSessionList',
		entityName: 'HRPerfAppraisalSession',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/HRPerfAppraisalSessions',
		orderBy: 'PeriodStart desc',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
		},
	},
	HRPerfAppraisalSessionSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfAppraisalSessionSubList',
		entityName: 'HRPerfAppraisalSession',
		fetchUriTemplate: 'hrpayrollyear(${subListParentId})/HRPerfAppraisalSessions',
		orderBy: 'PeriodStart desc',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	HRPerfTargetConfirmList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfTargetConfirmList',
		entityName: 'HRPerfTarget',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetAllPerfTargetsForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRPerfTargetYearList: {
		...fluxListDefaults,
		actionNamespace: 'HRPerfTargetYearList',
		entityName: 'HRPerfTarget',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetAllPerfTargetsForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRPerfTargetDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPerfTargetDocumentSubList',
		fetchUriTemplate: 'HRPerfTarget(${subListParentId})/Documents',
		exportUriTemplate: 'HRPerfTarget(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPerfTargetWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPerfTargetWorkflowHistorySubList',
		fetchUriTemplate: 'HRPerfTarget(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPerfTarget(${subListParentId})/ExportWorkflowHistory',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRPerfAppraisalView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPerfAppraisalView',
		entityName: 'HRPerfAppraisal',
		fetchUriTemplate: 'HRPerfAppraisal',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType)',
			'HRStaff($select=StaffNo,StaffName)',
			'NewGrade($select=Category,Grade,Designation)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRPerfAppraisalAccomps',
			'HRPerfAppraisalKpis',
			'HRPerfAppraisalReviewers($expand=Reviewer($select=StaffNo,StaffName))',
		],
	},
	
	HRPerfAppraisalAccompView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPerfAppraisalAccompView',
		entityName: 'HRPerfAppraisalAccomp',
		fetchUriTemplate: 'HRPerfAppraisalAccomp',
		selectFields: [],
		expandProps: [
            'HRPerfAppraisal($expand=HRStaff($select=StaffNo,StaffName),HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType))',
			'AccompReviewDTOs',
        ],
	},
	
	HRPerfAppraisalKpiView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPerfAppraisalKpiView',
		entityName: 'HRPerfAppraisalKpi',
		fetchUriTemplate: 'HRPerfAppraisalKpi',
		selectFields: [],
		expandProps: [
            'HRPerfAppraisal($expand=HRStaff($select=StaffNo,StaffName),HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType))',
			'KpiScoreDTOs',
        ],
	},

	HRPerfAppraisalSessionView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPerfAppraisalSessionView',
		entityName: 'HRPerfAppraisalSession',
		fetchUriTemplate: 'HRPerfAppraisalSession',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
		],
	},
	
	HRPerfTargetView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPerfTargetView',
		entityName: 'HRPerfTarget',
		fetchUriTemplate: 'HRPerfTarget',
		selectFields: [],
		expandProps: [
            'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRPerfTargetKpis',
			'HRPerfTargetReviewers($expand=Reviewer($select=StaffNo,StaffName))',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
