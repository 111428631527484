import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRGradePick: {
		...fluxListDefaults,
		actionNamespace: 'HRGradePick',
		entityName: 'HRGrade',
		fetchUriTemplate: 'HRGrade',
		orderBy: 'HierarchyId desc, Grade desc',
		selectFields: [],
		expandProps: ['HRHeirarchy'],
		searchFields: ['Grade', 'Designation', 'Description'],
		filterValues: {
			searchText: '',
		},
	},
	HRGradeSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRGradeSubList',
		entityName: 'HRGrade',
		fetchUriTemplate: 'HRHeirarchy(${subListParentId})/HRGrades',
		exportUriTemplate: 'HRHeirarchy(${subListParentId})/ExportHRGrades',
		orderBy: 'Grade asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	HRHeirarchyList: {
		...fluxListDefaults,
		actionNamespace: 'HRHeirarchyList',
		entityName: 'HRHeirarchy',
		fetchUriTemplate: 'HRHeirarchy',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
	HRHeirarchyPick: {
		...fluxListDefaults,
		actionNamespace: 'HRHeirarchyPick',
		entityName: 'HRHeirarchy',
		fetchUriTemplate: 'HRHeirarchy',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

    HROrganogramList: {
		...fluxListDefaults,
		actionNamespace: 'HROrganogramList',
		entityName: 'HROrganogram',
		fetchUriTemplate: 'orgcompany(${companyId})/GetHROrganogramsForCompany()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			closureType: PartnerState.Active,
		},
	},
	HROrganogramPick: {
		...fluxPickDefaults,
		actionNamespace: 'HROrganogramPick',
		entityName: 'HROrganogram',
		fetchUriTemplate: 'orgcompany(${companyId})/GetHROrganogramsForCompany()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Description'],
		filterValues: {
			searchText: '',
		},
	},

    HROrgDisciplineList: {
		...fluxListDefaults,
		actionNamespace: 'HROrgDisciplineList',
		entityName: 'HROrgDiscipline',
		fetchUriTemplate: 'HROrgDiscipline',
		orderBy: 'Name asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Code', 'Name', 'Description'],
		filterValues: {
			searchText: '',
			elementClass: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	HROrgDisciplinePick: {
		...fluxPickDefaults,
		actionNamespace: 'HROrgDisciplinePick',
		entityName: 'HROrgDiscipline',
		fetchUriTemplate: 'HROrgDiscipline',
		orderBy: 'Name asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Code', 'Name', 'Description'],
		filterValues: {
			searchText: '',
		},
    },

	StaffList: {
		...fluxListDefaults,
		actionNamespace: 'StaffList',
		entityName: 'Staff',
		fetchUriTemplate: 'Staff',
		orderBy: 'PartnerLabel asc',
		selectFields: ['Id', 'OrgCompanyId', 'PartnerSubClassId', 'PartnerNo', 'PartnerLabel', 'ClosureType'],
		expandProps: ['MasterRecord','OrgCompany($select=CoShortCode,CoName)', 'PartnerSubClass($select=SubClassCode)'],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	StaffPick: {
		...fluxPickDefaults,
		actionNamespace: 'StaffPick',
		entityName: 'Staff',
		fetchUriTemplate: 'Staff',
		orderBy: 'PartnerLabel asc',
		selectFields: ['Id', 'OrgCompanyId', 'PartnerSubClassId', 'PartnerNo', 'PartnerLabel', 'ClosureType'],
		expandProps: ['MasterRecord','OrgCompany($select=CoShortCode,CoName)', 'PartnerSubClass($select=SubClassCode)'],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

    StaffSubClassList: {
		...fluxListDefaults,
		actionNamespace: 'StaffSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	StaffSubClassPick: {
		...fluxPickDefaults,
		actionNamespace: 'StaffSubClassPick',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	HRHeirarchyView: {
		...fluxViewDefaults,
		actionNamespace: 'HRHeirarchyView',
		entityName: 'HRHeirarchy',
		fetchUriTemplate: 'HRHeirarchy',
		queryUseRestEndpoint: true,
	},

	StaffView: {
		...fluxViewDefaults,
		actionNamespace: 'StaffView',
		entityName: 'Staff',
		fetchUriTemplate: 'Staff',
		selectFields: [],
		expandProps: ['MasterRecord($expand=PrimaryAddress)'],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}


export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
