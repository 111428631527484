import { combineReducers } from 'redux';

import docmgt from './docmgt/_Reducers';
import notifications from './notifications/_Reducers';
import jobs from './jobs/_Reducers';
import security from './security/_Reducers';

export default combineReducers({
	docmgt,
	notifications,
	jobs,
	security,
});
