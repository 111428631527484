import { combineReducers } from 'redux';

import setup from './setup/_Reducers';
import discountloans from './discountloans/_Reducers';
import termloans from './termloans/_Reducers';

export default combineReducers({
	setup,
	discountloans,
	termloans,
});
