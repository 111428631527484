import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { oneYearAgo, tenYearsAgo, today } from 'app-model/ModelConstants';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxPxWorkflowHistorySubListDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyCashDisburseList: {
		...fluxListDefaults,
		actionNamespace: 'MyCashDisburseList',
		entityName: 'FACashDisburse',
		fetchUriTemplate: 'orgcompany(${companyId})/GetMyCashDisburseForCo(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'DisburseDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'DisburseDate', 'ItemDesc', 'Amount', 'Currency', 'CurrencyRate', 'Receiver', 'VoucherNo', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
		],
		searchFields: ['ItemDesc', 'VoucherNo', 'CashAcct/AcctNo', 'CashAcct/AcctTitle'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyCashDisburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyCashDisburseDocumentSubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/Documents',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/Documents(${entityId})/Export',
	},

	MyCashDisburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyCashDisburseWorkflowHistorySubList',
		fetchUriTemplate: 'FACashDisburse(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FACashDisburse(${subListParentId})/ExportWorkflowHistory',
	},

	MyExpenseAdvanceList: {
		...fluxListDefaults,
		actionNamespace: 'MyExpenseAdvanceList',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance/GetMyExpenseAdvances(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	MyExpenseAdvancePick: {
		...fluxPickDefaults,
		actionNamespace: 'MyExpenseAdvancePick',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: `FAExpenseAdvance/GetMyExpenseAdvances(StartDate=${tenYearsAgo},EndDate=${today},Assignment=null,ClosureType='${PxWorkflowStateFilter.ACTIVE}')`,
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
		},
	},

	MyExpenseAdvanceDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyExpenseAdvanceDocumentSubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/Documents(${entityId})/Export',
	},

	MyExpenseAdvanceWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyExpenseAdvanceWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseAdvance(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FAExpenseAdvance(${subListParentId})/ExportWorkflowHistory',
	},

	MyExpenseRetireList: {
		...fluxListDefaults,
		actionNamespace: 'MyExpenseRetireList',
        entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire/GetMyExpenseRetires(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyExpenseRetireDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyExpenseRetireDocumentSubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/Documents(${entityId})/Export',
	},

	MyExpenseRetireWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyExpenseRetireWorkflowHistorySubList',
		fetchUriTemplate: 'FAExpenseRetire(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'FAExpenseRetire(${subListParentId})/ExportWorkflowHistory',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyCashDisburseView: {
		...fluxViewDefaults,
		actionNamespace: 'MyCashDisburseView',
		entityName: 'FACashDisburse',
		fetchUriComposited: true,
		fetchUriTemplate: 'FACashDisburse/GetMyCashDisburseById(Id=${id})',
		selectFields: [],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			// 'Project($select=ProjectCode,ProjectName)',
			// 'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
