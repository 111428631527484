import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerBondAfsHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'CustomerBondAfsHoldingList',
		entityName: 'VALBondAfsHolding',
		fetchUriTemplate: 'Customer/BondAfsHoldings',
		exportUriTemplate: 'Customer/BondAfsHoldings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerBondHtmHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'CustomerBondHtmHoldingList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'Customer/BondHtmHoldings',
		exportUriTemplate: 'Customer/BondHtmHoldings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerMMPlacementList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerMMPlacementList',
		entityName: 'VALMMPlacement',
		fetchUriTemplate: 'Customer/VALMMPlacements',
		exportUriTemplate: 'Customer/VALMMPlacements/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	CustomerStockHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'CustomerStockHoldingList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'Customer/StockHoldings',
		exportUriTemplate: 'Customer/StockHoldings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerTermInvestList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerTermInvestList',
		entityName: 'VALTermInvest',
		fetchUriTemplate: 'Customer/TermInvests',
		exportUriTemplate: 'Customer/TermInvests/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	CustomerTermLoanList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerTermLoanList',
		entityName: 'VALTermLoan',
		fetchUriTemplate: 'Customer/ValTermLoans',
		exportUriTemplate: 'Customer/ValTermLoans/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	CustomerTradeActivityList: {
		...fluxListDefaults,
		delayFetch: true,
		actionNamespace: 'CustomerTradeActivityList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'Customer/StockTradeActivity',
		exportUriTemplate: 'Customer/StockTradeActivity/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
			enumParamSummary: EnumAllOptionValue,
		},
	},

	CustomerTradeSummaryList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerTradeSummaryList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'Customer/StockTradeSummary',
		exportUriTemplate: 'Customer/StockTradeSummary/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
		},
	},

	CustomerTRDiscLoanList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerTRDiscLoanList',
		entityName: 'VALDiscLoan',
		fetchUriTemplate: 'Customer/VALDiscLoans',
		exportUriTemplate: 'Customer/VALDiscLoans/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	CustomerTRTBillList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'CustomerTRTBillList',
		entityName: 'VALTBill',
		fetchUriTemplate: 'Customer/ValTBills',
		exportUriTemplate: 'Customer/ValTBills/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	VALCustActivityList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALCustActivityList',
		entityName: 'VALCustActivity',
		fetchUriTemplate: 'Customer/TransactionSummary',
		exportUriTemplate: 'Customer/TransactionSummary/Export',
		keyField: 'CustomerId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},

	VALCustLastActivityList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALCustLastActivityList',
		entityName: 'VALCustLastActivity',
		fetchUriTemplate: 'Customer/LastActivity',
		exportUriTemplate: 'Customer/LastActivity/Export',
		keyField: 'CustomerId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamClosureType: EnumAllOptionValue,
		},
	},

	VALCustSummaryList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'VALCustSummaryList',
		entityName: 'VALCustSummary',
		fetchUriTemplate: 'Customer/ValuationSummary',
		exportUriTemplate: 'Customer/ValuationSummary/Export',
		keyField: 'CustomerId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamOrigin: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'HRConfigView',
		entityName: 'HRConfig',
		fetchUriTemplate: 'HRConfig',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
