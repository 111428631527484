import { mdiAccountAlertOutline } from './mdiAccountAlertOutline';
import { mdiAccountCashOutline } from './mdiAccountCashOutline';
import { mdiAccountCheckOutline } from './mdiAccountCheckOutline';
import { mdiAccountClockOutline } from './mdiAccountClockOutline';
import { mdiAccountCog } from './mdiAccountCog';
import { mdiAccountCogOutline } from './mdiAccountCogOutline';
import { mdiAccountConvertOutline } from './mdiAccountConvertOutline';
import { mdiAccountDetailsOutline } from './mdiAccountDetailsOutline';
import { mdiAccountDetails } from './mdiAccountDetails';
import { mdiAccountEditOutline } from './mdiAccountEditOutline';
import { mdiAccountGroupOutline } from './mdiAccountGroupOutline';
import { mdiAccountHardHat } from './mdiAccountHardHat';
import { mdiAccountKey } from './mdiAccountKey';
import { mdiAccountKeyOutline } from './mdiAccountKeyOutline';
import { mdiAccountMultiplePlusOutline } from './mdiAccountMultiplePlusOutline';
import { mdiAccountOutline } from './mdiAccountOutline';
import { mdiAccountPlusOutline } from './mdiAccountPlusOutline';
import { mdiAccountQuestionOutline } from './mdiAccountQuestionOutline';
import { mdiAccountSupervisorOutline } from './mdiAccountSupervisorOutline';
import { mdiAccountTie } from './mdiAccountTie';
import { mdiAccountTieOutline } from './mdiAccountTieOutline';
import { mdiAccountVoice } from './mdiAccountVoice';
import { mdiAlertCircleOutline } from './mdiAlertCircleOutline';
import { mdiAlertOutline } from './mdiAlertOutline';
import { mdiAnimation } from './mdiAnimation';
import { mdiArchive } from './mdiArchive';
import { mdiArchiveArrowDownOutline } from './mdiArchiveArrowDownOutline';
import { mdiArchiveSyncOutline } from './mdiArchiveSyncOutline';
import { mdiArrowDecisionOutline } from './mdiArrowDecisionOutline';
import { mdiArrowDown } from './mdiArrowDown';
import { mdiArrowExpandVertical } from './mdiArrowExpandVertical';
import { mdiArrowLeftCircleOutline } from './mdiArrowLeftCircleOutline';
import { mdiArrowUp } from './mdiArrowUp';
import { mdiAtomVariant } from './mdiAtomVariant';
import { mdiAutorenew } from './mdiAutorenew';

import { mdiBadgeAccountHorizontalOutline } from './mdiBadgeAccountHorizontalOutline';
import { mdiBank } from './mdiBank';
import { mdiBankMinus } from './mdiBankMinus';
import { mdiBankOutline } from './mdiBankOutline'
import { mdiBankPlus } from './mdiBankPlus';
import { mdiBankRemove } from './mdiBankRemove';
import { mdiBankTransfer } from './mdiBankTransfer';
import { mdiBankTransferIn } from './mdiBankTransferIn';
import { mdiBankTransferOut } from './mdiBankTransferOut';
import { mdiBellCheckOutline } from './mdiBellCheckOutline';
import { mdiBellOffOutline } from './mdiBellOffOutline';
import { mdiBellRingOutline } from './mdiBellRingOutline';
import { mdiBookCheckOutline } from './mdiBookCheckOutline';
import { mdiBookEditOutline } from './mdiBookEditOutline';
import { mdiBookMinusMultipleOutline } from './mdiBookMinusMultipleOutline';
import { mdiBookOpenOutline } from './mdiBookOpenOutline';
import { mdiBookOpenPageVariant } from './mdiBookOpenPageVariant';
import { mdiBookOpenVariant } from './mdiBookOpenVariant';
import { mdiBookPlus } from './mdiBookPlus';
import { mdiBookPlusMultipleOutline } from './mdiBookPlusMultipleOutline';
import { mdiBookRemoveMultipleOutline } from './mdiBookRemoveMultipleOutline';
import { mdiBookSyncOutline } from './mdiBookSyncOutline';
import { mdiBuffer } from './mdiBuffer';

import { mdiCalculator } from './mdiCalculator';
import { mdiCalendarCheckOutline } from './mdiCalendarCheckOutline';
import { mdiCalendarClock } from './mdiCalendarClock';
import { mdiCalendarEdit } from './mdiCalendarEdit';
import { mdiCalendarMonth } from './mdiCalendarMonth';
import { mdiCalendarRangeOutline } from './mdiCalendarRangeOutline';
import { mdiCalendarSyncOutline } from './mdiCalendarSyncOutline';
import { mdiCallMerge } from './mdiCallMerge';
import { mdiCallSplit } from './mdiCallSplit';
import { mdiCarCog } from './mdiCarCog';
import { mdiCardAccountDetailsOutline } from './mdiCardAccountDetailsOutline';
import { mdiCardAccountDetailsStarOutline } from './mdiCardAccountDetailsStarOutline';
import { mdiCash } from './mdiCash';
import { mdiCashMinus } from './mdiCashMinus';
import { mdiCashMultiple } from './mdiCashMultiple';
import { mdiCashRefund } from './mdiCashRefund';
import { mdiCertificateOutline } from './mdiCertificateOutline';
import { mdiChartAreaspline } from './mdiChartAreaspline';
import { mdiChartBar } from './mdiChartBar';
import { mdiChartBarStacked } from './mdiChartBarStacked';
import { mdiChartBellCurve } from './mdiChartBellCurve';
import { mdiChartBellCurveCumulative } from './mdiChartBellCurveCumulative';
import { mdiChartGantt } from './mdiChartGantt';
import { mdiChartHistogram } from './mdiChartHistogram';
import { mdiChartLine } from './mdiChartLine';
import { mdiChartPpf } from './mdiChartPpf';
import { mdiChartSankey } from './mdiChartSankey';
import { mdiChartTimelineVariant } from './mdiChartTimelineVariant';
import { mdiCheck } from './mdiCheck';
import { mdiCheckUnderlineCircleOutline } from './mdiCheckUnderlineCircleOutline';
import { mdiChevronTripleDown } from './mdiChevronTripleDown';
import { mdiChevronTripleUp } from './mdiChevronTripleUp';
import { mdiClipboardCheckOutline } from './mdiClipboardCheckOutline';
import { mdiClipboardEditOutline } from './mdiClipboardEditOutline';
import { mdiClipboardListOutline } from './mdiClipboardListOutline';
import { mdiClipboardFlow } from './mdiClipboardFlow';
import { mdiClipboardTextOutline } from './mdiClipboardTextOutline';
import { mdiClose } from './mdiClose';
import { mdiCloseBoxOutline } from './mdiCloseBoxOutline';
import { mdiCogs } from './mdiCogs';
import { mdiCogClockwise } from './mdiCogClockwise';
import { mdiContentCopy } from './mdiContentCopy';
import { mdiCreditCardMinusOutline } from './mdiCreditCardMinusOutline';
import { mdiCreditCardOutline } from './mdiCreditCardOutline';
import { mdiCreditCardSettingsOutline } from './mdiCreditCardSettingsOutline';
import { mdiCreditCardSyncOutline } from './mdiCreditCardSyncOutline';
import { mdiCurrencyUsd } from './mdiCurrencyUsd';

import { mdiDatabaseOutline } from './mdiDatabaseOutline';
import { mdiDatabaseSyncOutline } from './mdiDatabaseSyncOutline';
import { mdiDebugStepOver } from './mdiDebugStepOver';
import { mdiDeleteEmptyOutline } from './mdiDeleteEmptyOutline';
import { mdiDeleteForeverOutline } from './mdiDeleteForeverOutline';
import { mdiDeleteSweepOutline } from './mdiDeleteSweepOutline';
import { mdiDesktopMacDashboard } from './mdiDesktopMacDashboard';
import { mdiDomain } from './mdiDomain';
import { mdiDownloadOffOutline } from './mdiDownloadOffOutline';
import { mdiExport } from './mdiExport';
import { mdiEraser } from './mdiEraser';
import { mdiEqualizerOutline } from './mdiEqualizerOutline';
import { mdiEye } from './mdiEye';
import { mdiEyeOutline } from './mdiEyeOutline';

import { mdiFamilyTree } from './mdiFamilyTree';
import { mdiFileAlertOutline } from './mdiFileAlertOutline';
import { mdiFileCabinet } from './mdiFileCabinet';
import { mdiFileCertificateOutline } from './mdiFileCertificateOutline';
import { mdiFileChartOutline } from './mdiFileChartOutline1';
import { mdiFileCheckOutline } from './mdiFileCheckOutline';
import { mdiFileCogOutline } from './mdiFileCogOutline';
import { mdiFileDocumentEditOutline } from './mdiFileDocumentEditOutline';
import { mdiFileDocumentMultipleOutline } from './mdiFileDocumentMultipleOutline';
import { mdiFileDocumentOutline } from './mdiFileDocumentOutline';
import { mdiFileEditOutline } from './mdiFileEditOutline';
import { mdiFileEyeOutline } from './mdiFileEyeOutline';
import { mdiFileMoveOutline } from './mdiFileMoveOutline';
import { mdiFileMultipleOutline } from './mdiFileMultipleOutline';
import { mdiFileQuestionOutline } from './mdiFileQuestionOutline1';
import { mdiFileRemoveOutline } from './mdiFileRemoveOutline';
import { mdiFileSendOutline } from './mdiFileSendOutline';
import { mdiFileUploadOutline } from './mdiFileUploadOutline';
import { mdiFinance } from './mdiFinance';
import { mdiFolderEditOutline } from './mdiFolderEditOutline';
import { mdiFormatListNumbered } from './mdiFormatListNumbered';
import { mdiForward } from './mdiForward';
import { mdiFruitCitrusOff } from './mdiFruitCitrusOff';

import { mdiGavel } from './mdiGavel';
import { mdiGiftOutline } from './mdiGiftOutline';

import { mdiHandHeartOutline } from './mdiHandHeartOutline';
import { mdiHandshakeOutline } from './mdiHandshakeOutline';
import { mdiHistory } from './mdiHistory';
import { mdiHomeAssistant } from './mdiHomeAssistant';
import { mdiHomeCityOutline } from './mdiHomeCityOutline';
import { mdiHomeExportOutline } from './mdiHomeExportOutline';
import { mdiHomeGroup } from './mdiHomeGroup';
import { mdiHumanCapacityIncrease } from './mdiHumanCapacityIncrease';
import { mdiHumanHandsdown } from './mdiHumanHandsdown';
import { mdiHumanMaleBoard } from './mdiHumanMaleBoard';
import { mdiHumanMaleFemale } from './mdiHumanMaleFemale';

import { mdiInformationVariant } from './mdiInformationVariant';
import { mdiImport } from './mdiImport';

import { mdiKey } from './mdiKey';
import { mdiKeyboard } from './mdiKeyboard';

import { mdiLan } from './mdiLan';
import { mdiLayers } from './mdiLayers';
import { mdiLockOpenVariantOutline } from './mdiLockOpenVariantOutline';
import { mdiLockOutline } from './mdiLockOutline';

import { mdiMailboxUpOutline } from './mdiMailboxUpOutline';
import { mdiMapMarkerRadiusOutline } from './mdiMapMarkerRadiusOutline';
import { mdiMessageSettingsOutline } from './mdiMessageSettingsOutline';

import { mdiNotebookEditOutline } from './mdiNotebookEditOutline';
import { mdiNotebookMultiple } from './mdiNotebookMultiple';
import { mdiNoteEditOutline } from './mdiNoteEditOutline';
import { mdiNoteTextOutline } from './mdiNoteTextOutline';

import { mdiOrderAlphabeticalAscending } from './mdiOrderAlphabeticalAscending';

import { mdiPackageVariantClosed } from './mdiPackageVariantClosed';
import { mdiPageNextOutline } from './mdiPageNextOutline';
import { mdiPlayOutline } from './mdiPlayOutline';
import { mdiPlayPause } from './mdiPlayPause';
import { mdiPlaylistRemove } from './mdiPlaylistRemove';
import { mdiPlus } from './mdiPlus';
import { mdiPoll } from './mdiPoll';

import { mdiReceipt } from './mdiReceipt';
import { mdiRedo } from './mdiRedo';
import { mdiRedoVariant } from './mdiRedoVariant';
import { mdiRefresh } from './mdiRefresh';
import { mdiRelationManyToMany } from './mdiRelationManyToMany';
import { mdiReplay } from './mdiReplay';
import { mdiRestore } from './mdiRestore';
import { mdiRss } from './mdiRss';

import { mdiSafe } from './mdiSafe';
import { mdiSafeSquareOutline } from './mdiSafeSquareOutline';
import { mdiSetCenter } from './mdiSetCenter';
import { mdiScaleBalance } from './mdiScaleBalance';
import { mdiScriptTextOutline } from './mdiScriptTextOutline';
import { mdiSendOutline } from './mdiSendOutline';
import { mdiShieldKeyOutline } from './mdiShieldKeyOutline';
import { mdiShuffleVariant } from './mdiShuffleVariant';
import { mdiSortBoolAscending } from './mdiSortBoolAscending';
import { mdiStackOverflow } from './mdiStackOverflow';
import { mdiSwapHorizontal } from './mdiSwapHorizontal';
import { mdiSwapVertical } from './mdiSwapVertical';
import { mdiSortVariantRemove } from './mdiSortVariantRemove';
import { mdiSwapVerticalCircleOutline } from './mdiSwapVerticalCircleOutline';

import { mdiTableAccount } from './mdiTableAccount';
import { mdiTableEye } from './mdiTableEye';
import { mdiTableLarge } from './mdiTableLarge';
import { mdiTableLock } from './mdiTableLock';
import { mdiTextAccount } from './mdiTextAccount';
import { mdiTextBoxMultipleOutline } from './mdiTextBoxMultipleOutline';
import { mdiTextBoxOutline } from './mdiTextBoxOutline';
import { mdiTextBoxRemoveOutline } from './mdiTextBoxRemoveOutline';
import { mdiThumbDownOutline } from './mdiThumbDownOutline';
import { mdiThumbUpOutline } from './mdiThumbUpOutline';
import { mdiTools } from './mdiTools';
import { mdiTransfer } from './mdiTransfer';
import { mdiTuneVariant } from './mdiTuneVariant';

import { mdiUndoVariant } from './mdiUndoVariant';

import { mdiWalletOutline } from './mdiWalletOutline';

import { mdiWrenchOutline } from './mdiWrenchOutline';


export const mdiIcons = Object.assign(
	{}, 
	{
		mdiAccountAlertOutline,
		mdiAccountCashOutline,
		mdiAccountCheckOutline,
		mdiAccountCog,
		mdiAccountCogOutline,
		mdiAccountClockOutline,
		mdiAccountConvertOutline,
		mdiAccountDetailsOutline,
		mdiAccountDetails,
		mdiAccountEditOutline,
		mdiAccountGroupOutline,
		mdiAccountHardHat,
		mdiAccountKey,
		mdiAccountKeyOutline,
		mdiAccountMultiplePlusOutline,
		mdiAccountOutline,
		mdiAccountPlusOutline,
		mdiAccountQuestionOutline,
		mdiAccountSupervisorOutline,
		mdiAccountTie,
		mdiAccountTieOutline,
		mdiAccountVoice,
		mdiAlertCircleOutline,
		mdiAlertOutline,
		mdiAnimation,
		mdiArchive,
		mdiArchiveArrowDownOutline,
		mdiArchiveSyncOutline,
		mdiArrowDecisionOutline,
		mdiArrowDown,
		mdiArrowExpandVertical,
		mdiArrowLeftCircleOutline,
		mdiArrowUp,
		mdiAtomVariant,
		mdiAutorenew,

		mdiBadgeAccountHorizontalOutline,
		mdiBank,
		mdiBankMinus,
		mdiBankPlus,
		mdiBankOutline,
		mdiBankRemove,
		mdiBankTransfer,
		mdiBankTransferIn,
		mdiBankTransferOut,
		mdiBellCheckOutline,
		mdiBellOffOutline,
		mdiBellRingOutline,
		mdiBookCheckOutline,
		mdiBookEditOutline,
		mdiBookMinusMultipleOutline,
		mdiBookOpenOutline,
		mdiBookOpenPageVariant,
		mdiBookOpenVariant,
		mdiBookPlus,
		mdiBookPlusMultipleOutline,
		mdiBookRemoveMultipleOutline,
		mdiBookSyncOutline,
		mdiBuffer,

		mdiCalculator,
		mdiCalendarCheckOutline,
		mdiCalendarClock,
		mdiCalendarEdit,
		mdiCalendarMonth,
		mdiCalendarRangeOutline,
		mdiCalendarSyncOutline,
		mdiCallMerge,
		mdiCallSplit,
		mdiCarCog,
		mdiCardAccountDetailsOutline,
		mdiCardAccountDetailsStarOutline,
		mdiCash,
		mdiCashMultiple,
		mdiCashMinus,
		mdiCashRefund,
		mdiCertificateOutline,
		mdiChartAreaspline,
		mdiChartBar,
		mdiChartBarStacked,
		mdiChartBellCurve,
		mdiChartBellCurveCumulative,
		mdiChartGantt,
		mdiChartHistogram,
		mdiChartLine,
		mdiChartPpf,
		mdiChartSankey,
		mdiChartTimelineVariant,
		mdiCheck,
		mdiCheckUnderlineCircleOutline,
		mdiChevronTripleDown,
		mdiChevronTripleUp,
		mdiClipboardCheckOutline,
		mdiClipboardEditOutline,
		mdiClipboardFlow,
		mdiClipboardListOutline,
		mdiClipboardTextOutline,
		mdiClose,
		mdiCloseBoxOutline,
		mdiCogs,
		mdiCogClockwise,
		mdiContentCopy,
		mdiCreditCardMinusOutline,
		mdiCreditCardOutline,
		mdiCreditCardSettingsOutline,
		mdiCreditCardSyncOutline,
		mdiCurrencyUsd,

		mdiDatabaseOutline,
		mdiDatabaseSyncOutline,
		mdiDebugStepOver,
		mdiDeleteEmptyOutline,
		mdiDeleteForeverOutline,
		mdiDeleteSweepOutline,
		mdiDesktopMacDashboard,
		mdiDomain,
		mdiDownloadOffOutline,

		mdiEraser,
		mdiExport,
		mdiEqualizerOutline,
		mdiEye,
		mdiEyeOutline,

		mdiFamilyTree,
		mdiFileAlertOutline,
		mdiFileCabinet,
		mdiFileCertificateOutline,
		mdiFileChartOutline,
		mdiFileCheckOutline,
		mdiFileCogOutline,
		mdiFileDocumentOutline,
		mdiFileDocumentEditOutline,
		mdiFileDocumentMultipleOutline,
		mdiFileEditOutline,
		mdiFileEyeOutline,
		mdiFileMoveOutline,
		mdiFileMultipleOutline,
		mdiFileQuestionOutline,
		mdiFileRemoveOutline,
		mdiFileSendOutline,
		mdiFileUploadOutline,
		mdiFinance,
		mdiFolderEditOutline,
		mdiFormatListNumbered,
		mdiForward,
		mdiFruitCitrusOff,

		mdiGavel,
		mdiGiftOutline,

		mdiHandHeartOutline,
		mdiHandshakeOutline,
		mdiHistory,
		mdiHomeAssistant,
		mdiHomeCityOutline,
		mdiHomeExportOutline,
		mdiHomeGroup,
		mdiHumanCapacityIncrease,
		mdiHumanHandsdown,
		mdiHumanMaleBoard,
		mdiHumanMaleFemale,

		mdiInformationVariant,
		mdiImport,

		mdiKey,
		mdiKeyboard,

		mdiLayers,
		mdiLockOpenVariantOutline,
		mdiLockOutline,
		mdiLan,

		mdiMailboxUpOutline,
		mdiMapMarkerRadiusOutline,

		mdiMessageSettingsOutline,

		mdiNotebookEditOutline,
		mdiNoteEditOutline,
		mdiNotebookMultiple,
		mdiNoteTextOutline,

		mdiOrderAlphabeticalAscending,

		mdiPackageVariantClosed,
		mdiPageNextOutline,
		mdiPlayOutline,
		mdiPlayPause,
		mdiPlaylistRemove,
		mdiPlus,
		mdiPoll,

		mdiRedo,
		mdiRedoVariant,
		mdiReceipt,
		mdiRefresh,
		mdiRelationManyToMany,
		mdiReplay,
		mdiRss,
		mdiRestore,

		mdiSafe,
		mdiSafeSquareOutline,
		mdiSetCenter,
		mdiSendOutline,
		mdiScaleBalance,
		mdiScriptTextOutline,
		mdiShieldKeyOutline,
		mdiShuffleVariant,
		mdiSortBoolAscending,
		mdiStackOverflow,
		mdiSwapHorizontal,
		mdiSwapVertical,
		mdiSortVariantRemove,
		mdiSwapVerticalCircleOutline,

		mdiTableAccount,
		mdiTableEye,
		mdiTableLarge,
		mdiTableLock,
		mdiTextAccount,
		mdiTextBoxMultipleOutline,
		mdiTextBoxOutline,
		mdiTextBoxRemoveOutline,
		mdiThumbDownOutline,
		mdiThumbUpOutline,
		mdiTools,
		mdiTransfer,
		mdiTuneVariant,

		mdiUndoVariant,

		mdiWalletOutline,
		mdiWrenchOutline,
	}
);
