import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { HRClosure } from 'app-model/enums';

const stLists : IListComponentControlList = {
	HRLeaveList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetAllLeavesForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'StartDate desc,HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: ['HRStaff/StaffNo','HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			leaveType: EnumAllOptionValue,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	HRLeaveSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveSubList',
		entityName: 'HRLeave',
		fetchUriTemplate: 'hrleaveyear(${subListParentId})/GetAllLeavesForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'StartDate desc,HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveType: EnumAllOptionValue,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRLeaveDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRLeaveDocumentSubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeave(${subListParentId})/Documents(${entityId})/Export',
	},

	HRLeaveWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRLeaveWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeave(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRLeave(${subListParentId})/ExportWorkflowHistory',
	},

	HRLeaveQuotaList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveQuotaList',
		entityName: 'HRLeaveQuota',
		fetchUriTemplate: 'HRLeaveQuota',
		exportUriTemplate: 'HRLeaveQuota/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			hierarchyId: NullEntityId,
			category: EnumAllOptionValue,
			leaveType: EnumAllOptionValue,
			closureType: HRClosure.Running,
		},
	},

	HRLeaveRotaList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveRotaList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${leaveYearId})/GetAllLeaveRotasForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc,SequenceNumber asc',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			leaveYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	HRLeaveRotaSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveRotaSubList',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'hrleaveyear(${subListParentId})/GetAllLeaveRotasForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc,SequenceNumber asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)'
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	HRLeaveRotaDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRLeaveRotaDocumentSubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/Documents',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/Documents(${entityId})/Export',
	},

	HRLeaveRotaWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRLeaveRotaWorkflowHistorySubList',
		fetchUriTemplate: 'HRLeaveRota(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRLeaveRota(${subListParentId})/ExportWorkflowHistory',
	},

	HRLeaveYearQuotaSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveYearQuotaSubList',
		entityName: 'HRLeaveYearQuota',
		fetchUriTemplate: 'HRLeaveYear(${subListParentId})/LeaveQuotas',
		exportUriTemplate: 'HRLeaveYear(${subListParentId})/LeaveQuotas/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			leaveType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	HRLeaveYearList: {
		...fluxListDefaults,
		actionNamespace: 'HRLeaveYearList',
		entityName: 'HRLeaveYear',
		fetchUriTemplate: 'HRLeaveYear',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
        ],
		searchFields: ['YearName'],
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRLeaveView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveView',
		entityName: 'HRLeave',
		fetchUriTemplate: 'HRLeave',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRLeaveDelegates($expand=HRStaff($select=StaffNo,StaffName))',
			'HRLeaveReviewers($expand=Reviewer($select=StaffNo,StaffName);$orderby=Precedence)',
		],
	},

	HRLeaveRotaView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveRotaView',
		entityName: 'HRLeaveRota',
		fetchUriTemplate: 'HRLeaveRota',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRLeaveRotaDates($orderby=StartDate)',
			'HRLeaveRotaReviewers($expand=Reviewer($select=StaffNo,StaffName);$orderby=Precedence)',
		],
	},

	HRLeaveYearView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveYearView',
		entityName: 'HRLeaveYear',
		fetchUriTemplate: 'HRLeaveYear',
		selectFields: [],
		expandProps: [
			'HRApprover($select=StaffNo,StaffName)',
			'MgtApprover($select=StaffNo,StaffName)',
			'HRLeaveYearQuotas($expand=HRStaff($select=StaffNo,StaffName);$orderby=HRStaff/StaffName)',
			'HRLeaveAnnualExclusions',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
