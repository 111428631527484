import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo, oneYearFuture, tenYearsAgo, threeYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQCertificateDepositList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertificateDepositList',
		entityName: 'EQCertificateDeposit',
		fetchUriTemplate: 'EQCertificateDeposit',
		orderBy: 'DepositDate desc',
		selectFields: [],
		expandProps: ['Customer($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel', 'CustomerName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	EQCertificateDepositDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCertificateDepositDocumentSubList',
		fetchUriTemplate: 'EQCertificateDeposit(${subListParentId})/Documents',
		exportUriTemplate: 'EQCertificateDeposit(${subListParentId})/Documents(${entityId})/Export',
	},

	EQCertificateDuplicateCustomerList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertificateDuplicateCustomerList',
		entityName: 'EQCertificateDuplicateCustomer',
		fetchUriTemplate: 'EQCertificateDuplicateCustomer/GetDuplicateSummary(CustomerId=${customerId},InstrumentId=${instrumentId},SearchText=${searchText})',
		exportUriTemplate: 'EQCertificateDuplicateCustomer/ExportDuplicateSummary',
		orderBy: 'CustomerName asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			customerId: NullEntityId,
			instrumentId: NullEntityId,
		},
	},

	EQCertificateDuplicateRegAcctList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertificateDuplicateRegAcctList',
		entityName: 'EQCertificateDuplicateRegAcct',
		fetchUriTemplate: 'EQCertificateDuplicateRegAcct/GetDuplicateSummary(InstrumentId=${instrumentId},SearchText=${searchText})',
		exportUriTemplate: 'EQCertificateDuplicateRegAcct/ExportDuplicateSummary',
		orderBy: 'CustomerName asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			instrumentId: NullEntityId,
		},
	},

	EQCertificateList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertificateList',
		entityName: 'EQCertificate',
		fetchUriTemplate: 'EQCertificate/GetAllWrkflwCertificates(StartDate=${startDate},EndDate=${endDate},CompanyId=${companyId},SubClassId=${subClassId},InstrId=${instrId},CertType=${certType},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'EQCertificate/ExportAllWrkflwCertificates',
		orderBy: 'EQCertificateDeposit/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'EQCertificateDeposit($select=DepositDate;$expand=Customer($select=PartnerNo,PartnerLabel))', 
			'EQInstrument($select=InstrumentCode,InstrumentName)', 
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'
		],
		searchFields: [
			'EQCertificateDeposit/Customer/PartnerNo', 'EQCertificateDeposit/Customer/PartnerLabel', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName', 
			'EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle', 'CertificateNo', 'NameOnCertificate', 'RegAccount'
		],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			instrId: NullEntityId,
			certType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	EQCertificatePick: {
		...fluxPickDefaults,
		actionNamespace: 'EQCertificatePick',
		entityName: 'EQCertificate',
		fetchUriTemplate: 'EQCertificate',
		orderBy: 'NameOnCertificate asc',
		selectFields: [],
		expandProps: [
			'EQInstrument($select=InstrumentCode,InstrumentName)', 
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'
		],
		searchFields: [
			'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName', 'EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle', 'CertificateNo', 'NameOnCertificate', 'RegAccount'
		],
		filterValues: {
			searchText: '',
		},
	},

	EQCertificateDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCertificateDocumentSubList',
		fetchUriTemplate: 'EQCertificate(${subListParentId})/Documents',
		exportUriTemplate: 'EQCertificate(${subListParentId})/Documents(${entityId})/Export',
	},

	EQCertificateWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'EQCertificateWorkflowHistorySubList',
		fetchUriTemplate: 'EQCertificate(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'EQCertificate(${subListParentId})/ExportWorkflowHistory',
	},

	EQCertRegDispatchList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertRegDispatchList',
		entityName: 'EQCertRegDispatch',
		fetchUriTemplate: 'EQCertRegDispatch',
		printUriTemplate: 'EQCertRegDispatch/PRINT',
		orderBy: 'DispatchDate desc',
		selectFields: [],
		expandProps: ['Registrar','LimitInstrument'],
		searchFields: ['DispatchNo', 'Dispatcher'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	EQCertRegDispatchDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCertRegDispatchDocumentSubList',
		fetchUriTemplate: 'EQCertRegDispatch(${subListParentId})/Documents',
		exportUriTemplate: 'EQCertRegDispatch(${subListParentId})/Documents(${entityId})/Export',
	},

	EQCertRegDispatchItemSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQCertRegDispatchItemSubList',
		entityName: 'EQCertRegDispatchItem',
		fetchUriTemplate: 'EQCertRegDispatch(${subListParentId})/EQCertRegDispatchItems',
		printUriTemplate: 'EQCertRegDispatch/PRINT',
		orderBy: 'EQCertificate/NameOnCertificate asc',
		selectFields: [],
		expandProps: [
			'EQCertificate($expand=EQInstrument($select=InstrumentCode,InstrumentName),EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))',
		],
		searchFields: ['NameOnCertificate', 'EQCertificate/EQAccount/InternalAcctNo', 'EQCertificate/EQAccount/ExchangeAcctNo', 'EQCertificate/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQCertificateDepositView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCertificateDepositView',
		entityName: 'EQCertificateDeposit',
		fetchUriTemplate: 'EQCertificateDeposit',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQCertificates($expand=EQInstrument($select=InstrumentCode,InstrumentName),EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'
		],
	},

	EQCertificateView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCertificateView',
		entityName: 'EQCertificate',
		fetchUriTemplate: 'EQCertificate',
		selectFields: [],
		expandProps: [
			'EQCertificateDeposit',
			'EQInstrument($select=InstrumentCode,InstrumentName)', 
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'
		],
	},

	EQCertRegDispatchView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCertRegDispatchView',
		entityName: 'EQCertRegDispatch',
		fetchUriTemplate: 'EQCertRegDispatch',
		printUriTemplate: 'EQCertRegDispatch/PRINT',
		selectFields: [],
		expandProps: [
			'Registrar','LimitInstrument'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
