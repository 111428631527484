import { combineReducers } from 'redux';

import admin from './admin/_Reducers';
import leave from './leave/_Reducers';
import compensation from './compensation/_Reducers';
import perfmgt from './perfmgt/_Reducers';
import records from './records/_Reducers';

export default combineReducers({
	admin,
	leave,
	compensation,
	perfmgt,
	records,
});
