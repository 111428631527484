import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { SecInstrumentState } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpFundParamList: {
		...fluxListDefaults,
		actionNamespace: 'CmpFundParamList',
		entityName: 'CmpFundParam',
		fetchUriTemplate: 'CmpFundParam/GetPrevailing(AtDate=${atDate},SearchText=${searchText})',
		exportUriTemplate: 'CmpFundParam/ExportPrevailing',
		keyField: 'FundId',
		orderBy: 'FundName asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
		},
	},

	FundInvestProfileList: {
		...fluxListDefaults,
		actionNamespace: 'FundInvestProfileList',
		entityName: 'FundInvestProfile',
		fetchUriTemplate: 'FundInvestProfile',
		orderBy: 'Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Fund($select=InstrumentCode,InstrumentName)',
			'Staff($select=StaffNo,StaffName)',
			'Customer($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Staff/StaffNo', 'Staff/StaffName', 'Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			fundId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	FundInvestProfilePick: {
		...fluxPickDefaults,
		actionNamespace: 'FundInvestProfilePick',
		entityName: 'FundInvestProfile',
		fetchUriTemplate: 'FundInvestProfile',
		orderBy: 'Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	FundInvestProfileLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'FundInvestProfileLocationSubList',
		cmdUriTemplate: 'FundInvestProfile(${subListParentId})/Locations',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/Locations',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/Locations/Export',
	},

	FundInvestProfileRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'FundInvestProfileRelationshipSubList',
		cmdUriTemplate: 'FundInvestProfile(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/RelatedParties',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/RelatedParties/Export',
	},

	FundList: {
		...fluxListDefaults,
		actionNamespace: 'FundList',
		entityName: 'Fund',
		fetchUriTemplate: 'Fund',
		orderBy: 'InstrumentName asc',
		selectFields: [],
		expandProps: ['ResearchSector($select=SectorCode)','InstrumentCompany($select=CoShortCode)','InstrumentCustomer($select=Id,PartnerNo,PartnerLabel)'],
		searchFields: ['ISINCode', 'InstrumentCode', 'InstrumentName'],
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			closureType: SecInstrumentState.Active,
		},
	},
	FundPick: {
		...fluxPickDefaults,
		actionNamespace: 'FundPick',
		entityName: 'Fund',
		fetchUriTemplate: 'Fund',
		orderBy: 'InstrumentName asc',
		selectFields: [],
		expandProps: ['ResearchSector($select=SectorCode)','InstrumentCompany($select=CoShortCode)','InstrumentCustomer($select=Id,PartnerNo,PartnerLabel)'],
		searchFields: ['ISINCode', 'InstrumentCode', 'InstrumentName'],
		filterValues: {
			searchText: '',
		},
	},

	FundParamSubList: {
		...fluxListDefaults,
		actionNamespace: 'FundParamSubList',
		entityName: 'FundParam',
		fetchUriTemplate: 'Fund(${subListParentId})/FundParams',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},
	
	FundVALBondHtmHoldingSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALBondHtmHoldingSubList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'Fund(${subListParentId})/GetFundBondHtmHoldings(AtDate=${atDate})',
		exportUriTemplate: 'Fund(${subListParentId})/ExportFundBondHtmHoldings',
		printUriTemplate: 'Fund(${subListParentId})/PrintFundBondHtmHoldings(AtDate=${atDate})',
		orderBy: 'InstrumentCode asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	FundVALCommPaperSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALCommPaperSubList',
		entityName: 'VALCommPaper',
		fetchUriTemplate: 'Fund(${subListParentId})/VALCommPaper',
		exportUriTemplate: 'Fund(${subListParentId})/VALCommPaper/Export',
		printUriTemplate: 'Fund(${subListParentId})/VALCommPaper/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	FundVALFundLoanSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALFundLoanSubList',
		entityName: 'VALFundLoan',
		fetchUriTemplate: 'Fund(${subListParentId})/GetFundLoans(AtDate=${atDate},TypeId=${typeId},SearchText=${searchText})',
		exportUriTemplate: 'Fund(${subListParentId})/ExportFundLoans',
		printUriTemplate: 'Fund(${subListParentId})/PrintFundLoans(AtDate=${atDate},TypeId=${typeId},SearchText=${searchText})',
		orderBy: 'ExpectedMaturity asc, PartnerLabel asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
		},
	},

	FundVALHoldingAggregateSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALHoldingAggregateSubList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'Fund(${subListParentId})/StockHoldings',
		exportUriTemplate: 'Fund(${subListParentId})/StockHoldings/Export',
		printUriTemplate: 'Fund(${subListParentId})/StockHoldingsPrint',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	FundVALMMPlacementSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALMMPlacementSubList',
		entityName: 'VALMMPlacement',
		fetchUriTemplate: 'Fund(${subListParentId})/VALMMPlacements',
		exportUriTemplate: 'Fund(${subListParentId})/VALMMPlacements/Export',
		printUriTemplate: 'Fund(${subListParentId})/VALMMPlacements/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	FundVALPromNoteSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALPromNoteSubList',
		entityName: 'VALPromNote',
		fetchUriTemplate: 'Fund(${subListParentId})/VALPromNotes',
		exportUriTemplate: 'Fund(${subListParentId})/VALPromNotes/Export',
		printUriTemplate: 'Fund(${subListParentId})/VALPromNotes/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	FundVALSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'FundVALSummarySubList',
		entityName: 'VALFundSummary',
		fetchUriTemplate: 'Fund(${subListParentId})/GetFundValuationHistory()',
		exportUriTemplate: 'Fund(${subListParentId})/ExportFundValuationHistory',
		printUriTemplate: 'Fund(${subListParentId})/PrintFundValuationReport()',
		orderBy: 'ValuationDate desc',
		keyField: 'CustomerId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	FundVALTBillSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'FundVALTBillSubList',
		entityName: 'VALTBill',
		fetchUriTemplate: 'Fund(${subListParentId})/ValTBills',
		exportUriTemplate: 'Fund(${subListParentId})/ValTBills/Export',
		printUriTemplate: 'Fund(${subListParentId})/ValTBills/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},
	

	ProfileVALBondHtmHoldingSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALBondHtmHoldingSubList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/GetProfileBondHtmHoldings(AtDate=${atDate})',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/ExportProfileBondHtmHoldings',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/PrintProfileBondHtmHoldings(AtDate=${atDate})',
		orderBy: 'InstrumentCode asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	ProfileVALCommPaperSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALCommPaperSubList',
		entityName: 'VALCommPaper',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/VALCommPapers',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/VALCommPapers/Export',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/VALCommPapers/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	ProfileVALFundLoanSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALFundLoanSubList',
		entityName: 'VALFundLoan',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/GetProfileLoans(AtDate=${atDate},TypeId=${typeId},SearchText=${searchText})',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/ExportProfileLoans',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/PrintProfileLoans(AtDate=${atDate},TypeId=${typeId},SearchText=${searchText})',
		orderBy: 'ExpectedMaturity asc, PartnerLabel asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			typeId: NullEntityId,
		},
	},

	ProfileVALHoldingAggregateSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALHoldingAggregateSubList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/StockHoldings',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/StockHoldings/Export',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/StockHoldings/Print',
		orderBy: 'InstrumentCode asc',
		keyField: 'InstrumentId',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	ProfileVALMMPlacementSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALMMPlacementSubList',
		entityName: 'VALMMPlacement',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/VALMMPlacements',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/VALMMPlacements/Export',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/VALMMPlacements/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	ProfileVALPromNoteSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALPromNoteSubList',
		entityName: 'VALPromNote',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/VALPromNotes',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/VALPromNotes/Export',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/VALPromNotes/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	ProfileVALSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'ProfileVALSummarySubList',
		entityName: 'VALCustSummary',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/ValuationHistory',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/ValuationHistory/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},

	ProfileVALTBillSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'ProfileVALTBillSubList',
		entityName: 'VALTBill',
		fetchUriTemplate: 'FundInvestProfile(${subListParentId})/ValTBills',
		exportUriTemplate: 'FundInvestProfile(${subListParentId})/ValTBills/Export',
		printUriTemplate: 'FundInvestProfile(${subListParentId})/ValTBills/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FundConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'FundConfigView',
		entityName: 'FundConfig',
		fetchUriTemplate: 'FundConfig',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoOrgType,CoShortCode,CoName)',
			'TradeProfitLossCenter($select=CenterCode,CenterName)',
			'PryIssueWalkinPartnerSubClass($select=SubClassCode)',
			'FundPartnerSubClass($select=SubClassCode)',
			'EmployeeSchemePartnerSubClass($select=SubClassCode)',
			'InvestorSchemePartnerSubClass($select=SubClassCode)',
			'FundConfigCurrencies('
			+ '$expand=CertificateFeeAcct($select=AcctNo,AcctTitle),TransferFeeAcct($select=AcctNo,AcctTitle)'
			+ ',MgtFeeAcct($select=AcctNo,AcctTitle),BrokerageAcct($select=AcctNo,AcctTitle)'
			+ ',CSCSFeeAccrualAcct($select=AcctNo,AcctTitle),NSEFeeAccrualAcct($select=AcctNo,AcctTitle)'
			+ ',SECFeeAccrualAcct($select=AcctNo,AcctTitle)'
			+ ',PryIssueOfferAcctRule($select=AcctRuleName),PryIssueRightAcctRule($select=AcctRuleName))'
		],
	},

	FundInvestProfileView: {
		...fluxViewDefaults,
		actionNamespace: 'FundInvestProfileView',
		entityName: 'FundInvestProfile',
		fetchUriTemplate: 'FundInvestProfile',
		exportUriTemplate: 'FundInvestProfile(${entityId})/ExportProfileValuation', //weird one with the AtDate casing
		printUriTemplate: 'FundInvestProfile(${entityId})/PrintProfileValuation(AtDate=${AtDate})', //weird one with the AtDate casing
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)'
		],
	},

	FundView: {
		...fluxViewDefaults,
		actionNamespace: 'FundView',
		entityName: 'Fund',
		fetchUriTemplate: 'Fund',
		exportUriTemplate: 'Fund(${entityId})/ExportFundValuation', //weird one with the AtDate casing
		printUriTemplate: 'Fund(${entityId})/PrintFundValuation(AtDate=${AtDate})', //weird one with the AtDate casing
		selectFields: [],
		expandProps: [
			'ListExchange($select=UniqueCode,CommonCode,CommonName)',
			'ListBoard($select=BoardCode,BoardName)',
			'ListSector($select=SectorCode,SectorName)',
			'ResearchSector($select=SectorCode,SectorName)',
			'TradeExchange($select=UniqueCode,CommonCode,CommonName)',
			'TradeBoard($select=BoardCode,BoardName)',
			'FundParams',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
