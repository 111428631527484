import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { today } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyDirectReportList: {
		...fluxListDefaults,
		actionNamespace: 'MyDirectReportList',
		entityName: 'HRStaffPosition',
		fetchUriTemplate: 'HRStaffPosition/GetMyDirectReports(AtDate=${atDate})',
		orderBy: 'StartDate desc, HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'OrgCompany($select=CoShortCode,CoName)',
			'OrgLocation($select=LocationCode,Description)',
			'OrgUnit($select=UnitCode,UnitName)',
			'OrgRole($select=Code,Name,Description)',
			'Supervisor($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo', 'HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today)
		},
	},

	MyStaffPositionList: {
		...fluxListDefaults,
		actionNamespace: 'MyStaffPositionList',
		entityName: 'HRStaffPosition',
		fetchUriTemplate: 'HRStaffPosition/GetMyStaffPositions()',
		orderBy: 'StartDate desc, HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'OrgCompany($select=CoShortCode,CoName)',
			'OrgLocation($select=LocationCode,Description)',
			'OrgUnit($select=UnitCode,UnitName)',
			'OrgRole($select=Code,Name,Description)',
			'Supervisor($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo', 'HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRLeaveView: {
		...fluxViewDefaults,
		actionNamespace: 'HRLeaveView',
		entityName: 'HRLeave',
		fetchUriTemplate: 'HRLeave',
		selectFields: [],
		expandProps: [
			'HRLeaveYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRLeaveDelegates($expand=HRStaff($select=StaffNo,StaffName))',
			'HRLeaveReviewers($expand=Reviewer($select=StaffNo,StaffName);$orderby=Precedence)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
