import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, threeYearsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowAssignmentFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerCaseCalloverList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCaseCalloverList',
		entityName: 'PartnerCaseCallover',
		fetchUriTemplate: 'PartnerCaseCallover/GetVendorCalloverForDate',
		exportUriTemplate: 'PartnerCaseCallover/ExportVendorCalloverForDate',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerCaseList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCaseList',
		entityName: 'PartnerCase',
		fetchUriTemplate: 'PartnerCase/GetCustomerWrkflwCases(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerCase/ExportCustomerWrkflwCases',
		orderBy: 'LodgeDate desc',
		selectFields: [],
		expandProps: ['PartnerMaster($select=PartnerNo,PartnerLabel)'],
		searchFields: ['PartnerMaster/PartnerNo', 'PartnerMaster/PartnerLabel', 'Subject', 'Notes'],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: EnumAllOptionValue
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerCaseView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerCaseView',
		entityName: 'PartnerCase',
		fetchUriTemplate: 'PartnerCase',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
