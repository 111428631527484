import { combineReducers } from 'redux';

import admin from 'app-modules/admin/__ModuleReducers';
import capitalmkt from 'app-modules/capitalmkt/__ModuleReducers';
import crm from 'app-modules/crm/__ModuleReducers';
import finance from 'app-modules/finance/__ModuleReducers';
import fundmgt from 'app-modules/fundmgt/__ModuleReducers';
import hcm from 'app-modules/hcm/__ModuleReducers';
import invoicing from 'app-modules/invoicing/__ModuleReducers';
import loanmgt from 'app-modules/loanmgt/__ModuleReducers';
import moneymkt from 'app-modules/moneymkt/__ModuleReducers';
import organisation from 'app-modules/organisation/__ModuleReducers';
import research from 'app-modules/research/__ModuleReducers';
import scm from 'app-modules/scm/__ModuleReducers';
import selfservice from 'app-modules/selfservice/__ModuleReducers';
import system from 'app-modules/system/__ModuleReducers';
import trustservice from 'app-modules/trustservice/__ModuleReducers';
import vrm from 'app-modules/vrm/__ModuleReducers';

import { globalsReducer } from './globalsReducer';
import { sessionReducer } from './sessionReducer';

const rootReducer = combineReducers({
	globals: globalsReducer,
	session: sessionReducer,
	admin,
	capitalmkt,
	crm,
	finance,
	fundmgt,
	hcm,
	invoicing,
	loanmgt,
	moneymkt,
	organisation,
	research,
	scm,
	selfservice,
	system,
	trustservice,
	vrm,
});

export default rootReducer;
