import { FunctionComponent } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import { Page404, Page500 } from 'app-widgets/NonDefault';
import { AppDefaultLayout } from 'app-modules/_home/_Lazy';

export const App: FunctionComponent<any> = () => {
    return (
		<HashRouter>
			<Switch>
				{/* <Route exact path="/login" name="Login Page" component={Login} /> */}
				{/* <Route exact path="/register" name="Register Page" component={Register} /> */}
				<Route exact path="/404" component={Page404} />
				<Route exact path="/500" component={Page500} />
				<Route path="/" component={AppDefaultLayout} />
			</Switch>
		</HashRouter>
    );
}

export default App;
