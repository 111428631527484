import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 9-11 support

import 'polyfill';

import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';

import * as serviceWorker from 'app/serviceWorker';
import store from 'app-store/store';

import { AppMsal } from 'app/indexApp';
import { msalConfig, msalInstance } from './adalConfig';

import 'index.css';

const rootElement = document.getElementById("root");

if (rootElement) {
	const root = createRoot(rootElement);

	root.render(
		// <StrictMode>
			<Provider store={store}>
				<MsalProvider instance={msalInstance}>
					<AppMsal />
				</MsalProvider>
			</Provider>
		// </StrictMode>
	);
} else {
	throw new Error("Could not find root element to mount to!");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
