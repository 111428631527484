import { combineReducers } from 'redux';

import leave from './leave/_Reducers';
import payroll from './payroll/_Reducers';
import perfmgt from './perfmgt/_Reducers';
import records from './records/_Reducers';
import confirm from './confirm/_Reducers';
import workforcemgt from './workforcemgt/_Reducers';
import setup from './setup/_Reducers';

export default combineReducers({
	leave,
	payroll,
	perfmgt,
	records,
	confirm,
	workforcemgt,
	setup,
});
