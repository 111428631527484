import { combineReducers } from 'redux';

import itemmgt from './itemmgt/_Reducers';
import purchasing from './purchasing/_Reducers';
import sales from './sales/_Reducers';
import inventory from './inventory/_Reducers';
import activity from './activity/_Reducers';
import setup from './setup/_Reducers';

export default combineReducers({
	itemmgt,
	inventory,
	purchasing,
	sales,
	activity,
	setup,
});
