import { combineReducers } from 'redux';

import assetmgt from './assetmgt/_Reducers';
import cashmgt from './cashmgt/_Reducers';
import expenseincome from './expenseincome/_Reducers';
import directentry from './directentry/_Reducers';
import ledger from './ledger/_Reducers';
import partnermgt from './partnermgt/_Reducers';
import partnertxn from './partnertxn/_Reducers';
import statements from './statements/_Reducers';
import reports from './reports/_Reducers';
import setup from './setup/_Reducers';

export default combineReducers({
	assetmgt,
	cashmgt,
	expenseincome,
    directentry,
	ledger,
	partnermgt,
	partnertxn,
	statements,
	reports,
	setup
});
