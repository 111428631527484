import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { HRClosure } from 'app-model/enums';
import { PartnerGlAcctTypes, TermInstrumentClosure, } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	HRLoanList: {
		...fluxListDefaults,
		actionNamespace: 'HRLoanList',
		entityName: 'TermLoan',
		fetchUriTemplate: 'TermLoan',
		orderBy: 'StartDate desc',
		selectFields: ['Id', 'InstrumentTypeId', 'StartDate', 'Tenure', 'ExpectedMaturity', 'FaceValue', 'InterestOnBalance', 'DelayedDrawDown', 'AutoRepay', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)', 'LoanType($select=TypeCode,TypeDesc,InterestRateDays)'],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'LoanType/TypeCode', 'LoanType/TypeDesc'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: TermInstrumentClosure.Running,
		},
	},
	HRLoanPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRLoanPick',
		entityName: 'TermLoan',
		fetchUriTemplate: 'TermLoan',
		orderBy: 'StartDate desc',
		selectFields: ['Id', 'InstrumentTypeId', 'StartDate', 'Tenure', 'ExpectedMaturity', 'FaceValue', 'InterestOnBalance', 'DelayedDrawDown', 'AutoRepay', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)', 'LoanType($select=TypeCode,TypeDesc,InterestRateDays)'],
		searchFields: ['Partner/PartnerNo', 'Partner/PartnerLabel', 'LoanType/TypeCode', 'LoanType/TypeDesc'],
		filterValues: {
			searchText: '',
		},
	},
	
	HRGradePackageList: {
		...fluxListDefaults,
		actionNamespace: 'HRGradePackageList',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'HRGradePackage',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			hierarchyId: null,
			atDate: toIsoDateString(today),
			category: EnumAllOptionValue,
			closureType: HRClosure.Running,
		},
	},
	HRGradePackagePick: {
		...fluxPickDefaults,
		actionNamespace: 'HRGradePackagePick',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'HRGradePackage',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			//atDate: toIsoDateString(today),
		},
	},

	HRPayItemList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayItemList',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		orderBy: 'ElementClass asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TypeCode', 'TypeName', 'Description'],
		filterValues: {
			searchText: '',
			elementClass: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	HRPayItemPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRPayItemPick',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		orderBy: 'ElementClass asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TypeCode', 'TypeName', 'Description'],
		filterValues: {
			searchText: '',
		},
	},

	HRPayrollList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollList',
		entityName: 'HRPayroll',
		fetchUriTemplate: 'HRPayroll',
		exportUriTemplate: 'HRPayroll/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			//closureType: EnumAllOptionValue,
		},
	},
	HRPayrollSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollSubList',
		entityName: 'HRPayroll',
		fetchUriTemplate: 'hrpayrollyear(${subListParentId})/PayrollRun',
		exportUriTemplate: 'hrpayrollyear(${subListParentId})/PayrollRun/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			//closureType: EnumAllOptionValue,
		},
	},

	HRPayrollDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPayrollDocumentSubList',
		fetchUriTemplate: 'HRPayroll(${subListParentId})/Documents',
		exportUriTemplate: 'HRPayroll(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPayrollWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPayrollWorkflowHistorySubList',
		fetchUriTemplate: 'HRPayroll(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPayroll(${subListParentId})/ExportWorkflowHistory',
	},

	HRPayrollReimburseList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollReimburseList',
		entityName: 'HRPayrollReimburse',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetHRPayrollReimbursesForYear()',
		orderBy: 'RequestDate desc',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRPayItem($select=TypeCode,TypeName)',
		],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName', 'Description'],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			payItemId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	HRPayrollReimburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPayrollReimburseDocumentSubList',
		fetchUriTemplate: 'HRPayrollReimburse(${subListParentId})/Documents',
		exportUriTemplate: 'HRPayrollReimburse(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPayrollReimburseWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPayrollReimburseWorkflowHistorySubList',
		fetchUriTemplate: 'HRPayrollReimburse(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPayrollReimburse(${subListParentId})/ExportWorkflowHistory',
	},

	HRPayrollStaffSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollStaffSubList',
		entityName: 'HRPayrollStaff',
		fetchUriTemplate: 'HRPayroll(${subListParentId})/PaySlip',
		exportUriTemplate: 'HRPayroll(${subListParentId})/PaySlip/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRPayrollStaffItemSubList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollStaffItemSubList',
		entityName: 'HRPayrollStaffItem',
		fetchUriTemplate: 'HRPayrollStaff(${subListParentId})/PaySlipItem',
		exportUriTemplate: 'HRPayrollStaff(${subListParentId})/PaySlipItem/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRPayrollYearList: {
		...fluxListDefaults,
		actionNamespace: 'HRPayrollYearList',
		entityName: 'HRPayrollYear',
		fetchUriTemplate: 'HRPayrollYear',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['YearName'],
		filterValues: {
			searchText: '',
			closureType: EnumAllOptionValue,
		},
	},
	HRPayrollYearPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRPayrollYearPick',
		entityName: 'HRPayrollYear',
		fetchUriTemplate: 'HRPayrollYear',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['YearName'],
		filterValues: {
			searchText: '',
		},
	},

	HRPaySchemeEnrolList: {
		...fluxListDefaults,
		actionNamespace: 'HRPaySchemeEnrolList',
		entityName: 'HRPaySchemeEnrol',
		fetchUriTemplate: 'HRPaySchemeEnrol',
		exportUriTemplate: 'HRPaySchemeEnrol/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			// payrollYearId: NullEntityId,
			payItemId: NullEntityId,
			category: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	
	HRPaySchemeEnrolDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'HRPaySchemeEnrolDocumentSubList',
		fetchUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/Documents',
		exportUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/Documents(${entityId})/Export',
	},

	HRPaySchemeEnrolWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'HRPaySchemeEnrolWorkflowHistorySubList',
		fetchUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/ExportWorkflowHistory',
	},

	HRPaySchemeEnrolScheduleSubList: {
		...fluxPickDefaults,
		actionNamespace: 'HRPaySchemeEnrolScheduleSubList',
		entityName: 'HRPaySchemeEnrolSchedule',
		fetchUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/SchemeSchedule',
		exportUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/SchemeSchedule/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
	
	HRPaySchemeEnrolRunForPayrollStaffSubList: {
		...fluxPickDefaults,
		actionNamespace: 'HRPaySchemeEnrolRunForPayrollStaffSubList',
		entityName: 'HRPaySchemeEnrolRun',
		fetchUriTemplate: 'HRPayrollStaff(${subListParentId})/SchemeRun',
		exportUriTemplate: 'HRPayrollStaff(${subListParentId})/SchemeRun/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
	
	HRPaySchemeEnrolRunForSchemeSubList: {
		...fluxPickDefaults,
		actionNamespace: 'HRPaySchemeEnrolRunForSchemeSubList',
		entityName: 'HRPaySchemeEnrolRun',
		fetchUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/SchemeRun',
		exportUriTemplate: 'HRPaySchemeEnrol(${subListParentId})/SchemeRun/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	HRRewardList: {
		...fluxListDefaults,
		actionNamespace: 'HRRewardList',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		orderBy: 'RewardType asc, RewardCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['RewardCode', 'RewardDescription', 'RewardNature'],
		filterValues: {
			searchText: '',
			rewardType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	HRRewardPick: {
		...fluxPickDefaults,
		actionNamespace: 'HRRewardPick',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		orderBy: 'RewardType asc, RewardCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['RewardCode', 'RewardDescription', 'RewardNature'],
		filterValues: {
			searchText: '',
		},
	},

	HRStaffRewardList: {
		...fluxListDefaults,
		actionNamespace: 'HRStaffRewardList',
		entityName: 'HRStaffReward',
		fetchUriTemplate: 'HRStaffReward',
		orderBy: 'RewardDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['HRStaff/StaffNo', 'HRStaff/StaffName', 'Notes'],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			rewardType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	StaffBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'StaffBalanceList',
		entityName: 'VALPartnerCash',
		fetchUriTemplate: 'orgcompany(${companyId})/StaffCashBalances',
		exportUriTemplate: 'orgcompany(${companyId})/StaffCashBalances/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			atDate: toIsoDateString(today),
			acctType: PartnerGlAcctTypes.Cash,
			currency: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	HRGradePackageView: {
		...fluxViewDefaults,
		actionNamespace: 'HRGradePackageView',
		entityName: 'HRGradePackage',
		fetchUriTemplate: 'HRGradePackage',
		selectFields: [],
		expandProps: [
			'HRGrade($select=Category,Grade,Designation;$expand=HRHeirarchy($select=StartDate,LevelsInGrade))',
			'HRGradePackageItems($expand=PayItem($select=ElementClass,TypeCode,TypeName))',
			'HRGradePackageLoanLimits($expand=LoanType($select=TypeCode,TypeDesc))'
		],
	},

	HRPayItemView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayItemView',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		selectFields: [],
		expandProps: [
			'ItemAccts($select=Id,OrgCompanyId,Currency,ExpenseAcctId,AccrueAcctId;$expand=OrgCompany($select=CoShortCode,CoName),ExpenseAcct($select=AcctNo,AcctTitle),AccrueAcct($select=AcctNo,AcctTitle))', 
			'ItemReliefs'
		],
	},

	HRPayrollView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayrollView',
		entityName: 'HRPayroll',
		fetchUriTemplate: 'HRPayroll',
		selectFields: [],
		expandProps: [
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRPayrollStaffs($expand=BillingCompany($select=CoShortCode,CoName))'
		],
	},

	HRPayrollStaffView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayrollStaffView',
		entityName: 'HRPayrollStaff',
		fetchUriTemplate: 'HRPayrollStaff',
		selectFields: [],
		expandProps: [
			'HRPayroll($select=Year,Month,RunDate)',
			'PayrollItems($expand=HRPayItem($select=ElementClass,TypeCode,TypeName))',
			'SchemeRuns($expand=SchemeEnrol($expand=HRPayItem($select=ElementClass,TypeCode,TypeName)))',
			'BillingCompany($select=CoShortCode,CoName)',
		],
	},

	HRPayrollReimburseView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayrollReimburseView',
		entityName: 'HRPayrollReimburse',
		fetchUriTemplate: 'HRPayrollReimburse',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRPayItem($select=ElementClass,TypeCode,TypeName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	HRPayrollYearView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPayrollYearView',
		entityName: 'HRPayrollYear',
		fetchUriTemplate: 'HRPayrollYear',
		selectFields: [],
		expandProps: [],
	},

	HRPaySchemeEnrolView: {
		...fluxViewDefaults,
		actionNamespace: 'HRPaySchemeEnrolView',
		entityName: 'HRPaySchemeEnrol',
		fetchUriTemplate: 'HRPaySchemeEnrol',
		queryUseRestEndpoint: true,
	},

	HRRewardView: {
		...fluxViewDefaults,
		actionNamespace: 'HRRewardView',
		entityName: 'HRReward',
		fetchUriTemplate: 'HRReward',
		selectFields: [],
		expandProps: [],
	},

	HRStaffRewardView: {
		...fluxViewDefaults,
		actionNamespace: 'HRStaffRewardView',
		entityName: 'HRStaffReward',
		fetchUriTemplate: 'HRStaffReward',
		selectFields: [],
		expandProps: ['HRStaff($select=StaffNo,StaffName)'],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
