import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeMonthsAgo, today } from 'app-model/ModelConstants';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { wrkflwJournalClosureOptions } from 'app-framework/AppDefaultListFilters';

const stLists : IListComponentControlList = {
	FACashBookBalanceList: {
		...fluxListDefaults,
        actionNamespace: 'FACashBookBalanceList',
        entityName: 'FACashBookBalance',
		fetchUriTemplate: 'FACashBookBalance/GetBalanceAtDate',
		exportUriTemplate: 'FACashBookBalance/ExportBalanceAtDate',
		keyField: 'GlAcctId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			atDate: toIsoDateString(today),
			enumParamAcctType: EnumAllOptionValue,
		},
	},

	FACashReimburseList: {
		...fluxListDefaults,
		actionNamespace: 'FACashReimburseList',
        entityName: 'FACashReimburse',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCoCashReimburse(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		orderBy: 'ReimburseDate desc',
		selectFields: ['Id', 'ReimburseDate', 'ReimburseMethod', 'ItemDesc', 'Amount', 'CurrencyRate', 'VoucherNo', 'ChequeNo', 'ClosureType'],
		expandProps: [
            'CashAcct($select=AcctNo,AcctTitle,FxCurrency;$expand=OrgCompany($select=CoShortCode,CoName))'
        ],
		searchFields: ['ItemDesc', 'VoucherNo', 'ChequeNo', 'CashAcct/AcctNo', 'CashAcct/AcctTitle'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FACashReimburseDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FACashReimburseDocumentSubList',
		fetchUriTemplate: 'FACashReimburse(${subListParentId})/Documents',
		exportUriTemplate: 'FACashReimburse(${subListParentId})/Documents(${entityId})/Export',
	},

	FACashSwapList: {
		...fluxListDefaults,
		actionNamespace: 'FACashSwapList',
        entityName: 'FACashSwap',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyCashSwap(StartDate=${startDate},EndDate=${endDate},ClosureType=${enumParamClosureType})',
		orderBy: 'ValueDate desc',
		selectFields: ['Id', 'SourceCurrency', 'InventoryRate', 'TargetCurrency', 'ValueDate', 'SwapRate', 'Amount', 'Description', 'VoucherNo', 'ClosureType'],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'SourceCashAcct($select=AcctNo,AcctTitle)',
			'TargetCashAcct($select=AcctNo,AcctTitle)'
		],
		searchFields: ['Description', 'VoucherNo', 'SourceCashAcct/AcctNo', 'SourceCashAcct/AcctTitle', 'TargetCashAcct/AcctNo', 'TargetCashAcct/AcctTitle'],
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FACashSwapDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FACashSwapDocumentSubList',
		fetchUriTemplate: 'FACashSwap(${subListParentId})/Documents',
		exportUriTemplate: 'FACashSwap(${subListParentId})/Documents(${entityId})/Export',
	},

	FAExpenseAdvanceList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseAdvanceList',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance/GetAllExpenseAdvances(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	FAExpenseAdvancePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseAdvancePick',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
		},
	},

	FAExpenseRetireList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseRetireList',
        entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire/GetAllExpenseRetires(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'RequestDate desc',
		selectFields: [
			'Id', 'IsFinanceEntry', 'RequestDate', 'Description', 'RequestCurrency', 'RequestAmount', 'PlItemId', 
			'PayDate', 'PayCurrency', 'PayCurrencyRate', 'PayAmount', 'PayMethod', 'PayReference', 'PayDifferenceAcctId', 
			'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'
		],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))'
		],
		searchFields: ['Description', 'HRStaff/StaffNo', 'HRStaff/StaffName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAExpenseTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAExpenseTypeList',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyExpenseTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAExpenseTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAExpenseTypePick',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyExpenseTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
		},
	},

	FAIncomeTypeList: {
		...fluxListDefaults,
		actionNamespace: 'FAIncomeTypeList',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyIncomeTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
			companyId: 1,
			active: EnumAllOptionValue,
		},
	},
	FAIncomeTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAIncomeTypePick',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyIncomeTypes()',
		orderBy: 'code asc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['Code', 'Description'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FACashReimburseView: {
		...fluxViewDefaults,
		actionNamespace: 'FACashReimburseView',
		entityName: 'FACashReimburse',
		fetchUriTemplate: 'FACashReimburse',
		selectFields: [],
		expandProps: [
			'CashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'ContraAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
		],
	},

	FACashSwapView: {
		...fluxViewDefaults,
		actionNamespace: 'FACashSwapView',
		entityName: 'FACashSwap',
		fetchUriTemplate: 'FACashSwap',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'SourceCashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId)',
			'SourceExpenseAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId)',
			'TargetCashAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId)',
			'TargetIncomeAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId)',
		],
	},

	FAExpenseAdvanceView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseAdvanceView',
		entityName: 'FAExpenseAdvance',
		fetchUriTemplate: 'FAExpenseAdvance',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'Project($select=ProjectCode,ProjectName)',
			//'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	FAExpenseRetireView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseRetireView',
		entityName: 'FAExpenseRetire',
		fetchUriTemplate: 'FAExpenseRetire',
		selectFields: [],
		expandProps: [
			'HRStaff($select=StaffNo,StaffName)',
			'PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'Project($select=ProjectCode,ProjectName)',
			//'FACenter($select=CenterCode,CenterName)',
			'DisburseApprover($select=StaffNo,StaffName)',
			'PayDifferenceAcct($select=AcctNo,AcctTitle,FxCurrency,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',

			//'FAExpenseAdvance($select=PayDate,PayCurrency,PayAmount,Description;$expand=HRStaff($select=StaffNo,StaffName),PlItem($select=Code,Description))',
			'FAExpenseAdvance($expand=HRStaff($select=StaffNo,StaffName),PlItem($select=Code,Description,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName)))',
		],
	},

	FAExpenseTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAExpenseTypeView',
		entityName: 'FAExpenseType',
		fetchUriTemplate: 'FAExpenseType',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)',
			'GlAccts($expand=PlAcct($select=AcctNo,AcctTitle),PrepayAcct($select=AcctNo,AcctTitle),PartnerInvoiceAcct($select=AcctNo,AcctTitle))'
		],
	},

	FAIncomeTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'FAIncomeTypeView',
		entityName: 'FAIncomeType',
		fetchUriTemplate: 'FAIncomeType',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)',
			'GlAccts($expand=PlAcct($select=AcctNo,AcctTitle),PrepayAcct($select=AcctNo,AcctTitle),PartnerInvoiceAcct($select=AcctNo,AcctTitle))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
