import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { ScheduleTranClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';
import { EnumAllOptionValue, today, threeMonthsAgo, threeYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FAJrnlGlEntrySubList: {
		...fluxListDefaults,
		actionNamespace: 'FAJrnlGlEntrySubList',
		entityName: 'FAJrnlGlEntry',
		fetchUriTemplate: 'FAJrnlGlPage(${subListParentId})/Entries',
		exportUriTemplate: 'FAJrnlGlPage(${subListParentId})/Entries/Export',
		cmdUriTemplate: 'FAJrnlGlPage(${subListParentId})/Entries',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	FAJrnlGlPageList: {
		...fluxListDefaults,
		actionNamespace: 'FAJrnlGlPageList',
		entityName: 'FAJrnlGlPage',
		fetchUriTemplate: 'orgcompany(${companyId})/UserJrnlGlPages',
		exportUriTemplate: 'orgcompany(${companyId})/UserJrnlGlPages/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			currency: EnumAllOptionValue,
			transactionMethod: EnumAllOptionValue,
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAJrnlItemPageList: {
		...fluxListDefaults,
		actionNamespace: 'FAJrnlItemPageList',
		entityName: 'FAJrnlItemPage',
		fetchUriTemplate: 'orgcompany(${companyId})/UserJrnlItemPages',
		exportUriTemplate: 'orgcompany(${companyId})/UserJrnlItemPages/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FAJrnlStatPageList: {
		...fluxListDefaults,
		actionNamespace: 'FAJrnlStatPageList',
		entityName: 'FAJrnlStatPage',
		fetchUriTemplate: 'orgcompany(${companyId})/UserJrnlStatPages',
		exportUriTemplate: 'orgcompany(${companyId})/UserJrnlStatPages/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	FARecurJrnlBatchList: {
		...fluxListDefaults,
		actionNamespace: 'FARecurJrnlBatchList',
		entityName: 'FARecurJrnlBatch',
		fetchUriTemplate: 'FARecurJrnlBatch',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Description'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: ScheduleTranClosure.Pending,
		},
	},

	FARecurJrnlBatchDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FARecurJrnlBatchDocumentSubList',
		fetchUriTemplate: 'FARecurJrnlBatch(${subListParentId})/Documents',
		exportUriTemplate: 'FARecurJrnlBatch(${subListParentId})/Documents(${entityId})/Export',
	},

	FARecurJrnlHistorySubList: {
		...fluxListDefaults,
		actionNamespace: 'FARecurJrnlHistorySubList',
		entityName: 'FARecurJrnlHistory',
		fetchUriTemplate: 'FARecurJrnlBatch(${subListParentId})/FARecurJrnlHistories',
		orderBy: 'RunDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FAJrnlGlPageView: {
		...fluxViewDefaults,
		actionNamespace: 'FAJrnlGlPageView',
		entityName: 'FAJrnlGlPage',
		fetchUriTemplate: 'FAJrnlGlPage',
		queryUseRestEndpoint: true,
	},

	FAJrnlStatPageView: {
		...fluxViewDefaults,
		actionNamespace: 'FAJrnlStatPageView',
		entityName: 'FAJrnlStatPage',
		fetchUriTemplate: 'FAJrnlStatPage',
		queryUseRestEndpoint: true,
	},

	FARecurJrnlBatchView: {
		...fluxViewDefaults,
		actionNamespace: 'FARecurJrnlBatchView',
		entityName: 'FARecurJrnlBatch',
		fetchUriTemplate: 'FARecurJrnlBatch',
		selectFields: [],
		expandProps: [
			'FARecurJrnlGlPages($expand=OrgCompany($select=CoShortCode,CoName),FxBalanceAcct($select=AcctNo,AcctTitle))', 
			'FARecurJrnlStatPages($expand=OrgCompany($select=CoShortCode,CoName))',
		],
	},

	FARecurJrnlGlPageView: {
		...fluxViewDefaults,
		actionNamespace: 'FARecurJrnlGlPageView',
		entityName: 'FARecurJrnlGlPage',
		fetchUriTemplate: 'FARecurJrnlGlPage',
		selectFields: [],
		expandProps: [
			'FARecurJrnlBatch', 
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)', 
			'FxBalanceAcct($select=AcctNo,AcctTitle)',
			'FARecurJrnlGlEntries($expand=FAGlAcct($select=AcctNo,AcctTitle),FACenter($select=CenterCode,CenterName),OrgLocation($select=LocationCode,Description))'
		],
	},

	FARecurJrnlStatPageView: {
		...fluxViewDefaults,
		actionNamespace: 'FARecurJrnlStatPageView',
		entityName: 'FARecurJrnlStatPage',
		fetchUriTemplate: 'FARecurJrnlStatPage',
		selectFields: [],
		expandProps: [
			'FARecurJrnlBatch', 
			'OrgCompany($select=CoShortCode,CoName,CoOrgType)', 
			'FARecurJrnlStatEntries($expand=FAStatAcct($select=Code,Title),FACenter($select=CenterCode,CenterName),OrgLocation($select=LocationCode,Description))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
