import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { PartnerGlAcctTypes, PartnerState, CurrencyCodes } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, oneYearAgo } from 'app-model/ModelConstants';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPartnerBankAcctSubListDefaults, fluxPartnerGlAcctSubListDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerBalanceList',
		entityName: 'VALPartnerCash',
		fetchUriTemplate: 'orgcompany(${companyId})/CustomerCashBalances',
		exportUriTemplate: 'orgcompany(${companyId})/CustomerCashBalances/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
			enumParamAcctType: PartnerGlAcctTypes.Cash,
			enumParamCurrency: CurrencyCodes.NGN,
		},
	},

	CustomerCreditRuleCalloverList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCreditRuleCalloverList',
		entityName: 'PartnerCreditRuleCallover',
		fetchUriTemplate: 'PartnerCreditRuleCallover/GetCustomerRuleCalloverForRange',
		exportUriTemplate: 'PartnerCreditRuleCallover/ExportCustomerRuleCalloverForRange',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			startDate: toIsoDateString(today),
			endDate: toIsoDateString(today),
		},
	},

	CustomerCreditRuleList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerCreditRuleList',
		entityName: 'CmpPartnerCreditRule',
		fetchUriTemplate: 'CmpPartnerCreditRule/GetCustomerRuleAtDate',
		exportUriTemplate: 'CmpPartnerCreditRule/ExportCustomerRuleAtDate',
		orderBy: 'PartnerLabel asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			enumParamCurrency: EnumAllOptionValue,
			companyId: NullEntityId,
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerDebitBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerDebitBalanceList',
		entityName: 'CmpPartnerDebitBalance',
		fetchUriTemplate: 'Customer/GetDebitBalanceAtDate',
		exportUriTemplate: 'Customer/ExportDebitBalanceAtDate',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			enumParamCurrency: EnumAllOptionValue,
			companyId: NullEntityId,
			subClassId: NullEntityId,
			relMgrId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CustomerGlAcctTOList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerGlAcctTOList',
		entityName: 'CustomerGlAcctTO',
		fetchUriTemplate: 'CustomerGlAcctTO/GetAllAccts',
		exportUriTemplate: 'CustomerGlAcctTO/ExportAllAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamAcctType: PartnerGlAcctTypes.Cash,
			enumParamCurrency: CurrencyCodes.NGN,
		},
	},

	CustomerList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerList',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		exportUriTemplate: 'Customer/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			startDate: '1900-01-01',
			endDate: '2099-01-01',
			typeCode: EnumAllOptionValue,
			partnerMode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	CustomerPick: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerPick',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		exportUriTemplate: 'Customer/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	CustomerBankAcctSubList: {
		...fluxPartnerBankAcctSubListDefaults,
		actionNamespace: 'CustomerBankAcctSubList',
		cmdUriTemplate: 'Customer(${subListParentId})/BankAccts',
		fetchUriTemplate: 'Customer(${subListParentId})/BankAccts',
		exportUriTemplate: 'Customer(${subListParentId})/BankAccts/Export',
	},

	CustomerBeneficiarySubList: {
		...fluxPickDefaults,
		actionNamespace: 'CustomerBeneficiarySubList',
		fetchUriTemplate: 'Customer(${partnerId})/GetCustomerBeneficiaries',
		exportUriTemplate: 'Customer(${partnerId})/ExportCustomerBeneficiaries',
		queryUseRestEndpoint: true,
	},

	CustomerDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'CustomerDocumentSubList',
		fetchUriTemplate: 'Customer(${subListParentId})/Documents',
		exportUriTemplate: 'Customer(${subListParentId})/Documents(${entityId})/Export',
	},

	CustomerGlAcctSubList: {
		...fluxPartnerGlAcctSubListDefaults,
		actionNamespace: 'CustomerGlAcctSubList',
		fetchUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
		exportUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
	},

	CustomerLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'CustomerLocationSubList',
		cmdUriTemplate: 'Customer(${subListParentId})/Locations',
		fetchUriTemplate: 'Customer(${subListParentId})/Locations',
		exportUriTemplate: 'Customer(${subListParentId})/Locations/Export',
	},

	CustomerRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'CustomerRelationshipSubList',
		cmdUriTemplate: 'Customer(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'Customer(${subListParentId})/RelatedParties',
		exportUriTemplate: 'Customer(${subListParentId})/RelatedParties/Export',
	},

	EQSalesAgentList: {
		...fluxListDefaults,
		actionNamespace: 'EQSalesAgentList',
		entityName: 'EQSalesAgent',
		fetchUriTemplate: 'EQSalesAgent',
		exportUriTemplate: 'EQSalesAgent/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	EQSalesAgentPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQSalesAgentPick',
		entityName: 'EQSalesAgent',
		fetchUriTemplate: 'EQSalesAgent',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQSalesAgentBankAcctSubList: {
		...fluxPartnerBankAcctSubListDefaults,
		actionNamespace: 'EQSalesAgentBankAcctSubList',
		cmdUriTemplate: 'FAVendor(${subListParentId})/BankAccts',
		fetchUriTemplate: 'FAVendor(${subListParentId})/BankAccts',
		exportUriTemplate: 'FAVendor(${subListParentId})/BankAccts/Export',
	},

	EQSalesAgentDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQSalesAgentDocumentSubList',
		fetchUriTemplate: 'EQSalesAgent(${subListParentId})/Documents',
		exportUriTemplate: 'EQSalesAgent(${subListParentId})/Documents(${entityId})/Export',
	},

	EQSalesAgentGlAcctSubList: {
		...fluxPartnerGlAcctSubListDefaults,
		actionNamespace: 'EQSalesAgentGlAcctSubList',
		fetchUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
		exportUriTemplate: 'Partner(${subListParentId})/PartnerGlAccts',
	},

	EQSalesAgentLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'EQSalesAgentLocationSubList',
		cmdUriTemplate: 'EQSalesAgent(${subListParentId})/Locations',
		fetchUriTemplate: 'EQSalesAgent(${subListParentId})/Locations',
		exportUriTemplate: 'EQSalesAgent(${subListParentId})/Locations/Export',
	},

	EQSalesAgentRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'EQSalesAgentRelationshipSubList',
		cmdUriTemplate: 'EQSalesAgent(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'EQSalesAgent(${subListParentId})/RelatedParties',
		exportUriTemplate: 'EQSalesAgent(${subListParentId})/RelatedParties/Export',
	},

	FAVendorList: {
		...fluxListDefaults,
		actionNamespace: 'FAVendorList',
		entityName: 'FAVendor',
		fetchUriTemplate: 'FAVendor',
		exportUriTemplate: 'FAVendor/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	FAVendorPick: {
		...fluxPickDefaults,
		actionNamespace: 'FAVendorPick',
		entityName: 'FAVendor',
		fetchUriTemplate: 'FAVendor',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAVendorBankAcctSubList: {
		...fluxPartnerBankAcctSubListDefaults,
		actionNamespace: 'FAVendorBankAcctSubList',
		cmdUriTemplate: 'FAVendor(${subListParentId})/BankAccts',
		fetchUriTemplate: 'FAVendor(${subListParentId})/BankAccts',
		exportUriTemplate: 'FAVendor(${subListParentId})/BankAccts/Export',
	},

	FAVendorDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'FAVendorDocumentSubList',
		fetchUriTemplate: 'FAVendor(${subListParentId})/Documents',
		exportUriTemplate: 'FAVendor(${subListParentId})/Documents(${entityId})/Export',
	},

	FAVendorGlAcctSubList: {
		...fluxPartnerGlAcctSubListDefaults,
		actionNamespace: 'FAVendorGlAcctSubList',
		cmdUriTemplate: 'FAVendor(${subListParentId})/PartnerGlAccts',
		fetchUriTemplate: 'FAVendor(${subListParentId})/PartnerGlAccts',
		exportUriTemplate: 'FAVendor(${subListParentId})/PartnerGlAccts',
	},

	FAVendorLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'FAVendorLocationSubList',
		cmdUriTemplate: 'FAVendor(${subListParentId})/Locations',
		fetchUriTemplate: 'FAVendor(${subListParentId})/Locations',
		exportUriTemplate: 'FAVendor(${subListParentId})/Locations/Export',
	},

	FAVendorRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'FAVendorRelationshipSubList',
		cmdUriTemplate: 'FAVendor(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'FAVendor(${subListParentId})/RelatedParties',
		exportUriTemplate: 'FAVendor(${subListParentId})/RelatedParties/Export',
	},

	PartnerBankAcctPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerBankAcctPick',
		entityName: 'PartnerBankAcct',
		fetchUriTemplate: 'Partner(${partnerId})/BankAccts',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	PartnerRelationshipPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerRelationshipPick',
		entityName: 'PartnerRelationship',
		fetchUriTemplate: 'Partner(${partnerId})/RelatedParties',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	PartnerGlAcctHistorySubList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerGlAcctHistorySubList',
		entityName: 'VALPartnerCash',
		fetchUriTemplate: 'PartnerGlAcct(${subListParentId})/GetPartnerGlAcctHistoryForAcct',
		exportUriTemplate: 'PartnerGlAcct(${subListParentId})/ExportPartnerGlAcctHistoryForAcct',
		printUriTemplate: 'PartnerGlAcct(${subListParentId})/PrintPartnerGlAcctHistoryForAcct',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamEntryType: EnumAllOptionValue,
			enumParamEntryMethod: EnumAllOptionValue,
		},
	},

	PartnerPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerPick',
		entityName: 'Partner',
		fetchUriTemplate: 'Partner',
		orderBy: 'PartnerLabel asc',
		selectFields: ['Id', 'OrgCompanyId', 'PartnerSubClassId', 'PartnerNo', 'PartnerLabel', 'ClosureType'],
		expandProps: ['MasterRecord','OrgCompany($select=CoShortCode,CoName)', 'PartnerSubClass($select=SubClassCode)'],
		searchFields: ['PartnerNo', 'PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	PartnerRelMgrSubList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerRelMgrSubList',
		entityName: 'PartnerRelMgr',
		fetchUriTemplate: 'Partner(${subListParentId})/PartnerRelMgrs',
		exportUriTemplate: 'Partner(${subListParentId})/ExportPartnerRelMgrs',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: ['RelMgr($select=PartnerNo,PartnerLabel)', 'SubAcctMgr($select=PartnerNo,PartnerLabel)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
		},
	},

	PartnerSubClassList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
			companyId: 1,
			partnerClass: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	PartnerSubClassPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerSubClassPick',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOrgCompanyPartnerSubClass()',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		searchFields: ['subclasscode'],
		filterValues: {
			searchText: '',
		},
	},

	VendorBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'VendorBalanceList',
        entityName: 'VALPartnerCash',
		fetchUriTemplate: 'orgcompany(${companyId})/FAVendorCashBalances',
		exportUriTemplate: 'orgcompany(${companyId})/FAVendorCashBalances/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
			enumParamAcctType: PartnerGlAcctTypes.Cash,
			enumParamCurrency: EnumAllOptionValue,
		},
	},

	VendorCreditRuleList: {
		...fluxListDefaults,
		actionNamespace: 'VendorCreditRuleList',
		entityName: 'CmpPartnerCreditRule',
		fetchUriTemplate: 'CmpPartnerCreditRule/GetFAVendorRulesAtDate',
		exportUriTemplate: 'CmpPartnerCreditRule/ExportFAVendorRulesAtDate',
		filterValues: {
			searchText: '',
			enumParamCurrency: EnumAllOptionValue,
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerView',
		entityName: 'Customer',
		fetchUriTemplate: 'Customer',
		queryUseRestEndpoint: true,
	},

	EQSalesAgentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQSalesAgentView',
		entityName: 'EQSalesAgent',
		fetchUriTemplate: 'EQSalesAgent',
		queryUseRestEndpoint: true,
	},

	FAVendorView: {
		...fluxViewDefaults,
		actionNamespace: 'FAVendorView',
		entityName: 'FAVendor',
		fetchUriTemplate: 'FAVendor',
		queryUseRestEndpoint: true,
	},

	PartnerBankAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerBankAcctView',
		entityName: 'PartnerBankAcct',
		fetchUriTemplate: 'PartnerBankAcct',
		selectFields: [],
		expandProps: [
			'Partner($select=TypeCode,PartnerNo,PartnerLabel)',
			'FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)',
		],
	},

	PartnerGlAcctView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerGlAcctView',
		entityName: 'PartnerGlAcct',
		fetchUriTemplate: 'PartnerGlAcct',
		selectFields: [],
		expandProps: [
			'Partner',
			'FAGlAcct($expand=OrgCompany($select=CoShortCode,CoName,CoOrgType),FAGlAcctRule($select=AcctRuleName))',
		],
	},

	PartnerLocationView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerLocationView',
		entityName: 'PartnerLocation',
		fetchUriTemplate: 'PartnerLocation',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=TypeCode,PartnerNo,PartnerLabel)',
		],
	},

	PartnerRelationshipView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerRelationshipView',
		entityName: 'PartnerRelationship',
		fetchUriTemplate: 'PartnerRelationship',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=TypeCode,PartnerNo,PartnerLabel)',
			'PrimaryAddress',
		],
	},

	PartnerSubClassView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerSubClassView',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'PartnerSubClass',
		selectFields: [],
		expandProps: [
            'OrgCompany($select=CoShortCode,CoName)', 
            'DefaultBuyCommission($select=GroupName)', 
            'DefaultSellCommission($select=GroupName)',
            'DefaultBenchmarkInstrument($select=InstrumentCode,InstrumentName)', 
            'DefaultAllocModel($select=ModelCode)', 
            'DefaultMgtFeeModel($select=ModelCode)',
			'EQSalesAgent($select=AgentCode,AgentName)', 
			'PartnerSubClassKYPs($expand=DocumentTranGroup($select=DocTranGroupCode))',
			'PartnerSubClassGlAcctRules($expand=OrgCompany($select=CoShortCode,CoName),FAGlAcctRule($select=AcctRuleName),InterestExpenseAcct($select=AcctNo,AcctTitle),InterestIncomeAcct($select=AcctNo,AcctTitle))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
