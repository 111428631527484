import { combineReducers } from 'redux';

import terminvests from './terminvests/_Reducers';
import debt from './debt/_Reducers';
import trading from './trading/_Reducers';

export default combineReducers({
	terminvests,
	debt,
	trading,
});
