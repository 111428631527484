import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo } from 'app-model/ModelConstants';
import { GeneralDataClosure } from 'app-model/enums';
import { MsgClosure } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CustomerBirthdayTOList: {
		...fluxListDefaults,
		actionNamespace: 'CustomerBirthdayTOList',
		entityName: 'CustomerBirthdayTO',
		fetchUriTemplate: 'orgcompany(${companyId})/GetCustomerBirthdaysComingUp(SubClassId=${subClassId},SearchText=${searchText})',
		exportUriTemplate: 'orgcompany(${companyId})/ExportCustomerBirthdaysComingUp',
		orderBy: 'DoBNakedNumber asc, PartnerLabel asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			staffId: NullEntityId
		},
	},

	CustomerCallNoteList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerCallNoteList',
        entityName: 'PartnerCallNote',
		fetchUriTemplate: 'PartnerCallNote',
		orderBy: 'CallDate desc, PartnerMaster/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)',
        ],
		searchFields: ['Notes', 'PartnerMaster/PartnerNo', 'PartnerMaster/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			partnerSubClassId: NullEntityId,
			callType: EnumAllOptionValue,
			closureType: GeneralDataClosure.Pending,
		},
	},
	
 	CustomerDefaultSubscriptionList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerDefaultSubscriptionList',
        entityName: 'NFDefaultSubscription',
		fetchUriTemplate: 'NFDefaultSubscription',
		orderBy: 'NotifyType/Code asc',
		selectFields: [],
		expandProps: [
            'PartnerSubClass($select=SubClassCode;$expand=OrgCompany($select=CoShortCode))',
            'NFTemplate($select=TemplateCode,TemplateDesc,DeliveryMethod)',
            'NotifyType($select=Code,Description)',
        ],
		searchFields: ['PartnerSubClass/SubClassCode'],
		filterValues: {
			searchText: '',
			notifyTypeId: NullEntityId,
		},
    },
    
	CustomerMessageList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerMessageList',
        entityName: 'NFMessage',
		fetchUriTemplate: 'NFMessage',
		orderBy: 'CreatedAt desc, NotifyType/Code asc',
		//selectFields: ['Id, NotificationType, RecipientId, DeliveryMethod, From3rdPartySystem, nFromDisplayName, nTo, nCc, nBcc, CreatedAt, ClosureType'],
		selectFields: ['Id, NotifyTypeId, RecipientId, DeliveryMethod, From3rdPartySystem, nFromDisplayName, nTo, nCc, nBcc, CreatedAt, ClosureType'],
		expandProps: [
            'Recipient($select=ClassCode,PartnerNo,PartnerLabel)',
            'NotifyType($select=Code,Description)',
        ],
		searchFields: ['nSubject', 'nTo', 'nCc', 'Recipient/PartnerNo', 'Recipient/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			partnerSubClassId: NullEntityId,
			notifyTypeId: NullEntityId,
			deliveryMethod: EnumAllOptionValue,
			closureType: MsgClosure.ClearedForSend,
		},
    },
    
	CustomerSubscriptionList: {
        ...fluxListDefaults,
		actionNamespace: 'CustomerSubscriptionList',
        entityName: 'NFSubscription',
		fetchUriTemplate: 'NFSubscription',
		orderBy: 'NotifyType/Code asc, PartnerMaster/PartnerLabel asc',
		selectFields: [],
		expandProps: [
            'PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)',
			'NFTemplate($select=TemplateCode,TemplateDesc,DeliveryMethod)',
            'NotifyType($select=Code,Description)',
        ],
		searchFields: ['PartnerMaster/PartnerNo', 'PartnerMaster/PartnerLabel'],
		filterValues: {
			searchText: '',
			partnerSubClassId: NullEntityId,
			notifyTypeId: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	CustomerCallNoteView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerCallNoteView',
		entityName: 'PartnerCallNote',
		fetchUriTemplate: 'PartnerCallNote',
		selectFields: [],
		expandProps: [
			'PartnerMaster($select=ClassCode,PartnerNo,PartnerLabel)',
			'ParticipantList($expand=Participant($select=PartnerNo,PartnerLabel))',
		],
	},

	CustomerMessageView: {
		...fluxViewDefaults,
		actionNamespace: 'CustomerMessageView',
		entityName: 'NFMessage',
		fetchUriTemplate: 'NFMessage',
		selectFields: [],
		expandProps: [
            'Recipient($select=ClassCode,PartnerNo,PartnerLabel)',
			'NFMessageAttachments',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
