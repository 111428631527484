import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { ContractClosure } from 'app-model/enums';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';
import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, oneYearAgo, tenYearsAgo, threeMonthsAgo, threeYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpAccountCommList: {
		...fluxListDefaults,
		actionNamespace: 'CmpAccountCommList',
		entityName: 'CmpAccountComm',
		fetchUriTemplate: 'CmpAccountComm/Prevailing',
		exportUriTemplate: 'CmpAccountComm/ExportPrevailing',
		orderBy: 'AccountTitle asc',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	CmpAccountFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpAccountFeeList',
		entityName: 'CmpAccountFee',
		fetchUriTemplate: 'CmpAccountFee/Prevailing',
		exportUriTemplate: 'CmpAccountFee/ExportPrevailing',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subClassId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	EQAccountActivityList: {
		...fluxListDefaults,
		delayFetch: true,
		actionNamespace: 'EQAccountActivityList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQAccount/TransactionSummary',
		exportUriTemplate: 'EQAccount/TransactionSummary/Export',
		keyField: 'AccountId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			enumParamSummary: EnumAllOptionValue,
		},
	},

	EQAccountList: {
		...fluxListDefaults,
		actionNamespace: 'EQAccountList',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount',
		exportUriTemplate: 'EQAccount/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			acctClass: EnumAllOptionValue,
			exchangeId: NullEntityId,
			subClassId: NullEntityId,
			closureType: PartnerState.Active,
		},
	},
	EQAccountPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQAccountPick',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQAccountCommAgentSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQAccountCommAgentSubList',
		entityName: 'EQAccountCommAgent',
		fetchUriTemplate: 'EQAccount(${subListParentId})/CommAgents',
		exportUriTemplate: 'EQAccount(${subListParentId})/CommAgents/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: null,
			endDate: null,
		},
	},

	EQAccountFeeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQAccountFeeSubList',
		entityName: 'EQAccountFee',
		fetchUriTemplate: 'EQAccount(${subListParentId})/Fees',
		exportUriTemplate: 'EQAccount(${subListParentId})/Fees/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: null,
			endDate: null,
		},
	},

	EQAccountBondAfsHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQAccountBondAfsHoldingList',
		entityName: 'VALBondAfsHolding',
		fetchUriTemplate: 'EQAccount/BondAfsHoldings',
		exportUriTemplate: 'EQAccount/BondAfsHoldings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	EQAccountBondHtmHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQAccountBondHtmHoldingList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'EQAccount/BondHtmHoldings',
		exportUriTemplate: 'EQAccount/BondHtmHoldings/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	EQAccountHoldingList: {
		...fluxListDefaults,
		delayFetch: true,
		sizePage: 50,
		actionNamespace: 'EQAccountHoldingList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'EQAccount/StockHoldings',
		exportUriTemplate: 'EQAccount/StockHoldings/Export',
		orderBy: 'OwnerName asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			atDate: toIsoDateString(today),
		},
	},

	EQAccountOpeningList: {
		...fluxListDefaults,
		actionNamespace: 'EQAccountOpeningList',
		entityName: 'EQAccountOpening',
		fetchUriTemplate: 'EQAccountOpening/GetAllWrkflwOpenings(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType},SearchText=${searchText})',
		exportUriTemplate: 'EQAccountOpening/ExportAllWrkflwOpenings',
		orderBy: 'RequestDate desc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'RefExchangeDef($select=UniqueCode,CommonName)',
			'Custodian($select=OperatorCode,OperatorLabel)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	EQAccountOpeningDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQAccountOpeningDocumentSubList',
		fetchUriTemplate: 'EQAccountOpening(${subListParentId})/Documents',
		exportUriTemplate: 'EQAccountOpening(${subListParentId})/Documents(${entityId})/Export',
	},

	EQAccountOpeningWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'EQAccountOpeningWorkflowHistorySubList',
		fetchUriTemplate: 'EQAccountOpening(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'EQAccountOpening(${subListParentId})/ExportWorkflowHistory',
	},

	EQContractNoteSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQContractNoteSubList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQAccount(${subListParentId})/EQContracts',
		exportUriTemplate: 'EQAccount(${subListParentId})/EQContracts/Export',
		printUriTemplate: 'EQContractNote/PRINT',
		keyField: 'ContractNoteId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentId: NullEntityId,
			tradeType: EnumAllOptionValue,
			closureType: ContractClosure.Posted,
		},
	},

	EQMovtSheetList: {
		...fluxListDefaults,
		actionNamespace: 'EQMovtSheetList',
		entityName: 'EQMovtSheet',
		fetchUriTemplate: 'EQMovtSheet',
		orderBy: 'TradeDate desc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonName)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		}
	},

	EQMovtTransferList: {
		...fluxListDefaults,
		actionNamespace: 'EQMovtTransferList',
		entityName: 'EQMovtTransfer',
		fetchUriTemplate: 'EQMovtTransfer',
		orderBy: 'EQMovtSheet/TradeDate desc, AccountNo asc',
		selectFields: [],
		expandProps: ['EQMovtSheet($select=TradeDate;$expand=RefExchangeDef($select=UniqueCode,CommonName))'],
		searchFields: ['AccountNo', 'Stock', 'ReferenceNo', 'Counterparty', 'TransNo', 'Description'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			transType: null,
			closureType: null
		},
	},

	EQMovtTransferSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMovtTransferSubList',
		entityName: 'EQMovtTransfer',
		fetchUriTemplate: 'EQMovtSheet(${subListParentId})/EQMovtTransfers',
		orderBy: 'AccountNo asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['AccountNo', 'Stock', 'ReferenceNo', 'Counterparty', 'TransNo', 'Description'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			transType: null,
			closureType: null
		},
	},

	EQOrderSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQOrderSubList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'EQAccount(${subListParentId})/GetEQOrderForEQAccount(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamTradeType},InstrId=${instrumentId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'EQAccount(${subListParentId})/ExportEQAccountEQOrders',
		orderBy: 'OrderDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentId: NullEntityId,
			enumParamChannel: EnumAllOptionValue,
			enumParamTradeType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	EQPosDepositSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQPosDepositSubList',
		entityName: 'EQPosDepositTO',
		fetchUriTemplate: 'EQMovtSheet(${subListParentId})/Positions',
		exportUriTemplate: 'EQMovtSheet(${subListParentId})/Positions/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
	
	EQPosResolveSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQPosResolveSubList',
		entityName: 'EQPosResolveTO',
		fetchUriTemplate: 'EQMovtSheet(${subListParentId})/Resolutions',
		exportUriTemplate: 'EQMovtSheet(${subListParentId})/Resolutions/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			matchType: EnumAllOptionValue,
			closureType: EnumAllOptionValue
		},
	},
	
	VALBondAfsHoldingSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'VALBondAfsHoldingSubList',
		entityName: 'VALBondAfsHolding',
		fetchUriTemplate: 'EQAccount(${subListParentId})/BondAfsHoldings',
		exportUriTemplate: 'EQAccount(${subListParentId})/BondAfsHoldings/Export',
		printUriTemplate: 'EQAccount(${subListParentId})/BondAfsHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},
	
	VALBondHtmHoldingSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'VALBondHtmHoldingSubList',
		entityName: 'VALBondHtmHolding',
		fetchUriTemplate: 'EQAccount(${subListParentId})/BondHtmHoldings',
		exportUriTemplate: 'EQAccount(${subListParentId})/BondHtmHoldings/Export',
		printUriTemplate: 'EQAccount(${subListParentId})/BondHtmHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	VALHoldingAggregateSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'VALHoldingAggregateSubList',
		entityName: 'VALHoldingAggregate',
		fetchUriTemplate: 'EQAccount(${subListParentId})/StockHoldings',
		exportUriTemplate: 'EQAccount(${subListParentId})/StockHoldings/Export',
		printUriTemplate: 'EQAccount(${subListParentId})/StockHoldings/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			atDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},

	VALStockHoldingJournalSubList: {
		...fluxListDefaults,
		sizePage: 50,
		actionNamespace: 'VALStockHoldingJournalSubList',
		entityName: 'VALStockJournal',
		fetchUriTemplate: 'EQAccount(${subListParentId})/StockJournal',
		exportUriTemplate: 'EQAccount(${subListParentId})/StockJournal/Export',
		printUriTemplate: 'EQAccount(${subListParentId})/StockJournal/Print',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			instrumentId: NullEntityId,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQAccountValuation: {
		...fluxViewDefaults,
		actionNamespace: 'EQAccountValuation',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount',
		queryUseRestEndpoint: true,
	},

	EQAccountView: {
		...fluxViewDefaults,
		actionNamespace: 'EQAccountView',
		entityName: 'EQAccount',
		fetchUriTemplate: 'EQAccount',
		queryUseRestEndpoint: true,
	},

	EQAccountOpeningView: {
		...fluxViewDefaults,
		actionNamespace: 'EQAccountOpeningView',
		entityName: 'EQAccountOpening',
		fetchUriTemplate: 'EQAccountOpening',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'RefExchangeDef($select=UniqueCode,CommonCode,CommonName)',
			'BuyComm($select=GroupName)',
			'SellComm($select=GroupName)',
			'Custodian($select=OperatorCode,OperatorLabel)',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorCode,OperatorLabel)',
			'DefSettlementAcct($select=OrgCompanyId,AcctNo,AcctTitle;$expand=OrgCompany($select=CoShortCode,CoName))',
		],
	},

	EQMovtSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMovtSheetView',
		entityName: 'EQMovtSheet',
		fetchUriTemplate: 'EQMovtSheet',
		selectFields: [],
		expandProps: [
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
