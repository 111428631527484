import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, threeYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	ResMoneyCreditStatList: {
		...fluxListDefaults,
		actionNamespace: 'ResMoneyCreditStatList',
		entityName: 'ResMoneyCreditStat',
		fetchUriTemplate: 'ResMoneyCreditStat',
		orderBy: 'EffectiveDate desc, Country asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['Source', 'Country'],
		filterValues: {
			searchText: '',
			country: 'NG',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	TRMarketRateList: {
		...fluxListDefaults,
		actionNamespace: 'TRMarketRateList',
		entityName: 'TRMarketRate',
		fetchUriTemplate: 'TRMarketRate',
		exportUriTemplate: 'TRMarketRate/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: 'NG',
			rateType: EnumAllOptionValue,
		},
	},
	TRMarketRatePick: {
		...fluxListDefaults,
		actionNamespace: 'TRMarketRatePick',
		entityName: 'TRMarketRate',
		fetchUriTemplate: 'TRMarketRate',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	
	TRMarketRateAtDateList: {
		...fluxListDefaults,
		actionNamespace: 'TRMarketRateAtDateList',
		entityName: 'TRMarketRateValue',
		fetchUriTemplate: 'TRMarketRateValue/AtDate',
		exportUriTemplate: 'TRMarketRateValue/AtDate/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: 'NG',
			rateType: EnumAllOptionValue,
			atDate: toIsoDateString(today),
		},
	},
	
	TRMarketRateValueList: {
		...fluxListDefaults,
		actionNamespace: 'TRMarketRateValueList',
		entityName: 'TRMarketRateValue',
		fetchUriTemplate: 'TRMarketRateValue',
		exportUriTemplate: 'TRMarketRateValue/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: 'NG',
			rateType: EnumAllOptionValue,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
