import { Channels, EQAccountClassEnum, EQAccountTypeEnum, ScheduleTypeEnum } from './_DefaultEnums';

export * from './_DefaultEnums';


export const IsClient = (channel: Channels): boolean => 
	channel === Channels.EMAIL || channel === Channels.LETTER || channel === Channels.SMS || channel === Channels.PORTAL || channel === Channels.REMOTEAPP || channel === Channels.INTEGRATION;

export const IsWeb = (channel: Channels): boolean => 
	channel === Channels.PORTAL || channel === Channels.REMOTEAPP || channel === Channels.INTEGRATION;


export interface IScheduledEntity {
	ScheduleType: ScheduleTypeEnum;
	ScheduleExecuteOn: number;
	ScheduleInterval: number;
}

export const GetScheduleDescriptionForEntity = (entity: IScheduledEntity) => {
	return GetScheduleDescription(entity.ScheduleType, entity.ScheduleExecuteOn, entity.ScheduleInterval);
};

export const GetScheduleDescription = (schedType: ScheduleTypeEnum, executeOn: number, interval: number) => {
	var lSchedDesc = '';

	switch (schedType)
	{
		case ScheduleTypeEnum.None:
			lSchedDesc = "No scheduling is specified";
			break;

		case ScheduleTypeEnum.Minute:
			lSchedDesc = `Repeat action every ${interval} minute(s) on the ${executeOn}th second`;
			break;

		case ScheduleTypeEnum.Hourly:
			lSchedDesc = `Repeat action every ${interval} hour(s) at the ${executeOn}th minute`;
			break;

		case ScheduleTypeEnum.Daily:
			lSchedDesc = `Repeat action every ${interval} day(s) on the ${executeOn}th hour`;
			break;

		case ScheduleTypeEnum.Weekly:
			lSchedDesc = `Repeat action every ${interval} week(s) on the ${executeOn}th day of the week`;
			break;

		case ScheduleTypeEnum.Monthly:
			lSchedDesc = `Repeat action every ${interval} month(s) on the ${executeOn}th`;
			break;

		case ScheduleTypeEnum.Quarterly:
			lSchedDesc = `Repeat action every ${interval} quarter(s) on the ${executeOn}th`;
			break;

		case ScheduleTypeEnum.Yearly:
			lSchedDesc = `Repeat action every ${interval} year(s) on the ${executeOn}th`;
			break;
	}

	return lSchedDesc;
}


export interface IEQAccountTypeEnumGroupEntries {
	[key: string]: EQAccountTypeEnum;
}

export interface IEQAccountTypeEnumGroup {
	default: EQAccountTypeEnum;
	entries: IEQAccountTypeEnumGroupEntries;
}

export interface IEQAccountTypeLookup {
	[key: string]: IEQAccountTypeEnumGroup;
}

export const EQAccountTypeLookup: IEQAccountTypeLookup = {
	Exchange: {
		default: EQAccountTypeEnum.ExchRegularTrade,
		entries: {
			ExchCollateralCustody: 'ExchCollateralCustody', //_Exchange | _Trading | _Collateral | _Custodian | _SettleConsideration | _CanSettleUniquely, //ConduitCollateral = 385 -> 6353
			ExchCollateralTracking: 'ExchCollateralTracking', //_Exchange | _Tracking | _Collateral | _SettleHoldings, //RegularCollateral = 257 -> 1121
			ExchCollateralTrade: 'ExchCollateralTrade', //_Exchange | _Trading | _Collateral | _Custodian | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //PartnerCustodian = 513 -> 7377

			ExchConduit: 'ExchConduit', //_Exchange | _Trading | _Custodian, //RegularConduit = 129 -> 161 -> 145

			ExchMktMaker: 'ExchMktMaker', //_Exchange | _MktMaker | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //MktMakerDirect = 17 -> 7177, MktMakerLeveraged = 1041 -> 7177 

			ExchRegularTrade: 'ExchRegularTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration, //RegularExchange = 1 -> 3089 

			//ExchRegularWithDCS: 'ExchRegularWithDCS', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _DirectCashSettle, //35857

			//ExchTradeConsidWithDCS: 'ExchTradeConsidWithDCS', //_Exchange | _Trading | _SettleConsideration | _DirectCashSettle, //34833

			ExchSecLendBorrower: 'ExchSecLendBorrower', //_Exchange | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingBorrower = 97 -> 7457
			ExchSecLendLender: 'ExchSecLendLender', //_Exchange | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //TrackingLender = 2065 -> 7713

			ExchSpecialTrade: 'ExchSpecialTrade', //_Exchange | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, // -> 7185 
		}
	},
	Nominee: {
		default: EQAccountTypeEnum.NomRegularTrade,
		entries: {
			NomCollateralCustody: 'NomCollateralCustody', //_Nominee | _Trading | _Custodian | _Collateral | _SettleConsideration,
			NomCollateralTrade: 'NomCollateralTrade', //_Nominee | _Trading | _Custodian | _Collateral | _SettleHoldings | _SettleConsideration, //CollateralNominee = 258 -> 3282

			NomConduit: 'NomConduit', //_Nominee | _Trading | _Custodian, //ConduitNominee = 130 -> 146
			NomRegularTrade: 'NomRegularTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration, //RegularNominee = 3 -> 3090

			//NomRegularWithDCS: 'NomRegularWithDCS', //_Nominee | _Trading | _SettleHoldings | _DirectCashSettle, //

			NomSchemeTrade: 'NomSchemeTrade', //_Nominee | _Trading | _SettleHoldings | _SettleConsideration | _SchemeAccount, //

			//NomSchemeWithDCS: 'NomSchemeWithDCS', //_Nominee | _Trading | _SettleHoldings | _SchemeAccount | _DirectCashSettle, //

			NomSecLendBorrower: 'NomSecLendBorrower', //_Nominee | _Tracking | _SecLendBorrower | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,
			NomSecLendLender: 'NomSecLendLender', //_Nominee | _Tracking | _SecLendLender | _SettleHoldings | _SettleConsideration | _CanSettleUniquely,
		}
	},
	OverTheCounter: {
		default: EQAccountTypeEnum.OtcRegularTrade,
		entries: {
			OtcFundAccount: 'OtcFundAccount', //_OverTheCounter | _Tracking | _SettleHoldings | _SettleConsideration | _FundSubscription, //11300

			OtcRegularTrade: 'OtcRegularTrade', //_OverTheCounter | _Trading | _SettleHoldings | _SettleConsideration | _CanSettleUniquely, //OverTheCounter = 4 -> 7188
		}
	}
};


export const canClassTieToExchange = (val: EQAccountClassEnum) => val === EQAccountClassEnum.Exchange || val === EQAccountClassEnum.Nominee;


export const isAcctTypeOfClass = (val: EQAccountTypeEnum, iclass: EQAccountClassEnum) =>
	Object.entries(EQAccountTypeLookup[iclass].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;


export const canTypeTieToExchange = (val: EQAccountTypeEnum) => {
	let result = false;

	//check the Exchange entries
	result =
		Object.entries(EQAccountTypeLookup[EQAccountClassEnum.Exchange].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;

	//if the val was not amongst the Exchange entries, check the Nominee entries
	if (result === false)
		result =
			Object.entries(EQAccountTypeLookup[EQAccountClassEnum.Nominee].entries).map(entry => entry[1]).findIndex(entry => entry === val) > -1;

	//return the result
	return result;
};

export interface IClassTypePair {
	ClassName: string;
	TypeNames: string[];
}

export interface IClassTypeMapping {
	[key: string]: IClassTypePair;
}

export const EQInstrumentTypeByClass: IClassTypeMapping = {
	Bonds: {
		ClassName: 'Bonds',
		TypeNames: ['BOND_CORP', 'BOND_NAT', 'BOND_SUBNAT']
	},
	Commodity: {
		ClassName: 'Commodity',
		TypeNames: ['CMDTY_PRODUCE', 'CMDTY_RESOURCE']
	},
	Currency: {
		ClassName: 'Currency',
		TypeNames: ['CURRENCY']
	},
	Equity: {
		ClassName: 'Equity',
		TypeNames: ['EQUITY', 'FML', 'GDR', 'RIGHTS', 'ETF_FOREIGN', 'ETF_LOCAL']
	},
	Fund: {
		ClassName: 'Fund',
		TypeNames: ['FUND']
	},
	Index: {
		ClassName: 'Index',
		TypeNames: ['INDEX']
	},
};


export const CurrencyCodes = {
	AED: 'AED',
	AUD: 'AUD',
	AWG: 'AWG',
	CAD: 'CAD',
	EGP: 'EGP',
	EUR: 'EUR',
	GBP: 'GBP',
	JPY: 'JPY',
	KWD: 'KWD',
	NGN: 'NGN',
	PEN: 'PEN',
	RSD: 'RSD',
	RUB: 'RUB',
	USD: 'USD',
	WAU: 'WAU',
	XXX: 'XXX',
	ZAR: 'ZAR',
	ZMK: 'ZMK',
	ZWD: 'ZWD',
};
export type CurrencyCodes = typeof CurrencyCodes[keyof typeof CurrencyCodes];


/// <summary></summary>
export const HRLeaveTypeDirectEnum = {
	//Annual: 'Annual', //1,
	Casual: 'Casual', //2,
	Exam: 'Exam', //3,
	Sick: 'Sick', //4,
	Bereavement: 'Bereavement', //5,
	Maternity: 'Maternity', //11,
	Paternity: 'Paternity', //12,
};
export type HRLeaveTypeDirectEnum = typeof HRLeaveTypeDirectEnum[keyof typeof HRLeaveTypeDirectEnum];


export const ItemMeasure = {
	Count: 'Count', //0,
	Area: 'Area', //1,
	Length: 'Length', //2,
	Mass: 'Mass', //3,
	Volume: 'Volume', //4,
	Weight: 'Weight', //5
};
export type ItemMeasure = typeof ItemMeasure[keyof typeof ItemMeasure];


export const MoneyMarketType_TBill = {
	TBILL_VAR: 'TBILL_VAR', //5000,
	TBILL_091: 'TBILL_091', //5091,
	TBILL_182: 'TBILL_182', //5182,
	TBILL_364: 'TBILL_364', //5364,
};
export type MoneyMarketType_TBill = typeof MoneyMarketType_TBill[keyof typeof MoneyMarketType_TBill];


/*** An enum indicating the various types of MovtTransfer */
export const MovtType = {
	/*** Indicates a difference between the reference transaction in Griffin and the CSD upload. */
	CERT: 'CERT', //,
	/*** Indicates a transfer into an account. */
	XFIN: 'XFIN', //,
	/*** Indicates a transfer out of an account. */
	XFOUT: 'XFOUT', //,
};
export type MovtType = typeof MovtType[keyof typeof MovtType];


export const NationalIdTypeCorporate = {
	None: 'None', //0,
	CompanyReg: 'CompanyReg', //10,
}
export type NationalIdTypeCorporate = typeof NationalIdTypeCorporate[keyof typeof NationalIdTypeCorporate];


export const NationalIdTypeIndividual = {
	None: 'None', //0,
	DriversLicense: 'DriversLicense', //20,
	InternationalPassport: 'InternationalPassport', //30,
	NationalIdCard: 'NationalIdCard', //40,
	ResidencePermit: 'ResidencePermit', //50,
	VotersCard: 'VotersCard', //60,
}
export type NationalIdTypeIndividual = typeof NationalIdTypeIndividual[keyof typeof NationalIdTypeIndividual];


export const PartnerStateFocused = {
	Discontinued: 'Discontinued', //-3,
	Inactive: 'Inactive', //-2,
	Pending: 'Pending', //-1,
	Reviewed: 'Reviewed', //0,
	Active: 'Active', //1
};
export type PartnerStateFocused = typeof PartnerStateFocused[keyof typeof PartnerStateFocused];


export const ProjectPartyClass = {
	CUSTOMER: 'CUSTOMER',
	VENDOR: 'VENDOR',
};
export type ProjectPartyClass = typeof ProjectPartyClass[keyof typeof ProjectPartyClass];


export const ProjectMemberClass = {
	CUSTOMER: 'CUSTOMER',
	STAFF: 'STAFF',
	VENDOR: 'VENDOR',
};
export type ProjectMemberClass = typeof ProjectMemberClass[keyof typeof ProjectMemberClass];


export const PxWorkflowStateFilter = {
	ACTIVE: 'ACTIVE',
	COMPLETED: 'COMPLETED',
	NEGLECTED: 'NEGLECTED',
};
export type PxWorkflowStateFilter = typeof PxWorkflowStateFilter[keyof typeof PxWorkflowStateFilter];

