import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { DefNgNseExchangeId, EnumAllOptionValue, NullEntityId, today, oneYearAgo, threeYearsAgo } from 'app-model/ModelConstants';
import { IFluxAction } from 'app-model/IFluxAction';
import { ContractClosure } from 'app-model/enums';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CmpTradeExclusionList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeExclusionList',
		entityName: 'CmpTradeExclusion',
		fetchUriTemplate: 'CmpTradeExclusion/Prevailing',
		exportUriTemplate: 'CmpTradeExclusion/ExportPrevailing',
		orderBy: 'CustomerName asc, InstrumentCode asc',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			atDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
		},
	},

	EQContractNoteList: {
		...fluxListDefaults,
		actionNamespace: 'EQContractNoteList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQContractNote',
		exportUriTemplate: 'EQContractNote/Export',
		printUriTemplate: 'EQContractNote/PRINT',
		keyField: 'ContractNoteId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			companyId: NullEntityId,
			subClassId: NullEntityId,
			exchangeId: NullEntityId,
			instrumentId: NullEntityId,
			tradeType: EnumAllOptionValue,
			closureType: ContractClosure.Posted,
		},
	},

	EQContractNoteDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQContractNoteDocumentSubList',
		fetchUriTemplate: 'EQContractNote(${subListParentId})/Documents',
		exportUriTemplate: 'EQContractNote(${subListParentId})/Documents(${entityId})/Export',
	},

	EQContractNoteSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQContractNoteSubList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/EQContracts',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/EQContracts/Export',
		printUriTemplate: 'EQContractNote/PRINT',
		keyField: 'ContractNoteId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
			tradeType: EnumAllOptionValue,
			closureType: ContractClosure.Posted,
		},
	},

	EQDealAdviceList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealAdviceList',
		entityName: 'EQDealAdvice',
		fetchUriTemplate: 'EQDealAdvice',
		orderBy: 'EQDealSheet/TradeDate desc, IsMarketMaker asc',
		selectFields: [],
		expandProps: [
			'EQDealSheet($select=TradeDate,ExchangeId;$expand=RefExchangeDef($select=UniqueCode,CommonName))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today)
		},
	},
	EQDealAdviceSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealAdviceSubList',
		entityName: 'EQDealAdvice',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/EQDealAdvices',
		orderBy: 'IsMarketMaker asc',
		selectFields: [],
		expandProps: [
			'EQDealSheet($select=TradeDate,ExchangeId;$expand=RefExchangeDef($select=UniqueCode,CommonName))',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQDealAllotSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealAllotSubList',
		entityName: 'EQDealAllot',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/EQDealAllots',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/ExportDealAllotsForDealSheet',
		printUriTemplate: 'EQDealAllot/PRINT',
		orderBy: 'EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
			tradeType: EnumAllOptionValue,
		},
	},

	EQDealAllotDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQDealAllotDocumentSubList',
		fetchUriTemplate: 'EQDealAllot(${subListParentId})/Documents',
		exportUriTemplate: 'EQDealAllot(${subListParentId})/Documents(${entityId})/Export',
	},

	EQDealSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealSubList',
		entityName: 'EQDeal',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/Deals',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/Deals/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
		},
	},

	EQDealDownloadSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealDownloadSubList',
		entityName: 'EQDealDownload',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/DealDownloads',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/DealDownloads/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			errorFlag: 0,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
			enumParamSource: EnumAllOptionValue,
		},
	},

	EQDealSheetList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealSheetList',
		entityName: 'EQDealSheet',
		fetchUriTemplate: 'EQDealSheet',
		orderBy: 'TradeDate desc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonName)'],
		searchFields: ['RefExchangeDef/UniqueCode', 'RefExchangeDef/CommonName'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	EQDealWarehouseSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'EQDealWarehouseSummarySubList',
		entityName: 'EQContractNote',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/WarehouseDealSummary',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/WarehouseDealSummary/Export',
		//printUriTemplate: 'EQContractNote/PRINT',
		keyField: 'WarehouseId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
			enumParamTradeType: EnumAllOptionValue,
		},
	},

	EQOrderList: {
		...fluxListDefaults,
		actionNamespace: 'EQOrderList',
		entityName: 'EQOrder',
		fetchUriTemplate: 'EQOrder/GetEQOrderForRange(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},InstrId=${instrId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'EQOrder/ExportEQOrderForRange',
		orderBy: 'OrderDate desc, EQAccount/AccountTitle asc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
		],
		searchFields: ['EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrId: NullEntityId,
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	EQOrderDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQOrderDocumentSubList',
		fetchUriTemplate: 'EQOrder(${subListParentId})/Documents',
		exportUriTemplate: 'EQOrder(${subListParentId})/Documents(${entityId})/Export',
	},

	EQOrderJobPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQOrderJobPick',
		entityName: 'EQOrderJob',
		fetchUriTemplate: 'EQDealSheet(${parentId})/GetOrdersForDealSheet(InstrId=${instrId},OrderType=${enumParamOrderType},OrderMode=${enumParamOrderMode},OrderValidation=${enumParamOrderValidation},Status=${enumParamStatus},SearchText=${searchText})',
		orderBy: 'AcctTitle asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			parentId: -1,
			instrId: NullEntityId,
			enumParamOrderType: EnumAllOptionValue,
			enumParamOrderMode: EnumAllOptionValue,
			enumParamOrderValidation: EnumAllOptionValue,
			enumParamStatus: EnumAllOptionValue,
		},
	},

	EQOrderJobSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQOrderJobSubList',
		entityName: 'EQOrderJob',
		fetchUriTemplate: 'EQDealSheet(${subListParentId})/GetOrdersForDealSheet(InstrId=${instrId},OrderType=${enumParamOrderType},OrderMode=${enumParamOrderMode},OrderValidation=${enumParamOrderValidation},Status=${enumParamStatus},SearchText=${searchText})',
		exportUriTemplate: 'EQDealSheet(${subListParentId})/ExportOrdersForDealSheet',
		orderBy: 'AcctTitle asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrId: NullEntityId,
			enumParamOrderType: EnumAllOptionValue,
			enumParamOrderMode: EnumAllOptionValue,
			enumParamOrderValidation: EnumAllOptionValue,
			enumParamStatus: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQDealAdviceView: {
		...fluxViewDefaults,
		actionNamespace: 'EQDealAdviceView',
		entityName: 'EQDealAdvice',
		fetchUriTemplate: 'EQDealAdvice',
		selectFields: [],
		expandProps: [
			'EQDealSheet($select=TradeDate,ExchangeId;$expand=RefExchangeDef($select=UniqueCode,CommonName))',
			'EQDealAdviceFees',
		],
	},

	EQDealAllotView: {
		...fluxViewDefaults,
		actionNamespace: 'EQDealAllotView',
		entityName: 'EQDealAllot',
		fetchUriTemplate: 'EQDealAllot',
		selectFields: [],
		expandProps: [
			'EQDealSheet($select=TradeDate,ExchangeId;$expand=RefExchangeDef($select=UniqueCode,CommonName))',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentType,InstrumentCode,InstrumentName)',
			'WarehouseTradeAcct($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'DealAllotCommission($select=GroupName)',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'EQDealAllotFees',
		],
	},

	EQDealSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQDealSheetView',
		entityName: 'EQDealSheet',
		fetchUriTemplate: 'EQDealSheet',
		selectFields: [],
		expandProps: [
			'RefExchangeDef($select=UniqueCode,CommonName)'
		],
	},

	EQOrderView: {
		...fluxViewDefaults,
		actionNamespace: 'EQOrderView',
		entityName: 'EQOrder',
		fetchUriTemplate: 'EQOrder',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName,Currency;$expand=ListExchange($select=UniqueCode,CommonName))',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'Executor($select=OperatorNo,OperatorCode,OperatorLabel)',
			'OrderCommission($select=GroupName)',
			'EQOrderFees'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
