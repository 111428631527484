import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	FABalanceSheetList: {
		...fluxListDefaults,
		actionNamespace: 'FABalanceSheetList',
		entityName: 'FAGlAcctBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/GetBalanceSheetDetailedAtDate',
		exportUriTemplate: 'orgcompany(${companyId})/ExportBalanceSheetDetailedAtDate',
		printUriTemplate: 'orgcompany(${companyId})/PrintBalanceSheetDetailedAtDate',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			atDate: toIsoDateString(today)
		},
	},
	
	FABasisCompIncomeList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisCompIncomeList',
		entityName: 'FABasisProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetCompIncomeForBasis/${basisId}',
		exportUriTemplate: 'orgcompany(${companyId})/ExportCompIncomeForBasis/${basisId}',
		printUriTemplate: 'orgcompany(${companyId})/PrintCompIncomeForBasis/${basisId}',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			basisId: NullEntityId,
		},
	},
	
	FABasisOciList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisOciList',
		entityName: 'FABasisProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOciForBasis/${basisId}',
		exportUriTemplate: 'orgcompany(${companyId})/ExportOciForBasis/${basisId}',
		printUriTemplate: 'orgcompany(${companyId})/PrintOciForBasis/${basisId}',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			basisId: NullEntityId,
		},
	},

	FABasisProfitLossList: {
		...fluxListDefaults,
		actionNamespace: 'FABasisProfitLossList',
		entityName: 'FABasisProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetProfitLossForBasis/${basisId}',
		exportUriTemplate: 'orgcompany(${companyId})/ExportProfitLossForBasis/${basisId}',
		printUriTemplate: 'orgcompany(${companyId})/PrintProfitLossForBasis/${basisId}',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			basisId: NullEntityId,
		},
	},

	FARangeCompIncomeList: {
		...fluxListDefaults,
		actionNamespace: 'FARangeCompIncomeList',
		entityName: 'FARangeProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetCompIncomeForRange',
		exportUriTemplate: 'orgcompany(${companyId})/ExportCompIncomeForRange',
		printUriTemplate: 'orgcompany(${companyId})/PrintCompIncomeForRange',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	FARangeOciList: {
		...fluxListDefaults,
		actionNamespace: 'FARangeOciList',
		entityName: 'FARangeProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetOciForRange',
		exportUriTemplate: 'orgcompany(${companyId})/ExportOciForRange',
		printUriTemplate: 'orgcompany(${companyId})/PrintOciForRange',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	FARangeProfitLossList: {
		...fluxListDefaults,
		actionNamespace: 'FARangeProfitLossList',
		entityName: 'FARangeProfitLoss',
		fetchUriTemplate: 'orgcompany(${companyId})/GetProfitLossForRange',
		exportUriTemplate: 'orgcompany(${companyId})/ExportProfitLossForRange',
		printUriTemplate: 'orgcompany(${companyId})/PrintProfitLossForRange',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
		},
	},
	
	FATrialBalanceList: {
		...fluxListDefaults,
		actionNamespace: 'FATrialBalanceList',
		entityName: 'FAGlTrialBalance',
		fetchUriTemplate: 'orgcompany(${companyId})/GetTrialBalanceSortedAtDate',
		exportUriTemplate: 'orgcompany(${companyId})/ExportTrialBalanceSortedAtDate',
		printUriTemplate: 'orgcompany(${companyId})/PrintTrialBalanceSortedAtDate',
		queryUseRestEndpoint: true,
		keyField: 'AccountId',
		sizePage: 100,
		filterValues: {
			searchText: '',
			companyId: 1,
			excludeZero: 1,
			atDate: toIsoDateString(today)
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	FATrialBalanceView: {
		...fluxViewDefaults,
		actionNamespace: 'FATrialBalanceView',
		entityName: 'FAGlTrialBalance',
		fetchUriTemplate: 'FAGlTrialBalance',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
