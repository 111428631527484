import { combineReducers } from 'redux';

import purchasing from './purchasing/_Reducers';
import sales from './sales/_Reducers';
import reconreports from './reconreports/_Reducers';
import transactions from './transactions/_Reducers';
import setup from './setup/_Reducers';

export default combineReducers({
	purchasing,
	sales,
	transactions,
	reconreports,
	setup,
});
