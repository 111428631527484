import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';
import { PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults, fluxPxWorkflowHistorySubListDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyPerfAppraisalList: {
		...fluxListDefaults,
		actionNamespace: 'MyPerfAppraisalList',
		entityName: 'HRPerfAppraisal',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetMyPerfAppraisalsForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate;$expand=HRPayrollYear($select=YearName,StartDate,EndDate))',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyPerfAppraisalDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyPerfAppraisalDocumentSubList',
		fetchUriTemplate: 'HRPerfAppraisal(${subListParentId})/Documents',
		exportUriTemplate: 'HRPerfAppraisal(${subListParentId})/Documents(${entityId})/Export',
	},

	MyPerfAppraisalWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyPerfAppraisalWorkflowHistorySubList',
		fetchUriTemplate: 'HRPerfAppraisal(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPerfAppraisal(${subListParentId})/ExportWorkflowHistory',
	},

	MyPerfTargetList: {
		...fluxListDefaults,
		actionNamespace: 'MyPerfTargetList',
		entityName: 'HRPerfTarget',
		fetchUriTemplate: 'hrpayrollyear(${payrollYearId})/GetMyPerfTargetsForYear(Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'HRStaff/StaffName asc',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
		searchFields: [
			'HRStaff/StaffNo','HRStaff/StaffName'
		],
		filterValues: {
			searchText: '',
			payrollYearId: NullEntityId,
			enumParamAssignment: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MyPerfTargetDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MyPerfTargetDocumentSubList',
		fetchUriTemplate: 'HRPerfTarget(${subListParentId})/Documents',
		exportUriTemplate: 'HRPerfTarget(${subListParentId})/Documents(${entityId})/Export',
	},

	MyPerfTargetWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'MyPerfTargetWorkflowHistorySubList',
		fetchUriTemplate: 'HRPerfTarget(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'HRPerfTarget(${subListParentId})/ExportWorkflowHistory',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyPerfAppraisalView: {
		...fluxViewDefaults,
		actionNamespace: 'MyPerfAppraisalView',
		entityName: 'HRPerfAppraisal',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRPerfAppraisal/GetMyPerfAppraisalById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType)',
			'HRStaff($select=StaffNo,StaffName)',
			'NewGrade($select=Category,Grade,Designation)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRPerfAppraisalAccomps',
			'HRPerfAppraisalKpis',
			'HRPerfAppraisalReviewers($expand=Reviewer($select=StaffNo,StaffName))',
		],
	},
	
	MyPerfAppraisalAccompView: {
		...fluxViewDefaults,
		actionNamespace: 'MyPerfAppraisalAccompView',
		entityName: 'HRPerfAppraisalAccomp',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRPerfAppraisalAccomp/GetMyPerfAppraisalAccompById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisal($expand=HRStaff($select=StaffNo,StaffName),HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType))',
			'AccompReviewDTOs',
		],
	},
	
	MyPerfAppraisalKpiView: {
		...fluxViewDefaults,
		actionNamespace: 'MyPerfAppraisalKpiView',
		entityName: 'HRPerfAppraisalKpi',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRPerfAppraisalKpi/GetMyPerfAppraisalKpiById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRPerfAppraisal($expand=HRStaff($select=StaffNo,StaffName),HRPerfAppraisalSession($select=PeriodStart,PeriodEnd,StartDate,EndDate,ClosureType))',
			'KpiScoreDTOs',
		],
	},
	
	MyPerfTargetView: {
		...fluxViewDefaults,
		actionNamespace: 'MyPerfTargetView',
		entityName: 'HRPerfTarget',
		fetchUriComposited: true,
		fetchUriTemplate: 'HRPerfTarget/GetMyPerfTargetById(Id=${id})',
		selectFields: [],
		expandProps: [
			'HRPayrollYear($select=YearName,StartDate,EndDate)',
			'HRStaff($select=StaffNo,StaffName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'HRPerfTargetKpis',
			'HRPerfTargetReviewers($expand=Reviewer($select=StaffNo,StaffName))',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
