import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today  } from 'app-model/ModelConstants';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQWarrantDuplicateCustomerList: {
		...fluxListDefaults,
		actionNamespace: 'EQWarrantDuplicateCustomerList',
		entityName: 'EQWarrantDuplicateCustomer',
		fetchUriTemplate: 'EQWarrantDuplicateCustomer/GetDuplicateSummary(CustomerId=${customerId},InstrumentId=${instrumentId},SearchText=${searchText})',
		exportUriTemplate: 'EQWarrantDuplicateCustomer/ExportDuplicateSummary',
		orderBy: 'CustomerName asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			customerId: NullEntityId,
			instrumentId: NullEntityId,
		},
	},

	EQWarrantDuplicateRegAcctList: {
		...fluxListDefaults,
		actionNamespace: 'EQWarrantDuplicateRegAcctList',
		entityName: 'EQWarrantDuplicateRegAcct',
		fetchUriTemplate: 'EQWarrantDuplicateRegAcct/GetDuplicateSummary(InstrumentId=${instrumentId},SearchText=${searchText})',
		exportUriTemplate: 'EQWarrantDuplicateRegAcct/ExportDuplicateSummary',
		orderBy: 'CustomerName asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			instrumentId: NullEntityId,
		},
	},

	EQWarrantPostBatchList: {
		...fluxListDefaults,
		actionNamespace: 'EQWarrantPostBatchList',
		entityName: 'EQWarrantPostBatch',
		fetchUriTemplate: 'EQWarrantPostBatch',
		printUriTemplate: 'EQWarrantPostBatch/PRINT',
		orderBy: 'BatchDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['BatchCode', 'BatchDescription'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	EQWarrantPostBatchDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQWarrantPostBatchDocumentSubList',
		fetchUriTemplate: 'EQWarrantPostBatch(${subListParentId})/Documents',
		exportUriTemplate: 'EQWarrantPostBatch(${subListParentId})/Documents(${entityId})/Export',
	},

	EQWarrantSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQWarrantSubList',
		entityName: 'EQWarrant',
		fetchUriTemplate: 'EQWarrantPostBatch(${subListParentId})/EQWarrants',
		printUriTemplate: 'EQWarrantPostBatch/PRINT',
		orderBy: 'EQWarrant/NameOnCertificate asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQInstrument($select=InstrumentCode,InstrumentName)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))',
		],
		searchFields: ['Description', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'EQAccount/InternalAcctNo', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			instrumentId: NullEntityId,
		},
	},

	EQXferDepositList: {
		...fluxListDefaults,
		actionNamespace: 'EQXferDepositList',
		entityName: 'EQXferDeposit',
		fetchUriTemplate: 'EQXferDeposit/GetAllWrkflwXferDeposits(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'CounterOperator($select=OperatorCode)',
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel', 'EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle', 'CounterOperator/OperatorCode', 'CounterAccountNo'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			xferDirection: EnumAllOptionValue,
			xferType: EnumAllOptionValue,
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	EQXferDepositDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQXferDepositDocumentSubList',
		fetchUriTemplate: 'EQXferDeposit(${subListParentId})/Documents',
		exportUriTemplate: 'EQXferDeposit(${subListParentId})/Documents(${entityId})/Export',
	},

	EQXferDepositWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'EQXferDepositWorkflowHistorySubList',
		fetchUriTemplate: 'EQXferDeposit(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'EQXferDeposit(${subListParentId})/ExportWorkflowHistory',
	},

	EQXferInstrumentList: {
		...fluxListDefaults,
		actionNamespace: 'EQXferInstrumentList',
		entityName: 'EQXferInstrument',
		fetchUriTemplate: 'EQXferInstrument',
		orderBy: 'Id desc',
		selectFields: [],
		expandProps: [
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'EQInstrument($select=InstrumentCode,InstrumentName)',
			'EQXferDeposit($select=InitiateDate)',
		],
		searchFields: ['EQAccount/ExchangeAcctNo', 'EQAccount/AccountTitle', 'CounterBrokerCode', 'CounterAccountNo'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			closureType: null,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQXferDepositView: {
		...fluxViewDefaults,
		actionNamespace: 'EQXferDepositView',
		entityName: 'EQXferDeposit',
		fetchUriTemplate: 'EQXferDeposit',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle,ExchangeId)',
			'CounterOperator($select=OperatorCode)',
			'EQXferInstruments($expand=EQInstrument($select=InstrumentCode,InstrumentName))'
		],
	},

	EQWarrantPostBatchView: {
		...fluxViewDefaults,
		actionNamespace: 'EQWarrantPostBatchView',
		entityName: 'EQWarrantPostBatch',
		fetchUriTemplate: 'EQWarrantPostBatch',
		selectFields: [],
		expandProps: [],
	},

	EQWarrantView: {
		...fluxViewDefaults,
		actionNamespace: 'EQWarrantView',
		entityName: 'EQWarrant',
		fetchUriTemplate: 'EQWarrant',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle,ExchangeId)',
			'EQInstrument($select=InstrumentCode,InstrumentName)'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
