import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, NullEntityId, today, threeMonthsAgo, DefNgNseExchangeId, tenYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PartnerState } from 'app-model/enums';

const stLists : IListComponentControlList = {
	CmpTradeFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeFeeList',
		entityName: 'CmpTradeFee',
		fetchUriTemplate: 'RefExchangeDef(${exchangeId})/GetPrevailingTradeFees(AtDate=${atDate},InstrType=${enumParamInstrType},FeeName=${searchText})',
		exportUriTemplate: 'RefExchangeDef(${exchangeId})/ExportPrevailingTradeFees',
		orderBy: 'InstrType asc, TradeType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQInstrumentTechnicalInfoTOList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentTechnicalInfoTOList',
		entityName: 'EQInstrumentTechnicalInfoTO',
		keyField: 'InstrumentId',
		fetchUriTemplate: 'RefExchangeDef(${exchangeId})/GetExchTechnicalInfoAtDate(AtDate=${atDate},InstrType=${enumParamInstrType},SearchText=${searchText})',
		exportUriTemplate: 'RefExchangeDef(${exchangeId})/ExportExchTechnicalInfoAtDate',
		orderBy: 'ExchangeCode asc, InstrumentCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			atDate: toIsoDateString(today),
			enumParamInstrType: EnumAllOptionValue,
		},
	},

	EQMarketDealAggrSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketDealAggrSubList',
		entityName: 'EQMarketDealAggr',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/EQMarketDealAggrs',
		orderBy: 'InstrumentCode asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['InstrumentCode', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketEquitySubList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketEquitySubList',
		entityName: 'EQMarketEquity',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/EQMarketEquities',
		orderBy: 'EqName asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['EqCode', 'EqName', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQMarketInfoSheetList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketInfoSheetList',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		orderBy: 'TradeDate desc',
		selectFields: [],
		expandProps: [
			//'EQMarketCapital($select=ASIndex,QuantityTraded,ValueTraded,NoDeals,EquityCap,BondCap,EtfCap,TotalCap)',
			'EQMarketCapital',
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
		searchFields: ['RefExchangeDef/UniqueCode', 'RefExchangeDef/CommonName'],
		filterValues: {
			searchText: '',
			exchangeId: DefNgNseExchangeId,
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	NSETerminalPriceSubList: {
		...fluxListDefaults,
		actionNamespace: 'NSETerminalPriceSubList',
		entityName: 'NSETerminalPrice',
		fetchUriTemplate: 'EQMarketInfoSheet(${subListParentId})/NSETerminalPrices',
		orderBy: 'InstrumentCode asc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: ['InstrumentCode', 'BoardCode', 'EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	ResReportTypeList: {
		...fluxListDefaults,
		actionNamespace: 'ResReportTypeList',
		entityName: 'ResReportType',
		fetchUriTemplate: 'ResReportType',
		orderBy: 'TypeClass asc, TypeCode asc',
		selectFields: ['Id', 'TypeClass', 'TypeCode', 'TypeName', 'IsKnowYourPartner', 'ValidityCheck', 'IssuerType', 'IssuerName'],
		expandProps: [],
		searchFields: ['typecode', 'typename', 'issuername'],
		filterValues: {
			searchText: '',
			typeClass: EnumAllOptionValue,
		},
	},
	ResReportTypePick: {
		...fluxPickDefaults,
		actionNamespace: 'ResReportTypePick',
		entityName: 'ResReportType',
		fetchUriTemplate: 'ResReportType',
		orderBy: 'TypeClass asc, TypeCode asc',
		selectFields: ['Id', 'TypeClass', 'TypeCode', 'TypeName', 'IsKnowYourPartner', 'ValidityCheck', 'IssuerType', 'IssuerName'],
		expandProps: [],
		searchFields: ['typecode', 'typename', 'issuername'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	EQInstrumentView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentView',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		selectFields: [],
		expandProps: [],
	},

	EQMarketInfoSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketInfoSheetView',
		entityName: 'EQMarketInfoSheet',
		fetchUriTemplate: 'EQMarketInfoSheet',
		selectFields: [],
		expandProps: [
			//'EQMarketCapital($select=ASIndex,QuantityTraded,ValueTraded,NoDeals,EquityCap,BondCap,EtfCap,TotalCap)',
			'EQMarketCapital',
			'RefExchangeDef($select=UniqueCode,CommonName)',
		],
	},

	ResCompanyResultView: {
		...fluxViewDefaults,
		actionNamespace: 'ResCompanyResultView',
		entityName: 'ResCompanyResult',
		fetchUriTemplate: 'ResCompanyResult',
		selectFields: [],
		expandProps: [
			'ResCompany($select=CoCode,CoName)'
		],
	},

	ResReportTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'ResReportTypeView',
		entityName: 'ResReportType',
		fetchUriTemplate: 'ResReportType',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
