import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { SecInstrumentState } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, tenYearsAgo, today, twentyYearsAgo } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	BondList: {
		...fluxListDefaults,
		actionNamespace: 'BondList',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		exportUriTemplate: 'Bond/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	BondPick: {
        ...fluxPickDefaults,
		actionNamespace: 'BondPick',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	CmpTradeFeeList: {
		...fluxListDefaults,
		actionNamespace: 'CmpTradeFeeList',
		entityName: 'CmpTradeFee',
		fetchUriTemplate: 'CmpTradeFee/Prevailing',
		exportUriTemplate: 'CmpTradeFee/ExportPrevailing',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			exchangeId: NullEntityId,
			atDate: toIsoDateString(today),
			enumParamInstrumentType: EnumAllOptionValue,
		},
	},

	EQConfigList: {
		...fluxListDefaults,
		actionNamespace: 'EQConfigList',
		entityName: 'EQConfig',
		fetchUriTemplate: 'EQConfig',
		orderBy: 'Id asc',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoShortCode,CoName)',
			'TradeProfitLossCenter($select=CenterCode,CenterName)',
			'PryIssueWalkinPartnerSubClass($select=SubClassCode)',
			'FundPartnerSubClass($select=SubClassCode)',
			'EmployeeSchemePartnerSubClass($select=SubClassCode)',
			'InvestorSchemePartnerSubClass($select=SubClassCode)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			companyId: NullEntityId,
		},
	},

	EQExchangeTradeAcctSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeAcctSubList',
		entityName: 'EQExchangeTradeAcct',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQExchangeTradeAccts',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoOrgType,CoShortCode,CoName)',
			'ClearingAcct($select=AcctNo,AcctTitle)',
			'ProfitLossAcct($select=AcctNo,AcctTitle)',
			'DealerBankAcct($select=AcctNo,AcctTitle)',
			'MakerBankAcct($select=AcctNo,AcctTitle)',
		],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	EQExchangeTradeRuleSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQExchangeTradeRuleSubList',
		entityName: 'EQExchangeTradeRule',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQExchangeTradeRules',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},

	EQIndexList: {
		...fluxListDefaults,
		actionNamespace: 'EQIndexList',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		exportUriTemplate: 'EQIndex/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EQIndexPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQIndexPick',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQInstrumentList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		exportUriTemplate: 'EQInstrument/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentClass: EnumAllOptionValue,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EQInstrumentPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQInstrumentPick',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'EQInstrument',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQInstrumentConvertibleSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentConvertibleSubList',
		entityName: 'EQInstrumentConvertible',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/GetCanConvertTo()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: ['TargetInstrument($select=InstrumentCode,InstrumentName)'],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentFeeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentFeeSubList',
		entityName: 'EQInstrumentFee',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/EQInstrumentFees',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TradeFeeName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
			tradeType: EnumAllOptionValue,
		},
	},

	EQInstrumentMarketMakerSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentMarketMakerSubList',
		entityName: 'EQInstrumentMarketMaker',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/GetMarketMakerForInstrument()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentOldCodeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentOldCodeSubList',
		entityName: 'EQInstrumentOldCode',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/GetOldCodes()',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(twentyYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentOutstandingSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentOutstandingSubList',
		entityName: 'EQInstrumentOutstanding',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/GetInstrumentOutstanding()',
		orderBy: 'FromDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentPriceSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentPriceSubList',
		entityName: 'EQInstrumentPrice',
		fetchUriTemplate: 'EQInstrument(${subListParentId})/GetInstrumentPrices()',
		orderBy: 'ValueStartDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			startDate: toIsoDateString(tenYearsAgo),
			endDate: toIsoDateString(today),
		},
	},

	EQInstrumentSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQInstrumentSubList',
		entityName: 'EQInstrument',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQInstrument',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			closureType: SecInstrumentState.Active,
		},
	},

	EQMarketOperatorList: {
		...fluxListDefaults,
		actionNamespace: 'EQMarketOperatorList',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		exportUriTemplate: 'EQMarketOperator/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	EQMarketOperatorPick: {
		...fluxPickDefaults,
		actionNamespace: 'EQMarketOperatorPick',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		exportUriTemplate: 'EQMarketOperator/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	EQMarketOperatorLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'EQMarketOperatorLocationSubList',
		cmdUriTemplate: 'EQMarketOperator(${subListParentId})/Locations',
		fetchUriTemplate: 'EQMarketOperator(${subListParentId})/Locations',
		exportUriTemplate: 'EQMarketOperator(${subListParentId})/Locations/Export',
	},

	EQMarketOperatorRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'EQMarketOperatorRelationshipSubList',
		cmdUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties',
		exportUriTemplate: 'EQMarketOperator(${subListParentId})/RelatedParties/Export',
	},

	EQTradeFeeList: {
        ...fluxListDefaults,
		actionNamespace: 'EQTradeFeeList',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		orderBy: 'RefExchangeDef/UniqueCode asc, InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
			ExchangeId: NullEntityId,
			InstrType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},
	EQTradeFeePick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQTradeFeePick',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		orderBy: 'InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['TradeFeeName', 'ChargeTo'],
		filterValues: {
			searchText: '',
		},
	},
	EQTradeFeeSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQTradeFeeSubList',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/EQTradeFees',
		orderBy: 'InstrType asc, TradeFeeName asc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			InstrType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
		},
	},

	EquityList: {
		...fluxListDefaults,
		actionNamespace: 'EquityList',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		exportUriTemplate: 'Equity/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			InstrumentType: EnumAllOptionValue, //EQInstrumentTypeEnum.EQUITY,
			ListExchangeId: NullEntityId,
			closureType: SecInstrumentState.Active,
		},
	},
	EquityPick: {
        ...fluxPickDefaults,
		actionNamespace: 'EquityPick',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefExchangeBoardPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefExchangeBoardPick',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefExchangeBoards',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefExchangeBoardSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeBoardSubList',
		entityName: 'RefExchangeBoard',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeBoards',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefExchangeDefList: {
        ...fluxListDefaults,
		actionNamespace: 'RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: NullEntityId,
			exchangeType: EnumAllOptionValue,
			exchangeOperations: 'ResearchReporting',
		},
	},
	RefExchangeDefPick: {
        ...fluxPickDefaults,
		actionNamespace: 'RefExchangeDefPick',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefExchangeDefLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'RefExchangeDefLocationSubList',
		cmdUriTemplate: 'RefExchangeDef(${subListParentId})/Locations',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/Locations',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/Locations/Export',
	},

	RefExchangeDefRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'RefExchangeDefRelationshipSubList',
		cmdUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties',
		exportUriTemplate: 'RefExchangeDef(${subListParentId})/RelatedParties/Export',
	},

	RefExchangeUserSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefExchangeUserSubList',
		entityName: 'RefExchangeUser',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefExchangeUsers',
		orderBy: 'ExchUserName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['ExchUserName','ExchUserRoles'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefSectorDefPick: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${parentId})/RefSectorDefs',
		orderBy: 'SectorCode asc',
		selectFields: [],
		expandProps: ['RefExchangeDef($select=UniqueCode,CommonCode,CommonName)'],
		searchFields: ['SectorCode','SectorName'],
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},
	RefSectorDefSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefSectorDefSubList',
		entityName: 'RefSectorDef',
		fetchUriTemplate: 'RefExchangeDef(${subListParentId})/RefSectorDefs',
		orderBy: 'SectorCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['SectorCode','SectorName'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	BondView: {
		...fluxViewDefaults,
		actionNamespace: 'BondView',
		entityName: 'Bond',
		fetchUriTemplate: 'Bond',
		selectFields: [],
		expandProps: [
			'ListExchange($select=UniqueCode,CommonCode,CommonName)',
			'ListBoard($select=BoardCode,BoardName)',
			'ListSector($select=SectorCode,SectorName)',
			'ResearchSector($select=SectorCode,SectorName)',
			'TradeExchange($select=UniqueCode,CommonCode,CommonName)',
			'TradeBoard($select=BoardCode,BoardName)',
		],
	},

	EQConfigView: {
		...fluxViewDefaults,
		actionNamespace: 'EQConfigView',
		entityName: 'EQConfig',
		fetchUriTemplate: 'EQConfig',
		selectFields: [],
		expandProps: [
			'OrgCompany($select=CoOrgType,CoShortCode,CoName)',
			'TradeProfitLossCenter($select=CenterCode,CenterName)',
			'PryIssueWalkinPartnerSubClass($select=SubClassCode)',
			'FundPartnerSubClass($select=SubClassCode)',
			'EmployeeSchemePartnerSubClass($select=SubClassCode)',
			'InvestorSchemePartnerSubClass($select=SubClassCode)',
			'EQConfigCurrencies('
			+ '$expand=CertificateFeeAcct($select=AcctNo,AcctTitle),TransferFeeAcct($select=AcctNo,AcctTitle)'
			+ ',MgtFeeAcct($select=AcctNo,AcctTitle),BrokerageAcct($select=AcctNo,AcctTitle)'
			+ ',CSCSFeeAccrualAcct($select=AcctNo,AcctTitle),NSEFeeAccrualAcct($select=AcctNo,AcctTitle)'
			+ ',SECFeeAccrualAcct($select=AcctNo,AcctTitle)'
			+ ',PryIssueOfferAcctRule($select=AcctRuleName),PryIssueRightAcctRule($select=AcctRuleName))'
		],
	},

	EQIndexView: {
		...fluxViewDefaults,
		actionNamespace: 'EQIndexView',
		entityName: 'EQIndex',
		fetchUriTemplate: 'EQIndex',
		selectFields: [],
		expandProps: [
			'ListExchange($select=UniqueCode,CommonCode,CommonName)',
			'ListBoard($select=BoardCode,BoardName)',
			'ListSector($select=SectorCode,SectorName)',
			'ResearchSector($select=SectorCode,SectorName)',
			'TradeExchange($select=UniqueCode,CommonCode,CommonName)',
			'TradeBoard($select=BoardCode,BoardName)',
		],
	},

	EQInstrumentPriceView: {
		...fluxViewDefaults,
		actionNamespace: 'EQInstrumentPriceView',
		entityName: 'EQInstrumentPrice',
		fetchUriTemplate: 'EQInstrumentPrice',
		selectFields: [],
		expandProps: [
			'EQInstrument($select=InstrumentCode,InstrumentName;$expand=ListExchange($select=UniqueCode))',
		],
	},

	EQMarketOperatorView: {
		...fluxViewDefaults,
		actionNamespace: 'EQMarketOperatorView',
		entityName: 'EQMarketOperator',
		fetchUriTemplate: 'EQMarketOperator',
		queryUseRestEndpoint: true,
	},

	EQTradeFeeView: {
		...fluxViewDefaults,
		actionNamespace: 'EQTradeFeeView',
		entityName: 'EQTradeFee',
		fetchUriTemplate: 'EQTradeFee',
		selectFields: [],
		expandProps: [
			'RefExchangeDef($select=UniqueCode,CommonCode,CommonName)',
			'EQTradeFeeValues($orderby=StartDate desc,TradeType asc)',
			'EQTradeFeeAccts($orderby=StartDate desc;'
			+ '$expand=OrgCompany($select=CoShortCode,CoName)'
			+ ',IndvAcct($select=AcctNo,AcctTitle),CorpAcct($select=AcctNo,AcctTitle)'
			+ ',DealerAcct($select=AcctNo,AcctTitle),MakerAcct($select=AcctNo,AcctTitle))'
		],
	},

	EquityView: {
		...fluxViewDefaults,
		actionNamespace: 'EquityView',
		entityName: 'Equity',
		fetchUriTemplate: 'Equity',
		selectFields: [],
		expandProps: [
			'ListExchange($select=UniqueCode,CommonCode,CommonName)',
			'ListBoard($select=BoardCode,BoardName)',
			'ListSector($select=SectorCode,SectorName)',
			'ResearchSector($select=SectorCode,SectorName)',
			'TradeExchange($select=UniqueCode,CommonCode,CommonName)',
			'TradeBoard($select=BoardCode,BoardName)',
		],
	},

	RefExchangeDefView: {
		...fluxViewDefaults,
		actionNamespace: 'RefExchangeDefView',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
