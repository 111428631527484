import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { EnumAllOptionValue, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';

import { fluxDocumentSubListDefaults, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, masterListReducer, masterViewReducer } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQCorpActionPryIssueList: {
        ...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueList',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		orderBy: 'IssueOpen desc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId)'],
		searchFields: ['EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionPryIssuePick: {
        ...fluxPickDefaults,
		actionNamespace: 'EQCorpActionPryIssuePick',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		orderBy: 'IssueOpen desc',
		selectFields: [],
		expandProps: ['EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId)'],
		searchFields: ['EQInstrument/InstrumentCode', 'EQInstrument/InstrumentName'],
		filterValues: {
			searchText: '',
		},
	},

	EQCorpActionPryIssueAllotReturnList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnList',
		entityName: 'EQCorpActionPryIssueAllotReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn',
		orderBy: 'DeclareDate desc',
		selectFields: [],
		expandProps: [
			'EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))'
		],
		searchFields: [
			'EQCorpActionPryIssue/EQInstrument/InstrumentCode',
			'EQCorpActionPryIssue/EQInstrument/InstrumentName'
		],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	EQCorpActionPryIssueAllotReturnDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnDocumentSubList',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Documents',
		exportUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Documents(${entityId})/Export',
	},

	EQCorpActionPryIssueRemitList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitList',
		entityName: 'EQCorpActionPryIssueRemit',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit',
		orderBy: 'RemitDate desc',
		selectFields: [],
		expandProps: [
			'EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))'
		],
		searchFields: [
			'EQCorpActionPryIssue/EQInstrument/InstrumentCode',
			'EQCorpActionPryIssue/EQInstrument/InstrumentName'
		],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQCorpActionPryIssueRemitItemSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitItemSubList',
		entityName: 'EQCorpActionPryIssueRemitItem',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/RemitItems',
		exportUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/ExportRemitItems',
		orderBy: 'EQHoldingPryIssue/SubscribeDate desc, EQHoldingPryIssue/ApplicationSurname asc, EQHoldingPryIssue/ApplicationOtherNames asc',
		selectFields: [],
		expandProps: ['EQHoldingPryIssue($expand=Customer($select=TypeCode,PartnerNo,PartnerLabel),EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'],
		searchFields: ['EQHoldingPryIssue/Customer/PartnerNo', 'EQHoldingPryIssue/EQAccount/ExchangeAcctNo', 'EQHoldingPryIssue/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQCorpActionPryIssueRemitDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitDocumentSubList',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/Documents',
		exportUriTemplate: 'EQCorpActionPryIssueRemit(${subListParentId})/Documents(${entityId})/Export',
	},

	EQHoldingPryIssueList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueList',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		orderBy: 'EQCorpAction/IssueOpen desc, ApplicationSurname asc, ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQCorpAction($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))',
			'Customer($select=TypeCode,PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'
		],
		searchFields: [
			'EQCorpAction/EQInstrument/InstrumentCode',
			'EQCorpAction/EQInstrument/InstrumentName',
			'ApplicationSurname',
			'ApplicationOtherNames',
			'Customer/PartnerNo',
			'EQAccount/ExchangeAcctNo',
			'EQAccount/AccountTitle'
		],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssuePick: {
		...fluxPickDefaults,
		actionNamespace: 'EQHoldingPryIssuePick',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		orderBy: 'EQCorpAction/IssueOpen desc, ApplicationSurname asc, ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQCorpAction($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))',
			'Customer($select=TypeCode,PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'
		],
		searchFields: [
			'EQCorpAction/EQInstrument/InstrumentCode',
			'EQCorpAction/EQInstrument/InstrumentName',
			'Customer/PartnerNo',
			'EQAccount/ExchangeAcctNo',
			'EQAccount/AccountTitle'
		],
		filterValues: {
			searchText: ''
		},
	},
	EQHoldingPryIssueSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueSubList',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue(${subListParentId})/EQCorpActionHoldings',
		exportUriTemplate: 'EQCorpActionPryIssue(${subListParentId})/ExportSubscriptions',
		orderBy: 'SubscribeDate desc, ApplicationSurname asc, ApplicationOtherNames asc',
		selectFields: [],
		expandProps: ['Customer($select=TypeCode,PartnerNo,PartnerLabel)', 'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)'],
		searchFields: [
			'ApplicationSurname',
			'ApplicationOtherNames',
			'Customer/PartnerNo',
			'EQAccount/ExchangeAcctNo',
			'EQAccount/AccountTitle'
		],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingPryIssueDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'EQHoldingPryIssueDocumentSubList',
		fetchUriTemplate: 'EQHoldingPryIssue(${subListParentId})/Documents',
		exportUriTemplate: 'EQHoldingPryIssue(${subListParentId})/Documents(${entityId})/Export',
	},

	EQHoldingPryIssueAllotList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueAllotList',
		entityName: 'EQHoldingPryIssueAllot',
		fetchUriTemplate: 'EQHoldingPryIssueAllot',
		orderBy: 'EQHoldingPryIssue/SubscribeDate desc, EQHoldingPryIssue/ApplicationSurname asc, EQHoldingPryIssue/ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQHoldingPryIssue($expand=Customer($select=TypeCode,PartnerNo,PartnerLabel)' 
			+ ',EQCorpAction($expand=EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;'
			+ 				'$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId)))'
			+ ',EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'
		],
		searchFields: ['EQHoldingPryIssue/Customer/PartnerNo', 'EQHoldingPryIssue/EQAccount/ExchangeAcctNo', 'EQHoldingPryIssue/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssueAllotSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueAllotSubList',
		entityName: 'EQHoldingPryIssueAllot',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Allots',
		orderBy: 'EQHoldingPryIssue/SubscribeDate desc, EQHoldingPryIssue/ApplicationSurname asc, EQHoldingPryIssue/ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQHoldingPryIssue($expand=Customer($select=TypeCode,PartnerNo,PartnerLabel)' 
			+ ',EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'
		],
		searchFields: ['EQHoldingPryIssue/Customer/PartnerNo', 'EQHoldingPryIssue/EQAccount/ExchangeAcctNo', 'EQHoldingPryIssue/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	EQHoldingPryIssueReturnList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueReturnList',
		entityName: 'EQHoldingPryIssueReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Returns',
		orderBy: 'EQHoldingPryIssue/SubscribeDate desc, EQHoldingPryIssue/ApplicationSurname asc, EQHoldingPryIssue/ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQHoldingPryIssue($expand=Customer($select=TypeCode,PartnerNo,PartnerLabel)' 
			+ ',EQCorpAction($expand=EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;'
			+ 				'$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId)))'
			+ ',EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'
		],
		searchFields: ['EQHoldingPryIssue/Customer/PartnerNo', 'EQHoldingPryIssue/EQAccount/ExchangeAcctNo', 'EQHoldingPryIssue/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			instrumentType: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},
	EQHoldingPryIssueReturnSubList: {
		...fluxListDefaults,
		actionNamespace: 'EQHoldingPryIssueReturnSubList',
		entityName: 'EQHoldingPryIssueReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn(${subListParentId})/Returns',
		orderBy: 'EQHoldingPryIssue/SubscribeDate desc, EQHoldingPryIssue/ApplicationSurname asc, EQHoldingPryIssue/ApplicationOtherNames asc',
		selectFields: [],
		expandProps: [
			'EQHoldingPryIssue($expand=Customer($select=TypeCode,PartnerNo,PartnerLabel)' 
			+ ',EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle))'
		],
		searchFields: ['EQHoldingPryIssue/Customer/PartnerNo', 'EQHoldingPryIssue/EQAccount/ExchangeAcctNo', 'EQHoldingPryIssue/EQAccount/AccountTitle'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	EQCorpActionPryIssueView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueView',
		entityName: 'EQCorpActionPryIssue',
		fetchUriTemplate: 'EQCorpActionPryIssue',
		selectFields: [],
		expandProps: [
			'EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId)',
			'CollatingCompany($select=CoShortCode,CoName)',
			'GlAccts($expand=OrgCompany($select=CoShortCode,CoName)'
			+ ',OfferAcct($select=AcctNo,AcctTitle),OfferWalkInCustomer($select=PartnerNo,PartnerLabel)'
			+ ',RightAcct($select=AcctNo,AcctTitle),RightWalkInCustomer($select=PartnerNo,PartnerLabel)'
			+ ',ChargesAcct($select=AcctNo,AcctTitle),PaymentDiffAcct($select=AcctNo,AcctTitle))',
		],
	},
	
	EQCorpActionPryIssueAllotReturnView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueAllotReturnView',
		entityName: 'EQCorpActionPryIssueAllotReturn',
		fetchUriTemplate: 'EQCorpActionPryIssueAllotReturn',
		selectFields: [],
		expandProps: [
			'EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))',
			'Slabs',
		],
	},

	EQCorpActionPryIssueRemitView: {
		...fluxViewDefaults,
		actionNamespace: 'EQCorpActionPryIssueRemitView',
		entityName: 'EQCorpActionPryIssueRemit',
		fetchUriTemplate: 'EQCorpActionPryIssueRemit',
		selectFields: [],
		expandProps: [
			'EQCorpActionPryIssue($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))',
		],
	},

	EQHoldingPryIssueView: {
		...fluxViewDefaults,
		actionNamespace: 'EQHoldingPryIssueView',
		entityName: 'EQHoldingPryIssue',
		fetchUriTemplate: 'EQHoldingPryIssue',
		selectFields: [],
		expandProps: [
			'EQCorpAction($select=ExDivDate,PryIssueTypeCode,IssueOpen,IssueClose;$expand=EQInstrument($select=InstrumentCode,InstrumentName,InstrumentType,Currency,ListExchangeId))',
			'Customer($select=PartnerNo,PartnerLabel)',
			'EQAccount($select=InternalAcctNo,ExchangeAcctNo,AccountTitle)',
			'Broker($select=OperatorNo,OperatorCode,OperatorLabel)',
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
