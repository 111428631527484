import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { PartnerState, CurrencyCodes } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo, oneYearFuture } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxPartnerLocationSubListDefaults, fluxPartnerRelationshipSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	CommissionGroupList: {
		...fluxListDefaults,
		actionNamespace: 'CommissionGroupList',
		entityName: 'CommissionGroup',
		fetchUriTemplate: 'CommissionGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			closureType: 'Approved',
		},
	},
	CommissionGroupPick: {
		...fluxPickDefaults,
		actionNamespace: 'CommissionGroupPick',
		entityName: 'CommissionGroup',
		fetchUriTemplate: 'CommissionGroup',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAFinHouseList: {
		...fluxListDefaults,
		actionNamespace: 'FAFinHouseList',
		entityName: 'FAFinHouse',
		fetchUriTemplate: 'FAFinHouse',
		exportUriTemplate: 'FAFinHouse/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			classCode: EnumAllOptionValue,
			closureType: PartnerState.Active,
		},
	},
	FAFinHousePick: {
		...fluxPickDefaults,
		actionNamespace: 'FAFinHousePick',
		entityName: 'FAFinHouse',
		fetchUriTemplate: 'FAFinHouse',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	FAFinHouseLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'FAFinHouseLocationSubList',
		cmdUriTemplate: 'FAFinHouse(${subListParentId})/Locations',
		fetchUriTemplate: 'FAFinHouse(${subListParentId})/Locations',
		exportUriTemplate: 'FAFinHouse(${subListParentId})/Locations/Export',
	},

	FAFinHouseRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'FAFinHouseRelationshipSubList',
		cmdUriTemplate: 'FAFinHouse(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'FAFinHouse(${subListParentId})/RelatedParties',
		exportUriTemplate: 'FAFinHouse(${subListParentId})/RelatedParties/Export',
	},

	KeyValueList: {
		...fluxListDefaults,
		actionNamespace: 'KeyValueList',
		entityName: 'KeyValueList',
		fetchUriTemplate: 'KeyValueList',
		orderBy: 'listname asc',
		selectFields: ['Id', 'ListName'],
		expandProps: [],
		searchFields: ['listname'],
		filterValues: {
			searchText: '',
		},
	},

	RefCentralBankList: {
		...fluxListDefaults,
		actionNamespace: 'RefCentralBankList',
		entityName: 'RefCentralBank',
		fetchUriTemplate: 'RefCentralBank',
		exportUriTemplate: 'RefCentralBank/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			country: NullEntityId,
		},
	},
	RefCentralBankPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefCentralBankPick',
		entityName: 'RefCentralBank',
		fetchUriTemplate: 'RefCentralBank',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefCentralBankLocationSubList: {
		...fluxPartnerLocationSubListDefaults,
		actionNamespace: 'RefCentralBankLocationSubList',
		cmdUriTemplate: 'RefCentralBank(${subListParentId})/Locations',
		fetchUriTemplate: 'RefCentralBank(${subListParentId})/Locations',
		exportUriTemplate: 'RefCentralBank(${subListParentId})/Locations/Export',
	},

	RefCentralBankRelationshipSubList: {
		...fluxPartnerRelationshipSubListDefaults,
		actionNamespace: 'RefCentralBankRelationshipSubList',
		cmdUriTemplate: 'RefCentralBank(${subListParentId})/RelatedParties',
		fetchUriTemplate: 'RefCentralBank(${subListParentId})/RelatedParties',
		exportUriTemplate: 'RefCentralBank(${subListParentId})/RelatedParties/Export',
	},

	RefCountryList: {
		...fluxListDefaults,
		actionNamespace: 'RefCountryList',
		entityName: 'RefCountry',
		fetchUriTemplate: 'RefCountry',
		exportUriTemplate: 'RefCountry/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
	RefCountryPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefCountryPick',
		entityName: 'RefCountry',
		fetchUriTemplate: 'RefCountry',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},

	RefCurrencyExchRateList: {
		...fluxListDefaults,
		actionNamespace: 'RefCurrencyExchRateList',
		entityName: 'RefCurrencyExchRate',
		fetchUriTemplate: 'RefCurrencyExchRate',
		exportUriTemplate: 'RefCurrencyExchRate/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			baseCurrency: CurrencyCodes.USD,
			floatingCurrency: CurrencyCodes.NGN,
		},
	},

	RefHolidayList: {
		...fluxListDefaults,
		actionNamespace: 'RefHolidayList',
		entityName: 'RefHoliday',
		fetchUriTemplate: 'RefHoliday',
		exportUriTemplate: 'RefHoliday/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(oneYearFuture),
		},
	},

	RefStateSubList: {
		...fluxListDefaults,
		actionNamespace: 'RefStateSubList',
		entityName: 'RefState',
		fetchUriTemplate: 'RefCountry(${subListParentId})/StateDivisions',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	RefTaxList: {
		...fluxListDefaults,
		actionNamespace: 'RefTaxList',
		entityName: 'RefTax',
		fetchUriTemplate: 'RefTax',
		orderBy: 'Country asc, TaxCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TaxCode','TaxName'],
		filterValues: {
			searchText: '',
			country: NullEntityId,
		},
	},
	RefTaxPick: {
		...fluxPickDefaults,
		actionNamespace: 'RefTaxPick',
		entityName: 'RefTax',
		fetchUriTemplate: 'RefTax',
		orderBy: 'Country asc, TaxCode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['TaxCode','TaxName'],
		filterValues: {
			searchText: '',
		},
	},

	RefTaxDateList: {
		...fluxListDefaults,
		actionNamespace: 'RefTaxDateList',
		entityName: 'RefTaxDate',
		fetchUriTemplate: 'RefTaxDate',
		orderBy: 'Country asc, TaxCode asc, StartDate desc',
		selectFields: [],
		expandProps: ['RefTax'],
		searchFields: ['RefTax/TaxCode', 'RefTax/TaxName'],
		filterValues: {
			searchText: '',
			country: NullEntityId,
			closureType: EnumAllOptionValue,
		},
	},

	SimpleValueList: {
		...fluxListDefaults,
		actionNamespace: 'SimpleValueList',
		entityName: 'SimpleValueList',
		fetchUriTemplate: 'SimpleValueList',
		orderBy: 'listname asc',
		selectFields: ['Id', 'ListName'],
		expandProps: [],
		searchFields: ['listname'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	CommissionGroupView: {
		...fluxViewDefaults,
		actionNamespace: 'CommissionGroupView',
		entityName: 'CommissionGroup',
		fetchUriTemplate: 'CommissionGroup',
		selectFields: [],
		expandProps: ['CommissionSlabs'],
	},

	FAFinHouseView: {
		...fluxViewDefaults,
		actionNamespace: 'FAFinHouseView',
		entityName: 'FAFinHouse',
		fetchUriTemplate: 'FAFinHouse',
		queryUseRestEndpoint: true,
	},

	KeyValueView: {
		...fluxViewDefaults,
		actionNamespace: 'KeyValueView',
		entityName: 'KeyValueList',
		fetchUriTemplate: 'KeyValueList',
		selectFields: [],
		expandProps: [],
	},

	RefCentralBankView: {
		...fluxViewDefaults,
		actionNamespace: 'RefCentralBankView',
		entityName: 'RefCentralBank',
		fetchUriTemplate: 'RefCentralBank',
		queryUseRestEndpoint: true,
	},

	RefCountryView: {
		...fluxViewDefaults,
		actionNamespace: 'RefCountryView',
		entityName: 'RefCountry',
		fetchUriTemplate: 'RefCountry',
		selectFields: [],
		expandProps: ['RefStates'],
	},
	
	RefTaxDateView: {
		...fluxViewDefaults,
		actionNamespace: 'RefTaxDateView',
		entityName: 'RefTaxDate',
		fetchUriTemplate: 'RefTaxDate',
		selectFields: [],
		expandProps: ['RefTax', 'RefTaxSlabs'],
	},

	SimpleValueView: {
		...fluxViewDefaults,
		actionNamespace: 'SimpleValueView',
		entityName: 'SimpleValueList',
		fetchUriTemplate: 'SimpleValueList',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}


export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
