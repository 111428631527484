import { combineReducers } from 'redux';

import capitalmkt from './capitalmkt/_Reducers';
import company from './company/_Reducers';
import macro from './macro/_Reducers';
import moneymkt from './moneymkt/_Reducers';
import news from './news/_Reducers';
import recommendations from './recommendations/_Reducers';

export default combineReducers({
	capitalmkt,
	company,
	macro,
	moneymkt,
    news,
	recommendations,
});
