import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { JournalClosure } from 'app-model/enums';
import { PxWorkflowAssignmentFilter, PxWorkflowStateFilter } from 'app-model/enums';
import { EnumAllOptionValue, NullEntityId, today, oneMonthAgo, threeMonthsAgo, oneYearAgo, oneYearFuture } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults, fluxPxWorkflowHistorySubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	EQWarrantPostBatchList: {
		...fluxListDefaults,
		actionNamespace: 'EQWarrantPostBatchList',
        entityName: 'EQWarrantPostBatch',
		fetchUriTemplate: 'EQWarrantPostBatch',
		orderBy: 'BatchDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: ['BatchCode', 'BatchDescription'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			closureType: JournalClosure.Pending,
		},
	},

	PartnerCashSwapList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerCashSwapList',
        entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap/GetAllWrkflwCashSwaps(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'SwapCurrency', 'SwapRateRequested', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: ['Partner($select=PartnerNo,PartnerLabel)'],
		searchFields: ['Description', 'VoucherNo', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	PartnerCashSwapDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'PartnerCashSwapDocumentSubList',
		fetchUriTemplate: 'PartnerCashSwap(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerCashSwap(${subListParentId})/Documents(${entityId})/Export',
	},

	PartnerCashSwapWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'PartnerCashSwapWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerCashSwap(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'PartnerCashSwap(${subListParentId})/ExportWorkflowHistory',
	},

	PartnerPaymentList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerPaymentList',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment/GetAllWrkflwPayments(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerPayment/ExportAllWrkflwPayments',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	PartnerPaymentPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerPaymentPick',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	PartnerPaymentDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'PartnerPaymentDocumentSubList',
		fetchUriTemplate: 'PartnerPayment(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerPayment(${subListParentId})/Documents(${entityId})/Export',
	},

	PartnerPaymentWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'PartnerPaymentWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerPayment(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'PartnerPayment(${subListParentId})/ExportWorkflowHistory',
	},

	PartnerReceiptList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerReceiptList',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt/GetAllWrkflwReceipts(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'PartnerReceipt/ExportAllWrkflwReceipts',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},
	PartnerReceiptPick: {
		...fluxPickDefaults,
		actionNamespace: 'PartnerReceiptPick',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'TransactionReference', 'Amount', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Description', 'VoucherNo', 'TransactionReference', 'Partner/PartnerNo', 'Partner/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	PartnerReceiptDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'PartnerReceiptDocumentSubList',
		fetchUriTemplate: 'PartnerReceipt(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerReceipt(${subListParentId})/Documents(${entityId})/Export',
	},

	PartnerReceiptWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'PartnerReceiptWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerReceipt(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'PartnerReceipt(${subListParentId})/ExportWorkflowHistory',
	},

	PartnerTransferList: {
		...fluxListDefaults,
		actionNamespace: 'PartnerTransferList',
        entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer/GetAllWrkflwTransfers(StartDate=${startDate},EndDate=${endDate},Assignment=${enumParamAssignment},ClosureType=${enumParamClosureType})',
		orderBy: 'InitiateDate desc',
		selectFields: ['Id', 'IsFinanceEntry', 'Channel', 'InitiateDate', 'ValueDate', 'Description', 'VoucherNo', 'Currency', 'CurrencyRate', 'CurrentStatusDate', 'CurrentStatusCode', 'ClosureType'],
		expandProps: [
			'Transferor($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['Description', 'VoucherNo', 'Transferor/PartnerNo', 'Transferor/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeMonthsAgo),
			endDate: toIsoDateString(today),
			enumParamAssignment: PxWorkflowAssignmentFilter.POSSIBLY_ME,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	PartnerTransferDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'PartnerTransferDocumentSubList',
		fetchUriTemplate: 'PartnerTransfer(${subListParentId})/Documents',
		exportUriTemplate: 'PartnerTransfer(${subListParentId})/Documents(${entityId})/Export',
	},

	PartnerTransferWorkflowHistorySubList: {
		...fluxPxWorkflowHistorySubListDefaults,
		actionNamespace: 'PartnerTransferWorkflowHistorySubList',
		fetchUriTemplate: 'PartnerTransfer(${subListParentId})/GetWorkflowHistory',
		exportUriTemplate: 'PartnerTransfer(${subListParentId})/ExportWorkflowHistory()',
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	PartnerCashSwapView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerCashSwapView',
		entityName: 'PartnerCashSwap',
		fetchUriTemplate: 'PartnerCashSwap',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'SwapCompany($select=CoShortCode,CoName,CoOrgType)',
			'CollectAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'PayoutAcct($select=AcctNo,AcctTitle,OrgCompanyId)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	PartnerPaymentView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerPaymentView',
		entityName: 'PartnerPayment',
		fetchUriTemplate: 'PartnerPayment',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	PartnerReceiptView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerReceiptView',
		entityName: 'PartnerReceipt',
		fetchUriTemplate: 'PartnerReceipt',
		selectFields: [],
		expandProps: [
			'Partner($select=PartnerNo,PartnerLabel)',
			'ContraCompany($select=CoShortCode,CoName,CoOrgType)',
			'ContraAcct($select=AcctNo,AcctTitle,OrgCompanyId;$expand=OrgCompany($select=CoShortCode,CoName))',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
		],
	},

	PartnerTransferView: {
		...fluxViewDefaults,
		actionNamespace: 'PartnerTransferView',
		entityName: 'PartnerTransfer',
		fetchUriTemplate: 'PartnerTransfer',
		selectFields: [],
		expandProps: [
			'Transferor($select=PartnerNo,PartnerLabel)',
			'CurrentAssignedUnit($select=UnitCode,UnitName)',
			'CurrentAssignee($select=StaffNo,StaffName)',
			'PartnerTransferEntries($expand=Transferee($select=PartnerNo,PartnerLabel))'
		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
