import { sygnet } from './sygnet'
import { logomd } from './logomd'
import { logosm } from './logosm'

export const logoIcons = Object.assign(
	{}, 
	{
		sygnet,
		logomd,
		logoNegative: logomd,
		logosm,
	}
);
