import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';

import { FiTradeClosure } from 'app-model/enums';
import { DefCountryCode, EnumAllOptionValue, NullEntityId, oneYearAgo, threeYearsAgo, today } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults, fluxDocumentSubListDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';
import { PxWorkflowStateFilter } from 'app-model/enums';

const stLists : IListComponentControlList = {
	MMOrderList: {
		...fluxListDefaults,
		actionNamespace: 'MMOrderList',
        entityName: 'MMOrder',
		fetchUriTemplate: 'MMOrder/GetMMOrderForRange(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},Currency=${enumParamCurrency},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'MMOrder/ExportMMOrderForRange',
		orderBy: 'OrderDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	MMOrderDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'MMOrderDocumentSubList',
		fetchUriTemplate: 'MMOrder(${subListParentId})/Documents',
		exportUriTemplate: 'MMOrder(${subListParentId})/Documents(${entityId})/Export',
	},

	MMOrderJobPick: {
		...fluxPickDefaults,
		actionNamespace: 'MMOrderJobPick',
		entityName: 'MMOrderJob',
		fetchUriTemplate: 'MMSheet(${parentId})/OrderJobs',
		orderBy: 'Order/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Order($expand=Customer($select=PartnerNo,PartnerLabel))',
			'JobDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'JobCompany($select=CoShortCode,CoName)',
		],
		searchFields: ['Order/Customer/PartnerNo', 'Order/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},

	MMOrderJobSubList: {
		...fluxPickDefaults,
		actionNamespace: 'MMOrderJobSubList',
		entityName: 'MMOrderJob',
		fetchUriTemplate: 'MMSheet(${subListParentId})/OrderJobs',
		orderBy: 'Order/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Order($expand=Customer($select=PartnerNo,PartnerLabel))',
			'JobDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'JobCompany($select=CoShortCode,CoName)',
		],
		searchFields: ['Order/Customer/PartnerNo', 'Order/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	MMPlacementBookSubList: {
		...fluxPickDefaults,
		actionNamespace: 'MMPlacementBookSubList',
		entityName: 'MMPlacement',
		fetchUriTemplate: 'MMSheet(${subListParentId})/MMPlacements',
		printUriTemplate: 'MMPlacement/PRINT',
		orderBy: 'Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'PlacementNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	MMPlacementExitSubList: {
		...fluxPickDefaults,
		actionNamespace: 'MMPlacementExitSubList',
		entityName: 'MMPlacement',
		fetchUriTemplate: 'MMSheet(${subListParentId})/GetMMPlacementExitingForSheet(ExitType=${enumParamExitType})',
		orderBy: 'Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'PlacementNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			closureType: EnumAllOptionValue,
		},
	},

	MMPlacementList: {
		...fluxListDefaults,
		actionNamespace: 'MMPlacementList',
		entityName: 'MMPlacement',
		fetchUriTemplate: 'MMPlacement',
		printUriTemplate: 'MMPlacement/PRINT',
		orderBy: 'StartDate desc',
		selectFields: ['Id', 'PlacementNo', 'AllotMode', 'AllotTreatment', 'StartDate', 'Tenure', 'MaturityDate', 'FaceValue', 'InterestRate', 'ExitConsideration', 'ExitDate', 'ClosureType'],
		expandProps: ['Customer($select=PartnerNo,PartnerLabel)', 'Warehouse($select=PartnerNo,PartnerLabel)'],
		searchFields: ['AllotNotes', 'PlacementNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			country: EnumAllOptionValue,
			currency: EnumAllOptionValue,
			closureType: FiTradeClosure.Running,
		},
	},
	
	MMPlacementWarehouseSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'MMPlacementWarehouseSummarySubList',
		entityName: 'MMPlacement',
		fetchUriTemplate: 'MMSheet(${subListParentId})/WarehouseTranSummary',
		exportUriTemplate: 'MMSheet(${subListParentId})/WarehouseTranSummary/Export',
		keyField: 'WarehouseId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
		},
	},

	MMSheetList: {
		...fluxListDefaults,
		actionNamespace: 'MMSheetList',
        entityName: 'MMSheet',
		fetchUriTemplate: 'MMSheet',
		orderBy: 'AuctionDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			country: DefCountryCode,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	TRTBillDealerList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillDealerList',
		entityName: 'TRTBillDealer',
		fetchUriTemplate: 'TRTBillDealer',
		orderBy: 'FAFinHouse/FinHouseCode asc',
		selectFields: [],
		expandProps: ['FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)'],
		searchFields: ['country', 'FAFinHouse/FinHouseNo', 'FAFinHouse/FinHouseCode', 'FAFinHouse/FinHouseName'],
		filterValues: {
			searchText: '',
		},
	},
	TRTBillDealerPick: {
		...fluxPickDefaults,
		actionNamespace: 'TRTBillDealerPick',
		entityName: 'TRTBillDealer',
		fetchUriTemplate: 'TRTBillDealer',
		orderBy: 'FAFinHouse/FinHouseCode asc',
		selectFields: [],
		expandProps: ['FAFinHouse($select=FinHouseNo,FinHouseCode,FinHouseName)'],
		searchFields: ['country', 'FAFinHouse/FinHouseNo', 'FAFinHouse/FinHouseCode', 'FAFinHouse/FinHouseName'],
		filterValues: {
			searchText: '',
		},
	},

	TRTBillOrderList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillOrderList',
		entityName: 'TRTBillOrder',
		fetchUriTemplate: 'TRTBillOrder/GetTRTBillOrderForRange(StartDate=${startDate},EndDate=${endDate},Channel=${enumParamChannel},OrderType=${enumParamOrderType},Currency=${enumParamCurrency},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'TRTBillOrder/ExportTRTBillOrderForRange',
		orderBy: 'OrderDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			'TRTBillType($select=TypeCode,Country,Currency)',
		],
		searchFields: ['Customer/PartnerNo', 'Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			startDate: toIsoDateString(threeYearsAgo),
			endDate: toIsoDateString(today),
			enumParamChannel: EnumAllOptionValue,
			enumParamOrderType: EnumAllOptionValue,
			enumParamCurrency: EnumAllOptionValue,
			enumParamClosureType: PxWorkflowStateFilter.ACTIVE,
		},
	},

	TRTBillOrderDocumentSubList: {
		...fluxDocumentSubListDefaults,
		actionNamespace: 'TRTBillOrderDocumentSubList',
		fetchUriTemplate: 'TRTBillOrder(${subListParentId})/Documents',
		exportUriTemplate: 'TRTBillOrder(${subListParentId})/Documents(${entityId})/Export',
	},

	TRTBillOrderJobPick: {
		...fluxPickDefaults,
		actionNamespace: 'TRTBillOrderJobPick',
		entityName: 'TRTBillOrderJob',
		fetchUriTemplate: 'TRTBillSheet(${parentId})/OrderJobs',
		orderBy: 'Order/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Order($expand=Customer($select=PartnerNo,PartnerLabel),TRTBillType($select=TypeCode,Country,Currency))',
			'JobBillType($select=TypeCode)',
			'JobDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'JobCompany($select=CoShortCode,CoName)',
		],
		searchFields: ['Order/Customer/PartnerNo', 'Order/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			parentId: -1,
		},
	},

	TRTBillOrderJobSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillOrderJobSubList',
		entityName: 'TRTBillOrderJob',
		fetchUriTemplate: 'TRTBillSheet(${subListParentId})/OrderJobs',
		orderBy: 'Order/Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Order($expand=Customer($select=PartnerNo,PartnerLabel),TRTBillType($select=TypeCode,Country,Currency))',
			'JobBillType($select=TypeCode,Country,Currency)',
			'JobDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'JobCompany($select=CoShortCode,CoName)',
		],
		searchFields: ['Order/Customer/PartnerNo', 'Order/Customer/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			tbillTypeId: NullEntityId,
			allotTreatment: EnumAllOptionValue,
			allotMode: EnumAllOptionValue,
			validationCode: EnumAllOptionValue,
		},
	},

	TRTBillSheetList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillSheetList',
        entityName: 'TRTBillSheet',
		fetchUriTemplate: 'TRTBillSheet',
		orderBy: 'AuctionDate desc',
		selectFields: [],
		expandProps: [],
		searchFields: [],
		filterValues: {
			searchText: '',
			country: DefCountryCode,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			closureType: EnumAllOptionValue,
		},
	},

	TRTBillTranBookSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranBookSubList',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillSheet(${subListParentId})/TRTBillTrans',
		exportUriTemplate: 'TRTBillSheet(${subListParentId})/ExportTRTBillTranForSheet',
		printUriTemplate: 'TRTBillTran/PRINT',
		orderBy: 'StartDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)', 
			'TRTBillType($select=TypeCode,Country,Currency)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'BillTranNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			tbillTypeId: NullEntityId,
			allotMode: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	TRTBillTranExitSubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranExitSubList',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillSheet(${subListParentId})/GetTRTBillTranExitingForSheet(ExitType=${enumParamExitType})',
		exportUriTemplate: 'TRTBillSheet(${subListParentId})/ExportTRTBillTranExitingForSheet',
		printUriTemplate: 'TRTBillTran/PRINT',
		orderBy: 'StartDate desc, Customer/PartnerLabel asc',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)', 
			'TRTBillType($select=TypeCode,Country,Currency)',
			'TRTBillDealer($select=Country,Currency;$expand=FAFinHouse($select=FinHouseNo,FinHouseName))',
			'TranCompany($select=CoShortCode,CoName)',
			'Warehouse($select=PartnerNo,PartnerLabel)',
		],
		searchFields: ['AllotNotes', 'BillTranNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			subListParentId: -1,
			tbillTypeId: NullEntityId,
			enumParamExitType: EnumAllOptionValue,
			allotTreatment: EnumAllOptionValue,
			closureType: EnumAllOptionValue,
		},
	},

	TRTBillTranExitingPick: {
		...fluxPickDefaults,
		actionNamespace: 'TRTBillTranExitingPick',
        entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillTran',
		orderBy: 'StartDate desc, Customer/PartnerLabel asc',
		selectFields: ['Id', 'BillTranNo', 'AllotMode', 'AllotTreatment', 'StartDate', 'Tenure', 'MaturityDate', 'FaceValue', 'DiscountRate', 'ExitConsideration', 'ExitDate','ClosureType'],
		expandProps: [
			// 'Customer($select=PartnerNo,PartnerLabel)', 
			// 'TRTBillType($select=TypeCode,Country,Currency)',
			// 'Warehouse($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['AllotNotes', 'BillTranNo'], //, 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
		},
	},

	TRTBillTranList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranList',
        entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillTran/GetTRTBillTranForRange(StartDate=${startDate},EndDate=${endDate},TBillTypeId=${tBillTypeId},ClosureType=${enumParamClosureType})',
		exportUriTemplate: 'TRTBillTran/ExportTRTBillTranForRange',
		printUriTemplate: 'TRTBillTran/PRINT',
		orderBy: 'StartDate desc, Customer/PartnerLabel asc',
		selectFields: ['Id', 'CustomerId', 'BillTranNo', 'AllotMode', 'AllotTreatment', 'StartDate', 'Tenure', 'MaturityDate', 'FaceValue', 'DiscountRate', 'ExitConsideration', 'ExitDate','ClosureType'],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel)', 
			'TRTBillType($select=TypeCode,Country,Currency)',
			'Warehouse($select=PartnerNo,PartnerLabel)'
		],
		searchFields: ['AllotNotes', 'BillTranNo', 'Customer/PartnerNo', 'Customer/PartnerLabel', 'Warehouse/PartnerNo', 'Warehouse/PartnerLabel'],
		filterValues: {
			searchText: '',
			tBillTypeId: NullEntityId,
			startDate: toIsoDateString(oneYearAgo),
			endDate: toIsoDateString(today),
			enumParamClosureType: FiTradeClosure.Running,
		},
	},
	
	TRTBillTranWarehouseSummarySubList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTranWarehouseSummarySubList',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillSheet(${subListParentId})/WarehouseTranSummary',
		exportUriTemplate: 'TRTBillSheet(${subListParentId})/WarehouseTranSummary/Export',
		keyField: 'WarehouseId',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
			subListParentId: -1,
			tBillTypeId: NullEntityId,
		},
	},

	TRTBillTypeList: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTypeList',
		entityName: 'TRTBillType',
		fetchUriTemplate: 'TRTBillType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'country', 'cenbankcode', 'cenbankname'],
		filterValues: {
			searchText: '',
			typeClass: EnumAllOptionValue,
		},
	},
	TRTBillTypePick: {
		...fluxListDefaults,
		actionNamespace: 'TRTBillTypePick',
		entityName: 'TRTBillType',
		fetchUriTemplate: 'TRTBillType',
		orderBy: 'typecode asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['typecode', 'country', 'cenbankcode', 'cenbankname'],
		filterValues: {
			searchText: '',
		},
    },
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MMOrderView: {
		...fluxViewDefaults,
		actionNamespace: 'MMOrderView',
		entityName: 'MMOrder',
		fetchUriTemplate: 'MMOrder',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'EQSalesAgent',
			'ExitTrans',
		],
	},

	MMPlacementView: {
		...fluxViewDefaults,
		actionNamespace: 'MMPlacementView',
		entityName: 'MMPlacement',
		fetchUriTemplate: 'MMPlacement',
		printUriTemplate: 'MMPlacement/PRINT',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'EQSalesAgent',
		],
	},

	MMSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'MMSheetView',
		entityName: 'MMSheet',
		fetchUriTemplate: 'MMSheet',
		selectFields: [],
		expandProps: [
			//'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'EQSalesAgent',
		],
	},

	TRTBillDealerView: {
		...fluxViewDefaults,
		actionNamespace: 'TRTBillDealerView',
		entityName: 'TRTBillDealer',
		fetchUriTemplate: 'TRTBillDealer',
		selectFields: [],
		expandProps: [
			'TRTBillDealerAccts($expand=OrgCompany($select=CoShortCode,CoName),' 
			+ 'DefChargeAccrualAcct($select=AcctNo,AcctTitle),DefBillTranAcct($select=AcctNo,AcctTitle),'
			+ 'OwnCommAcct($select=AcctNo,AcctTitle);$orderBy=OrgCompany/CoShortCode asc)',
			'FAFinHouse($select=FinHouseCode,FinHouseName)'
		],
	},

	TRTBillOrderView: {
		...fluxViewDefaults,
		actionNamespace: 'TRTBillOrderView',
		entityName: 'TRTBillOrder',
		fetchUriTemplate: 'TRTBillOrder',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			'TRTBillType($select=TypeCode,Country,Currency)',
			'EQSalesAgent($select=AgentNo,AgentCode,AgentName)',
			'ExitTrans'
		],
	},

	TRTBillSheetView: {
		...fluxViewDefaults,
		actionNamespace: 'TRTBillSheetView',
		entityName: 'TRTBillSheet',
		fetchUriTemplate: 'TRTBillSheet',
		selectFields: [],
		expandProps: [
			//'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			//'EQSalesAgent',
		],
	},

	TRTBillTranView: {
		...fluxViewDefaults,
		actionNamespace: 'TRTBillTranView',
		entityName: 'TRTBillTran',
		fetchUriTemplate: 'TRTBillTran',
		printUriTemplate: 'TRTBillTran/PRINT',
		selectFields: [],
		expandProps: [
			'Customer($select=PartnerNo,PartnerLabel;$expand=OrgCompany($select=CoShortCode,CoName))',
			'TRTBillType($select=TypeCode,Country,Currency)',
			//'EQSalesAgent',
		],
	},

	TRTBillTypeView: {
		...fluxViewDefaults,
		actionNamespace: 'TRTBillTypeView',
		entityName: 'TRTBillType',
		fetchUriTemplate: 'TRTBillType',
		selectFields: [],
		expandProps: [

		],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
