import { combineReducers } from 'redux';

import { IFluxAction } from 'app-model/IFluxAction';

import { EnumAllOptionValue, NullEntityId } from 'app-model/ModelConstants';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';

const stLists : IListComponentControlList = {
	MyStaffPayList: {
		...fluxListDefaults,
		actionNamespace: 'MyStaffPayList',
		entityName: 'HRStaffPay',
		fetchUriTemplate: 'Me/PaySlip',
		exportUriTemplate: 'Me/PaySlip/Export',
		queryUseRestEndpoint: true,
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}

const stViews : IViewComponentControlList = {
	MyStaffPayView: {
		...fluxViewDefaults,
		actionNamespace: 'MyStaffPayView',
		entityName: 'HRStaffPay',
		fetchUriTemplate: 'Me/PaySlip(${entityId})',
		exportUriTemplate: 'Me/PaySlip(${entityId})/Export',
		printUriTemplate: 'Me/PaySlip(${entityId})/Print',
		queryUseRestEndpoint: true,
		fetchUriComposited: true,
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}

export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
