import {
	cilActionUndo,
	cilAddressBook,
	cilAlignCenter,
	cilAlignLeft,
	cilAlignRight,
	cilApplicationsSettings,
	cilArrowBottom,
	cilArrowRight,
	cilArrowTop,
	cilAsterisk,
	cilBadge,
	cilBan,
	cilBarChart,
	cilBasket,
	cilBell,
	cilBike,
	cilBold,
	cilBook,
	cilBookmark,
	cilBriefcase,
	cilBug,
	cilCalculator,
	cilCalendar,
	cilCash,
	cilClipboard,
	cilClock,
	cilClone,
	cilCloudDownload,
	cilChart,
	cilChartLine,
	cilChartPie,
	cilChatBubble,
	cilCheck,
	cilCheckAlt,
	cilCheckCircle,
	cilChevronBottom,
	cilChevronLeft,
	cilChevronRight,
	cilChevronTop,
	cilCircle,
	cilCode,
	cilCog,
	cilCommentSquare,
	cilCompass,
	cilCompress,
	cilContact,
	cilCopy,
	cilCreditCard,
	cilCursor,
	cilCursorMove,
	cilCut,
	cilDescription,
	cilDevices,
	cilDrop,
	cilDollar,
	cilEnvelopeClosed,
	cilEnvelopeLetter,
	cilEnvelopeOpen,
	cilEuro,
	cilEyedropper,
	cilFactory,
	cilFactorySlash,
	cilFile,
	cilFolder,
	cilFolderOpen,
	cilFullscreen,
	cilFullscreenExit,
	cilGlobeAlt,
	cilGraph,
	cilGrid,
	cilGroup,
	cilHandPointUp,
	cilHome,
	cilInbox,
	cilIndentDecrease,
	cilIndentIncrease,
	cilIndustry,
	cilIndustrySlash,
	cilInputPower,
	cilInstitution,
	cilItalic,
	cilJustifyCenter,
	cilJustifyLeft,
	cilLaptop,
	cilLayers,
	cilLibrary,
	cilLibraryAdd,
	cilLightbulb,
	cilLink,
	cilLinkAlt,
	cilList,
	cilListFilter,
	cilListHighPriority,
	cilListLowPriority,
	cilListNumbered,
	cilListRich,
	cilLocationPin,
	cilLockLocked,
	cilMagnifyingGlass,
	cilMap,
	cilMediaSkipBackward,
	cilMoon,
	cilMoney,
	cilNewspaper,
	cilNoteAdd,
	cilNotes,
	cilOptions,
	cilPaperclip,
	cilPaperPlane,
	cilPenAlt,
	cilPencil,
	cilPenNib,
	cilPeople,
	cilPhone,
	cilPrint,
	cilPuzzle,
	cilRecycle,
	cilReload,
	cilReportSlash,
	cilRoom,
	cilRouter,
	cilSave,
	cilScrubber,
	cilSearch,
	cilSend,
	cilSettings,
	cilShare,
	cilShareAll,
	cilShareBoxed,
	cilShieldAlt,
	cilSignalCellular3,
	cilSitemap,
	cilSortAlphaDown,
	cilSortAlphaUp,
	cilSortAscending,
	cilSortDescending,
	cilSortNumericDown,
	cilSortNumericUp,
	cilSpeech,
	cilSpeedometer,
	cilSpreadsheet,
	cilStar,
	cilSun,
	cilSwapHorizontal,
	cilSwapVertical,
	cilTags,
	cilTask,
	cilToggleOff,
	cilToggleOn,
	cilTouchApp,
	cilTransfer,
	cilTrash,
	cilUnderline,
	cilUser,
	cilUserFemale,
	cilUserFollow,
	cilUserUnfollow,
	cilX,
	cilXCircle,
	cilVoiceOverRecord,
	cilWallet,
	cilWarning,
	cilWatch,
	cilWc,
} from '@coreui/icons'

export const cilIcons = Object.assign(
	{}, {
		cilActionUndo,
		cilAddressBook,
		cilAlignCenter,
		cilAlignLeft,
		cilAlignRight,
		cilApplicationsSettings,
		cilArrowBottom,
		cilArrowRight,
		cilArrowTop,
		cilAsterisk,
		cilBadge,
		cilBan,
		cilBarChart,
		cilBasket,
		cilBell,
		cilBike,
		cilBold,
		cilBook,
		cilBookmark,
		cilBriefcase,
		cilBug,
		cilCalculator,
		cilCalendar,
		cilCash,
		cilClipboard,
		cilClock,
		cilClone,
		cilCloudDownload,
		cilChart,
		cilChartLine,
		cilChartPie,
		cilChatBubble,
		cilCheck,
		cilCheckAlt,
		cilCheckCircle,
		cilChevronBottom,
		cilChevronLeft,
		cilChevronRight,
		cilChevronTop,
		cilCircle,
		cilCode,
		cilCog,
		cilCommentSquare,
		cilCompass,
		cilCompress,
		cilContact,
		cilCopy,
		cilCreditCard,
		cilCursor,
		cilCursorMove,
		cilCut,
		cilDescription,
		cilDevices,
		cilDrop,
		cilDollar,
		cilEnvelopeClosed,
		cilEnvelopeLetter,
		cilEnvelopeOpen,
		cilEuro,
		cilEyedropper,
		cilFactory,
		cilFactorySlash,
		cilFile,
		cilFolder,
		cilFolderOpen,
		cilFullscreen,
		cilFullscreenExit,
		cilGlobeAlt,
		cilGraph,
		cilGrid,
		cilGroup,
		cilHandPointUp,
		cilHome,
		cilInbox,
		cilIndentDecrease,
		cilIndentIncrease,
		cilIndustry,
		cilIndustrySlash,
		cilInputPower,
		cilInstitution,
		cilItalic,
		cilJustifyCenter,
		cilJustifyLeft,
		cilLaptop,
		cilLayers,
		cilLibrary,
		cilLibraryAdd,
		cilLightbulb,
		cilLink,
		cilLinkAlt,
		cilList,
		cilListFilter,
		cilListHighPriority,
		cilListLowPriority,
		cilListNumbered,
		cilListRich,
		cilLocationPin,
		cilLockLocked,
		cilMagnifyingGlass,
		cilMap,
		cilMediaSkipBackward,
		cilMoon,
		cilMoney,
		cilNewspaper,
		cilNoteAdd,
		cilNotes,
		cilOptions,
		cilPaperclip,
		cilPaperPlane,
		cilPenAlt,
		cilPencil,
		cilPenNib,
		cilPeople,
		cilPhone,
		cilPrint,
		cilPuzzle,
		cilRecycle,
		cilReload,
		cilReportSlash,
		cilRoom,
		cilRouter,
		cilSave,
		cilScrubber,
		cilSearch,
		cilSend,
		cilSettings,
		cilShare,
		cilShareAll,
		cilShareBoxed,
		cilShieldAlt,
		cilSignalCellular3,
		cilSitemap,
		cilSortAlphaDown,
		cilSortAlphaUp,
		cilSortAscending,
		cilSortDescending,
		cilSortNumericDown,
		cilSortNumericUp,
		cilSpeech,
		cilSpeedometer,
		cilSpreadsheet,
		cilStar,
		cilSun,
		cilSwapHorizontal,
		cilSwapVertical,
		cilTags,
		cilTask,
		cilToggleOff,
		cilToggleOn,
		cilTouchApp,
		cilTransfer,
		cilTrash,
		cilUnderline,
		cilUser,
		cilUserFemale,
		cilUserFollow,
		cilUserUnfollow,
		cilX,
		cilXCircle,
		cilVoiceOverRecord,
		cilWallet,
		cilWarning,
		cilWatch,
		cilWc,
	}
);
