import { combineReducers } from 'redux';

import { toIsoDateString } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { PartnerState } from 'app-model/enums';

import { masterListReducer, masterViewReducer, fluxListDefaults, fluxPickDefaults, fluxViewDefaults } from 'app-store/actionReducerCore';
import { IListComponentControlList, IViewComponentControlList } from 'app-framework/AppBaseInterfaces';


const stLists : IListComponentControlList = {
	InvstMgtAllocModelList: {
		...fluxListDefaults,
		actionNamespace: 'InvstMgtAllocModelList',
		entityName: 'InvstMgtAllocModel',
		fetchUriTemplate: 'InvstMgtAllocModel',
		orderBy: 'ModelName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['ModelCode', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			closureType: PartnerState.Active,
		},
	},
	InvstMgtAllocModelPick: {
		...fluxPickDefaults,
		actionNamespace: 'InvstMgtAllocModelPick',
		entityName: 'InvstMgtAllocModel',
		fetchUriTemplate: 'InvstMgtAllocModel',
		orderBy: 'ModelName asc',
		selectFields: [],
		expandProps: [],
		searchFields: ['ModelCode', 'ModelName'],
		filterValues: {
			searchText: '',
		},
	},

	InvstMgtFeeModelList: {
		...fluxListDefaults,
		actionNamespace: 'InvstMgtFeeModelList',
		entityName: 'InvstMgtFeeModel',
		fetchUriTemplate: 'InvstMgtFeeModel',
		orderBy: 'ModelName asc',
		selectFields: [],
		expandProps: ['ValueCommission($select=GroupName)', 'GrowthCommission($select=GroupName)'],
		searchFields: ['ModelCode', 'PartnerLabel'],
		filterValues: {
			searchText: '',
			closureType: PartnerState.Active,
		},
	},
	InvstMgtFeeModelPick: {
		...fluxPickDefaults,
		actionNamespace: 'InvstMgtFeeModelPick',
		entityName: 'InvstMgtFeeModel',
		fetchUriTemplate: 'InvstMgtFeeModel',
		orderBy: 'ModelName asc',
		selectFields: [],
		expandProps: ['ValueCommission($select=GroupName)', 'GrowthCommission($select=GroupName)'],
		searchFields: ['ModelCode', 'ModelName'],
		filterValues: {
			searchText: '',
		},
	},
};

function listReducer(state: IListComponentControlList = stLists, action: IFluxAction) : IListComponentControlList {
	return masterListReducer(state, action);
}


const stViews : IViewComponentControlList = {
	InvstMgtAllocModelView: {
		...fluxViewDefaults,
		actionNamespace: 'InvstMgtAllocModelView',
		entityName: 'InvstMgtAllocModel',
		fetchUriTemplate: 'InvstMgtAllocModel',
		selectFields: [],
		expandProps: [],
	},

	InvstMgtFeeModelView: {
		...fluxViewDefaults,
		actionNamespace: 'InvstMgtFeeModelView',
		entityName: 'InvstMgtFeeModel',
		fetchUriTemplate: 'InvstMgtFeeModel',
		selectFields: [],
		expandProps: [],
	},
};

function viewReducer(state: IViewComponentControlList = stViews, action: IFluxAction) : IViewComponentControlList {
	return masterViewReducer(state, action);
}


export default combineReducers({
	lists: listReducer,
	views: viewReducer,
});
