import { $isNull } from 'basicFunctions';
import { IFluxAction } from 'app-model/IFluxAction';
import { SysActions } from 'app-model/SysActions';
import { SecInstrumentState } from 'app-model/enums';
import { PartnerState } from 'app-model/enums';

import { fluxGlobalListDefaults, fluxViewDefaults } from './actionReducerCore';

const initialGlobalState: any = {
	defPageSize: 25,

	Global_MyUserView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_MyUserView',
		entityName: 'metaUser',
		fetchUriTemplate: 'MyProfile',
		selectFields: [],
		expandProps: [],
		entityData: { ApplicationList: [], RoleList: [] } //supply default values for enable tests succeed
	},
	Global_MyStaffView: {
		...fluxViewDefaults,
		actionNamespace: 'Global_MyStaffView',
		entityName: 'HRStaff',
		fetchUriTemplate: 'HRStaff/Me',
		selectFields: [],
		expandProps: [],
	},

	Global_DocumentTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_DocumentTypeList',
		entityName: 'DocumentType',
		fetchUriTemplate: 'DocumentType',
		queryUseRestEndpoint: true,
	},
	Global_Entity_CaseContextList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_Entity_CaseContextList',
		entityName: 'CaseContext',
		fetchUriTemplate: 'PartnerCase/GetCaseContextEntity()',
		orderBy: 'EntityName asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_Entity_DocumentableList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_Entity_DocumentableList',
		entityName: 'DocumentableEntity',
		fetchUriTemplate: 'DocumentType/GetDocumentableEntity()',
		orderBy: 'EntityName asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_Entity_WorkflowEnabledList: {
	},
	Global_EQInstrumentList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_EQInstrumentList',
		entityName: 'EQInstrument',
		fetchUriTemplate: `EQInstrument?ClosureType=${SecInstrumentState.Active}`,
		queryUseRestEndpoint: true,
	},
	Global_FAAssetGroupList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_FAAssetGroupList',
		entityName: 'FAAssetGroup',
		fetchUriTemplate: 'FAAssetGroup',
		orderBy: 'assetgroupname asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_FABasisList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_FABasisList',
		entityName: 'FABasis',
		fetchUriTemplate: 'FABasis',
		orderBy: 'BusinessStart desc',
		selectFields: [],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		filterExpressions: [],
	},
	Global_FundLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_FundLoanTypeList',
		entityName: 'FundLoanType',
		fetchUriTemplate: 'Fund/GetFundLoanTypeList()',
		orderBy: 'TypeCode asc',
		selectFields: [],
		expandProps: ['TypeFees'],
		filterExpressions: [],
	},
	Global_HRHeirarchyList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRHeirarchyList',
		entityName: 'HRHeirarchy',
		fetchUriTemplate: 'HRHeirarchy',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HRLeaveYearList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRLeaveYearList',
		entityName: 'HRLeaveYear',
		fetchUriTemplate: 'HRLeaveYear',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HROrganogramList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HROrganogramList',
		entityName: 'HROrganogram',
		fetchUriTemplate: 'HROrganogram',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HROrgDisciplineList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HROrgDisciplineList',
		entityName: 'HROrgDiscipline',
		fetchUriTemplate: 'HROrgDiscipline',
		orderBy: 'Code asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HROrgRoleList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HROrgRoleList',
		entityName: 'HROrgRole',
		fetchUriTemplate: 'HROrgRole',
		orderBy: 'Code asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HRPayItemList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRPayItemList',
		entityName: 'HRPayItem',
		fetchUriTemplate: 'HRPayItem',
		orderBy: 'ElementClass asc, TypeCode asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HRPayrollYearList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRPayrollYearList',
		entityName: 'HRPayrollYear',
		fetchUriTemplate: 'HRPayrollYear',
		orderBy: 'StartDate desc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_HRPerfCategoryList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRPerfCategoryList',
		entityName: 'HRPerfCategory',
		fetchUriTemplate: 'HRPerfCategory',
		queryUseRestEndpoint: true,
	},
	Global_HRStaffList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_HRStaffList',
		entityName: 'HRStaff',
		fetchUriTemplate: 'HRStaff',
		orderBy: 'StaffName asc',
		//selectFields: [],
		selectFields: ['Id', 'StaffNo', 'StaffName'],
		expandProps: [],
		filterExpressions: [
			`ClosureType eq '${PartnerState.Active}'`
		],
	},
	Global_LoanCollateralTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_LoanCollateralTypeList',
		entityName: 'LoanCollateralType',
		fetchUriTemplate: 'LoanCollateralType',
		queryUseRestEndpoint: true,
	},
	Global_NFBulkTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_NFBulkTypeList',
		entityName: 'NFBulkType',
		fetchUriTemplate: 'NFBulkType',
		queryUseRestEndpoint: true,
	},
	Global_NFTypeDefList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_NFTypeDefList',
		entityName: 'NFTypeDef',
		fetchUriTemplate: 'NFTypeDef',
		orderBy: 'Code asc, Description asc',
		//selectFields: ['Id', 'Code', 'Description', 'IsMassMail', 'DefDeliveryMethod', 'NeedsSubscription', 'IsScheduled', 'ScheduleType', 'ScheduleExecuteOn', 'ScheduleInterval', 'ClosureType', 'ClosureDate'],
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
	Global_OrgCompanyList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_OrgCompanyList',
		entityName: 'OrgCompany',
		fetchUriTemplate: 'OrgCompany',
		queryUseRestEndpoint: true,
	},
	Global_OrgLocationList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_OrgLocationList',
		entityName: 'OrgLocation',
		fetchUriTemplate: 'OrgLocation',
		queryUseRestEndpoint: true,
	},
	Global_OrgStoreList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_OrgStoreList',
		entityName: 'OrgStore',
		fetchUriTemplate: 'OrgStore',
		queryUseRestEndpoint: true,
	},
	Global_OrgUnitList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_OrgUnitList',
		entityName: 'OrgUnit',
		fetchUriTemplate: 'OrgUnit',
		queryUseRestEndpoint: true,
	},
	Global_PartnerSubClassList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_PartnerSubClassList',
		entityName: 'PartnerSubClass',
		fetchUriTemplate: 'PartnerSubClass',
		orderBy: 'OrgCompany/CoShortCode asc, ClassCode asc, Subclasscode asc',
		selectFields: ['Id', 'ClassCode', 'OrgCompanyId', 'SubClassCode', 'IsDefault', 'IsManagedGroup', 'IsExternallyManaged', 'DefaultCommissionSlice', 'HonorThirdPartyPayments', 'RoleFilterUser', 'ClosureType'],
		expandProps: ['OrgCompany($select=CoShortCode,CoName)'],
		filterExpressions: [],
	},
	Global_RefCentralBankList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefCentralBankList',
		entityName: 'RefCentralBank',
		fetchUriTemplate: 'RefCentralBank',
		queryUseRestEndpoint: true,
	},
	Global_RefCountryList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefCountryList',
		entityName: 'RefCountry',
		fetchUriTemplate: 'RefCountry',
		queryUseRestEndpoint: true,
	},
	Global_RefExchangeDefList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_RefExchangeDefList',
		entityName: 'RefExchangeDef',
		fetchUriTemplate: 'RefExchangeDef',
		queryUseRestEndpoint: true,
	},
	Global_ResCompanyList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_ResCompanyList',
		entityName: 'ResCompany',
		fetchUriTemplate: 'ResCompany',
		orderBy: 'coname asc',
		selectFields: ['Id', 'CoCode', 'CoName', 'CoType', 'RegCountry', 'RegNo', 'RegDate', 'ClosureType'],
		expandProps: [],
		filterExpressions: [],
	},
	Global_ScItemSaleClassList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_ScItemSaleClassList',
		entityName: 'ScItemSaleClass',
		fetchUriTemplate: 'ScItemSaleClass',
		queryUseRestEndpoint: true,
	},	
	Global_ScMeasureUnitClassList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_ScMeasureUnitClassList',
		entityName: 'ScMeasureUnitClass',
		fetchUriTemplate: 'ScMeasureUnitClass',
		queryUseRestEndpoint: true,
	},	
	Global_TermInvestTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TermInvestTypeList',
		entityName: 'TermInvestType',
		fetchUriTemplate: 'TermInvestType',
		queryUseRestEndpoint: true,
	},
	Global_TermLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TermLoanTypeList',
		entityName: 'TermLoanType',
		fetchUriTemplate: 'TermLoanType',
		queryUseRestEndpoint: true,
	},
	Global_TRDiscLoanTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TRDiscLoanTypeList',
		entityName: 'TRDiscLoanType',
		fetchUriTemplate: 'TRDiscLoanType',
		queryUseRestEndpoint: true,
	},
	Global_TRTBillTypeList: {
		...fluxGlobalListDefaults,
		actionNamespace: 'Global_TRTBillTypeList',
		entityName: 'TRTBillType',
		fetchUriTemplate: 'TRTBillType',
		orderBy: 'TypeCode asc',
		selectFields: [],
		expandProps: [],
		filterExpressions: [],
	},
};

// globals to load and manage
export function globalsReducer(state: any = initialGlobalState, action: IFluxAction) {
	//console.log('globalsReducer called');

	const actionTypeParts = action.type.split('/');

	if (actionTypeParts.length == 2) {
		if (!$isNull(initialGlobalState[actionTypeParts[0]])) {
			switch (actionTypeParts[1]) {
				case SysActions.VIEW_FETCHDATA:
					return {
						...state,

						[action.payload.viewName]: {
							...state[action.payload.viewName],
							isLoading: true,
						},
					};

				case SysActions.VIEW_RECEIVEDATA:
					return {
						...state,

						[action.payload.viewName]: {
							...state[action.payload.viewName],

							fetched: !action.payload.data.error,
							isLoading: false,

							...action.payload.data,
							
							entityData: actionTypeParts[0] != 'Global_MyUserView'
								? action.payload.data.entityData
								: {
									...action.payload.data.entityData,

									RoleList: String((action.payload.data.entityData && action.payload.data.entityData.Roles) || '').split(','),
									ApplicationList: String((action.payload.data.entityData && action.payload.data.entityData.Applications) || '').split(','),
								}
						},
					};

				case SysActions.LIST_FETCHDATA:
					return {
						...state,

						[action.payload.listName]: {
							...state[action.payload.listName],
							isLoading: true,
						},
					};

				case SysActions.LIST_RECEIVEDATA:
					return {
						...state,

						[action.payload.listName]: {
							...state[action.payload.listName],
							fetched: !action.payload.data.error,
							isLoading: false,
							error: action.payload.data.error,
							totalCount: action.payload.data.totalCount,
							listData: action.payload.data.listData,
						},
					};

				default:
					return state;
			}
		}
	}

	return state;
};
